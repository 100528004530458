import { createLogic } from 'redux-logic';
import { push } from 'connected-react-router';

import { LOGIN, GET_CURRENT_USER, LOGOUT, ACCOUNT } from '../actions/types';
import AuthService from '../services/AuthService';

export const login = createLogic({
  type: LOGIN.START,

  process({ httpClient, action }, dispatch, done) {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', 'random');
    formData.append('client_secret', 'secret');
    formData.append('username', action.payload.email);
    formData.append('password', action.payload.password);

    return httpClient
      .post('oauth/v2/token', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data)
      .then((data) => {
        AuthService.saveAuth(data);
        dispatch({ type: LOGIN.SUCCESS, payload: data });
        dispatch({ type: GET_CURRENT_USER.START });
        dispatch(push('/'));
      })
      .catch((err) => {
        if (!err.response || !err.response.data.error_description) {
          const err_desc = {
            data: {
              error_description: 'Internal server error',
            },
          };

          return dispatch({ type: LOGIN.ERROR, payload: err_desc });
        }

        return dispatch({ type: LOGIN.ERROR, payload: err.response });
      })
      .then(() => done());
  },
});

export const logout = createLogic({
  type: LOGOUT.START,

  process(data, dispatch, done) {
    AuthService.logout();
    dispatch(push('/login'));
    dispatch({ type: LOGOUT.END });
    dispatch({ type: LOGIN.CANCEL_CURRENT });
    dispatch({ type: 'STATE_RESET' });
    done();
  },
});

export const getMe = createLogic({
  type: GET_CURRENT_USER.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/me')
      .then((res) => res.data)
      .then((userData) => {
        dispatch({
          type: GET_CURRENT_USER.SUCCESS,
          payload: userData,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CURRENT_USER.ERROR,
          payload: err.response,
          isError: true,
        });
      })
      .then(done);
  },
});

export const setNewPassword = createLogic({
  type: ACCOUNT.SETNEWPASSWORD.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .put('/api/v1/users/resetpassword', action.payload)
      .then(() => {
        dispatch({
          type: ACCOUNT.SETNEWPASSWORD.SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACCOUNT.SETNEWPASSWORD.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const confirmEmail = createLogic({
  type: ACCOUNT.ACTIVATE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .post('/api/v1/users/activate', {
        confirmationToken: action.payload,
      })
      .then(() => {
        dispatch({
          type: ACCOUNT.ACTIVATE.SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACCOUNT.ACTIVATE.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const forgotPassword = createLogic({
  type: ACCOUNT.RESET.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .post('/api/v1/users/resetpassword', { email: action.payload })
      .then(() => {
        dispatch({
          type: ACCOUNT.RESET.SUCCESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: ACCOUNT.RESET.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  login,
  logout,
  getMe,
  setNewPassword,
  confirmEmail,
  forgotPassword,
};
