import React from 'react';

import Reports from './Reports';

import { WINNINGS_REPORTS_COLUMNS_NAMES } from '../../../utils/reports/consts';

class WinningsReport extends React.Component {
  render() {
    return <Reports reportsType="winnings" tableColumns={WINNINGS_REPORTS_COLUMNS_NAMES} />;
  }
}

export default WinningsReport;
