import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Page } from 'tabler-react';
import { connect } from 'react-redux';

import TopBar from '../../Dashboard/TopBar';
import LineChart from '../../Reusable/LineChart';
import BarChart from '../../Reusable/BarChart';
import Spinner from '../../Reusable/Spinner/Spinner';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import { getDashboardData } from '../../../actions/dashboardActions';

import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';
import { setProperMoneyValue } from '../../../utils/money';

const Label = styled('p')`
  margin-top: 0.5rem;
  margin-left: 2rem;
  color: #495057;
`;

const ChartContainer = styled('div')`
  background-color: #fff;
  padding: 10px;
  padding-right: 30px;
  margin-bottom: 25px;
  width: 100%;
`;

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.getDashboardData();
  }

  renderContent(dashboard) {
    const { charts, summary } = dashboard;
    const {
      newPlayersCurrentMonth,
      newPlayersLastMonth,
      playersTotal,
      ggrCurrentMonth,
      ggrLastMonth,
      ggrTotal,
      sellingLastMonth,
      sellingCurrentMonth,
      sellingTotal,
    } = summary;
    const { betLinesPerDay, newPlayersPerDay, betsPerLottery } = charts;
    const ggr = {
      currentMonth: setProperMoneyValue(ggrCurrentMonth),
      lastMonth: setProperMoneyValue(ggrLastMonth),
      total: setProperMoneyValue(ggrTotal),
    };
    const players = {
      currentMonth: parseInt(newPlayersCurrentMonth, 10),
      lastMonth: parseInt(newPlayersLastMonth, 10),
      total: parseInt(playersTotal, 10),
    };
    const sales = {
      currentMonth: setProperMoneyValue(sellingCurrentMonth),
      lastMonth: setProperMoneyValue(sellingLastMonth),
      total: setProperMoneyValue(sellingTotal),
    };

    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin]} forbiddenRedirect={'/'}>
        <TopBar ggr={ggr} players={players} balance={parseInt(sellingTotal, 10)} sales={sales} />
        <div className="row">
          <div className="col-lg-6">
            <ChartContainer>
              <Label>Number of lines per day</Label>
              <LineChart data={betLinesPerDay} dataKey={'count'} xAxisDataKey={'date'} />
            </ChartContainer>
          </div>
          <div className="col-lg-6">
            <ChartContainer>
              <Label>New registrations per day</Label>
              <LineChart data={newPlayersPerDay} dataKey={'count'} xAxisDataKey={'date'} />
            </ChartContainer>
          </div>
          <div className="col-lg-12">
            <ChartContainer>
              <Label>Number of bets per lottery from the last 30 days</Label>
              <BarChart data={betsPerLottery} dataKey={'count'} xAxisDataKey={'lottery'} />
            </ChartContainer>
          </div>
        </div>
      </AccessControl>
    );
  }

  render() {
    const { dashboard, dashboardPending, dashboardError } = this.props;

    if (dashboardPending) {
      return <Spinner class="mb-5" />;
    }

    if (dashboardError) {
      return <ErrorHandler error={dashboardError} />;
    }

    return (
      <div className="my-4">
        <Page.Header>
          <Page.Title>
            <span>Dashboard</span>
          </Page.Title>
        </Page.Header>
        {dashboard && this.renderContent(dashboard)}
      </div>
    );
  }
}

Dashboard.propTypes = {
  getDashboardData: PropTypes.func,
  dashboard: PropTypes.object,
  dashboardError: PropTypes.object,
  dashboardPending: PropTypes.bool,
};

export default connect(
  (state) => ({
    dashboard: state.dashboard.data,
    dashboardError: state.dashboard.error,
    dashboardPending: state.dashboard.isPending,
  }),
  {
    getDashboardData,
  }
)(Dashboard);
