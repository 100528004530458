import React from 'react';

import Reports from './Reports';

import { TRANSACTIONS_REPORTS_COLUMNS_NAMEs } from '../../../utils/reports/consts';

class TransactionsReport extends React.Component {
  render() {
    return <Reports reportsType="transactions" tableColumns={TRANSACTIONS_REPORTS_COLUMNS_NAMEs} />;
  }
}

export default TransactionsReport;
