import { createLogic } from 'redux-logic';

import { SYSTEM_CONSTS } from '../actions/types';

export const getAllowedCurrencies = createLogic({
  type: SYSTEM_CONSTS.CURRENCIES.GET.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/system-consts/allowed-currencies')
      .then((res) => {
        dispatch({
          type: SYSTEM_CONSTS.CURRENCIES.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SYSTEM_CONSTS.CURRENCIES.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const getGameResultAgencies = createLogic({
  type: SYSTEM_CONSTS.GAME_RESULT_AGENCIES.GET.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/system-consts/game-result-agencies')
      .then((res) => {
        dispatch({
          type: SYSTEM_CONSTS.GAME_RESULT_AGENCIES.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SYSTEM_CONSTS.GAME_RESULT_AGENCIES.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const getProviderDrawStatuses = createLogic({
  type: SYSTEM_CONSTS.PROVIDER_DRAW_STATUSES.GET.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/system-consts/provider-draw-statuses')
      .then((res) => {
        dispatch({
          type: SYSTEM_CONSTS.PROVIDER_DRAW_STATUSES.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SYSTEM_CONSTS.PROVIDER_DRAW_STATUSES.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const getAvailableWinningSelections = createLogic({
  type: SYSTEM_CONSTS.AVAILABLE_WINNING_SELECTIONS.GET.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/system-consts/available-winning-selections')
      .then((res) => {
        dispatch({
          type: SYSTEM_CONSTS.AVAILABLE_WINNING_SELECTIONS.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SYSTEM_CONSTS.AVAILABLE_WINNING_SELECTIONS.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const getPrizesTypes = createLogic({
  type: SYSTEM_CONSTS.PRIZE_TYPES.GET.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/system-consts/prize-types')
      .then((res) => {
        dispatch({
          type: SYSTEM_CONSTS.PRIZE_TYPES.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SYSTEM_CONSTS.PRIZE_TYPES.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export default {
  getAllowedCurrencies,
  getGameResultAgencies,
  getProviderDrawStatuses,
  getAvailableWinningSelections,
  getPrizesTypes,
};
