import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Formik } from 'formik';
import { Button, Card } from 'tabler-react';

import AccessControl from '../AccessControl';
import { userRoles } from '../../utils/roles';
import { ErrorHandler } from '../Reusable/ErrorHandler';
import httpClient from '../../services/httpClient';
import roles from '../../utils/roles';
import Spinner from '../Reusable/Spinner/Spinner';
import { SearchBrand, Field, CheckboxField } from '../Reusable/FormFields';

class AddUser extends React.Component {
  constructor() {
    super();

    this.state = {
      submitUserSuccess: false,
      submitUserError: null,
    };
  }

  validateUser(values) {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    if (!values.phone) {
      errors.phone = 'Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }

  submitUser = (values, actions) => {
    const { userId } = this.props;

    this.setState({
      submitUserSuccess: false,
      submitUserError: null,
    });

    const newUserValue = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      brands: values.brands ? values.brands : [],
      roles: values.roles,
      active: values.active,
    };

    actions.setSubmitting(true);

    if (userId) {
      return httpClient
        .put(`/api/v1/users/${userId}`, newUserValue)
        .then(() => {
          this.setState(
            {
              submitUserSuccess: true,
              submitUserError: null,
            },
            () => {
              this.props.getBackendUser(userId);
            }
          );
        })
        .catch((err) => {
          actions.setSubmitting(false);
          this.setState({
            submitUserError: err.response,
          });
        });
    }

    return httpClient
      .post('/api/v1/users', newUserValue)
      .then(() => {
        actions.resetForm();
        this.setState({
          submitUserSuccess: true,
          submitUserError: false,
        });
      })
      .catch((err) => {
        actions.setSubmitting(false);
        this.setState({
          submitUserSuccess: false,
          submitUserError: err.response,
        });
      });
  };

  renderForm() {
    const { submitUserError, submitUserSuccess } = this.state;
    const { user, isPending, userId, error } = this.props;

    if (isPending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    if (error) {
      return (
        <div className="mt-3 mr-3 ml-3">
          <ErrorHandler error={error} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Card.Title>
            {user && `User: ${user.firstName}`}
            {!user && 'Add new user'}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {submitUserSuccess && user && <div className="alert alert-success">User edited successfully</div>}
          {submitUserSuccess && !user && <div className="alert alert-success">User added successfully</div>}
          {submitUserError && <ErrorHandler error={submitUserError} />}
          <Formik
            initialValues={{
              firstName: userId && user ? user.firstName : '',
              lastName: userId && user ? user.lastName : '',
              phone: userId && user ? user.phone : '',
              brands: userId && user ? user.brands : [],
              email: userId && user ? user.email : '',
              roles: userId && user ? (user.roles ? user.roles : []) : [],
              active: userId && user ? user.active : false,
            }}
            validate={(values) => this.validateUser(values)}
            onSubmit={(values, actions) => this.submitUser(values, actions)}
            render={({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, setFieldTouched }) => (
              <form className="add-brand-form" onSubmit={handleSubmit}>
                <Field
                  label="First name"
                  type="text"
                  name="firstName"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('firstName', true)}
                  value={values.firstName}
                  disabled={false}
                  touched={touched.firstName}
                  error={errors.firstName}
                />
                <Field
                  label="Last name"
                  type="text"
                  name="lastName"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('lastName', true)}
                  value={values.lastName}
                  disabled={false}
                  touched={touched.lastName}
                  error={errors.lastName}
                />
                <Field
                  label="Email"
                  type="text"
                  name="email"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('email', true)}
                  value={values.email}
                  disabled={false}
                  touched={touched.email}
                  error={errors.email}
                />
                <Field
                  label="Phone number"
                  type="text"
                  name="phone"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('phone', true)}
                  value={values.phone}
                  disabled={false}
                  touched={touched.phone}
                  error={errors.phone}
                />
                <div className="form-group">
                  <SearchBrand
                    value={values.brands}
                    setFieldValue={setFieldValue}
                    className="react-select"
                    labelText="User's brands"
                    multi
                  />
                </div>
                <CheckboxField
                  label="Active"
                  name="active"
                  value={values.active}
                  onChange={setFieldValue}
                  disabled={false}
                />
                <AccessControl roles={[userRoles.superAdmin]}>
                  <div className="form-group">
                    <label>Roles</label>
                    <div>
                      <FieldArray
                        name="roles"
                        render={(arrayHelpers) => (
                          <React.Fragment>
                            {roles.map((role, key) => (
                              <label className="pr-3" key={key}>
                                <CheckboxField
                                  label={role.name}
                                  name={role.id}
                                  value={values.roles.includes(role.id)}
                                  onChange={(name, checked) => {
                                    if (checked) {
                                      arrayHelpers.push(role.id);
                                    } else {
                                      const id = values.roles.indexOf(role.id);
                                      arrayHelpers.remove(id);
                                    }
                                  }}
                                  disabled={false}
                                />
                              </label>
                            ))}
                          </React.Fragment>
                        )}
                      />
                    </div>
                  </div>
                </AccessControl>
                <div className="d-flex justify-content-between align-items-center">
                  <Button loading={isSubmitting} type="submit" color="primary">
                    Send
                  </Button>
                </div>
              </form>
            )}
          />
        </Card.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        {this.renderForm()}
      </Card>
    );
  }
}

AddUser.propTypes = {
  getBackendUsers: PropTypes.func,
  userId: PropTypes.string,
  getBackendUser: PropTypes.func,
  user: PropTypes.object,
  isPending: PropTypes.bool,
  error: PropTypes.object,
};

export default AddUser;
