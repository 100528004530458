import { LOTTERY_SCHEDULE_POST } from '../../actions/types';

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOTTERY_SCHEDULE_POST.START:
      return {
        ...state,
        isLoading: true,
      };
    case LOTTERY_SCHEDULE_POST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case LOTTERY_SCHEDULE_POST.SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        data: action.payload,
      };
    default:
      return state;
  }
};
