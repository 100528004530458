import moment from 'moment';

export default function formatDate(date, type, period = 'day') {
  const dateClone = date.clone();

  if (period === 'year' && type === 'to') {
    dateClone.add(1, 'years');
  }

  if (period === 'month' && type === 'to') {
    dateClone.add(1, 'months').subtract(1, 'days');
  }

  if (date.hours() === 0 && type === 'to') {
    dateClone.add({ hours: 23, minutes: 59 });
  }

  if (period === 'Day') {
    if (type === 'to') {
      dateClone.endOf('day');
    }

    if (type === 'from') {
      dateClone.startOf('day');
    }
  }

  return moment(dateClone).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
}
