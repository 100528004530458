import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Page, Card } from 'tabler-react';

import LabeledValue from '../../Reusable/LabeledValue';
import CheckboxField from '../../Reusable/FormFields/CheckboxField';
import Spinner from '../../Reusable/Spinner';

import { getPlayerStats, clearPlayerStats } from '../../../actions/playerActions';
import { getSinglePlayer, putPlayer } from '../../../actions/players';

import { isPlayerTested } from '../../../selectors/players';
import { setProperMoneyValue } from '../../../utils/money';

class Player extends React.Component {
  componentDidMount() {
    const { getPlayerStats, getSinglePlayer } = this.props;
    const id = this.getID();

    getPlayerStats(id);
    getSinglePlayer(id);
  }

  componentDidUpdate(prevProps) {
    const id = this.getID();

    if (prevProps.match.params.id !== id) {
      this.props.getPlayerStats(id);
    }
  }

  componentWillUnmount() {
    this.props.clearPlayerStats();
  }

  getID() {
    return this.props.match.params.id;
  }

  getPlayerSource(type) {
    const length = type.length - 6;
    const final = type.substring(0, length);
    return final[0].toUpperCase() + final.slice(1);
  }

  renderContent() {
    const { data, isTestingPlayer, isPending } = this.props;

    if (!data && isPending) {
      return <Spinner />;
    }

    if (!data) {
      return null;
    }

    return (
      <Page>
        <Page.Title>
          <p>Player {data.playerName}</p>
        </Page.Title>
        <Grid.Row>
          <Grid.Col md={6}>
            <Card>
              <Card.Status color="blue" />
              <Card.Body>
                <LabeledValue label="Player Name" value={data.playerName} />
                <LabeledValue label="Player ID" value={data.playerId} />
                <LabeledValue label="Source" value={this.getPlayerSource(data.playerType)} />
                <CheckboxField
                  name="testedPlayer"
                  label="Test player"
                  value={isTestingPlayer}
                  onChange={(name, value) => this.props.putPlayer(this.getID(), value)}
                />
              </Card.Body>
            </Card>
          </Grid.Col>

          <Grid.Col md={6}>
            <Card>
              <Card.Status color="blue" />
              <Card.Body>
                <LabeledValue label="Total spent" value={setProperMoneyValue(data.totalCorrectTicketsCost)} />
                <LabeledValue label="Total paid out" value={setProperMoneyValue(data.totalWinning)} />
                <LabeledValue label="Nr. of bets" value={data.totalNumberOfCorrectTickets.toString()} />
                <LabeledValue label="Nr. of lines" value={data.totalNumberOfCorrectLines.toString()} />
                <LabeledValue label="Most popular" value={data.mostPlayedGame ? data.mostPlayedGame.name : ''} />
                <LabeledValue label="Avg. bet value" value={setProperMoneyValue(data.avgTicketCost)} />
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: '30px' }}>{this.renderContent()}</div>
      </React.Fragment>
    );
  }
}

Player.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.id,
    }),
  }),
  data: PropTypes.object,
  isTestingPlayer: PropTypes.bool,
  isPending: PropTypes.bool,

  getPlayerStats: PropTypes.func.isRequired,
  getSinglePlayer: PropTypes.func.isRequired,
  clearPlayerStats: PropTypes.func.isRequired,
  putPlayer: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isPending: state.player.isPending,
    error: state.player.error,
    data: state.player.data,
    isTestingPlayer: isPlayerTested(state),
  }),
  {
    getPlayerStats,
    getSinglePlayer,
    clearPlayerStats,
    putPlayer,
  }
)(Player);
