import React from 'react';
import { Page, Grid, Button } from 'tabler-react';
import Select from 'react-select';
import propTypes from 'prop-types';

import CustomTable from '../../Reusable/Table/CustomTable';
import CustomModal from '../../Reusable/Modal/CustomModal';

import { getData, getColumns, getNewItem } from '../../../utils/brandUtils';

class BrandAdditionalData extends React.Component {
  state = {
    selectedCurrency: null,
    selectedType: null,
    isDeleteModalOpen: false,
    deleteId: null,
    data: [],
  };

  componentDidMount() {
    this.getDesiredData();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (data.isSuccess !== prevProps.data.isSuccess && data.isSuccess) {
      this.getDesiredData();
    }
  }

  getDesiredData() {
    const { data, brandName, brandId, parentBrandId, type } = this.props;

    if (!data) {
      return;
    }

    const desiredData = getData(
      data.data,
      {
        brandName,
        brandId,
        parentBrandId,
        handleInputChange: this.handleInputChange,
      },
      type
    );

    this.setState({
      data: desiredData,
    });
  }

  getCurrencies() {
    const { currencies } = this.props;
    const { data } = this.state;

    if (!currencies.data || currencies.error || !data) {
      return [];
    }

    return currencies.data
      .filter((elem) => {
        const isSelected = data.some((rate) => rate.exchangeCurrency === elem);

        return !isSelected;
      })
      .map((elem) => ({ label: elem, value: elem }));
  }

  deleteRecord(id) {
    const { data } = this.state;
    const newData = [...data].filter((elem) => elem.id !== id);

    this.setState({ data: newData });
  }

  handleCurrencyChange = (value) => {
    this.setState({
      selectedCurrency: value,
    });
  };

  handleTaxTypeChange = (value) => {
    this.setState({
      selectedType: value,
    });
  };

  handleInputChange = (id, value, name) => {
    if (name === 'percentageValue' && (value > 100 || value < 0)) {
      return;
    }

    const newData = [...this.state.data];
    const singleRecord = newData.find((elem) => elem.id === id);
    singleRecord[name].value = value;

    this.setState({ data: newData });
  };

  handleToggleModal = (id) => {
    this.setState((prevState) => ({
      isDeleteModalOpen: !prevState.isDeleteModalOpen,
      deleteId: id,
    }));
  };

  handleDelete = () => {
    const { data, deleteItem, brandId } = this.props;
    const { deleteId } = this.state;

    const fromAPI = data.data.some((elem) => elem.id === deleteId);

    if (fromAPI) {
      deleteItem(deleteId, brandId);

      return this.setState({
        isDeleteModalOpen: false,
        deleteId: null,
      });
    }

    this.deleteRecord(deleteId);
    this.setState({
      isDeleteModalOpen: false,
      deleteId: null,
    });
  };

  handleAddNewRateClick = () => {
    const { brandName, brandId, type } = this.props;
    const { selectedCurrency, selectedType, data } = this.state;

    const newData = [...data];

    const newRate = getNewItem(type, {
      brandName,
      brandId,
      selectedCurrency,
      selectedType,
      handleInputChange: this.handleInputChange,
    });

    newData.push(newRate);

    this.setState({ data: newData });
  };

  handleSubmitChanges = () => {
    const { data } = this.state;
    const { putData, brandId } = this.props;

    putData(data, brandId);
  };

  renderContent() {
    const { data } = this.state;
    const { type } = this.props;

    if (!data) {
      return <div className="alert alert-primary">There is no data</div>;
    }

    return (
      <CustomTable
        columns={getColumns(type, { handleToggleModal: this.handleToggleModal })}
        data={data}
        allowedPagination={false}
      />
    );
  }

  render() {
    const { type, displayName } = this.props;
    const { selectedCurrency, selectedType, isDeleteModalOpen } = this.state;

    return (
      <Page>
        <Page.Header>
          <Page.Title>{`Brand ${displayName}`}</Page.Title>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={12}>{this.renderContent()}</Grid.Col>
          <Grid.Col md={6} offset={6}>
            <div className="d-flex w-100 align-items-center">
              <div className="w-100 d-flex align-items-center">
                <Select
                  name="currency"
                  options={this.getCurrencies()}
                  className="react-select form-group w-75 mb-0 mr-2 "
                  onChange={(option) => this.handleCurrencyChange(option)}
                  value={selectedCurrency}
                  placeholder={'Choose currency'}
                  required
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
                {type === 'rates' ? <span>-&gt; EUR</span> : null}
                {type === 'taxes' ? (
                  <Select
                    name="taxType"
                    options={[
                      { label: 'Percentage', value: 'percentage' },
                      { label: 'Exact', value: 'exact' },
                    ]}
                    className="react-select form-group w-75 mb-0 mr-2 "
                    onChange={(option) => this.handleTaxTypeChange(option)}
                    value={selectedType}
                    placeholder={'Choose tax type'}
                    required
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                  />
                ) : null}
              </div>
              <div className="d-flex">
                <Button
                  className="mx-2"
                  disabled={!selectedCurrency || (type === 'taxes' && !selectedType)}
                  onClick={this.handleAddNewRateClick}
                  color={'primary'}
                >
                  Add
                </Button>
                <Button className="mx-2" onClick={this.handleSubmitChanges} color={'success'}>
                  Submit Changes
                </Button>
              </div>
            </div>
          </Grid.Col>
        </Grid.Row>
        <CustomModal
          isOpen={isDeleteModalOpen}
          toggleModal={this.handleToggleModal}
          handleSubmit={this.handleDelete}
          successMessage="Item has been deleted"
          title="Are you sure you want to delete this?"
          classname="react-modal-custom__delete"
          statusColor="red"
          btnConfirmText="Delete"
          icon="trash"
        />
      </Page>
    );
  }
}

BrandAdditionalData.propTypes = {
  brandName: propTypes.string,
  brandId: propTypes.string,
  displayName: propTypes.string,
  parentBrandId: propTypes.string,
  currencies: propTypes.object,
  data: propTypes.object,
  type: propTypes.string,

  putData: propTypes.func,
  deleteItem: propTypes.func,
};

export default BrandAdditionalData;
