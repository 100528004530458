import React from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'tabler-react';
import propTypes from 'prop-types';
import Modal from 'react-modal';

import { Field } from '../Reusable/FormFields';
import ModalConnector from '../Reusable/Modal/ModalConnector';
import httpClient from '../../services/httpClient';

import {
  changeSearchBetId,
  changeSearchLotteryId,
  changeSearchBetGlobalIntId,
  resetState,
} from '../../actions/searchActions';

import { UUID_LENGTH } from '../../utils/constans';
import names from './names';

class SearchModal extends React.Component {
  state = {
    lotteryId: '',
    betId: '',
    betGlobalIntId: '',
    lotteryIdError: '',
    betIdError: '',
    betGlobalIntIdError: '',
  };

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    const { resetState } = this.props;

    resetState();
  };

  resetLocalState = () => {
    this.setState({
      lotteryId: '',
      betId: '',
      betGlobalIntId: '',
      lotteryIdError: '',
      betIdError: '',
      betGlobalIntIdError: '',
    });
  };

  handleModalClose = (modalClose) => {
    this.resetLocalState();
    this.resetState();
    modalClose();
  };

  handleClick = (routerData, onClose) => {
    const { changeSearchBetId, changeSearchLotteryId, changeSearchBetGlobalIntId } = this.props;
    const { lotteryId, betId, betGlobalIntId } = this.state;

    if (lotteryId.length === UUID_LENGTH) {
      changeSearchLotteryId(lotteryId);
      routerData.push(`/lottery/lotteries/${lotteryId}`);

      this.handleModalClose(onClose);
    } else {
      this.setState({
        lotteryIdError: !this.getIsProperId(lotteryId) ? 'An invalid ID was given' : '',
      });
    }

    if (betId.length === UUID_LENGTH) {
      changeSearchBetId(betId);
      routerData.push(`/draws/bet/${betId}`);

      this.handleModalClose(onClose);
    } else {
      this.setState({
        betIdError: !this.getIsProperId(betId) ? 'An invalid ID was given' : '',
      });
    }

    if (betGlobalIntId.length) {
      changeSearchBetGlobalIntId(betGlobalIntId);
      httpClient
        .get(`/api/v1/bets-by-gintid/${betGlobalIntId}`)
        .then((res) => {
          let betId = res.data && res.data.id ? res.data.id : '';
          if (betId) {
            routerData.push(`/draws/bet/${betId}`);
            this.handleModalClose(onClose);
          } else {
            this.setState({
              betGlobalIntIdError: 'No bet found with this Ticket ID',
            });
          }
        })
        .catch((err) => {
          this.setState({
            betGlobalIntIdError: 'An invalid ID was given',
          });
        })
        .finally(() => {});
    }
  };

  getIsProperId(id) {
    return id.length === UUID_LENGTH || id.length === 0;
  }

  render() {
    const { lotteryId, betId, betGlobalIntId, lotteryIdError, betIdError, betGlobalIntIdError } = this.state;

    return (
      <ModalConnector name={names.SEARCH}>
        {({ isOpen, data, onClose }) => {
          return (
            <Modal
              isOpen={isOpen}
              onRequestClose={() => this.handleModalClose(onClose)}
              className={'react-modal-custom h-auto'}
            >
              <Card>
                <Card.Status color={'blue'} />
                <Card.Header>
                  <Card.Title>
                    <p className="m-0">Search for Lottery or Bet by ID</p>
                    <Button className="react-modal-close" color="danger" onClick={() => this.handleModalClose(onClose)}>
                      X
                    </Button>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex flex-column justify-content-start">
                    <div>
                      <Field
                        name="lotteryId"
                        value={lotteryId}
                        label="Search Lottery by ID"
                        onChange={(name, value) => this.setState({ lotteryId: value })}
                        error={lotteryIdError}
                        touched
                      />
                      <Field
                        name="betId"
                        value={betId}
                        label="Search Bet by ID"
                        onChange={(name, value) => this.setState({ betId: value })}
                        error={betIdError}
                        touched
                      />
                      <Field
                        name="betGlobalIntId"
                        value={betGlobalIntId}
                        label="Search Bet by Retail Ticket ID"
                        onChange={(name, value) => this.setState({ betGlobalIntId: value, betGlobalIntIdError: '' })}
                        error={betGlobalIntIdError}
                        touched
                      />
                    </div>
                    <div>
                      <Button className="mx-2" color="danger" onClick={() => this.handleModalClose(onClose)}>
                        Close
                      </Button>
                      <Button className="mx-2" color="primary" onClick={() => this.handleClick(data, onClose)}>
                        Search
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Modal>
          );
        }}
      </ModalConnector>
    );
  }
}

SearchModal.propTypes = {
  changeSearchLotteryId: propTypes.func,
  changeSearchBetId: propTypes.func,
  changeSearchBetGlobalIntId: propTypes.func,
  resetState: propTypes.func,
};

export default connect(null, {
  changeSearchLotteryId,
  changeSearchBetId,
  changeSearchBetGlobalIntId,
  resetState,
})(SearchModal);
