import { actionTypesCreator } from '../utils/actionsTypesCreator/actionTypesCreator';

export const PRICES = {
  SET: 'PRICES_SET',
  EDIT_START: 'PRICES_EDIT_START',
  CLEAR: 'PRICES_CLEAR',
  CHANGE_PRICE_TYPE_START: 'PRICES_CHANGE_PRICE_TYPE_START',
  CHANGE_PRICE_TYPE: 'PRICES_CHANGE_PRICE_TYPE',
  CHANGE_CURRENCY_START: 'PRICES_CHANGE_CURRENCY_START',
  CHANGE_CURRENCY: 'PRICES_CHANGE_CURRENCY',
  PREPARE_EDIT: 'PRICES_PREPARE_EDIT',
};

export const CURRENCY = {
  SET: {
    START: 'CURRENCY_SET_START',
    SUCCESS: 'CURRENCY_SET_SUCCESS',
    ALL: 'CURRENCY_SET_ALL',
  },
  EDIT: {
    START: 'CURRENCY_EDIT_START',
  },
  CHANGE: {
    ADD: 'CURRENCY_CHANGE_ADD',
    REMOVE: 'CURRENCY_CHANGE_REMOVE',
  },
  CHANGE_BASE: 'CURRENCY_CHANGE_BASE',
  RESET: 'CURRENCY_RESET',
};

export const BRANDS = {
  FETCH: 'BRANDS_FETCH',
  SUCCESS: 'BRANDS_SUCCESS',
  ERROR: 'BRANDS_ERROR',
  CHANGE_ENTRIES: 'BANDS_CHANGE_ENTRIES',
  CHANGE_SEARCH_TERM: 'BRANDS_CHANGE_SEARCH_TERM',
  DELETE_START: 'BRANDS_DELETE_START',
  DELETE_SUCCESS: 'BRANDS_DELETE_SUCCESS',
  DELETE_ERROR: 'BRANDS_DELETE_ERROR',
  MODIFY_START: 'BRANDS_MODIFY_START',
  MODIFY_SUCCESS: 'BRANDS_MODIFY_SUCCESS',
  MODIFY_ERROR: 'BRANDS_MODIFY_ERROR',
};

export const i18n = {
  FETCH: 'I18N_FETCH',
  SUCCESS: 'I18N_SUCCESS',
  LANGUAGE: {
    FETCH: {
      START: 'I18N_LANGUAGE_FETCH_START',
      SUCCESS: 'I18N_LANGUAGE_FETCH_SUCCESS',
      ERROR: 'I18N_LANGUAGE_FETCH_ERROR',
    },
    POST: {
      START: 'I18N_LANGUAGE_POST_START',
      SUCCESS: 'I18N_LANGUAGE_POST_SUCCESS',
      ERROR: 'I18N_LANGUAGE_POST_ERROR',
    },
    PATCH: {
      START: 'I18N_LANGUAGE_PATCH_START',
      SUCCESS: 'I18N_LANGUAGE_PATCH_SUCCESS',
      ERROR: 'I18N_LANGUAGE_PATCH_ERROR',
    },
    DELETE: 'I18N_LANGUAGE_DELETE',
    CLEAR: 'I18N_LANGUAGE_CLEAR',
  },
  CLEAR: 'I18N_CLEAR',
  ERROR: 'I18N_ERROR',
};

export const DRAWS = {
  FETCH: 'DRAWS_FETCH',
  SUCCESS: 'DRAWS_SUCCESS',
  ERROR: 'DRAWS_ERROR',
  CHANGE_ENTRIES: 'DRAWS_CHANGE_ENTRIES',
  CHANGE_SEARCH_TERM: 'DRAWS_CHANGE_SEARCH_TERM',
  DELETE_START: 'DRAWS_DELETE_START',
  DELETE_SUCCESS: 'DRAWS_DELETE_SUCCESS',
  DELETE_ERROR: 'DRAWS_DELETE_ERROR',
};

export const DRAWS_FILTER = {
  LOTTERY_TYPE: 'DRAWS_FILTER_LOTTERY_TYPE',
  PARENT_BRAND: 'DRAWS_FILTER_PARENT_BRAND',
  FUTURE_DRAWS: 'DRAWS_FILTER_FUTURE_DRAWS',
  NOT_PROCESSED: 'DRAWS_FILTER_NOT_PROCESSED',
  CLEAR: 'DRAWS_FILTER_CLEAR',
};

export const DRAW = {
  FETCH: 'DRAW_FETCH',
  SUCCESS: 'DRAW_SUCCESS',
  ERROR: 'DRAW_ERROR',
  CLEAR: 'DRAW_CLEAR',
  GET_BETS_START: 'DRAW_GET_BETS_START',
  GET_BETS_SUCCESS: 'DRAW_GET_BETS_SUCCESS',
  GET_BETS_ERROR: 'DRAW_GET_BETS_ERROR',
  CHANGE_BETS_ENTRIES: 'CHANGE_BETS_ENTRIES',
  PUT_JACKPOT_START: 'DRAW_PUT_JACKPOT_START',
  PUT_JACKPOT_ERROR: 'DRAW_PUT_JACKPOT_ERROR',
  PUT_JACKPOT_SUCCESS: 'DRAW_PUT_JACKPOT_SUCCESS',
};

export const DRAWSTATS = {
  FETCH: 'DRAWSTATS_FETCH',
  SUCCESS: 'DRAWSTATS_SUCCESS',
  ERROR: 'DRAW_ERROR',
};

export const BET = {
  FETCH: 'BET_FETCH',
  SUCCESS: 'BET_SUCCESS',
  ERROR: 'BET_ERROR',
  RESET: 'RESET',
};

export const BET_LINES = {
  FETCH: 'BET_LINES_FETCH',
  SUCCESS: 'BET_LINES_SUCCESS',
  ERROR: 'BET_LINES_ERROR',
};

export const BET_PERMS = {
  FETCH: 'BET_PERMS_FETCH',
  SUCCESS: 'BET_PERMS_SUCCESS',
  ERROR: 'BET_PERMS_ERROR',
};

export const BACKEND_USERS = {
  FETCH: 'BACKEND_USERS_FETCH',
  SUCCESS: 'BACKEND_USERS_SUCCESS',
  ERROR: 'BACKEND_USERS_ERROR',
  CHANGE_ENTRIES: 'BACKEND_USERS_CHANGE_ENTRIES',
  CHANGE_SEARCH_TERM: 'BACKEND_USERS_CHANGE_SEARCH_TERM',
  DELETE_START: 'BACKEND_USERS_DELETE_START',
  DELETE_SUCCESS: 'BACKEND_USERS_DELETE_SUCCESS',
  DELETE_ERROR: 'BACKEND_USERS_DELETE_ERROR',
};

export const BACKEND_USER = {
  FETCH: 'BACKEND_USER_FETCH',
  SUCCESS: 'BACKEND_USER_SUCCESS',
  ERROR: 'BACKEND_USER_ERROR',
  CLEAR: 'BACKEND_USER_CLEAR',
};

export const BRAND = {
  FETCH: 'BRAND_FETCH',
  SUCCESS: 'BRAND_SUCCESS',
  ERROR: 'BRAND_ERROR',
  CLEAR: 'BRAND_CLEAR',
};

export const LOTTERY = {
  FETCH: 'LOTTERY_FETCH',
  SUCCESS: 'LOTTERY_SUCCESS',
  ERROR: 'LOTTERY_ERROR',
  CLEAR: 'LOTTERY_CLEAR',
  CHANGE_STRUCT_START: 'LOTTERY_CHANGE_STRUCT_START',
  CHANGE_STRUCT_SUCCESS: 'LOTTERY_CHANGE_STRUCT_SUCCESS',
  CHANGE_STRUCT_ERROR: 'LOTTERY_CHANGE_STRUCT_ERROR',
  CHANGE_STRUCT_RESET: 'CHANGE_STRUCT_RESET',
  ADD_START: 'LOTTERY_ADD_START',
  ADD_ERROR: 'LOTTERY_ADD_ERROR',
  ADD_SUCCESS: 'LOTTERY_ADD_SUCCESS',
  HANDLE_CHANGE_PRICE_START: 'LOTTERY_HANDLE_CHANGE_PRICE_START',
  HANDLE_CHANGE_PRICE: 'LOTTERY_HANDLE_CHANGE_PRICE',
  UPDATE: 'LOTTERY_UPDATE',
  STRUCTURE_GET: 'LOTTERY_STRUCTURE_GET',
  STRUCTURE_CHANGE: 'LOTTERY_STRUCTURE_CHANGE',
  STRUCTURE_RESET: 'LOTTERY_STRUCTURE_RESET',
  MESSAGE_TEMPLATE_START: 'LOTTERY_MESSAGE_TEMPLATE_START',
  MESSAGE_TEMPLATE_SUCCESS: 'LOTTERY_MESSAGE_TEMPLATE_SUCCESS',
  MESSAGE_TEMPLATE_ERROR: 'LOTTERY_MESSAGE_TEMPLATE_ERROR',
  CHANGE_LOTTERY_FILTER: 'LOTTERY_CHANGE_LOTTERY_FILTER',
  CHANGE_BRAND_FILTER: 'LOTTERY_CHANGE_BRAND_FILTER',
  CHANGE_STATUS_FILTER: 'LOTTERY_CHANGE_STATUS_FILTER',
  RESET_LOTTERY_FILTERS: 'LOTTERY_RESET_LOTTERY_FILTERS',
  CHANGE_PRIZE_POOL_PERCENTAGE: 'LOTTERY_CHANGE_PRIZE_POOL_PERCENTAGE',
  CHANGE_MAIN_CURRENCY: 'LOTTERY_CHANGE_MAIN_CURRENCY',
  CHANGE_BASE_TICKET_COST: 'LOTTERY_CHANGE_BASE_TICKET_COST',
  RESET_CURRENCY: 'LOTTERY_RESET_CURRENCY',
};

export const LOTTERY_INSTRUCTIONS = {
  FETCH: {
    START: 'LOTTERY_INSTRUCTIONS_FETCH_START',
    SUCCESS: 'LOTTERY_INSTRUCTIONS_FETCH_SUCCESS',
    ERROR: 'LOTTERY_INSTRUCTIONS_FETCH_ERROR,',
  },
  RESET: 'LOTTERY_INSTRUCTIONS_RESET',
};

export const LOTTERY_INSTRUCTION_SINGLE = {
  FETCH: {
    START: 'LOTTERY_INSTRUCTION_SINGLE_FETCH_START',
    SUCCESS: 'LOTTERY_INSTRUCTION_SINGLE_FETCH_SUCCESS',
    ERROR: 'LOTTERY_INSTRUCTION_SINGLE_FETCH_ERROR,',
  },
  POST: {
    START: 'LOTTERY_INSTRUCTIONS_SINGLE_POST_START',
    SUCCESS: 'LOTTERY_INSTRUCTIONS_SINGLE_POST_SUCCESS',
    ERROR: 'LOTTERY_INSTRUCTIONS_SINGLE_POST_ERROR,',
  },
  PUT: {
    START: 'LOTTERY_INSTRUCTIONS_SINGLE_PUT_START',
    ERROR: 'LOTTERY_INSTRUCTIONS_SINGLE_PUT_ERROR',
    SUCCESS: 'LOTTERY_INSTRUCTIONS_SINGLE_PUT_SUCCESS',
  },
  RESET: 'LOTTERY_INSTRUCTION_SINGLE_FETCH_RESET',
};

export const LOTTERIES = {
  FETCH: 'LOTTERIES_FETCH',
  SUCCESS: 'LOTTERIES_SUCCESS',
  ERROR: 'LOTTERIES_ERROR',
  CHANGE_ENTRIES: 'LOTTERIES_CHANGE_ENTRIES',
  CHANGE_SEARCH_TERM: 'LOTTERIES_CHANGE_SEARCH_TERM',
  DELETE_START: 'LOTTERIES_DELETE_START',
  DELETE_SUCCESS: 'LOTTERIES_DELETE_SUCCESS',
  DELETE_ERROR: 'LOTTERIES_DELETE_ERROR',
};

export const ACCOUNT = {
  RESET: {
    START: 'ACCOUNT_RESET_START',
    SUCCESS: 'ACCOUNT_RESET_SUCCESS',
    ERROR: 'ACCOUNT_RESET_ERROR',
    RESET: 'ACCOUNT_RESET_RESET',
  },
  ACTIVATE: {
    START: 'ACCOUNT_ACTIVATE_START',
    SUCCESS: 'ACCOUNT_ACTIVATE_SUCCESS',
    ERROR: 'ACCOUNT_ACTIVATE_ERROR',
    RESET: 'ACCOUNT_ACTIVATE_RESET',
  },
  SETNEWPASSWORD: {
    START: 'ACCOUNT_SETNEWPASSWORD_START',
    SUCCESS: 'ACCOUNT_SETNEWPASSWORD_SUCCESS',
    ERROR: 'ACCOUNT_SETNEWPASSWORD_ERROR',
    RESET: 'ACCOUNT_SETNEWPASSWORD_RESET',
  },
};

export const AUTH = {
  GET: 'AUTH_GET',
};

export const LOGIN = {
  START: 'LOGIN_START',
  SUCCESS: 'LOGIN_SUCCESS',
  ERROR: 'LOGIN_ERROR',
  CANCEL_CURRENT: 'LOGIN_CANCEL_CURRENT',
};

export const LOGOUT = {
  START: 'LOGOUT_START',
  END: 'LOGOUT_END',
};

export const GET_CURRENT_USER = {
  ERROR: 'GET_CURRENT_USER_ERROR',
  START: 'GET_CURRENT_USER_START',
  SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  CANCEL_CURRENT: 'GET_CURRENT_USER_CANCEL_CURRENT',
};

export const SET_USER_ROLE = 'SET_USER_ROLE';

export const REPORTS = {
  FETCH: 'REPORTS_FETCH',
  SUCCESS: 'REPORTS_SUCCESS',
  ERROR: 'REPORTS_ERROR',
  CHANGE_ENTRIES: 'REPORTS_ENTRIES',
  CSV: {
    FETCH: 'REPORTS_CSV_FETCH',
    SUCCESS: 'REPORTS_CSV_SUCCESS',
    ERROR: 'REPORTS_CSV_ERROR',
  },
};

export const REPORTS_FILTER = {
  LOTTERY_TYPE: 'REPORTS_FILTER_LOTTERY_TYPE',
  PERIOD: 'REPORTS_FILTER_PERIOD',
  DATE_FROM: 'REPORTS_FILTER_DATE_FROM',
  DATE_TO: 'REPORTS_FILTER_DATE_TO',
  PARENT_BRAND: 'REPORTS_FILTER_PARENT_BRAND',
  PLAYER: 'REPORTS_FILTER_PLAYER',
  CLEAR: 'REPORTS_FILTER_CLEAR',
  BET_ID: 'REPORTS_FILTER_BET_ID',
  DRAW_ID: 'REPORTS_FILTER_DRAW_ID',
  WITH_SALES: 'REPORTS_FILTER_WITH_SALES',
  POSSIBLE_PRIZE_ID: 'REPORTS_FILTER_POSSIBLE_PRIZE_ID',
};

export const BETS = {
  FETCH: 'BETS_FETCH',
  SUCCESS: 'BETS_SUCCESS',
  ERROR: 'BETS_ERROR',
  CHANGE_ENTRIES: 'BETS_ENTREIS',
};

export const DASHBOARD = {
  FETCH: 'DASHBOARD_FETCH',
  SUCCESS: 'DASHBOARD_SUCCESS',
  ERROR: 'DASHBOARD_ERROR',
};

export const TICKETS = {
  FETCH: 'TICKETS_FETCH',
  SUCCESS: 'TICKETS_SUCCESS',
  ERROR: 'TICKETS_ERROR',
  CHANGE_ENTRIES: 'TICKETS_ENTRIES',
  CHANGE_TICKETS_TYPE: 'TICKETS_CHANGE_TICKETS_TYPE',
  CHANGE_DATE_FROM: 'TICKETS_DATE_FROM',
  CHANGE_DATE_TO: 'TICKETS_DATE_TO',
  CHANGE_CREATED_AT_FROM: 'TICKETS_CREATED_AT_FROM',
  CHANGE_CREATED_AT_TO: 'TICKETS_CREATED_AT_TO',
  CHANGE_TICKET_ID: 'TICKETS_TICKET_ID',
  CHANGE_PLAYER: 'TICKETS_PLAYER',
  CHANGE_DRAW: 'TICKETS_DRAW',
  CHANGE_BRAND: 'TICKETS_BRAND',
  CHANGE_LOTTERY: 'TICKETS_LOTTERY',
  CHANGE_STATUS: 'TICKETS_STATUS',
  CHANGE_PAGE: 'TICKETS_PAGE',
  CHANGE_POSSIBLE_PRIZE_ID: 'TICKETS_CHANGE_POSSIBLE_PRIZE_ID',
  RESET_FILTERS: 'TICKETS_RESET_FILTERS',
  RESET: 'TICKETS_RESET',
  CSV: {
    FETCH: 'TICKETS_CSV_FETCH',
    SUCCESS: 'TICKETS_CSV_SUCCESS',
    ERROR: 'TICKETS_CSV_ERROR',
  },
};

export const PLAYERSTATS = {
  FETCH: 'PLAYERSTATS_FETCH',
  SUCCESS: 'PLAYERSTATS_SUCCESS',
  ERROR: 'PLAYERSTATS_ERROR',
  CLEAR: 'PLAYERSTATS_CLEAR',
};

export const TIMEZONES = {
  FETCH: 'TIMEZONES_FETCH',
  SUCCESS: 'TIMEZONES_SUCCESS',
  ERROR: 'TIMEZONES_ERROR',
};

export const RESULT_CONFIRM = {
  POST: 'RESULT_CONFIRM_POST',
  SUCCESS: 'RESULT_CONFIRM_SUCCESS',
  ERROR: 'RESULT_CONFIRM_ERROR',
  RESET: 'RESULT_CONFIRM_RESET',
};

export const RESULT_CONFIRM_DELETE = {
  START: 'RESULT_CONFIRM_DELETE.START',
  SUCCESS: 'RESULT_CONFIRM_DELETE_SUCCESS',
  ERROR: 'RESULT_CONFIRM_DELETE_ERROR',
};

export const LOBBY_VERSIONS_GET = {
  START: 'LOBBY_VERSIONS_GET_START',
  SUCCESS: 'LOBBY_VERSIONS_GET_SUCCESS',
  ERROR: 'LOBBY_VERSIONS_GET_ERROR',
};

export const LOBBY_VERSION_GET = {
  START: 'LOBBY_VERSION_GET_START',
  SUCCESS: 'LOBBY_VERSION_GET_SUCCESS',
  ERROR: 'LOBBY_VERSION_GET_ERROR',
};

export const LOBBY_VERSION_CREATE = {
  START: 'LOBBY_VERSION_CREATE_START',
  SUCCESS: 'LOBBY_VERSION_CREATE_SUCCESS',
  ERROR: 'LOBBY_VERSION_CREATE_ERROR',
};

export const LOBBY_VERSION_PUBLISH = {
  START: 'LOBBY_VERSION_PUBLISH_START',
  SUCCESS: 'LOBBY_VERSION_PUBLISH_SUCCESS',
  ERROR: 'LOBBY_VERSION_PUBLISH_ERROR',
};

export const LOBBY_VERSION_DELETE = {
  START: 'LOBBY_VERSION_DELETE_START',
  SUCCESS: 'LOBBY_VERSION_DELETE_SUCCESS',
  ERROR: 'LOBBY_VERSION_DELETE_ERROR',
};

export const BETTING_AGENCIES = {
  START: 'BETTING_AGENCIES_START',
  SUCCESS: 'BETTING_AGENCIES_SUCCESS',
  ERROR: 'BETTING_AGENCIES_ERROR',
};

export const BETTING_AGENCY = {
  START: 'BETTING_AGENCY_START',
  SUCCESS: 'BETTING_AGENCY_SUCCESS',
  ERROR: 'BETTING_AGENCY_ERROR',
  RESET: 'BETTING_AGENCY_RESET',
};

export const LOTTERY_SCHEDULE_POST = {
  START: 'LOTTERY_SCHEDULE_POST',
  ERROR: 'LOTTERY_SCHEDULE_ERROR',
  SUCCESS: 'LOTTERY_SCHEDULE_SUCCESS',
};

export const NOTIFICATION = {
  RESET: 'NOTIFICATION_RESET',
  OPEN_SUCCESS: 'NOTIFICATION_OPEN_SUCCESS',
  OPEN_ERROR: 'NOTIFICATION_OPEN_ERROR',
};

export const REPORT_SUMMARY = {
  FETCH: 'REPORT_SUMMARY_FETCH',
  SUCCESS: 'REPORT_SUMMARY_SUCCESS',
  ERROR: 'REPORT_SUMMARY_ERROR',
};

export const OUTBOUND_CHANNELS = {
  FETCH: 'OUTBOUND_CHANNELS_FETCH',
  SUCCESS: 'OUTBOUND_CHANNELS_SUCCESS',
  ERROR: 'OUTBOUND_CHANNELS_ERROR',

  AVAILABLE_INTEGRATIONS: {
    FETCH: 'UOTBOUND_CHANNELS_AVAILABLE_INTEGRATIONS_FETCH',
    SUCCESS: 'OUTBOUND_CHANNELS_AVAILABLE_INTEGRATIONS_SUCCESS',
    ERROR: 'OUTBOUND_CHANNELS_AVAILABLE_INTEGRATIONS_ERROR',
  },
  PLACEHOLDERS: {
    FETCH: 'OUTBOUND_CHANNELS_PLACEHOLDERS_FETCH',
    SUCCESS: 'OUTBOUND_CHANNELS_PLACEHOLDERS_SUCCESS',
    ERROR: 'OUTBOUND_CHANNELS_PLACEHOLDERS_ERROR',
  },
  TEMPLATE: {
    FETCH: 'OUTBOUND_CHANNELS_TEMPLATE_FETCH',
    SUCCESS: 'OUTBOUND_CHANNELS_TEMPLATE_SUCCESS',
    ERROR: 'OUTBOUND_CHANNELS_TEMPLATE_ERROR',
  },
  CHANNEL: {
    FETCH: 'OUTBOUND_CHANNELS_CHANNEL_FETCH',
    POST: 'OUTBOUND_CHANNELS_CHANNEL_POST',
    PATCH: 'OUTBOUND_CHANNELS_CHANNEL_PATCH',
    DELETE: 'OUTBOUND_CHANNELS_CHANNEL_DELETE',
    SUCCESS: 'OUTBOUND_CHANNELS_CHANNEL_SUCCESS',
    ERROR: 'OUTBOUND_CHANNELS_CHANNEL_ERROR',
    CLEAR: 'OUTBOUND_CHANNELS_CHANNEL_CLEAR',
  },
};

export const LOBBY_VERSION_CHANGE_ENTRIES = 'LOBBY_VERSION_CHANGE_ENTRIES';

export const SYSTEM_CONSTS = {
  CURRENCIES: {
    GET: {
      START: 'SYSTEM_CONSTS_CURRENCIES_GET_START',
      ERROR: 'SYSTEM_CONSTS_CURRENCIES_GET_ERROR',
      SUCCESS: 'SYSTEM_CONSTS_CURRENCIES_GET_SUCCESS',
    },
  },
  GAME_RESULT_AGENCIES: {
    GET: {
      START: 'SYSTEM_CONSTS_GAME_RESULT_AGENCIES_GET_START',
      ERROR: 'SYSTEM_CONSTS_GAME_RESULT_AGENCIES_GET_ERROR',
      SUCCESS: 'SYSTEM_CONSTS_GAME_RESULT_AGENCIES_GET_SUCCESS',
      RESET: 'SYSTEM_CONSTS_GAME_RESULT_AGENCIES_RESET',
    },
  },
  PROVIDER_DRAW_STATUSES: {
    GET: {
      START: 'SYSTEM_CONSTS_PROVIDER_DRAW_STATUSES_GET_START',
      ERROR: 'SYSTEM_CONSTS_PROVIDER_DRAW_STATUSES_GET_ERROR',
      SUCCESS: 'SYSTEM_CONSTS_PROVIDER_DRAW_STATUSES_GET_SUCCESS',
      RESET: 'SYSTEM_CONSTS_PROVIDER_DRAW_STATUSES_RESET',
    },
  },
  AVAILABLE_WINNING_SELECTIONS: {
    GET: {
      START: 'SYSTEM_CONSTS_AVAILABLE_WINNING_SELECTIONS_GET_START',
      ERROR: 'SYSTEM_CONSTS_AVAILABLE_WINNING_SELECTIONS_GET_ERROR',
      SUCCESS: 'SYSTEM_CONSTS_AVAILABLE_WINNING_SELECTIONS_GET_SUCCESS',
      RESET: 'SYSTEM_CONSTS_AVAILABLE_WINNING_SELECTIONS_RESET',
    },
  },
  PRIZE_TYPES: {
    GET: {
      START: 'SYSTEM_CONSTS_PRIZE_TYPES_GET_START',
      ERROR: 'SYSTEM_CONSTS_PRIZE_TYPES_GET_ERROR',
      SUCCESS: 'SYSTEM_CONSTS_PRIZE_TYPES_GET_SUCCESS',
      RESET: 'SYSTEM_CONSTS_PRIZE_TYPES_RESET',
    },
  },
  RESET: 'SYSTEM_CONSTS_RESET',
};

export const EXCHANGE_RATES = {
  GET: {
    START: 'EXCHANGE_RATES_GET_START',
    ERROR: 'EXCHANGE_RATES_GET_ERROR',
    SUCCESS: 'EXCHANGE_RATES_GET_SUCCESS',
  },
  PUT: {
    START: 'EXCHANGE_RATES_PUT_START',
    ERROR: 'EXCHANGE_RATES_PUT_ERROR',
    SUCCESS: 'EXCHANGE_RATES_PUT_SUCCESS',
  },
  PATCH: {
    START: 'EXCHANGE_RATES_PATCH_START',
    ERROR: 'EXCHANGE_RATES_PATCH_ERROR',
    SUCCESS: 'EXCHANGE_RATES_PATCH_SUCCESS',
  },
  POST: {
    START: 'EXCHANGE_RATES_POST_START',
    ERROR: 'EXCHANGE_RATES_POST_ERROR',
    SUCCESS: 'EXCHANGE_RATES_POST_SUCCESS',
  },
  DELETE: {
    START: 'EXCHANGE_RATES_DELETE_START',
    ERROR: 'EXCHANGE_RATES_DELETE_ERROR',
    SUCCESS: 'EXCHANGE_RATES_DELETE_SUCCESS',
  },
};

export const LOTTERY_PROVIDERS = {
  GET: {
    START: 'LOTTERY_PROVIDERS_GET_START',
    ERROR: 'LOTTERY_PROVIDERS_GET_ERROR',
    SUCCESS: 'LOTTERY_PROVIDERS_GET_SUCCESS',
  },
};

export const PROVIDERS = {
  GET: {
    START: 'PROVIDERS_GET_START',
    ERROR: 'PROVIDERS_GET_ERROR',
    SUCCESS: 'PROVIDERS_GET_SUCCESS',
  },
  RESET: 'PROVIDERS_RESET',
};

export const SINGLE_PROVIDER = {
  GET: {
    START: 'SINGLE_PROVIDER_GET_START',
    ERROR: 'SINGLE_PROVIDER_GET_ERROR',
    SUCCESS: 'SINGLE_PROVIDER_GET_SUCCESS',
  },
  POST: {
    START: 'SINGLE_PROVIDER_POST_START',
    ERROR: 'SINGLE_PROVIDER_POST_ERROR',
    SUCCESS: 'SINGLE_PROVIDER_POST_SUCCESS',
  },
  PUT: {
    START: 'SINGLE_PROVIDER_PUT_START',
    ERROR: 'SINGLE_PROVIDER_PUT_ERROR',
    SUCCESS: 'SINGLE_PROVIDER_PUT_SUCCESS',
  },
  PATCH: {
    START: 'SINGLE_PROVIDER_PATCH_START',
    ERROR: 'SINGLE_PROVIDER_PATCH_ERROR',
    SUCCESS: 'SINGLE_PROVIDER_PATCH_SUCCESS',
  },
  DELETE: {
    START: 'SINGLE_PROVIDER_DELETE_START',
    ERROR: 'SINGLE_PROVIDER_DELETE_ERROR',
    SUCCESS: 'SINGLE_PROVIDER_DELETE_SUCCESS',
  },
  RESET: 'SINGLE_PROVIDER_RESET',
  SCHEDULE: {
    POST: {
      START: 'PROVIDERS_SCHEDULE_POST_START',
      ERROR: 'PROVIDERS_SCHEDULE_POST_ERROR',
      SUCCESS: 'PROVIDERS_SCHEDULE_POST_SUCCESS',
    },
  },
};

export const SYSTEM_SETTINGS = {
  FETCH: {
    START: 'SYSTEM_SETTINGS_FETCH_START',
    ERROR: 'SYSTEM_SETTINGS_FETCH_ERROR',
    SUCCESS: 'SYSTEM_SETTINGS_FETCH_SUCCESS',
  },
  PUT: {
    START: 'SYSTEM_SETTINGS_PUT_START',
    ERROR: 'SYSTEM_SETTINGS_PUT_ERROR',
    SUCCESS: 'SYSTEM_SETTINGS_PUT_SUCCESS',
  },
  PATCH: {
    START: 'SYSTEM_SETTINGS_PATCH_START',
    ERROR: 'SYSTEM_SETTINGS_PATCH_ERROR',
    SUCCESS: 'SYSTEM_SETTINGS_PATCH_SUCCESS',
  },
};

export const PAGE_CONFIG = {
  SET: {
    PAGE_NAME: 'PAGE_CONFIG_SET_PAGE_NAME',
    LOCATION_KEY: 'PAGE_CONFIG_SET_LOCATION_KEY',
    PAGI_NUMBER: 'PAGE_CONFIG_SET_PAGI_NUMBER',
    DATA: 'PAGE_CONFIG_SET_DATA',
  },
  RESET: 'PAGE_CONFIG_RESET',
};

export const PLAYERS = {
  GET: {
    START: 'PLAYERS_GET_START',
    SUCCESS: 'PLAYERS_GET_SUCCESS',
    ERROR: 'PLAYERS_GET_ERROR',
  },
  FILTERS: {
    NAME: 'PLAYERS_FILTERS_NAME',
    PHONE: 'PLAYERS_FILTERS_PHONE',
    ID: 'PLAYERS_FILTERS_ID',
    RESET: 'PLAYERS_FILTERS_RESET',
  },
};

export const SINGLE_PLAYER = {
  GET: {
    START: 'SINGLE_PLAYER_GET_START',
    SUCCESS: 'SINGLE_PLAYER_GET_SUCCESS',
    ERROR: 'SINGLE_PLAYER_GET_ERROR',
  },
  PUT: {
    START: 'SINGLE_PLAYER_PUT_START',
    SUCCESS: 'SINGLE_PLAYER_PUT_SUCCESS',
    ERROR: 'SINGLE_PLAYER_PUT_ERROR',
  },
};

export const TAXES = {
  GET: {
    START: 'TAXES_GET_START',
    ERROR: 'TAXES_GET_ERROR',
    SUCCESS: 'TAXES_GET_SUCCESS',
  },
  PUT: {
    START: 'TAXES_PUT_START',
    ERROR: 'TAXES_PUT_ERROR',
    SUCCESS: 'TAXES_PUT_SUCCESS',
  },
  POST: {
    START: 'TAXES_POST_START',
    ERROR: 'TAXES_POST_ERROR',
    SUCCESS: 'TAXES_POST_SUCCESS',
  },
  DELETE: {
    START: 'TAXES_DELETE_START',
    ERROR: 'TAXES_DELETE_ERROR',
    SUCCESS: 'TAXES_DELETE_SUCCESS',
  },
};

export const MANAGEMENT_ACTION = {
  START: 'MANAGEMENT_ACTION_START',
  ERROR: 'MANAGEMENT_ACTION_ERROR',
  SUCCESS: 'MANAGEMENT_ACTION_SUCCESS',
};

export const PROVIDER_DRAWS = {
  FETCH: {
    START: 'PROVIDER_DRAWS_FETCH_START',
    SUCCESS: 'PROVIDER_DRAWS_FETCH_SUCCESS',
    ERROR: 'PROVIDER_DRAWS_FETCH_ERROR',
  },
  GAME_DRAWS: {
    FETCH: {
      START: 'PROVIDER_DRAWS_GAME_DRAWS_FETCH_START',
      SUCCESS: 'PROVIDER_DRAWS_GAME_DRAWS_FETCH_SUCCESS',
      ERROR: 'PROVIDER_DRAWS_GAME_DRAWS_FETCH_ERROR',
    },
  },
  FILTERS: {
    DATE_FROM: 'PROVIDER_DRAWS_FILTERS_DATE_FROM',
    DATE_TO: 'PROVIDER_DRAWS_FILTERS_DATE_TO',
    TYPE: 'PROVIDER_DRAWS_FILTERS_TYPE',
    PROVIDER: 'PROVIDER_DRAWS_FILTERS_PROVIDER',
    STATUS: 'PROVIDER_DRAWS_FILTERS_STATUS',
    HIDE_FUTURE_DRAWS: 'PROVIDER_DRAWS_FILTERS_HIDE_FUTURE_DRAWS',
    HIDE_PROCESSED_DRAWS: 'PROVIDER_DRAWS_FILTERS_HIDE_PROCESSED_DRAWS',
    RESET: 'PROVIDER_DRAWS_FILTERS_RESET',
  },
  RESET: 'PROVIDER_DRAWS_RESET',
};

export const PROVIDER_DRAW = {
  FETCH: {
    START: 'PROVIDER_DRAW_FETCH_START',
    SUCCESS: 'PROVIDER_DRAW_FETCH_SUCCESS',
    ERROR: 'PROVIDER_DRAW_FETCH_ERROR',
  },
  RESET: 'PROVIDER_DRAW_RESET',
};

export const DRAW_SUMMARY = {
  FETCH: {
    START: 'DRAW_SUMMARY_FETCH_START',
    SUCCESS: 'DRAW_SUMMARY_FETCH_SUCCESS',
    ERROR: 'DRAW_SUMMARY_FETCH_ERROR',
  },
  RESET: 'DRAW_SUMMARY_RESET',
};

/*
As you can see our types file is giant, so i wrote actionTypesCreator, let me know what do you think about it.
Or we should just move types to proper files. For example /actions/types/promotionsTypes.js etc.

export const PROMOTIONS = {
  GET: {
    START: 'PROMOTIONS_GET_START',
    SUCCESS: 'PROMOTIONS_GET_SUCCESS',
    ERROR: 'PROMOTIONS_GET_ERROR'
  },
  PUT: {
    START: 'PROMOTIONS_PUT_START',
    SUCCESS: 'PROMOTIONS_PUT_SUCCESS',
    ERROR: 'PROMOTIONS_PUT_ERROR'
  },
  POST: {
    START: 'PROMOTIONS_POST_START',
    SUCCESS: 'PROMOTIONS_POST_SUCCESS',
    ERROR: 'PROMOTIONS_POST_ERROR'
  },
  DELETE: {
    START: 'PROMOTIONS_DELETE_START',
    SUCCESS: 'PROMOTIONS_DELETE_SUCCESS',
    ERROR: 'PROMOTIONS_DELETE_ERROR'
  },
  RESET: 'PROMOTIONS_RESET',
};

 vs

export const PROMOTIONS = actionTypesCreator('promotions');
 */
export const PROGRESSIVE_JACKPOT = actionTypesCreator('progressive_jackpot', null, [
  'save_initial_data',
  'change',
  ['enable', 'initial_amount', 'ttl', 'sale_percentage', 'include_current_draw'],
]);
export const PROMOTIONS = actionTypesCreator('promotions', ['get', 'post']);
export const PROMOTION = actionTypesCreator('promotion', ['get', 'delete', 'put', 'patch']);
export const PROMOTION_DRAWS = actionTypesCreator('promotion_draws', ['get']);
export const PROMOTION_DRAWS_DATES = actionTypesCreator('promotion_draws_dates', ['get']);
export const MODAL = actionTypesCreator('modal', null, ['open', 'close', 'close_actual_opened']);
export const SEARCH = actionTypesCreator('search', null, ['lottery', ['id'], 'bet', ['id']]);
