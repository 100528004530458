import React from 'react';
import PropTypes from 'prop-types';
import { Page, Button } from 'tabler-react';
import classnames from 'classnames';

import AccessControl from '../../AccessControl';
import CustomTable from '../../Reusable/Table/CustomTable';

import { userRoles } from '../../../utils/roles';
import { displayDateWithoutTime, displayOnlyTime } from '../../../utils/date';
import { getMoneyWithCurrency } from '../../../utils/money';
import { DRAW_PROTOCOL_WINNINGS } from '../../../utils/tableConstans';

import './DrawProtocol.scss';

class DrawProtocol extends React.Component {
  checkIfNumberWon(selectionName, number) {
    const { wonBetSelection } = this.props;

    if (!wonBetSelection) {
      return false;
    }

    const wonNumbers = wonBetSelection.asArray[selectionName];

    if (typeof wonNumbers === 'object') {
      return wonNumbers.indexOf(number) !== -1;
    }

    return wonNumbers === number;
  }

  renderNumbers() {
    const { drawStructure } = this.props;

    return drawStructure.map((selection, selectionIndex) => {
      if (selection.name === 'chance' || selection.name === 'turbo' || selection.name === 'nap') {
        return null;
      }

      const range = selection.range.end;

      return (
        <div className="numbers-wrapper d-flex flex-wrap p-3" key={`${selection.name}-${selectionIndex}`}>
          {Array(range)
            .fill(0)
            .map((elem, index) => {
              const number = index + selection.range.start;
              const isWon = this.checkIfNumberWon(selection.name, number);

              return (
                <div
                  className={classnames('number m-1', {
                    'number-won': isWon,
                  })}
                  key={`${number}-${selection.name}`}
                >
                  {number}
                </div>
              );
            })}
        </div>
      );
    });
  }

  render() {
    const { drawSummary, drawNumber } = this.props;
    const drawSummaryData = drawSummary.data;

    if (!drawSummaryData) {
      return null;
    }

    return (
      <AccessControl
        roles={[
          userRoles.superAdmin,
          userRoles.admin,
          userRoles.brand,
          userRoles.resultsOperator,
          userRoles.reportsManager,
        ]}
        forbiddenRedirect={'/'}
      >
        <Page>
          <Page.Header>
            <div className="d-flex justify-content-between w-100 draw-protocol-header">
              <Page.Title>Draw Protocol</Page.Title>
              <Button className="draw-protocol-btn mx-5" color="primary" onClick={() => window.print()}>
                Print
              </Button>
            </div>
          </Page.Header>
          <div className="mb-4">
            <p>LLC &quot;FIRST INTERNATIONAL BOOKMEKING COMPANY&quot;</p>
            <p>125047, Moscow, Lesnoy 4-y lane, 4, floor 14, room. fifteen</p>
            <p>OGRN 1087746968759 dated 11.08.2008</p>
            <p>INN 7729607406, KPP 771001001</p>
          </div>
          <div>
            <p>
              Draw commission protocol № <span className="txt--normal">{drawSummaryData.systemWideDrawNumber}</span> от{' '}
              <span className="txt--normal">{displayDateWithoutTime(drawSummaryData.drawDatetime)}</span> official
              results of the drawing.
            </p>
            <p>
              Draw № <span className="txt--normal">{drawNumber}</span>{' '}
              <span className="txt--normal">{drawSummaryData.lotteryName}</span> (winnings determination algorithm X)
            </p>
            <p className="txt--normal">{drawSummaryData.gameName}</p>
            <p>Organizer: Ministry of Finance of the Russian Federation</p>
            <p>Operator: Limited Liability Company &quot;Sports Lotteries&quot;</p>
            <p>Venue: Foo</p>
            <p>
              Date of draw: <span className="txt--normal">{displayDateWithoutTime(drawSummaryData.drawDatetime)}</span>
            </p>
            <p>
              Time of draw: <span className="txt--normal">{displayOnlyTime(drawSummaryData.drawDatetime)}</span>
            </p>
            <p>
              Draw commission acting by order № {/*number of order*/} from{' '}
              <span className="txt--normal">{displayDateWithoutTime(drawSummaryData.drawDatetime)}</span>, confirms that
              acceptance of lottery bets has been completed by thetime of the drawing..
            </p>
            <p>
              The draw commission confirms that, according to the data of the main computing center, the circulation №{' '}
              <span className="txt--normal">{drawNumber}</span>
            </p>
          </div>
          <div className="my-4">
            <p>
              1. <span className="txt--normal">{drawSummaryData.totalTicketsCount}</span> lottery tickets participate in
              the draw (XXX electronic lottery tickets) in amount
            </p>
            <p className="ml-5 txt--normal">{getMoneyWithCurrency(drawSummaryData.totalSales)}</p>
            <p>
              2. The Prize fund of draw № <span className="txt--normal">{drawNumber}</span> is
            </p>
            <p className="ml-5 txt--normal">{getMoneyWithCurrency(drawSummaryData.prizePoolForPercentagePrizes)}</p>
            <p>
              The draw commission confirms the results of the drawing of the draw prize fund №{' '}
              <span className="txt--normal">{drawNumber}</span>
            </p>
          </div>
          <div className="my-4">
            <p>The winning lottery combination:</p>
            <p>
              Draw № <span className="txt--normal">{drawNumber}</span>
            </p>
            <div className="col-lg-10 d-flex">{this.renderNumbers()}</div>
          </div>
          <div>
            <p>
              The prize fund of the current draw No.<span className="txt--normal">{drawNumber}</span> is
            </p>
            <p className="ml-5 txt--normal">{getMoneyWithCurrency(drawSummaryData.prizePoolForPercentagePrizes)}</p>
            <p>Including the completed prize fund</p>
            <p className="ml-5 txt--normal">{getMoneyWithCurrency(drawSummaryData.prizePoolForPercentagePrizes)}</p>
            <CustomTable columns={DRAW_PROTOCOL_WINNINGS} data={drawSummaryData.prizes} allowedPagination={false} />
          </div>
          <div className="my-4">
            <p>
              Undrawn Super Prize of the Draw № <span className="txt--normal">{drawNumber}</span>, to be drawn in the
              next draw is
            </p>
            <p className="ml-5 txt--normal">{getMoneyWithCurrency(drawSummaryData.prizePoolForPercentagePrizes)}</p>
            <div className="d-flex my-4">
              <div className="col-lg-3">
                <p>Chairman of the Draw Commission</p>
              </div>
              <div className="col-lg-3 sign-wrapper">
                <p className="txt--m0 sign">(Personal sign)</p>
              </div>
              <div className="col-lg-3 sign-wrapper">
                <p className="txt--m0 sign">(Full name)</p>
              </div>
            </div>
            <div className="d-flex my-4">
              <div className="col-lg-3">
                <p>Secretary of the Draw Commission</p>
              </div>
              <div className="col-lg-3 sign-wrapper">
                <p className="txt--m0 sign">(Personal sign)</p>
              </div>
              <div className="col-lg-3 sign-wrapper">
                <p className="txt--m0 sign">(Full name)</p>
              </div>
            </div>
            <div className="d-flex my-4">
              <div className="col-lg-3">
                <p>Member of the Draw Commission</p>
              </div>
              <div className="col-lg-3 sign-wrapper">
                <p className="txt--m0 sign">(Personal sign)</p>
              </div>
              <div className="col-lg-3 sign-wrapper">
                <p className="txt--m0 sign">(Full name)</p>
              </div>
            </div>
          </div>
        </Page>
      </AccessControl>
    );
  }
}

DrawProtocol.propTypes = {
  drawSummary: PropTypes.object,
  drawNumber: PropTypes.number,
  wonBetSelection: PropTypes.object,
  drawStructure: PropTypes.array,
};

export default DrawProtocol;
