class Schedule {
  static getNewSchedule({ hour, minute, second, timeZoneName }, dayOfWeek) {
    return {
      hour: hour.toString(),
      minute: minute.toString(),
      second: second.toString(),
      timeZone: timeZoneName,
      dayOfWeek,
    };
  }

  static getDefaultSchedule(timeZone) {
    return {
      hour: '',
      minute: '',
      second: '',
      timeZone,
      dayOfWeek: '',
    };
  }
}

export default Schedule;
