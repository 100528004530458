import { LOBBY_LIST } from '../../actions/lobbyActions/lobbyTypes';

const initialState = {
  lobbies: null,
  entries: 10,
  isPending: false,
  error: null,
  headers: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOBBY_LIST.FETCH:
      return {
        ...state,
        isPending: true,
        error: null,
        lobbies: null,
      };
    case LOBBY_LIST.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        lobbies: null,
      };
    case LOBBY_LIST.SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
        lobbies: action.payload.data,
        headers: action.payload.headers,
      };
    case LOBBY_LIST.CHANGE_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };
    default:
      return state;
  }
};
