import React from 'react';
import PropTypes from 'prop-types';
import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';

import { SearchLottery, SearchBrand, CheckboxField } from '../FormFields';

class DrawsFilter extends React.Component {
  handleChangeLottery = (value) => {
    this.props.changeLotteryType(value);
  };

  handleBrandChange = (brandId) => {
    this.props.changeParentBrand(brandId);
  };

  handleChangeShowFutureDraws = () => {
    this.props.changeShowFutureDraws(!this.props.filterStatus.isFutureDrawsChecked);
  };

  handleChangeShowNotProcessedDraws = () => {
    this.props.changeShowNotProcessedDraws(!this.props.filterStatus.isShowOnlyNotProcessedDrawsChecked);
  };

  render() {
    const { filterStatus, trigger, reset } = this.props;

    return (
      <div className="row">
        <AccessControl roles={[userRoles.superAdmin, userRoles.admin]}>
          <div className="col-lg-6">
            <div className="form-group">
              <SearchBrand labelText="Brand" handleChange={this.handleBrandChange} value={filterStatus.parentBrand} />
            </div>
          </div>
        </AccessControl>
        <div className="col-lg-6">
          <div className="form-group">
            <SearchLottery
              game
              brandId={filterStatus.parentBrand.id}
              labelText="Lottery"
              handleChange={this.handleChangeLottery}
              value={filterStatus.lotteryType}
            />
          </div>
        </div>
        <div className="col-12 d-flex align-items-center">
          <CheckboxField
            name={'check'}
            value={filterStatus.isFutureDrawsChecked}
            onChange={this.handleChangeShowFutureDraws}
          />
          <span>Hide future draws</span>
        </div>
        <div className="col-12 d-flex align-items-center pb-5">
          <CheckboxField
            name={'check'}
            value={filterStatus.isShowOnlyNotProcessedDrawsChecked}
            onChange={this.handleChangeShowNotProcessedDraws}
          />
          <span>Hide processed draws</span>
        </div>
        <div className="col-lg-3">
          <div className="form-group">
            <button
              className={'firstFilteringButton btn btn-primary'}
              onClick={() => {
                trigger(1);
              }}
            >
              Filter
            </button>
            <button
              className={' btn btn-secondary'}
              onClick={() => {
                reset();
                trigger(1);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}

DrawsFilter.propTypes = {
  filterStatus: PropTypes.shape({
    isFutureDrawsChecked: PropTypes.bool,
    isShowOnlyNotProcessedDrawsChecked: PropTypes.bool,
    lotteryType: PropTypes.object,
    parentBrand: PropTypes.object,
    bets: PropTypes.object,
  }),
  getDraws: PropTypes.func,

  changeLotteryType: PropTypes.func,
  changeParentBrand: PropTypes.func,
  changeShowFutureDraws: PropTypes.func,
  changeShowNotProcessedDraws: PropTypes.func,
  trigger: PropTypes.func,
  reset: PropTypes.func,
};

export default DrawsFilter;
