import { LOTTERIES } from '../../actions/types';

const initialState = {
  entries: 25,
  searchTerm: '',
  data: null,
  isPending: false,
  isError: false,
  error: null,
  headers: null,
  delete: {
    isPending: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOTTERIES.CHANGE_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };
    case LOTTERIES.CHANGE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case LOTTERIES.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
        data: null,
      };
    case LOTTERIES.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        headers: null,
        data: null,
      };
    case LOTTERIES.SUCCESS: {
      const { data, headers } = action.payload;
      const newData = data.map((game) => {
        game['prizeResolverName'] = game.lottery.structure.supportedBetResolvers.find(
          (elem) => elem.service === game.prizeResolverClass
        ).name;
        return game;
      });

      return {
        ...state,
        isPending: false,
        error: null,
        data: newData,
        headers: headers,
      };
    }
    case LOTTERIES.DELETE_START:
      return {
        ...state,
        delete: {
          isPending: true,
          error: null,
        },
      };
    case LOTTERIES.DELETE_SUCCESS: {
      const newData = state.data.filter((item) => item.id !== action.payload);
      return {
        ...state,
        delete: {
          isPending: false,
          error: null,
        },
        data: newData,
      };
    }
    case LOTTERIES.DELETE_ERROR:
      return {
        ...state,
        delete: {
          isPending: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
