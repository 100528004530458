import { applyMiddleware, createStore, compose } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
// import { routerMiddleware } from 'react-router-redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import httpClient from '../services/httpClient';
import reducers from '../reducers/index';
import logic from '../logic/index';

const isDevTools = process.env.NODE_ENV === 'development' && window.devToolsExtension !== undefined;
//const isDevelopment = process.env.NODE_ENV === 'development';

const dep = {
  httpClient,
};

const getEnhancer = (history) => {
  const logicMiddleware = createLogicMiddleware(logic, dep);
  const reactRouterMiddleware = routerMiddleware(history);

  // if (isDevelopment) {
  //   logicMiddleware.monitor$.subscribe(
  //     /* eslint-disable no-console */
  //     x => console.log(x)
  //     /* eslint-enable no-console */
  //   );
  // }

  const middleware = applyMiddleware(logicMiddleware, reactRouterMiddleware);

  return isDevTools ? compose(middleware, window.devToolsExtension()) : middleware;
};

export default (history) => {
  return createStore(connectRouter(history)(reducers), getEnhancer(history));
};
