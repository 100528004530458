import React from 'react';
import { Page } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import { postOverrideSchedule } from '../../../actions/lotteryActions';

import Schedule from '../../../services/Schedule';

import LotteryScheduleForm from './LotteryScheduleForm';

import selectors from '../../../selectors/schedule';

class LotterySchedule extends React.PureComponent {
  getCurrentSchedule = () => {
    const { drawTimesOverride } = this.props.lotteryData;

    if (!drawTimesOverride) {
      return null;
    }

    return drawTimesOverride.map((elem) => {
      return Schedule.getNewSchedule({ ...elem.timeData }, elem.dayOfWeek);
    });
  };

  renderContent() {
    const { lotteryData, postOverrideSchedule, isLoading } = this.props;

    // if (lotteryData.active) {
    //   return (
    //     <Alert type="primary" icon="danger" >
    //       Lottery is active. You can not override schedule while lottery is active.
    //     </Alert>
    //   );
    // }

    return (
      <LotteryScheduleForm
        id={lotteryData.id}
        timezone={lotteryData.timezone}
        onScheduleSubmit={(schedule) => postOverrideSchedule(lotteryData.id, schedule)}
        currentSchedule={this.getCurrentSchedule()}
        isSubmitting={isLoading}
      />
    );
  }

  render() {
    return (
      <Page>
        <Page.Header>
          <Page.Title>Edit lottery schedule</Page.Title>
        </Page.Header>
        <div className="row">
          <div className="col-lg-12">{this.renderContent()}</div>
        </div>
      </Page>
    );
  }
}

LotterySchedule.propTypes = {
  lotteryData: propTypes.object,
  postOverrideSchedule: propTypes.func,
  isLoading: propTypes.bool,
};

export default connect(
  (state) => ({
    lotteryData: selectors.getDataForSchedule(state),
    isLoading: state.schedule.isLoading,
  }),
  {
    postOverrideSchedule,
  }
)(LotterySchedule);
