import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import LabeledPicker from './common/LabeledPicker';
import ShadowPicker from './common/ShadowPicker';

class SectionButton extends React.Component {
  render() {
    const { index } = this.props;

    return (
      <div className="border-bottom buttonSubsection">
        <label className="title">Button: </label>
        <div className="flexRow col-lg-12 py-1 justify-content-start">
          <div className="col-lg-6 d-flex pl-0 align-items-center">
            <Field
              name={`sections.${index}.button.text.text`}
              className="form-control mr-2"
              placeholder="Insert button text"
            />
            <Field
              name={`sections.${index}.button.text.fontSize`}
              type="number"
              className="form-control width-picker"
            />
            <Field name={`sections.${index}.button.text.fontWeight`} className="mx-2 selector" component="select">
              <option value={700} label="Bold">
                Bold
              </option>
              <option value={400} label="Regular">
                Regular
              </option>
            </Field>
          </div>
          <div className="col-lg-6 d-flex border-left">
            <Field name={`sections.${index}.button.buttonColor`} component={LabeledPicker} label="Fill" />
            <Field name={`sections.${index}.button.text.color`} component={LabeledPicker} label="Font" />
            <Field name={`sections.${index}.button.text.borderColor`} component={LabeledPicker} label="Border" />
            <Field
              name={`sections.${index}.button.text.borderWidth`}
              className="form-control width-picker"
              type="number"
            />
          </div>
        </div>
        <div className="flexRow col-lg-12 justify-content-between py-1">
          <div className="col-lg-6 pl-0">
            <Field name={`sections.${index}.button.link`} className="form-control" placeholder="Insert link" />
          </div>
          <div className="d-flex col-lg-6 border-left">
            <Field name={`sections.${index}.button.shadowColor`} component={LabeledPicker} label="Shadow" />
            <ShadowPicker index={index} />
          </div>
        </div>
      </div>
    );
  }
}
SectionButton.propTypes = {
  index: PropTypes.number,
};

export default SectionButton;
