import React from 'react';
import PropTypes from 'prop-types';

class TableTopBar extends React.Component {
  changeSelect = (e) => {
    const { onChangeEntries, paginHeaders } = this.props;

    onChangeEntries(paginHeaders['x-page'], parseInt(e.target.value));
  };

  // handleSearchInput(e) {
  //   const { handleSearchTermChange, onPhraseSearched } = this.props;
  //
  //   handleSearchTermChange(e.target.value);
  //   return onPhraseSearched();
  // }

  render() {
    const { paginHeaders } = this.props;
    return (
      <div className="custom-table__top-bar">
        <div className="d-flex align-items-center justify-content-between">
          <div className="select-entries d-flex align-items-center">
            Show
            <select className="mr-2 ml-2" id="entries" value={paginHeaders['x-per-page']} onChange={this.changeSelect}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </div>
          {/*<div className="search-entries d-flex align-items-center">*/}
          {/*Search*/}
          {/*<input type="text" className="form-control ml-2" onChange={this.handleSearchInput.bind(this)}/>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}

TableTopBar.propTypes = {
  paginHeaders: PropTypes.object,
  onChangeEntries: PropTypes.func,
};

export default TableTopBar;
