const getDataForSchedule = (state) => ({
  id: state.lottery.data.id,
  timezone: state.lottery.data.timezone,
  active: state.lottery.data.active,
  drawTimesOverride: state.lottery.data.drawTimesOverride,
});

export default {
  getDataForSchedule,
};
