import React from 'react';
import { TabbedCard, Tab } from 'tabler-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';

import ProviderFormGeneral from './ProviderFormGeneral';
import ProvidersSchedule from './ProvidersSchedule';

import { WITH_SCHEDULE } from '../../../utils/providers/data/commonConstans';

import { getSingleProviderConfig, resetSingleProviderData } from '../../../actions/providersActions';

class ProviderFormPage extends React.Component {
  componentDidMount() {
    const { getSingleProviderConfig, edition } = this.props;

    if (!edition) {
      return;
    }

    getSingleProviderConfig(this.getProviderName(), this.getProviderId());
  }

  componentWillUnmount() {
    const { resetSingleProviderData } = this.props;

    resetSingleProviderData();
  }

  getProviderName() {
    const { name } = this.props.match.params;

    return name;
  }

  getProviderId() {
    const { id } = this.props.match.params;

    return id;
  }

  renderScheduleTab() {
    const { provider } = this.props;
    const name = this.getProviderName();
    const id = this.getProviderId();

    if (WITH_SCHEDULE.indexOf(name) != -1) {
      return (
        <Tab title={'Schedule'}>
          <ProvidersSchedule {...{ provider, id, name }} />
        </Tab>
      );
    }

    return null;
  }

  render() {
    const { provider, edition } = this.props;
    const name = this.getProviderName();
    const id = this.getProviderId();

    return (
      <div className="mt-5">
        <TabbedCard initialTab={'General'} className={'mt-5'}>
          <Tab title={'General'}>
            <ProviderFormGeneral {...{ provider, id, name, edition }} />
          </Tab>
          {this.renderScheduleTab()}
        </TabbedCard>
      </div>
    );
  }
}

ProviderFormPage.propTypes = {
  getSingleProviderConfig: propTypes.func.isRequired,
  resetSingleProviderData: propTypes.func.isRequired,
  edition: propTypes.bool.isRequired,
  provider: propTypes.shape({
    isLoading: propTypes.bool,
    error: propTypes.object,
    data: propTypes.object,
  }).isRequired,
  match: propTypes.shape({
    params: propTypes.shape({
      name: propTypes.string,
      id: propTypes.string,
    }),
  }).isRequired,
};

ProviderFormPage.defaultProps = {
  edition: true,
};

export default withRouter(
  connect(
    (state) => ({
      provider: state.singleProvider,
    }),
    {
      getSingleProviderConfig,
      resetSingleProviderData,
    }
  )(ProviderFormPage)
);
