import { createLogic } from 'redux-logic';

import { PLAYERSTATS } from '../actions/types';

export const getPlayerStats = createLogic({
  type: PLAYERSTATS.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;

    const address = `/api/v1/players/${id}/stats`;

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: PLAYERSTATS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: PLAYERSTATS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getPlayerStats,
};
