import React from 'react';
import { Page, Grid, Button } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AccessControl from '../../AccessControl';
import TableContainer from '../../Reusable/Table/TableContainer';

import withPageConfig from '../../hoc/withPageConfig';

import { getPromotions } from '../../../actions/promotions';

import { userRoles } from '../../../utils/roles';
import { PROMOTIONS } from '../../../utils/pagesConstans';
import { PROMOTIONS_COLUMNS } from '../../../utils/tableConstans';

class PromotionsPage extends React.Component {
  componentDidMount() {
    const { getPromotions, pagiNumber } = this.props;

    getPromotions(pagiNumber);
  }

  render() {
    const { getPromotions, promotions, onPaginationPageChange } = this.props;

    return (
      <AccessControl
        roles={[
          userRoles.superAdmin,
          userRoles.admin,
          userRoles.brand,
          userRoles.resultsOperator,
          userRoles.reportsManager,
        ]}
        forbiddenRedirect={'/'}
      >
        <Page>
          <Page.Header>
            <div className="w-100 d-flex justify-content-between">
              <Page.Title>Promotions</Page.Title>
              <Link to="/promotions/add">
                <Button color="primary">Add Promotion</Button>
              </Link>
            </div>
          </Page.Header>
          <Grid.Row>
            <div>
              <TableContainer
                asyncAction={getPromotions}
                columns={PROMOTIONS_COLUMNS}
                paginHeaders={promotions.headers}
                data={promotions.data}
                loading={promotions.isLoading}
                onPageChange={onPaginationPageChange}
              />
            </div>
          </Grid.Row>
        </Page>
      </AccessControl>
    );
  }
}

PromotionsPage.propTypes = {
  promotions: propTypes.shape({
    data: propTypes.oneOfType([propTypes.array, propTypes.oneOf([null])]),
    isLoading: propTypes.bool,
    error: propTypes.oneOfType([propTypes.object, propTypes.oneOf([null])]),
    headers: propTypes.oneOfType([propTypes.object, propTypes.oneOf([null])]),
  }).isRequired,

  getPromotions: propTypes.func.isRequired,

  pagiNumber: propTypes.number.isRequired,
  onPaginationPageChange: propTypes.func.isRequired,
};

export default connect(
  (state) => ({
    promotions: state.promotions,
  }),
  {
    getPromotions,
  }
)(withPageConfig(PROMOTIONS)(PromotionsPage));
