import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Container, Wrapper, Clock, ClockItem, ClockItemText } from './CountdownStyles';

class Countdown extends React.Component {
  state = {
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
  };

  componentDidMount() {
    const { field, draw } = this.props;
    const drawDate = draw[field];

    if (!drawDate || draw.status !== 'open') {
      return null;
    }

    const eventTime = moment(drawDate, 'YYYY-MM-DD HH:mm:ss-ZZ').unix();
    let currentTime, diffTime, duration;
    const interval = 1000;

    if (diffTime < 0) {
      return;
    }

    this.timer = setInterval(() => {
      //counting duration in every interval to avoid JS messing up time
      currentTime = moment().unix();
      diffTime = eventTime - currentTime;
      duration = moment.duration(diffTime * 1000, 'milliseconds');

      duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');

      if (duration.asMilliseconds() <= 0) {
        clearInterval(this.timer);

        this.setState({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }

      this.setState({
        days: moment.duration(duration).days(),
        hours: moment.duration(duration).hours(),
        minutes: moment.duration(duration).minutes(),
        seconds: moment.duration(duration).seconds(),
      });
    }, interval);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    const daysClock = days < 10 ? `0${days}` : days;
    const hoursClock = hours < 10 ? `0${hours}` : hours;
    const minutesClock = minutes < 10 ? `0${minutes}` : minutes;
    const secondsClock = seconds < 10 ? `0${seconds}` : seconds;

    if (!days && !hours && !minutes && !seconds) {
      return null;
    }

    return (
      <Container>
        <Wrapper>
          <Clock>
            <ClockItem>
              <span>{daysClock}</span>
              <ClockItemText>days</ClockItemText>
            </ClockItem>
            <ClockItem>
              <span>{hoursClock}</span>
              <ClockItemText>hrs</ClockItemText>
            </ClockItem>
            <ClockItem>
              <span>{minutesClock}</span>
              <ClockItemText>min</ClockItemText>
            </ClockItem>
            <ClockItem>
              <span>{secondsClock}</span>
              <ClockItemText>sec</ClockItemText>
            </ClockItem>
          </Clock>
        </Wrapper>
      </Container>
    );
  }
}

Countdown.propTypes = {
  draw: PropTypes.object,
  field: PropTypes.string,
};

export default Countdown;
