import TimeFormatter from './TimeFilterFormatter';

class BetQueryBuilder {
  static getBetQuery(filters) {
    const brandId = filters.parentBrand ? filters.parentBrand.id : '';
    const playerID = filters.player.id || '';
    const playerName = filters.player.name || '';
    const dateFrom = filters.dateFrom;
    const dateTo = filters.dateTo;
    const betID = filters.betID;
    const gameID = filters.lotteryType ? filters.lotteryType.id : '';
    const drawID = filters.drawID;

    const gameIDUrl = gameID ? `search[game][game-uuid]=${gameID}` : '';
    const brandIdUrl = brandId ? `search[brand][brand-uuid]=${brandId}` : '';
    const drawIDUrl = drawID ? `search[gameDraw][uuid]=${drawID}` : '';
    const playerIDUrl = playerID ? `search[player][uuid]=${playerID}` : '';
    const playerNameUrl = playerName ? `search[player][name]=${playerName}` : '';
    const dateBeforeUrl = dateTo ? `search[createdAt][datetime-range][before]=${TimeFormatter(dateTo, 'to')}` : '';
    const dateAfterUrl = dateFrom ? `search[createdAt][datetime-range][after]=${TimeFormatter(dateFrom)}` : '';
    const betIDUrl = betID ? `search[id][uuid]=${betID}` : '';

    const query = [gameIDUrl, brandIdUrl, playerIDUrl, dateBeforeUrl, dateAfterUrl, playerNameUrl, betIDUrl, drawIDUrl]
      .filter((queryPart) => queryPart != '')
      .map((queryPart) => '&' + queryPart)
      .join('');
    return query;
  }
}

export default BetQueryBuilder;
