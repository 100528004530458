import React from 'react';
import propTypes from 'prop-types';

import Spinner from '../Reusable/Spinner/Spinner';
import ErrorHandler from '../Reusable/ErrorHandler/ErrorHandler';
import CustomTable from '../Reusable/Table/CustomTable';

import { toUpperCase } from '../../utils/toUpperCase';

class BetList extends React.Component {
  getLinesColumns() {
    const { data } = this.props;

    const asArrayColumns = Object.keys(data[0].selection.asArray)
      .map((elem) => {
        if (elem === 'chance' || elem === 'turbo' || elem === 'nap') {
          return {
            header: toUpperCase(elem),
            type: 'string',
            accessor: `selection.asArray.${elem}`,
          };
        }

        return {
          header: toUpperCase(elem),
          type: 'tableAsString',
          accessor: `selection.asArray.${elem}`,
        };
      })
      .sort((elem) => (elem.header === 'Main' ? -1 : 1));

    return [
      ...asArrayColumns,
      {
        header: 'Winnings',
        type: 'price',
        accessor: 'wonPrize.amount.amount',
        currencyAccessor: 'wonPrize.amount.currency',
      },
    ];
  }

  handleChangeEntries = (pageNumber, entries) => {
    const { asyncAction, id } = this.props;
    asyncAction(id, pageNumber, entries);
  };

  handlePageChange = (pageNumber) => {
    const { asyncAction, id } = this.props;
    asyncAction(id, pageNumber);
  };

  renderLines() {
    const { data, headers, error, pending, isDefaultBet } = this.props;

    if (pending) {
      return <Spinner />;
    }

    if (error) {
      return <ErrorHandler error={error} />;
    }

    if (!data || data.length === 0) {
      return <div className="alert alert-primary">There is no data</div>;
    }

    return (
      <CustomTable
        onPageChanged={this.handlePageChange}
        onChangeEntries={this.handleChangeEntries}
        columns={this.getLinesColumns()}
        data={data}
        paginHeaders={headers}
        allowedPagination={isDefaultBet}
      />
    );
  }

  render() {
    return this.renderLines();
  }
}

BetList.propTypes = {
  id: propTypes.string,
  asyncAction: propTypes.func,
  data: propTypes.array,
  headers: propTypes.object,
  error: propTypes.object,
  pending: propTypes.bool,
  isDefaultBet: propTypes.bool,
};

export default BetList;
