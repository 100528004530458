import { DRAWS_FILTER } from '../../actions/types';

const initialState = {
  lotteryType: {},
  brand: {},
  showFutureDraws: true,
  showOnlyNotProcessedDraws: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAWS_FILTER.LOTTERY_TYPE:
      return {
        ...state,
        lotteryType: action.payload || {},
      };
    case DRAWS_FILTER.PARENT_BRAND:
      return {
        ...state,
        brand: action.payload || {},
      };
    case DRAWS_FILTER.FUTURE_DRAWS:
      return {
        ...state,
        showFutureDraws: action.payload,
      };
    case DRAWS_FILTER.NOT_PROCESSED:
      return {
        ...state,
        showOnlyNotProcessedDraws: action.payload,
      };
    case DRAWS_FILTER.CLEAR:
      return initialState;
    default:
      return state;
  }
};
