import React from 'react';
import PropTypes from 'prop-types';
import { Card, Alert } from 'tabler-react';

import LabeledValue from '../Reusable/LabeledValue';

import { setProperMoneyValue } from '../../utils/money';
import { DRAW_STATUS_PROCESSED } from '../../utils/constans/drawConsts';

class DrawFinancials extends React.Component {
  renderInfoBox() {
    const { status } = this.props;

    if (status === DRAW_STATUS_PROCESSED) {
      return null;
    }

    return (
      <Alert type="primary" icon="bell">
        Draw is not processed, data is estimated
      </Alert>
    );
  }

  render() {
    const { financial } = this.props.drawStats;
    const { jackpotBalance } = this.props;

    return (
      <Card>
        <Card.Status color="blue" />
        <Card.Body>
          <h3>Financials</h3>
          {this.renderInfoBox()}
          <LabeledValue
            value={setProperMoneyValue(financial.totalBetValue)}
            label="Total Bet Value"
            labelWidth="50"
          ></LabeledValue>
          <LabeledValue
            value={setProperMoneyValue(financial.totalPayouts)}
            label="Total Payouts"
            labelWidth="50"
          ></LabeledValue>
          <LabeledValue value={setProperMoneyValue(financial.ggr)} label="GGR" labelWidth="50"></LabeledValue>
          <LabeledValue value={setProperMoneyValue(jackpotBalance)} label="Jackpot Balance" labelWidth="50" />
        </Card.Body>
      </Card>
    );
  }
}

DrawFinancials.propTypes = {
  drawStats: PropTypes.object.isRequired,
  jackpotBalance: PropTypes.object,
  status: PropTypes.string,
};

export default DrawFinancials;
