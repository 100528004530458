import React from 'react';
import { Page, Grid } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { IntegrationConfiguration as ConfigForm } from '../../Lottery';
import Spinner from '../../Reusable/Spinner';
import Modal from '../../Reusable/Modal/CustomModal';

import { deleteProviderConfig, postNewConfig, putProviderConfig } from '../../../actions/providersActions';
import { getTimezones } from '../../../actions/lotteryActions';
import { getAvailableWinningSelections } from '../../../actions/systemConsts';
import providerSelectors from '../../../selectors/providers';

import { getProviderFormBuilderData } from '../../../utils/providers/providers';
import { WITH_WINNING_SELECTIONS_CLASS, WITH_TIMEZONES } from '../../../utils/providers/data/commonConstans';

class ProviderFormGeneral extends React.Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    const { name, getTimezones, getAvailableWinningSelections } = this.props;

    if (WITH_TIMEZONES.indexOf(name) !== -1) {
      getTimezones();
    }

    if (WITH_WINNING_SELECTIONS_CLASS.indexOf(name) !== -1) {
      getAvailableWinningSelections();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      history: { push },
      isAsyncActionSuccess,
      name,
    } = this.props;

    if (prevProps.isAsyncActionSuccess !== isAsyncActionSuccess && isAsyncActionSuccess) {
      push(`/providers/${name}`);
    }
  }

  handleSubmit = (values) => {
    const { edition, postNewConfig, putProviderConfig, name, id } = this.props;

    if (edition) {
      putProviderConfig(name, id, values);
      return;
    }

    postNewConfig(name, values);
  };

  handleDelete = () => {
    const { deleteProviderConfig, name, id } = this.props;

    deleteProviderConfig(name, id);
  };

  handleModalToggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  renderContent() {
    const {
      provider: { isLoading, data, error },
      edition,
      timezones,
      availableWinningSelections,
      name,
    } = this.props;
    const formName = `${edition ? 'Edit' : 'Add'} Configuration`;

    if (isLoading && edition) {
      return <Spinner />;
    }

    if (!data && edition) {
      return null;
    }

    if (error && edition) {
      return <div className="alert alert-danger">{error.response.data.message}</div>;
    }

    const additionalData = {
      timezones,
      availableWinningSelections,
    };

    return (
      <ConfigForm
        name={formName}
        fields={getProviderFormBuilderData(name, additionalData)}
        data={data}
        onSubmit={(values) => this.handleSubmit(values)}
        onDelete={this.handleModalToggle}
        supportSubmit
        supportDeletion
      />
    );
  }

  render() {
    const { edition } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Page>
        <Page.Header>
          <div className="w-100 d-flex justify-content-between">
            <Page.Title>{edition ? 'Edit configuration' : 'Add new configuration'}</Page.Title>
          </div>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={6}>{this.renderContent()}</Grid.Col>
        </Grid.Row>
        <Modal
          isOpen={isModalOpen}
          toggleModal={this.handleModalToggle}
          handleSubmit={() => {
            this.handleDelete();
            this.handleModalToggle();
          }}
          title="Are you sure you want to delete this config?"
          classname="react-modal-custom__delete"
          statusColor="danger"
          btnConfirmText="Confirm"
          btnConfirmColor="danger"
        />
      </Page>
    );
  }
}

ProviderFormGeneral.propTypes = {
  name: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  edition: propTypes.bool.isRequired,
  isAsyncActionSuccess: propTypes.bool.isRequired,
  provider: propTypes.shape({
    isLoading: propTypes.bool,
    error: propTypes.object,
    data: propTypes.object,
  }).isRequired,
  match: propTypes.shape({
    params: propTypes.shape({
      name: propTypes.string,
      id: propTypes.string,
    }),
  }).isRequired,
  history: propTypes.shape({
    push: propTypes.func,
  }).isRequired,
  deleteProviderConfig: propTypes.func.isRequired,
  postNewConfig: propTypes.func.isRequired,
  putProviderConfig: propTypes.func.isRequired,
  getTimezones: propTypes.func,
  timezones: propTypes.array,
  getAvailableWinningSelections: propTypes.func,
  availableWinningSelections: propTypes.array,
};

export default withRouter(
  connect(
    (state) => ({
      timezones: state.lottery.timezonesData.timezones,
      availableWinningSelections: state.availableWinningSelections.data,
      isAsyncActionSuccess: providerSelectors.getIsAsyncSuccess(state),
    }),
    {
      deleteProviderConfig,
      postNewConfig,
      putProviderConfig,
      getTimezones,
      getAvailableWinningSelections,
    }
  )(ProviderFormGeneral)
);
