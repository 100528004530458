import React from 'react';
import { Grid, Button } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import SearchPlayers from '../FormFields/SearchPlayers';

import { changeIdFilter, changeNameFilter, changePhoneFilter, resetFilters } from '../../../actions/players';

class PlayersFilters extends React.Component {
  handleResetClick = () => {
    const { onFilterSubmit, resetFilters } = this.props;

    resetFilters();
    onFilterSubmit();
  };

  handleFilterSubmit = () => {
    const { onFilterSubmit, id, onPlayerIdSubmit } = this.props;

    if (id) {
      onPlayerIdSubmit(id);
      return;
    }

    onFilterSubmit();
  };

  render() {
    const { changeIdFilter, changeNameFilter, changePhoneFilter, name, phone, id } = this.props;

    return (
      <div className="my-2">
        <Grid.Row>
          <div className="col-lg-4">
            <SearchPlayers updateFilters={(value) => changeNameFilter(value)} value={name} type="Player name" />
          </div>
          <div className="col-lg-4">
            <SearchPlayers updateFilters={(value) => changeIdFilter(value)} value={id} type="Player id" />
          </div>
          <div className="col-lg-4">
            <SearchPlayers updateFilters={(value) => changePhoneFilter(value)} value={phone} type="Player phone" />
          </div>
          <div className="col-lg-12">
            <Button onClick={() => this.handleFilterSubmit()} className="mx-2" color="primary">
              Filter
            </Button>
            <Button onClick={() => this.handleResetClick()} className="mx-2" color="secondary">
              Reset
            </Button>
          </div>
        </Grid.Row>
      </div>
    );
  }
}

PlayersFilters.propTypes = {
  name: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  phone: propTypes.string.isRequired,

  changeIdFilter: propTypes.func.isRequired,
  changeNameFilter: propTypes.func.isRequired,
  changePhoneFilter: propTypes.func.isRequired,
  resetFilters: propTypes.func.isRequired,

  onPlayerIdSubmit: propTypes.func.isRequired,

  onFilterSubmit: propTypes.func.isRequired,
};

export default connect(
  (state) => ({
    name: state.playersFilters.name,
    id: state.playersFilters.id,
    phone: state.playersFilters.phone,
  }),
  {
    changeIdFilter,
    changeNameFilter,
    changePhoneFilter,
    resetFilters,
  }
)(PlayersFilters);
