import changeState from './generic/changeState';
import genericAsyncReducer from './generic/genericAsyncReducer';
import reducerFactory from './generic/reducerFactory';

import { toUpperSnakeCase } from '../utils/toSnakeCase';

const PROVIDER_DRAWS = 'PROVIDER_DRAWS';
const PROVIDER_DRAW = 'PROVIDER_DRAW';
const GAME_DRAWS = 'GAME_DRAWS';
const PROVIDER_DRAW_GAME_DRAWS = `${PROVIDER_DRAWS}_${GAME_DRAWS}`;
const FETCH = 'FETCH';

const initialFiltersState = {
  dateFrom: null,
  dateTo: null,
  type: null,
  provider: null,
  status: null,
  hideFutureDraws: true,
  hideProcessedDraws: true,
};

const filtersHandlers = {};

Object.keys(initialFiltersState).forEach((elem) => {
  filtersHandlers[`PROVIDER_DRAWS_FILTERS_${toUpperSnakeCase(elem)}`] = changeState(elem);
});

const providerDraws = genericAsyncReducer(PROVIDER_DRAWS, FETCH);
const providerDraw = genericAsyncReducer(PROVIDER_DRAW, FETCH);
const providerDrawGameDraws = genericAsyncReducer(PROVIDER_DRAW_GAME_DRAWS, FETCH);
const providerDrawFilters = reducerFactory(initialFiltersState, filtersHandlers, PROVIDER_DRAWS);

export default {
  providerDraws,
  providerDraw,
  providerDrawFilters,
  providerDrawGameDraws,
};
