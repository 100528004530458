import { createLogic } from 'redux-logic';

import { BRAND, BRANDS } from '../actions/types';

export const getBrands = createLogic({
  type: BRANDS.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page } = action.payload;
    const perPage = getState().brands.entries;
    const searchTerm = getState().brands.searchTerm;
    let address = '';

    if (searchTerm && searchTerm.length >= 3) {
      address = `/api/v1/brands?page=${page}&perPage=${perPage}&search[name][phrase]=${searchTerm}`;
    } else {
      address = `/api/v1/brands?page=${page}&perPage=${perPage}`;
    }

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: BRANDS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: BRANDS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const deleteBrand = createLogic({
  type: BRANDS.DELETE_START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;
    return httpClient
      .delete(`/api/v1/brands/${id}`)
      .then(() => {
        dispatch({
          type: BRANDS.DELETE_SUCCESS,
          payload: id,
        });
      })
      .catch((err) => {
        dispatch({
          type: BRANDS.DELETE_ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getBrand = createLogic({
  type: BRAND.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;

    return httpClient
      .get(`/api/v1/brands/${id}`)
      .then((res) => {
        dispatch({
          type: BRAND.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BRAND.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const modifyBrand = createLogic({
  type: BRANDS.MODIFY_START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = action.payload.id ? `/api/v1/brands/${action.payload.id}` : '/api/v1/brands/';
    const req = action.payload.id
      ? httpClient.put(url, action.payload.data)
      : httpClient.post(url, action.payload.data);

    return req
      .then((res) => {
        dispatch({
          type: BRANDS.MODIFY_SUCCESS,
          payload: res.data,
        });
        if (action.payload.id) {
          dispatch({
            type: BRAND.FETCH,
            payload: action.payload.id,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: BRANDS.MODIFY_ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getBrands,
  getBrand,
  deleteBrand,
  modifyBrand,
};
