import React from 'react';
import propTypes from 'prop-types';
import { Formik } from 'formik';

import Dater from '../../Reusable/Dater/Dater';
import { Field } from '../../Reusable/FormFields';

class ProviderDrawDetails extends React.Component {
  render() {
    const { providerDraw } = this.props;

    return (
      <Formik
        initialValues={{
          status: providerDraw.status,
          registeredResultsAgency: providerDraw.registeredGameResultAt,
          originalDrawTime: providerDraw.originalDrawTime,
          drawTime: providerDraw.drawTime,
          providerId: providerDraw.gameResultAgencyConfig.id,
          providerType: providerDraw.gameResultAgencyConfig.type.toLowerCase(),
          providerName: providerDraw.gameResultAgencyConfig.name,
        }}
        render={({ values }) => (
          <form className="add-brand-form drawStyles">
            <div className="form-group">
              <Field
                value={values.providerName}
                name="providerId"
                label="Results provider"
                readonly={true}
                link={`/providers/${values.providerType}/${values.providerId}`}
              />
            </div>
            <div className="form-group">
              <Field value={values.status} name="status" label="Status" readonly={true} />
            </div>
            <label>Registered at Results Agency</label>
            <div className="fieldWrapper">
              <Dater time={values.registeredResultsAgency} />
            </div>
            <label>Original Draw Time</label>
            <div className="fieldWrapper">
              <Dater time={values.originalDrawTime} />
            </div>
            <label>Draw Time</label>
            <div className="fieldWrapper">
              <Dater time={values.drawTime} />
            </div>
          </form>
        )}
      />
    );
  }
}

ProviderDrawDetails.propTypes = {
  providerDraw: propTypes.object.isRequired,
};

export default ProviderDrawDetails;
