import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'tabler-react';

class AddTicketCost extends React.Component {
  state = {
    amount: '',
  };

  handleAmountChange = (e) => {
    const { onBasePriceChange, currency } = this.props;

    onBasePriceChange(e.target.value, currency);
    this.setState({
      amount: e.target.value,
    });
  };

  renderMainContent() {
    const { currency } = this.props;
    const { value } = this.state;

    return (
      <Table hasOutline highlightRowOnHover className="table-vcenter">
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Currency</Table.ColHeader>
            <Table.ColHeader>Value</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Col className="border-none w-50">
              <input
                type="text"
                name="currency"
                className="form-control"
                value={currency}
                placeholder={'Choose currency'}
                readOnly
              />
            </Table.Col>
            <Table.Col className="border-none w-50">
              <input
                type="number"
                min={0}
                step="any"
                name={'amount'}
                className="form-control"
                placeholder="Base ticket cost"
                value={value}
                onChange={this.handleAmountChange}
              />
            </Table.Col>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <Card.Status color="blue" />
          <Card.Header>Edit ticket base cost:</Card.Header>
          <Card.Body>{this.renderMainContent()}</Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

AddTicketCost.propTypes = {
  currency: PropTypes.object,
  onBasePriceChange: PropTypes.func,
};

export default AddTicketCost;
