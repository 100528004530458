import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Async from 'react-select/lib/Async';

import httpClient from '../../../services/httpClient';
import { changeLotteryType } from '../../../actions/lotteryActions';

class SearchLottery extends React.Component {
  sortData(data) {
    return data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }

  getOptions = (text) => {
    const { brandId, game, searchByStructureName } = this.props;
    const searchQuery = searchByStructureName ? 'search[lottery.name][phrase]' : 'search[name][phrase]';

    if (game) {
      const params = {
        'search[brand][uuid]': brandId || null,
        [searchQuery]: text || null,
      };

      return httpClient
        .get(`/api/v1/games`, { params })
        .then((res) => {
          return this.sortData(res.data);
        })
        .catch(() => {
          return [];
        });
    }

    const params = {
      [searchQuery]: text || null,
    };

    return httpClient
      .get(`/api/v1/lotteries`, { params })
      .then((res) => {
        return this.sortData(res.data);
      })
      .catch(() => {
        return [];
      });
  };

  handleChange = (type) => {
    const { setFieldValue, handleChange } = this.props;

    if (setFieldValue && typeof setFieldValue === 'function') {
      setFieldValue('lottery', type);
    }

    if (handleChange && typeof handleChange === 'function') {
      handleChange(type);
    }
  };

  getOptionLabel = (option) => {
    const { searchByStructureName } = this.props;

    return searchByStructureName ? option.lottery.name : option.name;
  };

  render() {
    const { className, disabled, handleBlur, value, labelText, brandId } = this.props;

    return (
      <React.Fragment>
        <label htmlFor="search-lottery">{labelText}</label>
        <Async
          key={brandId}
          id="search-lottery"
          className={`${className}`}
          defaultOptions
          loadOptions={this.getOptions}
          onChange={this.handleChange}
          isDisabled={disabled}
          onBlur={handleBlur}
          value={value}
          getOptionLabel={(option) => this.getOptionLabel(option)}
          getOptionValue={(option) => option.id}
          isClearable={true}
          placeholder={'Type to search...'}
          styles={{ control: (styles) => ({ ...styles, backgroundColor: 'white' }) }}
        />
      </React.Fragment>
    );
  }
}

SearchLottery.defaultProps = {
  labelText: 'Lottery supplier',
};

SearchLottery.propTypes = {
  brandId: PropTypes.string,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  className: PropTypes.string,
  searchByStructureName: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  changeLotteryType: PropTypes.func,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  labelText: PropTypes.string,
  game: PropTypes.bool,
};

export default connect(null, {
  changeLotteryType,
})(SearchLottery);
