import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Alert } from 'tabler-react';
import Modal from 'react-modal';

import ResultConfirmationForm from '../ResultConfirmationForm';
import moment from 'moment';

class ResultConfirmationModal extends React.Component {
  renderForm() {
    const { toggleModal, data, onFormSubmit, onDoneClick, results, drawResultsData, errorMessage } = this.props;
    const { success } = drawResultsData;

    if (!data) {
      return <Alert type="danger">{errorMessage}</Alert>;
    }

    return (
      <ResultConfirmationForm
        onCancel={toggleModal}
        onFormSubmit={onFormSubmit}
        winningStructure={data}
        results={results}
        success={success}
        onDoneClick={onDoneClick}
      />
    );
  }

  render() {
    const { isOpen, toggleModal, drawResultsData, lotteryName, drawDate, userData } = this.props;
    const { error, isError, success } = drawResultsData;

    return (
      <Modal isOpen={isOpen} onRequestClose={() => toggleModal(null)} className={'react-modal-custom h-auto'}>
        <Card>
          <Card.Status color={'blue'} />
          <Card.Header>
            <Card.Title>
              <p className="m-0">Submit Results</p>
              <Button className="react-modal-close" color="danger" onClick={() => toggleModal(null)}>
                X
              </Button>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="w-100">
              {isError && (
                <Alert type="danger" icon="alert-triangle">
                  {error.message}
                </Alert>
              )}
              {success && <Alert type="success">Results send successfully</Alert>}
            </div>
            <div>
              {lotteryName && <p className="m-0 font-weight-bold">{lotteryName}</p>}
              <p className="font-weight-bold">{moment(drawDate).format('DD-MM-YYYY HH:mm')}</p>
            </div>
            {userData && userData.candidateProviderUser && (
              <div>
                <p>
                  <span className="font-weight-bold">Results candidate provided</span>
                  <br />
                  by: {userData.candidateProviderUser.firstName} ({userData.candidateProviderUser.email})
                  <br />
                  at: {moment(userData.candidateResultsProvidedAt).format('DD-MM-YYYY HH:mm')}
                </p>
              </div>
            )}
            {userData && userData.confirmationProviderUser && (
              <div>
                <p>
                  <span className="font-weight-bold">Results confirmation provided</span>
                  <br />
                  by: {userData.confirmationProviderUser.firstName} ({userData.confirmationProviderUser.email})
                  <br />
                  at:{' '}
                  {userData.resultsConfirmedAt ? moment(userData.resultsConfirmedAt).format('DD-MM-YYYY HH:mm') : null}
                </p>
              </div>
            )}
            {this.renderForm()}
          </Card.Body>
        </Card>
      </Modal>
    );
  }
}

ResultConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  onFormSubmit: PropTypes.func,
  data: PropTypes.array,
  drawResultsData: PropTypes.object,
  onDoneClick: PropTypes.func,
  lotteryName: PropTypes.string,
  drawDate: PropTypes.string,
  userData: PropTypes.object,
  results: PropTypes.object,
  errorMessage: PropTypes.string,
};

export default ResultConfirmationModal;
