import { LOTTERY, LOTTERIES, TIMEZONES, BETTING_AGENCIES, BETTING_AGENCY, LOTTERY_SCHEDULE_POST } from './types';

export const getLottery = (id) => ({
  type: LOTTERY.FETCH,
  payload: id,
});

export const clearLottery = () => ({
  type: LOTTERY.CLEAR,
});

export const getLotteries = (page, phrase) => ({
  type: LOTTERIES.FETCH,
  payload: {
    page,
    phrase,
  },
});

export const deleteLottery = (id) => ({
  type: LOTTERIES.DELETE_START,
  payload: id,
});

export const changeEntries = (entries) => ({
  type: LOTTERIES.CHANGE_ENTRIES,
  payload: entries,
});

export const changeLotteryType = (text) => ({
  type: LOTTERY.CHANGE_STRUCT_START,
  payload: text,
});

export const resetLotteryStruct = () => ({
  type: LOTTERY.CHANGE_STRUCT_RESET,
});

export const addLottery = (values, action) => ({
  type: LOTTERY.ADD_START,
  payload: {
    values: values,
    action: action,
  },
});

export const updateLottery = (path, value) => ({
  type: LOTTERY.UPDATE,
  payload: {
    path,
    value,
  },
});

export const getLotteryStructure = (value) => ({
  type: LOTTERY.STRUCTURE_GET,
  payload: value,
});

export const changeLotteryStructure = (path, value) => ({
  type: LOTTERY.STRUCTURE_CHANGE,
  payload: {
    path,
    value,
  },
});

export const resetLotteryStructure = () => ({
  type: LOTTERY.STRUCTURE_RESET,
});

export const getTimezones = () => ({
  type: TIMEZONES.FETCH,
});

export const getBettingAgencies = () => ({
  type: BETTING_AGENCIES.START,
});

export const getBettingAgency = (id) => ({
  type: BETTING_AGENCY.START,
  payload: id,
});

export const resetBettingAgency = () => ({
  type: BETTING_AGENCY.RESET,
});

export const postOverrideSchedule = (id, schedule) => ({
  type: LOTTERY_SCHEDULE_POST.START,
  payload: {
    id,
    schedule,
  },
});

export const editMessageTemplates = (data) => ({
  type: LOTTERY.MESSAGE_TEMPLATE_START,
  payload: {
    data,
  },
});

export const changeLotteryFilter = (lottery) => ({
  type: LOTTERY.CHANGE_LOTTERY_FILTER,
  payload: lottery,
});

export const changeLotteryStatus = (status) => ({
  type: LOTTERY.CHANGE_STATUS_FILTER,
  payload: status,
});

export const changeBrandFilter = (brand) => ({
  type: LOTTERY.CHANGE_BRAND_FILTER,
  payload: brand,
});

export const resetLotteryFilters = () => ({
  type: LOTTERY.RESET_LOTTERY_FILTERS,
});

export const changePrizePoolPercentage = (value) => ({
  type: LOTTERY.CHANGE_PRIZE_POOL_PERCENTAGE,
  payload: value,
});

export const changeLotteryMainCurrency = (value) => ({
  type: LOTTERY.CHANGE_MAIN_CURRENCY,
  payload: value,
});

export const changeBaseTicketCost = (value) => ({
  type: LOTTERY.CHANGE_BASE_TICKET_COST,
  payload: value,
});

export const resetCurrency = () => ({
  type: LOTTERY.RESET_CURRENCY,
});
