import { NOTIFICATION } from '../actions/types';

const initialState = {
  isOpen: false,
  isSuccess: false,
  isError: false,
  message: '',
  onReset: null,
  time: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION.OPEN_SUCCESS:
      return {
        isOpen: true,
        isSuccess: true,
        message: action.payload.message,
        time: action.payload.time,
        isSystemNotification: action.payload.isSystemNotification,
      };
    case NOTIFICATION.OPEN_ERROR:
      return {
        isOpen: true,
        isError: true,
        message: action.payload.message,
        time: action.payload.time,
        isSystemNotification: action.payload.isSystemNotification,
      };
    case NOTIFICATION.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
