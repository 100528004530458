import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import numeral from 'numeral';

import NetworkService from './services/networkService';
import configureStore from './store/configureStore';
import App from './components/App';

import { addLocaleData, IntlProvider } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
const messages = require('./assets/i18n/errors.json');
addLocaleData([...en]);

import './assets/styles/index.scss';
import 'tabler-react/dist/Tabler.css';

const history = createBrowserHistory();

const store = configureStore(history);

NetworkService.setupInterceptors(store);

// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: '€',
  },
});

// switch between locales
numeral.locale('fr');

ReactDOM.render(
  <IntlProvider locale="en" messages={messages}>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
);
