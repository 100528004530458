import React from 'react';
import { Page, Alert } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import AccessControl from '../../AccessControl';
import ProviderDrawsFilters from './ProviderDrawsFilters';
import Spinner from '../../Reusable/Spinner/Spinner';
import CustomTable from '../../Reusable/Table/CustomTable';
import withPageConfig from '../../hoc/withPageConfig';

import { getProviderDraws } from '../../../actions/providerDraws';

import { userRoles } from '../../../utils/roles';
import { PROVIDER_DRAWS_PAGE } from '../../../utils/pagesConstans';

class ProviderDraws extends React.Component {
  state = {
    page: 1,
    perPage: 25,
  };

  componentDidMount() {
    const { getProviderDraws, pagiNumber } = this.props;

    getProviderDraws(pagiNumber);
  }

  handlePageChange = (page) => {
    const { getProviderDraws, onPaginationPageChange } = this.props;
    const { perPage } = this.state;

    onPaginationPageChange(page);
    getProviderDraws(page, perPage);

    this.setState({
      page,
    });
  };

  handleChangeEntriesNumber = (page, entries) => {
    const { getProviderDraws } = this.props;

    getProviderDraws(1, entries);

    this.setState({
      perPage: entries,
    });
  };

  renderContent() {
    const { providerDraws, error, isLoading, headers } = this.props;

    if (error) {
      return (
        <Alert type="danger" icon="alert-triangle">
          Something went wrong. Please try again later.
        </Alert>
      );
    }

    if (isLoading) {
      return <Spinner class="mb-5" />;
    }

    return (
      <CustomTable
        onChangeEntries={this.handleChangeEntriesNumber}
        onPageChanged={this.handlePageChange}
        paginHeaders={headers}
        loading={isLoading}
        data={providerDraws}
        columns={[
          {
            header: 'Agency',
            accessor: 'gameResultAgencyConfig.name',
            type: 'string',
          },
          {
            header: 'Type',
            accessor: 'gameResultAgencyConfig.type',
            type: 'string',
          },
          {
            header: 'Draw time',
            accessor: 'drawTime',
            secondAccessor: 'gameResultAgencyConfig.timezone',
            type: 'date',
          },
          {
            header: 'Provider ID',
            accessor: 'drawIntegrationIdentificator',
            type: 'string',
          },
          {
            header: 'Selections',
            accessor: 'drawResults',
            type: 'drawSelection',
          },
          {
            header: 'Status',
            accessor: 'status',
            type: 'string',
          },
          {
            header: '',
            id: 'id',
            type: 'icons',
            elements: [
              {
                header: '',
                id: 'id',
                type: 'event',
                linkto: '/provider-draws',
                icon: 'info',
              },
            ],
          },
        ]}
      />
    );
  }

  render() {
    return (
      <AccessControl
        roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator]}
        forbiddenRedirect={'/'}
      >
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>
              <span>Provider Draws</span>
            </Page.Title>
          </div>
        </Page.Header>
        <ProviderDrawsFilters />
        {this.renderContent()}
      </AccessControl>
    );
  }
}

ProviderDraws.propTypes = {
  providerDraws: propTypes.array,
  error: propTypes.object,
  isLoading: propTypes.bool,
  headers: propTypes.object,

  getProviderDraws: propTypes.func,
  onPaginationPageChange: propTypes.func,
  pagiNumber: propTypes.number,
};

export default connect(
  (state) => ({
    providerDraws: state.providerDraws.data,
    error: state.providerDraws.error,
    isLoading: state.providerDraws.isLoading,
    headers: state.providerDraws.headers,
  }),
  {
    getProviderDraws,
  }
)(withPageConfig(PROVIDER_DRAWS_PAGE)(ProviderDraws));
