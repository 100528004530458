import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Page, Button, Alert } from 'tabler-react';
import { Link } from 'react-router-dom';

import CustomTable from '../../Reusable/Table/index';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import Spinner from '../../Reusable/Spinner/Spinner';
import CustomModal from '../../Reusable/Modal/CustomModal';
import AccessControl from '../../AccessControl';

import withPageConfig from '../../hoc/withPageConfig';

import { userRoles } from '../../../utils/roles';
import { BRANDS_PAGE } from '../../../utils/pagesConstans';

import httpClient from '../../../services/httpClient';

import { getBrands, deleteBrand, changeEntries, changeSearchTerm } from '../../../actions/brandsActions';

class Brands extends React.Component {
  constructor() {
    super();

    this.state = {
      isDeleteModalOpen: false,
      deleteId: undefined,
      confirmSuccess: false,
      confirmError: null,
    };
  }

  componentDidMount() {
    const { getBrands, pagiNumber } = this.props;

    getBrands(pagiNumber);
  }

  toggleDeleteModal = (id, errorOccured) => {
    this.setState((prevState) => ({
      isDeleteModalOpen: !prevState.isDeleteModalOpen,
      deleteId: id,
      confirmError: errorOccured ? prevState.confirmError : null, //we want to remove the error when we close the modal
    }));
  };

  handlePageChange = (pageNumber) => {
    const { getBrands, onPaginationPageChange } = this.props;

    getBrands(pageNumber);
    onPaginationPageChange(pageNumber);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    const { changeEntries, getBrands } = this.props;

    changeEntries(entries);
    getBrands(1);
  };

  handleDeleteItem = () => {
    httpClient
      .delete(`/api/v1/brands/${this.state.deleteId}`)
      .then(() => {
        this.setState(
          {
            ...this.state,
            confirmSuccess: true,
            confirmError: null,
          },
          () => {
            this.props.getBrands(1);
            this.toggleDeleteModal(null);
          }
        );
      })
      .catch((err) => {
        this.setState(
          {
            ...this.state,
            confirmSuccess: false,
            confirmError: err.response,
          },
          () => this.toggleDeleteModal(null, true)
        );
      });
  };

  handlePhraseSearch = (phrase) => {
    this.props.getBrands(1, phrase);
  };

  render() {
    const { brandsData, brandsPending, brandsError, brandsHeaders } = this.props;

    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin]} forbiddenRedirect={'/'}>
        <div>
          <Page.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Page.Title>
                <span>Brands</span>
              </Page.Title>
              <Link to="/brands/add">
                <Button color="primary">Add brand</Button>
              </Link>
            </div>
          </Page.Header>
          {this.state.confirmSuccess && (
            <Alert type="success" isDismissible>
              Brand has been deleted
            </Alert>
          )}
          {this.state.confirmError && (
            <Alert type="danger" isDismissible>
              {this.state.confirmError.data.message}
            </Alert>
          )}
          {brandsError && <ErrorHandler error={brandsError} />}
          {brandsPending && !brandsHeaders && <Spinner class="mb-5" />}
          {brandsHeaders && (
            <CustomTable
              onChangeEntries={this.handleChangeEntriesNumber}
              onPageChanged={this.handlePageChange}
              onPhraseSearched={this.handlePhraseSearch}
              handleSearchTermChange={this.props.changeSearchTerm}
              deleteHandler={this.handleDeleteItem}
              paginHeaders={brandsHeaders}
              loading={brandsPending}
              data={brandsData}
              columns={[
                {
                  header: 'Name',
                  accessor: 'name',
                  id: 'id',
                  linkto: '/brands',
                  type: 'event',
                },
                {
                  header: 'E-mail',
                  accessor: 'email',
                  type: 'string',
                },
                {
                  header: 'Parent brand',
                  accessor: 'parentBrand.name',
                  id: 'parentBrand.id',
                  linkto: '/brands',
                  type: 'event',
                },
                {
                  header: 'Active',
                  accessor: 'active',
                  type: 'boolean',
                },
                {
                  header: '',
                  id: 'id',
                  accessor: 'delete',
                  type: 'delete',
                  handler: this.toggleDeleteModal,
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
              ]}
            />
          )}

          <CustomModal
            isOpen={this.state.isDeleteModalOpen}
            toggleModal={this.toggleDeleteModal}
            handleSubmit={this.handleDeleteItem}
            reqSuccess={this.state.confirmSuccess}
            reqError={this.state.confirmError}
            successMessage="Brand has been deleted"
            title="Are you sure you want to delete this?"
            classname="react-modal-custom__delete"
            statusColor="red"
            btnConfirmText="Delete"
            icon="trash"
          />
        </div>
      </AccessControl>
    );
  }
}

Brands.propTypes = {
  getBrands: PropTypes.func,
  brandsData: PropTypes.array,
  brandsPending: PropTypes.bool,
  brandsHeaders: PropTypes.object,
  brandsError: PropTypes.object,
  brandsDeleteError: PropTypes.object,
  changeEntries: PropTypes.func,
  deleteBrand: PropTypes.func,
  changeSearchTerm: PropTypes.func,
  pagiNumber: PropTypes.number,
  onPaginationPageChange: PropTypes.func,
};

export default connect(
  (state) => ({
    brandsError: state.brands.error,
    brandsPending: state.brands.isPending,
    brandsData: state.brands.data,
    brandsHeaders: state.brands.headers,
    brandsDeleteError: state.brands.delete.error,
  }),
  {
    getBrands,
    deleteBrand,
    changeEntries,
    changeSearchTerm,
  }
)(withPageConfig(BRANDS_PAGE)(Brands));
