import { i18n } from './types';

export const getLanguages = (id) => ({
  type: i18n.FETCH,
  payload: { id },
});

export const getLanguage = (id) => ({
  type: i18n.LANGUAGE.FETCH.START,
  payload: id,
});

export const saveLanguage = (language, id) => ({
  type: i18n.LANGUAGE.PATCH.START,
  payload: { language, id },
});

export const addLanguage = (language, id) => ({
  type: i18n.LANGUAGE.POST.START,
  payload: { language, id },
});

export const clearLanguage = () => ({
  type: i18n.LANGUAGE.CLEAR,
});

export const clearLanguages = () => ({
  type: i18n.CLEAR,
});

export const deleteLanguage = (languageId, lotteryId) => ({
  type: i18n.LANGUAGE.DELETE,
  payload: { languageId, lotteryId },
});
