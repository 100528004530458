import moment from 'moment';
import FileSaver from 'file-saver';

import httpClient from './httpClient';
import TimeFormatter from './TimeFilterFormatter';
class Reports {
  static getFilterParams({
    lotteryType,
    parentBrand,
    player,
    betID,
    drawID,
    period,
    dateFrom,
    dateTo,
    possiblePrizeId,
  }) {
    const gameId = lotteryType?.id;
    const brandId = parentBrand?.id;
    const playerId = player?.id;
    const betId = betID || null;
    const drawId = drawID || null;
    const properPrizeId = possiblePrizeId || null;
    const properPeriod = period?.toLowerCase();
    const fromDate = dateFrom ? TimeFormatter(moment(dateFrom), 'from', period) : null;
    const toDate = dateTo ? TimeFormatter(moment(dateTo), 'to', period) : null;

    const params = {
      gameId,
      brandId,
      playerId,
      betId,
      drawId,
      fromDate,
      toDate,
      possiblePrizeId: properPrizeId,
      period: properPeriod,
    };

    return params;
  }

  static exportToCSV(reportsType, reportsFiltersData) {
    const params = this.getFilterParams(reportsFiltersData);

    httpClient
      .get(`/reports/${reportsType}-report.csv`, { params })
      .then((data) => {
        const { data: fileData } = data;
        const blob = new Blob([fileData], { type: 'text/csv' });

        FileSaver.saveAs(blob, `${reportsType}_reports.csv`);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  static getReportSummaryName(reportsType) {
    switch (reportsType) {
      case 'winnings':
        return 'winnings';
      case 'instantWinnings':
        return 'instant-winnings';
      case 'draws':
        return 'draws';
      case 'players':
        return 'players';
      case 'sales':
        return 'sales';
      case 'transactions':
        return 'transactions';
    }
  }
}

export default Reports;
