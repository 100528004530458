import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Page } from 'tabler-react';

import { AddBrand } from '../../Brands/index';

import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';

class Brand extends React.Component {
  constructor() {
    super();

    this.state = {
      error: null,
    };
  }

  render() {
    const { brand, getBrand, brandId } = this.props;

    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin]} forbiddenRedirect={'/'}>
        <Page>
          <Page.Header>
            <Page.Title>Brand</Page.Title>
          </Page.Header>
          <Grid.Row>
            <Grid.Col md={6}>
              <AddBrand
                brand={brand.data}
                error={brand.error}
                isPending={brand.isPending}
                getBrand={getBrand}
                brandId={brandId}
              />
            </Grid.Col>
          </Grid.Row>
        </Page>
      </AccessControl>
    );
  }
}

Brand.propTypes = {
  brand: PropTypes.object,
  brandId: PropTypes.string,
  getBrand: PropTypes.func,
};

export default Brand;
