import { createLogic } from 'redux-logic';
import FileSaver from 'file-saver';

import { NOTIFICATION, REPORTS, REPORT_SUMMARY } from '../actions/types';

import Reports from '../services/Reports';
import { camelToTrainCase } from '../utils/toSnakeCase';

export const getReports = createLogic({
  type: REPORTS.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page, reportType } = action.payload;
    const state = getState();
    const perPage = state.reports.entries;
    const params = Reports.getFilterParams(state.reportsFilters[reportType]);
    const properType = camelToTrainCase(reportType);
    const address = `/api/backoffice/v1/reports/v1/${properType}-report?page=${page}&perPage=${perPage}`;

    return httpClient
      .get(address, { params })
      .then((res) => {
        dispatch({
          type: REPORTS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
        dispatch({
          type: REPORT_SUMMARY.FETCH,
          payload: reportType,
        });
      })
      .catch((err) => {
        dispatch({
          type: REPORTS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getReportsCSV = createLogic({
  type: REPORTS.CSV.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { reportType } = action.payload;
    const state = getState();
    const params = Reports.getFilterParams(state.reportsFilters[reportType]);
    const address = `/reports/${reportType}-report.csv`;

    dispatch({
      type: NOTIFICATION.OPEN_SUCCESS,
      payload: {
        message: `CSV is downloading. Please be patient`,
      },
    });

    return httpClient
      .get(address, { params })
      .then((data) => {
        const { data: fileData } = data;
        const blob = new Blob([fileData], { type: 'text/csv' });

        FileSaver.saveAs(blob, `${reportType}_reports.csv`);
      })
      .catch((err) => {
        dispatch({
          type: REPORTS.CSV.ERROR,
          payload: err.response,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: `Something went wrong`,
          },
        });
      })
      .then(() => {
        dispatch({
          type: REPORTS.CSV.SUCCESS,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: `CSV downloaded successfully.`,
          },
        });
        done();
      });
  },
});

export const getReportSummary = createLogic({
  type: REPORT_SUMMARY.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const reportType = action.payload;
    const params = Reports.getFilterParams(getState().reportsFilters[reportType]);

    const address = `/api/backoffice/v1/reports/v1/${Reports.getReportSummaryName(reportType)}-summary`;
    return httpClient
      .get(address, { params })
      .then((res) => {
        dispatch({
          type: REPORT_SUMMARY.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: REPORT_SUMMARY.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getReports,
  getReportSummary,
  getReportsCSV,
};
