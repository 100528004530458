import React from 'react';
import { Grid, Page } from 'tabler-react';

import { AddBrand } from '../../Brands/index';

class AddBrandPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Page>
        <Page.Header>
          <Page.Title>Add brand</Page.Title>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={6}>
            <AddBrand />
          </Grid.Col>
        </Grid.Row>
      </Page>
    );
  }
}

export default AddBrandPage;
