import React from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';

import { SearchLottery, SearchBrand, SearchPlayers, CustomSelect, SearchPlayerNames } from '../FormFields';

import ReportSelectors from '../../../selectors/reportFilters';

import {
  changeEntries,
  changeLotteryType,
  changeDateFrom,
  changeDateTo,
  changeParentBrand,
  changePeriod,
  changePlayer,
  changeBetID,
  resetFilters,
  changeShowWithSales,
  changeDrawID,
  changePossiblePrizeId,
} from '../../../actions/reportsActions';

import { getFormatMode } from '../../../utils/date';
import { INSTANT_WINNINGS, REPORTS_ALL_FILTERS, REPORTS_PLAYER_FILTERS } from '../../../utils/reports/consts';

const Row = styled('div')`
  .rdtPicker {
    width: 100%;
  }
`;

const dateTime = css`
  input {
    background-color: white !important;
  }
`;

const filterButtons = css`
  margin-bottom: 15px;
`;

class ReportsFilters extends React.Component {
  componentDidMount() {
    const { changeDateFrom, changeDateTo, reportsType } = this.props;

    this.getFiltersFromUrl();
    changeDateFrom(moment().subtract(7, 'day'), reportsType);
    changeDateTo(moment(), reportsType);
  }

  getFiltersFromUrl() {
    const { changeDrawID, changePossiblePrizeId, reportsType } = this.props;
    const filters = queryString.parse(location.search);

    Object.keys(filters).map((elem) => {
      if (REPORTS_ALL_FILTERS.indexOf(reportsType) !== -1) {
        if (elem === 'drawId') {
          changeDrawID(filters[elem], reportsType);
        } else if (elem === 'possiblePrizeId') {
          changePossiblePrizeId(filters[elem], reportsType);
        }
      }
    });
  }

  handleChangePlayer = (prop) => {
    const { filters, changePlayer, reportsType } = this.props;

    changePlayer(prop ? { ...filters.player, ...prop } : {}, reportsType);
  };

  handleResetClick = () => {
    const { resetFilters, getReports, reportsType } = this.props;

    resetFilters(reportsType);
    getReports();
  };

  handleChangeFilterValue = (filterAction, value) => {
    const { reportsType } = this.props;

    filterAction(value, reportsType);
  };

  renderPlayerFilter() {
    const { reportsType } = this.props;
    const { player } = this.props.filters;

    if (REPORTS_PLAYER_FILTERS.indexOf(reportsType) !== -1) {
      return (
        <div className={'col-lg-3'}>
          <div className="form-group">
            <SearchPlayerNames
              labelText="Player name"
              handleChange={(player) => this.handleChangePlayer(player)}
              value={player}
            />
          </div>
        </div>
      );
    }
  }

  renderMoreFilters() {
    const { changeDrawID, reportsType } = this.props;
    const { drawID, player, possiblePrizeId } = this.props.filters;

    if (REPORTS_ALL_FILTERS.indexOf(reportsType) === -1) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={'col-lg-3'}>
          <div className="form-group">
            <SearchPlayers
              updateFilters={(value) => this.handleChangePlayer({ id: value.id || value })}
              value={player.id}
              type="Player id"
            />
          </div>
        </div>
        {/* <div className={'col-lg-3'}>
          <div className="form-group">
            <SearchPlayers
              updateFilters={(value) => this.handleChangeFilterValue(changeBetID, value)}
              value={betID}
              type="Bet id"
            />
          </div>
        </div> */}
        {reportsType !== INSTANT_WINNINGS && (
          <div className={'col-lg-3'}>
            <div className="form-group">
              <SearchPlayers
                updateFilters={(value) => this.handleChangeFilterValue(changeDrawID, value)}
                value={drawID}
                type="Draw id"
              />
            </div>
          </div>
        )}
        {drawID && (
          <div className={'col-lg-3'}>
            <div className="form-group">
              <SearchPlayers
                updateFilters={(value) => this.handleChangeFilterValue(changePossiblePrizeId, value)}
                value={possiblePrizeId}
                type="Possible Prize id"
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { dateFrom, dateTo, lotteryType, parentBrand, period } = this.props.filters;
    const {
      changeLotteryType,
      changeDateFrom,
      changeDateTo,
      changeParentBrand,
      changePeriod,
      getReports,
      reportsType,
    } = this.props;
    const { dateFormat, viewMode } = getFormatMode(period);

    return (
      <React.Fragment>
        <Row className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <SearchBrand
                labelText="Brand"
                value={parentBrand}
                handleChange={(value) => this.handleChangeFilterValue(changeParentBrand, value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <SearchLottery
                game
                labelText="Lottery"
                brandId={parentBrand.id}
                value={lotteryType}
                handleChange={(value) => this.handleChangeFilterValue(changeLotteryType, value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <CustomSelect
                name="period"
                label="Period"
                options={['Year', 'Month', 'Day']}
                handleChange={(name, value) => this.handleChangeFilterValue(changePeriod, value.value)}
                value={{ value: period, label: period }}
                isSearchable={false}
                backspaceRemovesValue={false}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label>From</label>
              <Datetime
                className={dateTime}
                value={dateFrom}
                onChange={(value) => this.handleChangeFilterValue(changeDateFrom, value)}
                dateFormat={dateFormat}
                viewMode={viewMode}
                timeFormat={reportsType === 'tickets' ? 'HH:mm:ss' : false}
                closeOnSelect
                inputProps={{ readOnly: true }}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label>To</label>
              <Datetime
                className={dateTime}
                value={dateTo}
                onChange={(value) => this.handleChangeFilterValue(changeDateTo, value)}
                dateFormat={dateFormat}
                viewMode={viewMode}
                timeFormat={reportsType === 'tickets' ? 'HH:mm:ss' : false}
                closeOnSelect
                inputProps={{ readOnly: true }}
              />
            </div>
          </div>
          {this.renderPlayerFilter()}
          {this.renderMoreFilters()}
        </Row>
        <div className={filterButtons}>
          <button className={'firstFilteringButton btn btn-primary'} onClick={() => getReports()}>
            Filter
          </button>
          <button className={'btn btn-secondary'} onClick={this.handleResetClick}>
            Reset
          </button>
        </div>
      </React.Fragment>
    );
  }
}

ReportsFilters.propTypes = {
  reportsType: PropTypes.string.isRequired,

  filters: PropTypes.object,

  getReports: PropTypes.func,
  changeShowWithSales: PropTypes.func,
  changePlayer: PropTypes.func,
  changeBetID: PropTypes.func,
  changeDrawID: PropTypes.func,
  changeLotteryType: PropTypes.func,
  changeDateFrom: PropTypes.func,
  changeDateTo: PropTypes.func,
  changeParentBrand: PropTypes.func,
  changePeriod: PropTypes.func,
  changePossiblePrizeId: PropTypes.func,
  resetFilters: PropTypes.func,
};

export default connect(
  (state, ownProps) => ({
    filters: ReportSelectors.getFilterStatus(state, ownProps.reportsType),
  }),
  {
    changeEntries,
    changeLotteryType,
    changeDateFrom,
    changeDateTo,
    changeParentBrand,
    changePeriod,
    changePlayer,
    changeBetID,
    resetFilters,
    changeShowWithSales,
    changeDrawID,
    changePossiblePrizeId,
  }
)(ReportsFilters);
