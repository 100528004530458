/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from 'tabler-react';
import httpClient from '../../../services/httpClient';

function TicketExportEmail({ data }) {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(true);
  const [email, setEmail] = useState('');

  //loading
  // if (loading) {
  //   return (
  //     <div className="spinner-container text-center mt-5">
  //       <svg
  //         className="spinner"
  //         width="50px"
  //         height="50px"
  //         viewBox="0 0 66 66"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <circle className="path" fill="none" cx="33" cy="33" r="30"></circle>
  //       </svg>
  //     </div>
  //   );
  // }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleDeleteEmailClick = async (name) => {
    try {
      if (!confirm('Are you sure you want to delete this email address?')) {
        return false;
      }

      //update the data property for emails
      if (!data.ticketsExportTargetEmails) {
        data.ticketsExportTargetEmails = [];
      }

      //filter the email without this
      data.ticketsExportTargetEmails = data.ticketsExportTargetEmails.filter((e) => e != name);

      //start loading
      setLoading(true);

      await httpClient.put(`/api/v1/games/${data.id}`, data);

      //end loading
      setLoading(false);

      setEmail('');
      return;
    } catch (err) {
      if (err.response) {
        setError(err.response.data);
      }
    }

    //end loading
    setLoading(false);
  };

  const handleAddEmailClick = async () => {
    try {
      //validate email
      if (!validateEmail(email)) {
        alert('Please enter a valid email address!');
        return false;
      }

      //update the data property for emails
      if (!data.ticketsExportTargetEmails) {
        data.ticketsExportTargetEmails = [];
      }

      //check if email already exists
      if (data.ticketsExportTargetEmails.includes(email)) {
        alert('This email is already added!');
        return false;
      }

      //add the email
      data.ticketsExportTargetEmails.push(email);

      //start loading
      setLoading(true);

      await httpClient.put(`/api/v1/games/${data.id}`, data);

      //end loading
      setLoading(false);

      setEmail('');
      return;
    } catch (err) {
      if (err.response) {
        setError(err.response.data);
      }
    }

    //end loading
    setLoading(false);
  };

  return (
    <div>
      <div className="card">
        <div className="card-status bg-blue"></div>
        <div className="card-header">Tickets Export (CSV file by email)</div>
        <div className="card-body">
          <p>Send email with lines exported to .csv to following receipients:</p>

          {data && data.ticketsExportTargetEmails && (
            <div>
              <ul>
                {data.ticketsExportTargetEmails.map((name, id) => (
                  <li style={{ paddingTop: '10px' }} key={id}>
                    {name}{' '}
                    <Button
                      onClick={() => {
                        handleDeleteEmailClick(name);
                      }}
                      className="btn btn-danger"
                    >
                      X
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="form-group form-check">
            <div className="d-flex">
              <input
                className="form-control"
                name="email"
                type="email"
                value={email}
                onChange={(ev) => {
                  setEmail(ev.target.value);
                }}
              />
              &nbsp;
              <Button disabled={loading} onClick={handleAddEmailClick} className="btn btn-primary">
                +ADD
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketExportEmail;
