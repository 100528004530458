const getFilterStatus = (state, reportType) => {
  return {
    lotteryType: state.reportsFilters[reportType].lotteryType,
    period: state.reportsFilters[reportType].period,
    parentBrand: state.reportsFilters[reportType].parentBrand,
    dateFrom: state.reportsFilters[reportType].dateFrom,
    dateTo: state.reportsFilters[reportType].dateTo,
    player: state.reportsFilters[reportType].player,
    drawID: state.reportsFilters[reportType].drawID,
    betID: state.reportsFilters[reportType].betID,
    possiblePrizeId: state.reportsFilters[reportType].possiblePrizeId,
  };
};

export default {
  getFilterStatus,
};
