import { INSTANT_TICKETS_COLUMNS, TICKETS_COLUMNS } from './consts';

export const getTicketsColumns = (type) => {
  switch (type) {
    case 'default':
      return TICKETS_COLUMNS;
    case 'instant':
      return INSTANT_TICKETS_COLUMNS;
    default:
      return TICKETS_COLUMNS;
  }
};
