const initialState = { data: null, isLoading: false, error: null, isSuccess: false };

const providerReducerFactory = (name, asyncAction) => {
  return (state = initialState, action) => {
    switch (action.type) {
      case `${name}_${asyncAction}_START`:
        return { data: null, isLoading: true, error: null, isSuccess: false };
      case `${name}_${asyncAction}_SUCCESS`:
        return { data: action.payload, isLoading: false, error: null, isSuccess: true };
      case `${name}_${asyncAction}_ERROR`:
        return { data: null, isLoading: false, error: action.payload, isSuccess: false };
      case `${name}_RESET`:
        return initialState;
      default:
        return state;
    }
  };
};

const providersList = providerReducerFactory('PROVIDERS', 'GET');
const singleProvider = providerReducerFactory('SINGLE_PROVIDER', 'GET');
const deletedProvider = providerReducerFactory('SINGLE_PROVIDER', 'DELETE');
const postedProvider = providerReducerFactory('SINGLE_PROVIDER', 'POST');
const puttedProvider = providerReducerFactory('SINGLE_PROVIDER', 'PUT');

export default {
  providersList,
  singleProvider,
  deletedProvider,
  postedProvider,
  puttedProvider,
};
