import { PROGRESSIVE_JACKPOT } from '../../actions/types';

const initialState = {
  amount: null,
  initialAmount: null,
  TTL: 0,
  salePercentage: 0,
  includeCurrentDraw: false,
  isEnabled: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROGRESSIVE_JACKPOT.SAVE_INITIAL_DATA:
      return {
        amount: action.payload.progressiveJackpotFund,
        initialAmount: action.payload.progressiveJackpotInitialAmount
          ? action.payload.progressiveJackpotInitialAmount.amount
          : null,
        TTL: action.payload.progressiveJackpotFundTTL,
        salePercentage: action.payload.progressiveJackpotFundSalePercent,
        includeCurrentDraw: action.payload.progressiveJackpotIncludeCurrentDraw,
        isEnabled: action.payload.progressiveJackpotFundEnabled,
      };
    case PROGRESSIVE_JACKPOT.CHANGE.INITIAL_AMOUNT:
      return {
        ...state,
        initialAmount: action.payload,
      };
    case PROGRESSIVE_JACKPOT.CHANGE.TTL:
      return {
        ...state,
        TTL: action.payload,
      };
    case PROGRESSIVE_JACKPOT.CHANGE.SALE_PERCENTAGE:
      return {
        ...state,
        salePercentage: action.payload,
      };
    case PROGRESSIVE_JACKPOT.CHANGE.ENABLE:
      return {
        ...state,
        isEnabled: action.payload,
        ...(!action.payload && {
          initialAmount: null,
          TTL: 0,
          includeCurrentDraw: false,
          salePercentage: 0,
        }),
      };
    case PROGRESSIVE_JACKPOT.CHANGE.INCLUDE_CURRENT_DRAW:
      return {
        ...state,
        includeCurrentDraw: action.payload,
      };
    case PROGRESSIVE_JACKPOT.RESET:
      return initialState;
    default:
      return state;
  }
};
