import { PLAYERSTATS } from './types';

export const getPlayerStats = (id) => ({
  type: PLAYERSTATS.FETCH,
  payload: id,
});

export const clearPlayerStats = () => ({
  type: PLAYERSTATS.CLEAR,
});
