import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { buildSelectOptions } from '../../../utils/select/buildSelectOptions';

class CustomSelect extends React.Component {
  render() {
    const {
      disabled,
      options,
      name,
      handleChange,
      value,
      label,
      isSearchable,
      backspaceRemovesValue,
      placeholder,
      isMulti,
      isClearable,
      optionLabel = 'name',
      optionValue = 'id',
      saveOptionData = false,
    } = this.props;

    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <Select
          name={name ? name : 'custom-select'}
          options={buildSelectOptions(options, optionValue, optionLabel, saveOptionData)}
          className="react-select "
          onChange={(value) => handleChange(name, value)}
          value={value}
          placeholder={placeholder}
          isSearchable={isSearchable}
          backspaceRemovesValue={backspaceRemovesValue}
          isMulti={isMulti}
          isDisabled={disabled}
          isClearable={isClearable}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        ></Select>
      </div>
    );
  }
}

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  options: PropTypes.array,
  name: PropTypes.string,
  isClearable: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  saveOptionData: PropTypes.bool,
};

export default CustomSelect;
