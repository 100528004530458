import React from 'react';
import { Page, Button, Form } from 'tabler-react';
import Modal from '../../../Reusable/Modal/CustomModal';
import Spinner from '../../../Reusable/Spinner/Spinner';
import { IntegrationConfiguration } from '../../../Lottery/index';
import { connect } from 'react-redux';
import FromService from '../../../../services/FormService';
import propTypes from 'prop-types';

import {
  getIntegrations,
  getChannel,
  patchChannel,
  createChannel,
  clearChannel,
  deleteChannel,
} from '../../../../actions/outboundChannelsActions';

const initialState = {
  activeIntegrationConfiguration: undefined,
  isDeleteModalOpen: false,
};

class OutboundChannel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { match, getIntegrations, getChannel } = this.props;

    getIntegrations();

    if (match.params.id) {
      getChannel(match.params.id);
    }
  }

  componentDidUpdate() {
    const { channel } = this.props;
    if (channel) {
      this.setState({
        selectedIntegration: channel.channelImplementationIdentifier,
        channelImplementationIdentifier: channel.channelImplementationIdentifier,
      });
    }
  }

  componentWillUnmount() {
    const { match } = this.props;
    if (match.params.id) {
      this.props.clearChannel();
    }
  }

  submitChannel = () => {
    const { channel, patchChannel, createChannel, match } = this.props;
    const { activeIntegrationConfiguration, selectedIntegration } = this.state;
    if (channel) {
      patchChannel(match.params.id, {
        channelImplementationIdentifier: channel.channelImplementationIdentifier,
        type: channel.channelImplementationIdentifier,
        ...activeIntegrationConfiguration,
      });
    } else {
      createChannel({
        channelImplementationIdentifier: selectedIntegration,
        type: selectedIntegration,
        ...activeIntegrationConfiguration,
      });
    }
  };

  changeSelectedIntegration = (name) => {
    const { integrations } = this.props;
    this.setState({
      selectedIntegration: name,
      activeIntegrationConfiguration: FromService.prepareEmptyDynamicForm(integrations[name]),
    });
  };

  handleModalToggle = () => {
    this.setState((state) => ({ isDeleteModalOpen: !state.isDeleteModalOpen }));
  };

  deleteChannel = () => {
    const { match } = this.props;
    this.props.deleteChannel(match.params.id);
  };

  render() {
    const { integrations, channel, match } = this.props;
    const { selectedIntegration } = this.state;

    return (
      <Page>
        <Modal
          isOpen={this.state.isDeleteModalOpen}
          toggleModal={this.handleModalToggle}
          handleSubmit={() => {
            this.deleteChannel();
            this.handleModalToggle();
          }}
          title="Are you sure you want to delete this channel?"
          classname="react-modal-custom__delete"
          statusColor="danger"
          btnConfirmText="Confirm"
          btnConfirmColor="danger"
        />

        <Page.Header>
          <Page.Title>
            {channel ? (
              <React.Fragment>Edit lottery outbound channel: </React.Fragment>
            ) : (
              <React.Fragment>Create lottery outbound channel: </React.Fragment>
            )}
          </Page.Title>
        </Page.Header>

        {!integrations && <Spinner class="mb-5" />}
        {integrations && (
          <div className="row">
            <div className="col-md-6">
              {!channel && (
                <React.Fragment>
                  <h4>Select integration chanel: </h4>
                  <Form.SelectGroup pills>
                    {Object.keys(integrations).map((integration, i) => {
                      return (
                        <Form.SelectGroupItem
                          key={`pill-${i}`}
                          label={integration}
                          name="integration"
                          value={integration}
                          onChange={(e) => {
                            this.changeSelectedIntegration(e.target.value);
                          }}
                        />
                      );
                    })}
                  </Form.SelectGroup>
                </React.Fragment>
              )}

              {selectedIntegration && (
                <IntegrationConfiguration
                  name={'Outbound channel configuration :'}
                  fields={integrations[selectedIntegration]}
                  data={channel}
                  onChange={(name, value) =>
                    this.setState((state) => ({
                      activeIntegrationConfiguration: {
                        ...state.activeIntegrationConfiguration,
                        [name]: value,
                      },
                    }))
                  }
                />
              )}

              {selectedIntegration && (
                <Button.List>
                  <Button onClick={this.submitChannel} block color="primary">
                    Submit
                  </Button>
                  {channel && match.params.id && (
                    <Button onClick={this.handleModalToggle} block color="danger">
                      Delete
                    </Button>
                  )}
                </Button.List>
              )}
            </div>
          </div>
        )}
      </Page>
    );
  }
}

OutboundChannel.propTypes = {
  getIntegrations: propTypes.func,
  getChannel: propTypes.func,
  clearChannel: propTypes.func,
  patchChannel: propTypes.func,
  createChannel: propTypes.func,
  deleteChannel: propTypes.func,
  integrations: propTypes.object,
  channel: propTypes.object,
  template: propTypes.object,
  match: propTypes.object,
};

export default connect(
  (state) => ({
    integrations: state.outboundChannels.integrations.data,
    template: state.outboundChannels.template.data,
    channel: state.outboundChannels.channel.data,
  }),
  {
    getIntegrations,
    getChannel,
    clearChannel,
    createChannel,
    patchChannel,
    deleteChannel,
  }
)(OutboundChannel);
