import { createLogic } from 'redux-logic';

import {
  LOBBY_VERSIONS_GET,
  LOBBY_VERSION_GET,
  LOBBY_VERSION_CREATE,
  LOBBY_VERSION_PUBLISH,
  LOBBY_VERSION_DELETE,
} from '../actions/types';

export const getLobbyVersions = createLogic({
  type: LOBBY_VERSIONS_GET.START,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page, id } = action.payload;
    const perPage = getState().lobbyVersions.entries;
    const adress = `/api/v1/lobbies/${id}/versions?page=${page}&perPage=${perPage}`;

    return httpClient
      .get(adress)
      .then((res) => {
        dispatch({
          type: LOBBY_VERSIONS_GET.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_VERSIONS_GET.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getVersion = createLogic({
  type: LOBBY_VERSION_GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { versionId, lobbyId } = action.payload;
    const adress = `/api/v1/lobbies/${lobbyId}/versions/${versionId}`;

    return httpClient
      .get(adress)
      .then((res) => {
        dispatch({
          type: LOBBY_VERSION_GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_VERSION_GET.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const createVersion = createLogic({
  type: LOBBY_VERSION_CREATE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { data, id } = action.payload;
    const adress = `/api/v1/lobbies/${id}/versions`;

    return httpClient
      .post(adress, data)
      .then((res) => {
        dispatch({
          type: LOBBY_VERSION_CREATE.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_VERSION_CREATE.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const publishVersion = createLogic({
  type: LOBBY_VERSION_PUBLISH.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { data, id, versionId } = action.payload;
    const adress = `/api/v1/lobbies/${id}/versions${versionId}/publish`;

    return httpClient
      .post(adress, data)
      .then((res) => {
        dispatch({
          type: LOBBY_VERSION_PUBLISH.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_VERSION_PUBLISH.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const deleteLobbyVersion = createLogic({
  type: LOBBY_VERSION_DELETE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { lobbyId, versionId } = action.payload;

    const address = `api/v1/lobbies/${lobbyId}/versions/${versionId}`;

    return httpClient
      .delete(address)
      .then(() => {
        dispatch({
          type: LOBBY_VERSION_DELETE.SUCCESS,
          payload: versionId,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_VERSION_DELETE.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getLobbyVersions,
  getVersion,
  createVersion,
  publishVersion,
  deleteLobbyVersion,
};
