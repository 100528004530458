const getIsAsyncSuccess = (state) => {
  const { deletedProvider, postedProvider, puttedProvider } = state;

  return deletedProvider.isSuccess || postedProvider.isSuccess || puttedProvider.isSuccess;
};

const getProvidersData = (state) => {
  const { data } = state.providersList;

  return data ? data.list : null;
};

const getProvidersHeaders = (state) => {
  const { data } = state.providersList;

  return data ? data.headers : null;
};

const getLotteryAgencies = (state, name) => {
  const { lottery } = state;

  return lottery.lotteryAgencies[name.toLowerCase()] || null;
};

export default {
  getIsAsyncSuccess,
  getProvidersData,
  getProvidersHeaders,
  getLotteryAgencies,
};
