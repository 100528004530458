import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'tabler-react';
// import Select from 'react-select';
import LabeledValue from '../Reusable/LabeledValue';
import { setProperMoneyValue } from '../../utils/money';

import Spinner from '../Reusable/Spinner/Spinner';

import { Field, CheckboxField } from '../Reusable/FormFields';

import {
  reset,
  changeEnable,
  changeIncludeCurrentDraw,
  changeInitialAmount,
  changeSalePercentage,
  changeTTL,
} from '../../actions/progressiveJackpotActions';

import './Lottery.scss';

class ProgressiveJackpot extends React.Component {
  componentWillUnmount() {
    const { reset } = this.props;

    reset();
  }

  renderContent() {
    const { changeTTL, changeInitialAmount, changeEnable, changeSalePercentage, changeIncludeCurrentDraw } = this.props;
    const { isEnabled, initialAmount, TTL, salePercentage, includeCurrentDraw, amount } = this.props.data;

    return (
      <React.Fragment>
        <CheckboxField
          label="Progressive Jackpot Fund Enable"
          name="isEnabled"
          value={isEnabled}
          onChange={(name, value) => changeEnable(value)}
        />
        {isEnabled && (
          <div>
            <div>
              <Field
                label="Initial Fund"
                type="number"
                name="initialAmount"
                onChange={(name, value) => changeInitialAmount(value)}
                value={initialAmount}
                hint="Initial value of PJF, also, a value that will be used to set JPF in case of Jackpot winning or Jackpot fund reset due to TTL"
              />
            </div>
            <div>
              <Field
                label="Jackpot Fund Lifetime"
                type="number"
                name="ttl"
                onChange={(name, value) => changeTTL(value)}
                value={TTL}
                hint="Time after which Jacpot fund will be reseted to initial amount"
              />
            </div>
            <div>
              <Field
                label="Sales Percent Increase"
                type="number"
                name="salePercentage"
                onChange={(name, value) => changeSalePercentage(value)}
                value={salePercentage}
                hint="Percentage of sales that will be added to JPF fund after draw ends with no Jackpot winners"
              />
            </div>
            <div>
              <CheckboxField
                label="Include Current Draw"
                name="includeCurrentDraw"
                value={includeCurrentDraw}
                onChange={(name, value) => changeIncludeCurrentDraw(value)}
                hint="Defines if current draw will be included in Jackpot prize calculations. If disabled, Jackpot fund will be based on previous draws only"
              />
            </div>
            <div>
              <LabeledValue
                value={setProperMoneyValue(amount.value, true)}
                label="Current JPF Amount"
                labelWidth="50"
              ></LabeledValue>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { isPending, data } = this.props;

    return (
      <Card>
        <Card.Status color="blue" />
        <Card.Header>Progressive Jackpot Fund</Card.Header>
        <Card.Body>
          {isPending && <Spinner />}
          {!isPending && data && this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}

ProgressiveJackpot.propTypes = {
  isPending: PropTypes.bool,
  data: PropTypes.object,
  editMode: PropTypes.bool,
  changeEnable: PropTypes.func,
  changeIncludeCurrentDraw: PropTypes.func,
  changeInitialAmount: PropTypes.func,
  changeSalePercentage: PropTypes.func,
  changeTTL: PropTypes.func,
  reset: PropTypes.func,
};

export default connect(
  (state) => ({
    data: state.progressiveJackpot,
    isPending: state.lottery.isPending,
  }),
  {
    changeEnable,
    changeIncludeCurrentDraw,
    changeInitialAmount,
    changeSalePercentage,
    changeTTL,
    reset,
  }
)(ProgressiveJackpot);
