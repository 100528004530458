import { SEARCH } from '../actions/types';

const initialState = {
  lottery: {
    id: '',
  },
  bet: {
    id: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH.LOTTERY.ID:
      return {
        ...state,
        lottery: {
          id: action.payload.id,
        },
      };
    case SEARCH.BET.ID:
      return {
        ...state,
        bet: {
          id: action.payload.id,
        },
      };
    case SEARCH.RESET:
      return initialState;
    default:
      return state;
  }
};
