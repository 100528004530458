import { PAGE_CONFIG } from '../actions/types';

import { initialState } from '../utils/pagesConstans';

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_CONFIG.SET.DATA:
      return {
        ...state,
        [action.payload.name]: {
          key: action.payload.key,
          pagiNumber: action.payload.pagiNum,
        },
      };
    case PAGE_CONFIG.SET.LOCATION_KEY:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          key: action.payload.key,
        },
      };
    case PAGE_CONFIG.SET.PAGI_NUMBER:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          pagiNumber: action.payload.pagiNum,
        },
      };
    case PAGE_CONFIG.RESET:
      return initialState;
    default:
      return state;
  }
};
