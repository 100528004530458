import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

class LiveChart extends React.Component {
  render() {
    const { data, dataKey, strokeColor, width, height, xAxisDataKey } = this.props;

    return (
      <ResponsiveContainer width={width} height={height}>
        <LineChart data={data}>
          <XAxis dataKey={xAxisDataKey} interval="preserveStartEnd" />
          <YAxis allowDecimals={false} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Line type="monotone" dataKey={dataKey} stroke={strokeColor} strokeWidth={2.5} />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

LiveChart.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string,
  xAxisDataKey: PropTypes.string,
  strokeColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.number,
};

LiveChart.defaultProps = {
  strokeColor: '#82ca9d',
  width: '100%',
  height: 300,
};

export default LiveChart;
