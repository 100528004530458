import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/lib/Async';

import httpClient from '../../../services/httpClient';

class SearchBrand extends React.Component {
  getOptions = (text) => {
    const params = {
      'search[name][phrase]': text,
    };

    return httpClient
      .get(`/api/v1/brands`, { params })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });
  };

  handleChange = (id) => {
    const { setFieldValue, handleChange, multi } = this.props;

    if (setFieldValue && typeof setFieldValue === 'function') {
      setFieldValue(`brand${multi ? 's' : ''}`, id);
    }

    if (handleChange && typeof handleChange === 'function') {
      handleChange(id);
    }
  };

  render() {
    const { className, disabled, handleBlur, value, labelText, multi } = this.props;

    const styles = {
      menu: (base) => ({
        ...base,
        zIndex: '999 !important',
        backgroundColor: 'white',
      }),
    };

    return (
      <div className="form-group">
        <label htmlFor="search-brand">{labelText}</label>
        <Async
          id="search-brand"
          className={`${className}`}
          defaultOptions
          loadOptions={this.getOptions}
          onChange={(id) => this.handleChange(id)}
          isDisabled={disabled}
          onBlur={handleBlur}
          value={value}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable={true}
          isMulti={multi}
          placeholder={'Type to search...'}
          styles={styles}
        />
      </div>
    );
  }
}

SearchBrand.defaultProps = {
  labelText: 'Parent brand',
};

SearchBrand.propTypes = {
  setFieldValue: PropTypes.func,
  className: PropTypes.string,
  handleBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  handleChange: PropTypes.func,
  labelText: PropTypes.string,
};

export default SearchBrand;
