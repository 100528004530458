import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Page, Grid, Button } from 'tabler-react';
import { Link } from 'react-router-dom';

import CustomTable from '../Reusable/Table/CustomTable';
import Spinner from '../Reusable/Spinner/Spinner';
import { ErrorHandler } from '../Reusable/ErrorHandler';

import withPageConfig from '../hoc/withPageConfig';

import { getLobbies, changeEntries } from '../../actions/lobbyActions/lobbyActions';

import { LOBBIES_PAGE } from '../../utils/pagesConstans';

class Lobbies extends React.Component {
  componentDidMount() {
    const { getLobbies, pagiNumber } = this.props;

    getLobbies(pagiNumber);
  }

  handlePageChange = (pageNumber) => {
    const { getLobbies, onPaginationPageChange } = this.props;

    getLobbies(pageNumber);
    onPaginationPageChange(pageNumber);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    this.props.changeEntries(entries);
    this.props.getLobbies(1);
  };

  getHeaderType(header) {
    switch (header) {
      case 'brand':
        return {
          type: 'string',
          accessor: 'brand.name',
        };
      case 'game':
        return {
          type: 'string',
          accessor: 'name',
        };
      case 'latestDraft':
        return {
          type: 'date',
          accessor: 'lastVersion.createdAt',
        };
      case 'latestPublished':
        return {
          type: 'date',
          accessor: 'publishedVersion.publishedAt',
        };
      case 'actions':
        return {
          type: 'actions',
          accessor: '',
        };
      case 'versions':
        return {
          type: 'versions',
          accessor: '',
        };
      case 'lobbyActions':
        return {
          type: 'lobbyActions',
          accessor: '',
        };
      case '':
        return {
          type: 'icons',
          accessor: header,
        };
    }
  }

  prepareLobbyTableData(lobbiesData) {
    const titles = ['brand', 'game', 'latestDraft', 'latestPublished', 'actions', 'versions', 'lobbyActions'];
    const headers = this.props.headers;

    headers['cache-control'] = 'no-cache, private';
    headers['content-type'] = 'application/json';
    headers['x-report-column-names'] = titles;

    const columns = titles.map((title) => {
      if (this.getHeaderType(title).type === 'actions') {
        return {
          header: 'Actions',
          accessor: 'actions',
          type: 'actions',
          id: 'id',
          elements: [
            {
              header: 'Actions',
              lobbyId: 'id',
              type: 'edit',
              linkto: '/lobbies/lobby/:id',
              txt: 'Edit Draft',
            },
            {
              header: '',
              lobbyId: 'id',
              type: 'edit',
              linkto: '/lobbies/lobby/:id/published',
              txt: 'Edit Published',
            },
          ],
        };
      }
      if (this.getHeaderType(title).type === 'lobbyActions') {
        return {
          header: '',
          accessor: 'actions',
          type: 'icons',
          id: 'id',
          elements: [
            {
              header: '',
              id: 'id',
              type: 'event',
              linkto: '/lobbies/lobby/edit',
              icon: 'edit',
            },
          ],
        };
      }

      if (this.getHeaderType(title).type === 'versions') {
        return {
          header: 'Versions',
          accessor: 'versions',
          type: 'versions',
          id: 'id',
          elements: [
            {
              header: 'Versions',
              lobbyId: 'id',
              type: 'event',
              linkto: '/lobbies/:id/versions',
              txt: 'Versions',
            },
          ],
        };
      }
      return {
        header: title.replace(/([A-Z])/g, ' $1'),
        accessor: this.getHeaderType(title).accessor,
        type: this.getHeaderType(title).type,
      };
    });

    const preparedData = {
      headers,
      columns,
      data: lobbiesData,
    };

    return preparedData;
  }

  createTableProps(lobbiesData) {
    const preparedData = this.prepareLobbyTableData(lobbiesData);

    const tableProps = {
      paginHeaders: preparedData.headers,
      onPageChanged: this.handlePageChange,
      onChangeEntries: this.handleChangeEntriesNumber,
      loading: preparedData.data.pending,
      data: preparedData.data,
      columns: preparedData.columns,
    };

    return tableProps;
  }

  render() {
    const lobbies = this.props.lobbies ? this.props.lobbies : null;
    const tableProps = lobbies ? this.createTableProps(lobbies) : null;

    return (
      <Page>
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>
              <span>Lobbies</span>
            </Page.Title>
            <Link to="/lobbies/add">
              <Button color="primary">Add lobby</Button>
            </Link>
          </div>
        </Page.Header>

        {lobbies && (
          <Grid.Row>
            <div style={{ width: `100%` }}>
              {lobbies.error && <ErrorHandler error={lobbies.error} />}
              {lobbies.pending && !lobbies && <Spinner />}
              {lobbies && !lobbies.pending && <CustomTable {...tableProps} />}
            </div>
          </Grid.Row>
        )}
      </Page>
    );
  }
}

Lobbies.propTypes = {
  getLobbies: PropTypes.func,
  changeEntries: PropTypes.func,
  headers: PropTypes.object,
  lobbies: PropTypes.array,

  pagiNumber: PropTypes.number,
  onPaginationPageChange: PropTypes.func,
};

export default connect(
  (state) => ({
    lobbies: state.lobbyList.lobbies,
    headers: state.lobbyList.headers,
  }),
  {
    getLobbies,
    changeEntries,
  }
)(withPageConfig(LOBBIES_PAGE)(Lobbies));
