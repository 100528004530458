/**
 * Another problem in project is that we have a lot of Selects from react-select lib, and for options prop we have to pass array with objects: {value: foo, label: foo}.
 * And there is a lot of ways in which we were transforming simple arrays to arrays with this object.
 * So here is the one function that we should be using for all of Selects.
 * @param {any[]} data - array with data
 * @param {string} valueAccessor - if data is array of objects pass accessor to object which should be used to assing to value
 * @param {string} labelAccessor - if data is array of objects pass accessor to object which should be used to assing to label (default = same as valueAccessor)
 * @param {boolean} destructuredRestObj - pass true if you want to have all other object data
 */
export function buildSelectOptions(data, valueAccessor, labelAccessor, destructuredRestObj = false) {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data.map((elem) => {
    if (typeof elem === 'object') {
      const properLabelAccessor = labelAccessor || valueAccessor;

      if (elem['value'] || elem['label']) {
        return elem;
      }

      if (destructuredRestObj) {
        return {
          ...makeSelectObject(elem[valueAccessor], elem[properLabelAccessor]),
          ...elem,
        };
      }

      return makeSelectObject(elem[valueAccessor], elem[properLabelAccessor]);
    }

    return makeSelectObject(elem, elem);
  });
}

export function makeSelectObject(value, label) {
  return {
    value,
    label,
  };
}
