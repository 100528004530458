import { LOGIN } from '../../actions/types';

const initialState = {
  isPending: false,
  error: null,
  success: false,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.START:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
      };
    case LOGIN.SUCCESS:
      return {
        ...state,
        isPending: false,
        success: true,
        data: action.payload,
        error: null,
      };
    case LOGIN.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        data: null,
      };
    case LOGIN.CANCEL_CURRENT:
      return initialState;
    default:
      return state;
  }
};
