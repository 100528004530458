import { createLogic } from 'redux-logic';
import { BETS } from '../actions/types';

import BetsQueryBuilder from '../services/Bets';

// whole 'reports' logic is based on naming it by "tickets" on front-end side, so we need this kind of switch to generate proper api URL
const getApiBetType = (type) => {
  switch (type) {
    case 'tickets':
      return 'bets';
    case 'instantTickets':
      return 'instant-bets';
    default:
      return 'bets';
  }
};

export const getBets = createLogic({
  type: BETS.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page, type } = action.payload;
    const betType = getApiBetType(type);
    const perPage = getState().reports.entries;
    const queryFilters = BetsQueryBuilder.getBetQuery(getState().reportsFilters[type]);
    const address = `/api/v1/${betType}?page=${page}&perPage=${perPage}${queryFilters}`;

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: BETS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: BETS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getBets,
};
