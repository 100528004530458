class LobbyService {
  static color = { r: 0, g: 0, b: 0, a: 1 };

  static getLineCount(type) {
    switch (type) {
      case 'BIG':
        return 4;
      case 'MEDIUM':
        return 3;
      default:
        return 2;
    }
  }

  static getBase64(file, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //cutting off metadata
      callback(reader.result.slice(reader.result.indexOf(',') + 1));
    };

    reader.onerror = function (error) {
      return error ? error : null;
    };
  }

  static getLabels(type) {
    if (type === 'BIG') {
      return [...Array(4)].map((currElement, index) => JSON.parse(JSON.stringify(this.getLabel(index))));
    }
    if (type === 'MEDIUM') {
      return [...Array(3)].map((currElement, index) => JSON.parse(JSON.stringify(this.getLabel(index))));
    }
    return [...Array(2)].map((currElement, index) => JSON.parse(JSON.stringify(this.getLabel(index))));
  }

  static getLabel(position) {
    return {
      text: '',
      fontSize: 20,
      fontWeight: 400,
      color: this.color,
      borderColor: this.color,
      borderWidth: 0,
      position: position === undefined ? null : position,
    };
  }

  static createNewSection(type, position) {
    return {
      type,
      background: {
        fillColor: this.color,
        borderColor: this.color,
        borderWidth: 0,
      },
      logo: {
        fillColor: this.color,
        borderColor: this.color,
        borderWidth: 0,
      },
      button: {
        text: this.getLabel(),
        link: '',
        buttonColor: this.color,
        shadowColor: this.color,
        shadowProps: { x: 0, y: 0, b: 0 },
      },
      labels: this.getLabels(type),
      position,
    };
  }

  static createLobbyLanguage(sectionCount, language) {
    function getType(i) {
      switch (i % 3) {
        case 0:
          return 'BIG';
        case 1:
          return 'MEDIUM';
        case 2:
          return 'SMALL';
      }
    }

    const lobby = {
      sections: [],
      language,
    };

    for (let i = 0; i < sectionCount; i++) {
      const x = this.createNewSection(getType(i));
      lobby.sections.push(x);
    }

    return lobby;
  }

  static createLobby() {
    return [this.createLobbyLanguage(4, 'IN'), this.createLobbyLanguage(7, 'CN'), this.createLobbyLanguage(2, 'FR')];
  }
}

export default LobbyService;
