import React from 'react';
import PropTypes from 'prop-types';

import ClickOutside from '../../Reusable/ClickOutside/ClickOutside';
import { ChromePicker } from 'react-color';

import './ColorPicker.scss';

class ColorPicker extends React.Component {
  constructor() {
    super();

    this.state = {
      isPickerActive: false,
      color: { r: 0, g: 0, b: 0, a: 0 },
      initialValueAssigned: false,
    };

    this.pickerRef = React.createRef();
  }

  componentDidMount() {
    this.assignInitialValue();
  }

  assignInitialValue = () => {
    const { initialColor } = this.props;
    initialColor ? this.setState({ color: initialColor }) : this.setState({ color: { r: 0, g: 0, b: 0, a: 0 } });
  };

  componentDidUpdate = () => {
    const { initialColor } = this.props;
    const { initialValueAssigned } = this.state;

    if (initialColor !== this.state.color && !initialValueAssigned) {
      this.assignInitialValue();
      this.setState({ initialValueAssigned: true });
    }
  };

  handleColorChange = (color) => {
    this.setState({ color: color.rgb });
    if (this.props.onColorChange) {
      this.props.onColorChange(this.state.color);
    }
  };

  toggleColorPicker = () => {
    const { isPickerActive } = this.state;
    isPickerActive ? this.setState({ isPickerActive: false }) : this.setState({ isPickerActive: true });
  };

  hideColorPicker = () => {
    this.setState({ isPickerActive: false });
  };

  render() {
    const { isPickerActive, color } = this.state;
    const parsedColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
    const checkeredBg = color.a < 0.3 ? 'checkeredBg' : '';

    return (
      <div className="colorPicker">
        <div
          className={`colorSnippet ${checkeredBg}`}
          onClick={this.toggleColorPicker}
          style={{ backgroundColor: parsedColor }}
        />
        {isPickerActive && (
          <ClickOutside action={this.hideColorPicker}>
            <ChromePicker
              className="picker"
              color={this.state.color}
              onChangeComplete={this.handleColorChange}
              ref={this.setPickerRef}
            />
          </ClickOutside>
        )}
      </div>
    );
  }
}
ColorPicker.propTypes = {
  onColorChange: PropTypes.func,
  initialColor: PropTypes.object,
};

export default ColorPicker;
