import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Page, Grid } from 'tabler-react';
import { Formik, Field, Form, FieldArray } from 'formik';
import orderBy from 'lodash/orderBy';

import {
  getLobby,
  publishLobby,
  updateLobby,
  getLobbyVersion,
  postLobbyVersion,
  publishLobbyVersion,
} from '../../actions/lobbyActions/lobbyActions';

import LobbyService from '../../services/LobbyService';

// import { ErrorHandler } from '../Reusable/ErrorHandler';
import Spinner from '../Reusable/Spinner/index';
import LobbyHeader from './LobbyHeader';
import Section from './Section/Section';

import lobbySelectors from '../../selectors/lobby';

import './Lobby.scss';

const LOBBY_PUBLISH = 'LOBBY_PUBLISH';
const SAVE_DRAFT = 'LOBBY_SAVE_DRAFT';

class Lobby extends React.Component {
  constructor() {
    super();

    this.state = {
      variants: [],
      lastAction: null,
      displayNotify: false,
      isVersion: false,
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    const isVersion = this.props.match.path.includes('version');
    const isPublished = this.props.match.path.includes('published');

    if (isVersion) {
      this.props.getLobbyVersion(this.getCurrentLobbyID(), this.getVersionID());
    } else {
      this.props.getLobby(this.getCurrentLobbyID(), isPublished);
    }

    this.setActiveLanguage(this.props.defaultLanguage);

    this.setState({ isVersion });
  }
  componentDidUpdate(prevProps) {
    const { defaultLanguage } = this.props;
    const { isVariant } = this.state;

    if (prevProps.defaultLanguage !== defaultLanguage && !isVariant) {
      this.setActiveLanguage(defaultLanguage);
    }
  }

  onAddSectionClick = (arrayHelpers, generalForm) => {
    const { values } = generalForm;
    const sectionsSize = values.sections.length;

    arrayHelpers.insert(sectionsSize, LobbyService.createNewSection(values.sectionSize, sectionsSize));
  };

  onSectionSwap = (arrayHelpers, indexA, indexB) => {
    arrayHelpers.move(indexA, indexB);
  };

  initSavingAsDraft = () => {
    this.form.current.handleSubmit();
  };

  setNotifyTimer = () => {
    this.timeoutNotify = setTimeout(() => {
      this.setState({ displayNotify: false });
    }, 5000);
  };

  getNotificationText = () => {
    const { lastAction } = this.state;
    const { lobbyError } = this.props;
    if (lobbyError !== null) {
      return 'Something went wrong';
    } else {
      return lastAction === LOBBY_PUBLISH ? 'Lobby published successfully' : 'Draft saved successfully';
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutNotify);
  }

  publishLobby = () => {
    const { publishLobby } = this.props;
    const form = this.form.current;
    const language = this.state.activeLanguage;
    const sections = form.values ? form.values.sections : form.initialValues.sections;

    //changing section position key to match its position in sections array
    sections.forEach((section, index) => {
      section.position = index;
    });

    const newPublishedLobby = {
      lobbySections: sections,
      language,
    };

    this.setState({ lastAction: LOBBY_PUBLISH, displayNotify: true });
    this.setNotifyTimer();

    publishLobby(newPublishedLobby, this.getCurrentLobbyID());
  };

  saveAsDraft = (values) => {
    const { postLobbyVersion, updateLobby } = this.props;
    const { isVariant } = this.state;

    const language = this.state.activeLanguage;

    this.setState({ lastAction: SAVE_DRAFT, displayNotify: true });
    this.setNotifyTimer();

    //changing section position key to match its position in sections array
    values.sections.forEach((section, index) => {
      section.position = index;
    });

    const newVariant = {
      lobbySections: values.sections,
      language,
    };

    isVariant
      ? postLobbyVersion(newVariant, this.getCurrentLobbyID())
      : updateLobby(newVariant, this.getCurrentLobbyID());
  };

  setActiveLanguage = (language) => {
    this.setState({ activeLanguage: language });
  };

  getCurrentLobbyID = () => this.props.match.params.id;
  getVersionID = () => this.props.match.params.versionId;

  getActiveLanguageIndex = () => {
    const { activeLanguage } = this.state;
    const { variants } = this.props;

    if (variants) {
      return variants.findIndex((e) => {
        return e.language === activeLanguage;
      });
    }
    return -1;
  };

  getSections = () => {
    const { variants } = this.props;

    if (variants && this.getActiveLanguageIndex() !== -1) {
      return variants[this.getActiveLanguageIndex()].lobbySections;
    }
    return [];
  };

  orderByPosition = (sections) => {
    return sections.map((currElement) => ({ ...currElement, labels: orderBy(currElement.labels, 'position') }));
  };

  renderSections = (arrayHelpers, generalForm) => {
    return generalForm.values.sections.map((section, index) => (
      <Section
        key={section.id}
        index={index}
        setFieldValue={generalForm.setFieldValue}
        section={generalForm.values.sections[index]}
        sectionsLength={generalForm.values.sections.length}
        onSectionDelete={() => arrayHelpers.remove(index)}
        onSwapDown={() => this.onSectionSwap(arrayHelpers, index, index + 1)}
        onSwapUp={() => this.onSectionSwap(arrayHelpers, index, index - 1)}
      />
    ));
  };

  renderAddSectionsButton = (arrayHelpers, generalForm) => {
    return (
      <React.Fragment>
        <Field name="sectionSize" component="select" className="selector">
          <option value="BIG" label="Large">
            Large
          </option>
          <option value="MEDIUM" label="Medium">
            Medium
          </option>
          <option value="SMALL" label="Small">
            Small
          </option>
        </Field>

        <button
          type="button"
          className="btn btn-primary sectionButton"
          color="primary"
          onClick={() => this.onAddSectionClick(arrayHelpers, generalForm)}
        >
          Add section
        </button>
      </React.Fragment>
    );
  };

  renderLobbyHeader = () => {
    const lobbyError = this.props.lobbyError !== null;
    const { displayNotify } = this.state;

    return (
      <React.Fragment>
        {displayNotify && lobbyError && (
          <div className="lobby-alert alert alert-danger">{this.getNotificationText()}</div>
        )}
        {displayNotify && !lobbyError && (
          <div className="lobby-alert alert alert-success">{this.getNotificationText()}</div>
        )}
        <LobbyHeader
          publishLobby={this.publishLobby}
          saveAsDraft={this.initSavingAsDraft}
          changeLanguage={this.setActiveLanguage}
          activeLanguage={this.state.activeLanguage}
          versionId={this.props.versionId}
        />
      </React.Fragment>
    );
  };

  renderLobbyFormik = (isLobbyPending) => {
    const sections = this.orderByPosition(this.getSections());

    if (isLobbyPending) {
      return <Spinner class={'mt-5'} />;
    }

    return (
      <Formik
        innerRef={this.form}
        onSubmit={this.saveAsDraft}
        enableReinitialize
        initialValues={{
          sections,
          sectionSize: 'BIG',
        }}
      >
        {(generalForm) => (
          <Form id="lobbyForm">
            <FieldArray
              name="sections"
              render={(arrayHelpers) => (
                <React.Fragment>
                  {this.renderAddSectionsButton(arrayHelpers, generalForm)}
                  {this.renderSections(arrayHelpers, generalForm)}
                </React.Fragment>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    return (
      <Page>
        {this.renderLobbyHeader()}
        <Grid>{this.renderLobbyFormik(this.props.lobbyPending)}</Grid>
      </Page>
    );
  }
}

Lobby.propTypes = {
  getLobby: PropTypes.func,
  publishLobby: PropTypes.func,
  updateLobby: PropTypes.func,
  lobbyName: PropTypes.string,
  variants: PropTypes.any,
  match: PropTypes.object,
  defaultLanguage: PropTypes.string,
  versionId: PropTypes.string,
  lobbyError: PropTypes.object,
  lobbyPending: PropTypes.bool,
  getLobbyVersion: PropTypes.func,
  publishLobbyVersion: PropTypes.func,
  postLobbyVersion: PropTypes.func,
};

export default connect(
  (state) => ({
    lobby: state.lobby,
    variants: lobbySelectors.getSortedVariants(state.lobby.variants),
    defaultLanguage: state.lobby.defaultLanguage,
    versionId: state.lobby.versionId,
    lobbyError: state.lobby.error,
    lobbyPending: state.lobby.isPending,
  }),
  {
    getLobby,
    publishLobby,
    updateLobby,
    getLobbyVersion,
    postLobbyVersion,
    publishLobbyVersion,
  }
)(Lobby);
