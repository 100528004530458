import { GET_CURRENT_USER, SET_USER_ROLE } from '../../actions/types';

const initialState = {
  isPending: false,
  error: null,
  isError: false,
  success: false,
  data: null,
  userRole: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_USER.START:
      return {
        ...state,
        isPending: true,
        error: null,
        isError: false,
      };
    case GET_CURRENT_USER.SUCCESS:
      return {
        ...state,
        isPending: false,
        success: true,
        data: action.payload,
        error: null,
        isError: false,
      };
    case GET_CURRENT_USER.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        isError: true,
      };
    case SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case GET_CURRENT_USER.CANCEL_CURRENT:
      return initialState;
    default:
      return state;
  }
};
