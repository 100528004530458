import React from 'react';
import Select from 'react-select';
import propTypes from 'prop-types';

class AsyncSelect extends React.Component {
  componentDidMount() {
    const { asyncAction } = this.props;

    asyncAction();
  }

  componentDidUpdate(prevProps) {
    const { loadCondition, asyncAction } = this.props;

    if (prevProps.loadCondition !== loadCondition) {
      asyncAction();
    }
  }

  renderOptions() {
    const { data } = this.props;

    if (!data) {
      return [];
    }

    return data.map((elem) => {
      if (elem.name) {
        return {
          label: elem.name,
          value: elem.name,
          ...elem,
        };
      }

      return {
        label: elem,
        value: elem,
      };
    });
  }

  render() {
    const { onSelectElement, selectedValue } = this.props;

    return (
      <Select
        options={this.renderOptions()}
        className="mb-2 "
        onChange={(value) => onSelectElement(value)}
        value={selectedValue}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    );
  }
}

AsyncSelect.propTypes = {
  asyncAction: propTypes.func,
  data: propTypes.array,
  selectedValue: propTypes.object,
  onSelectElement: propTypes.func,
  loadCondition: propTypes.any,
};

export default AsyncSelect;
