import { TICKETS } from '../../actions/types';

const initialState = {
  ticketsType: 'default',

  default: {
    draw: null,
    playerId: null,
    brand: null,
    lottery: null,
    ticketId: null,
    dateTo: null,
    dateFrom: null,
    dateCreatedAtTo: null,
    dateCreatedAtFrom: null,
    status: null,
  },

  instant: {
    playerId: null,
    brand: null,
    lottery: null,
    ticketId: null,
    dateCreatedAtTo: null,
    dateCreatedAtFrom: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TICKETS.CHANGE_TICKETS_TYPE:
      return {
        ...state,
        ticketsType: action.payload,
      };
    case TICKETS.CHANGE_DATE_FROM:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          dateFrom: action.payload,
        },
      };
    case TICKETS.CHANGE_DATE_TO:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          dateTo: action.payload,
        },
      };
    case TICKETS.CHANGE_CREATED_AT_FROM:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          dateCreatedAtFrom: action.payload,
        },
      };
    case TICKETS.CHANGE_CREATED_AT_TO:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          dateCreatedAtTo: action.payload,
        },
      };
    case TICKETS.CHANGE_PLAYER:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          playerId: action.payload,
        },
      };
    case TICKETS.CHANGE_DRAW:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          draw: action.payload,
        },
      };
    case TICKETS.CHANGE_BRAND:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          brand: action.payload,
        },
      };
    case TICKETS.CHANGE_TICKET_ID:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          ticketId: action.payload,
        },
      };
    case TICKETS.CHANGE_LOTTERY:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          lottery: action.payload,
        },
      };
    case TICKETS.CHANGE_STATUS:
      return {
        ...state,
        [state.ticketsType]: {
          ...state[state.ticketsType],
          status: action.payload,
        },
      };
    case TICKETS.RESET_FILTERS:
      return {
        ...state,
        [state.ticketsType]: {
          ...initialState[state.ticketsType],
        },
      };
    default:
      return state;
  }
};
