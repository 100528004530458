import styled from 'react-emotion';

export const Container = styled('div')`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled('div')`
  display: flex;
`;

export const Clock = styled('div')`
  font-size: 0.875rem;
  letter-spacing: -0.25px;
  display: flex;
`;

export const ClockItem = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.5rem;
`;

export const ClockItemText = styled('span')`
  font-size: 0.55rem;
  text-transform: uppercase;
  font-weight: 300;
`;

export const Text = styled('p')`
  margin: 0;
  text-transform: uppercase;
  font-weight: 300;
`;

export const Icon = styled('i')`
  font-size: 1rem;
  margin-right: 5px;
  display: flex;
  align-items: center;
`;
