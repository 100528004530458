import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { SearchLottery, SearchBrand, Field, DateField, CustomSelect } from '../FormFields';

import {
  changeDateFrom,
  changeDateTo,
  changeDateCreatedAtFrom,
  changeDateCreatedAtTo,
  changeTicketId,
  changePlayerId,
  changeLottery,
  changeBrand,
  changeStatus,
  changeDraw,
  resetFilters,
} from '../../../actions/ticketsActions';
import { getDraw } from '../../../actions/draws';

import './TicketFilters.scss';
import { DEFAULT_TICKETS_TYPES, BETS_STATUSES } from '../../../utils/tickets/consts';

class TicketFilters extends React.Component {
  componentDidMount() {
    const { isDefaultType } = this.props;

    if (isDefaultType) {
      this.setDefaultDateFilters();
    }
  }

  setDefaultDateFilters() {
    const { changeDateFrom, changeDateTo, changeDateCreatedAtFrom, changeDateCreatedAtTo } = this.props;
    const today = moment();
    const minusWeek = moment().subtract(7, 'day');

    changeDateFrom(minusWeek);
    changeDateTo(today);
    changeDateCreatedAtFrom(minusWeek);
    changeDateCreatedAtTo(today);
  }

  handleResetClick = () => {
    const { resetFilters, getTickets } = this.props;

    resetFilters();
    this.setDefaultDateFilters();

    getTickets(1);
  };

  handleDrawIdChange = (drawId) => {
    const { changeDraw, getDraw } = this.props;

    if (drawId.length === 36) {
      getDraw(drawId);
    }

    changeDraw(drawId);
  };

  handleGenericChange = (data, callback) => {
    callback(data);
  };

  render() {
    const {
      changePlayerId,
      changeLottery,
      changeBrand,
      changeDateFrom,
      changeDateTo,
      getTickets,
      changeStatus,
      changeTicketId,
      changeDateCreatedAtFrom,
      changeDateCreatedAtTo,
      isDefaultType,
    } = this.props;
    const { ticketId, draw, playerId, brand, lottery, dateFrom, dateTo, dateCreatedAtTo, dateCreatedAtFrom, status } =
      this.props.filters;

    return (
      <div className="row winningsFilters">
        <Field
          formGroupClassName="col-lg-3"
          label="Player ID"
          type="text"
          value={playerId}
          onChange={(name, value) => this.handleGenericChange(value, changePlayerId)}
        />
        {isDefaultType && (
          <Field
            formGroupClassName="col-lg-3"
            label="Draw ID"
            type="text"
            value={draw ? draw : ''}
            onChange={(name, value) => this.handleDrawIdChange(value)}
          />
        )}
        <Field
          formGroupClassName="col-lg-3"
          label="Ticket ID"
          type="text"
          value={ticketId}
          onChange={(name, value) => this.handleGenericChange(value, changeTicketId)}
        />
        <div className="col-lg-3">
          <div className="form-group">
            <SearchBrand
              labelText="Brand"
              handleChange={(data) => this.handleGenericChange(data, changeBrand)}
              value={brand}
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group">
            <SearchLottery
              game
              brandId={brand ? brand.id : null}
              labelText="Lottery"
              handleChange={(data) => this.handleGenericChange(data, changeLottery)}
              value={lottery}
            />
          </div>
        </div>
        {isDefaultType && (
          <React.Fragment>
            <DateField
              formGroupClassName="col-lg-3"
              label="Draw Date From"
              value={dateFrom}
              onChange={(name, value) => this.handleGenericChange(value, changeDateFrom)}
            />
            <DateField
              formGroupClassName="col-lg-3"
              label="Draw Date To"
              value={dateTo}
              onChange={(name, value) => this.handleGenericChange(value, changeDateTo)}
            />
          </React.Fragment>
        )}
        <DateField
          formGroupClassName="col-lg-3"
          label="Ticket Date from"
          value={dateCreatedAtFrom}
          onChange={(name, value) => this.handleGenericChange(value, changeDateCreatedAtFrom)}
        />
        <DateField
          formGroupClassName="col-lg-3"
          label="Ticket Date to"
          value={dateCreatedAtTo}
          onChange={(name, value) => this.handleGenericChange(value, changeDateCreatedAtTo)}
        />
        {isDefaultType && (
          <div className="col-lg-3">
            <CustomSelect
              options={BETS_STATUSES}
              name="status"
              handleChange={(name, value) => this.handleGenericChange(value, changeStatus)}
              value={status}
              label="Status"
              placeholder="Change ticket status"
            />
          </div>
        )}
        <div className="col-lg-12">
          <div className="form-group">
            <button className={'firstFilteringButton btn btn-primary'} onClick={() => getTickets(1)}>
              Filter
            </button>
            <button className={' btn btn-secondary'} onClick={this.handleResetClick}>
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TicketFilters.propTypes = {
  changeDateFrom: PropTypes.func,
  changeDateTo: PropTypes.func,
  changeLottery: PropTypes.func,
  changeBrand: PropTypes.func,
  changeDraw: PropTypes.func,
  changePlayerId: PropTypes.func,
  changeStatus: PropTypes.func,
  changeTicketId: PropTypes.func,
  changeDateCreatedAtFrom: PropTypes.func,
  changeDateCreatedAtTo: PropTypes.func,
  getDraw: PropTypes.func,
  getTickets: PropTypes.func,
  resetFilters: PropTypes.func,

  isDefaultType: PropTypes.bool,
  type: PropTypes.string,
  filters: PropTypes.object,
};

export default connect(
  (state, ownProps) => ({
    filters: state.ticketsFilters[ownProps.type],
    isDefaultType: DEFAULT_TICKETS_TYPES.indexOf(ownProps.type) !== -1,
  }),
  {
    changeDateTo,
    changeDateFrom,
    changeDateCreatedAtFrom,
    changeDateCreatedAtTo,
    changeLottery,
    changeBrand,
    changeDraw,
    changeTicketId,
    changePlayerId,
    changeStatus,
    getDraw,
    resetFilters,
  }
)(TicketFilters);
