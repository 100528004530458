const initialState = { data: null, isLoading: false, error: null, isSuccess: false };

const DELETE = 'DELETE';
const PUT = 'PUT';
const GET = 'GET';
const NAME = 'EXCHANGE_RATES';

const exchangeRatesReducerFactory = (name, asyncAction) => {
  return (state = initialState, action) => {
    switch (action.type) {
      case `${name}_${asyncAction}_START`:
        return { data: null, isLoading: true, error: null, isSuccess: false };
      case `${name}_${asyncAction}_SUCCESS`:
        return { data: action.payload, isLoading: false, error: null, isSuccess: true };
      case `${name}_${asyncAction}_ERROR`:
        return { data: null, isLoading: false, error: action.payload, isSuccess: false };
      case `${name}_RESET`:
        return initialState;
      default:
        return state;
    }
  };
};

const exchangeRatesList = exchangeRatesReducerFactory(NAME, GET);
const deletedExchangeRate = exchangeRatesReducerFactory(NAME, DELETE);
const puttedExchangeRate = exchangeRatesReducerFactory(NAME, PUT);

export default {
  exchangeRatesList,
  deletedExchangeRate,
  puttedExchangeRate,
};
