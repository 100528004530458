import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'tabler-react';
import { connect } from 'react-redux';

import Spinner from '../Reusable/Spinner/Spinner';
import EditTicketCostSingle from './EditTicketCostSingle';

import {
  changeCurrencyAmount,
  addCurrencyToChange,
  removeCurrencyToChange,
  resetCurrencies,
} from '../../actions/currenciesActions';

class EditTicketCost extends React.Component {
  componentWillUnmount() {
    this.props.resetCurrencies();
  }

  renderMainContent() {
    const { isPending, data } = this.props;

    if (isPending) {
      return <Spinner />;
    }

    if (!isPending && data) {
      return this.renderEditContent();
    }
  }

  onCheckedChange = (index, name) => {
    const { changeCurrencyAmount, addCurrencyToChange, currenciesToChange, additionalCurrencies, currencies } =
      this.props;

    const currency = currencies.filter((item) => item.currency === name)[0];
    const baseCurrency = this.props.baseCurrency.currency;
    const additionalCurrency = additionalCurrencies
      ? additionalCurrencies.filter((item) => item.currency === name)
      : [];
    const isAdditionalCurrency = !!additionalCurrency.length;

    if (name === baseCurrency) {
      return;
    }

    if (!currenciesToChange.includes(name) && currency.amount !== '') {
      addCurrencyToChange(name);
    }

    if (isAdditionalCurrency && currency.amount === '') {
      return changeCurrencyAmount(index, additionalCurrency[0].amount);
    }

    if (isAdditionalCurrency && currency.amount !== '') {
      return changeCurrencyAmount(index, '');
    }
  };

  renderEditContent() {
    const {
      currencies,
      changeCurrencyAmount,
      addCurrencyToChange,
      removeCurrencyToChange,
      additionalCurrencies,
      currenciesToChange,
    } = this.props;

    return (
      <Table hasOutline responsive highlightRowOnHover className="table-vcenter">
        <Table.Header>
          <Table.Row>
            <Table.ColHeader />
            <Table.ColHeader>Currency</Table.ColHeader>
            <Table.ColHeader>Value</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {currencies.map((elem, index) => {
            const filteredAdditionalCurrencies =
              additionalCurrencies && additionalCurrencies.filter((item) => item.currency === elem.currency);
            const checked =
              elem.amount !== '' &&
              (elem.currency === 'EUR' || (filteredAdditionalCurrencies && filteredAdditionalCurrencies.length > 0));

            return (
              <EditTicketCostSingle
                key={`${elem.currency}${index}`}
                index={index}
                changeCurrencyAmount={changeCurrencyAmount}
                addCurrencyToChange={addCurrencyToChange}
                removeCurrencyToChange={removeCurrencyToChange}
                name={elem.currency}
                value={elem.amount}
                currenciesToChange={currenciesToChange}
                checked={checked}
                handleCheckedChange={this.onCheckedChange}
              />
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <Card.Status color="blue" />
          <Card.Header>Edit ticket costs</Card.Header>
          <Card.Body>{this.renderMainContent()}</Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

EditTicketCost.propTypes = {
  currencies: PropTypes.array,
  data: PropTypes.object,
  isPending: PropTypes.bool,
  changeCurrencyAmount: PropTypes.func.isRequired,
  addCurrencyToChange: PropTypes.func.isRequired,
  removeCurrencyToChange: PropTypes.func.isRequired,
  resetCurrencies: PropTypes.func.isRequired,
  additionalCurrencies: PropTypes.array.isRequired,
  currenciesToChange: PropTypes.array.isRequired,
  baseCurrency: PropTypes.object,
};

export default connect(
  (state) => ({
    currencies: state.currencies.allCurrencies,
    additionalCurrencies: state.currencies.additionalCurrencies,
    currenciesToChange: state.currencies.currenciesToChange,
    baseCurrency: state.currencies.baseCurrency,
  }),
  {
    changeCurrencyAmount,
    addCurrencyToChange,
    removeCurrencyToChange,
    resetCurrencies,
  }
)(EditTicketCost);
