import PropTypes from 'prop-types';
import React from 'react';

class CheckboxField extends React.Component {
  render() {
    const { label, name, onChange, onBlur, value, disabled, labelClassName, inputClassName, hint } = this.props;
    return (
      <div className="form-group form-check">
        <input
          id={name}
          className={`form-check-input ${inputClassName}`}
          type="checkbox"
          name={name}
          disabled={disabled}
          onChange={(e) => onChange(name, e.target.checked)}
          onBlur={onBlur}
          value={value}
          checked={value}
        />
        {label && (
          <label htmlFor={name} className={labelClassName}>
            {label}
          </label>
        )}
        {hint && <label className="hint">{hint}</label>}
      </div>
    );
  }
}

CheckboxField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hint: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

export default CheckboxField;
