import React from 'react';
import propTypes from 'prop-types';
import './Popup.scss';

function Popup(props) {
  if (!props.id || !props.isPopupVisible) {
    return null;
  }

  function handleToClipboard() {
    const el = document.createElement('textarea');
    el.value = props.id;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-99999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (
    <div className="clipper__popup">
      <div className="id">{props.id}</div>
      <i className="fe fe-copy button--copy" onClick={handleToClipboard} />
    </div>
  );
}

Popup.propTypes = {
  id: propTypes.string,
  isPopupVisible: propTypes.bool,
};

export default Popup;
