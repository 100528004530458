import React from 'react';
import propTypes from 'prop-types';

import CheckboxField from '../../Reusable/FormFields/CheckboxField';

class SectionCheckbox extends React.Component {
  render() {
    const { label, onCheckboxChange, name, value } = this.props;

    return (
      <div>
        <CheckboxField
          label={label}
          onChange={(name, value) => onCheckboxChange(name, value)}
          id={name}
          name={name}
          value={value}
          checked={value}
          labelClassName={'title'}
          inputClassName={'lobby-checkbox'}
        />
      </div>
    );
  }
}

SectionCheckbox.propTypes = {
  label: propTypes.string.isRequired,
  onCheckboxChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.bool.isRequired,
};

export default SectionCheckbox;
