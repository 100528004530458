import React from 'react';
import { Page, Grid, Card } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import AccessControl from '../../AccessControl';
import DrawNumbers from '../../Draws/DrawNumbers';
import Spinner from '../../Reusable/Spinner';
import ProviderDrawDetails from './ProviderDrawDetails';
import TablePage from '../../Reusable/Table/TablePage';
import ResultConfirmationModal from '../../Reusable/Modal/ResultConfirmationModal';
import ResultResetModal from '../../Reusable/Modal/ResultResetModal';

import { getSingleProviderDraw, getDrawsForProviderDraw } from '../../../actions/providerDraws';
import { postResults, resetResultsData, deleteResults } from '../../../actions/resultConfirmationActions';

import { DRAW_COLUMNS } from '../../../utils/columns';

import { userRoles } from '../../../utils/roles';

class ProviderDraw extends React.Component {
  state = {
    isResultsModalOpen: false,
    isResetResultModalOpen: false,
  };

  componentDidMount() {
    const { getSingleProviderDraw } = this.props;

    getSingleProviderDraw(this.getID());
  }

  toggleResultsModal = () => {
    const { resetResultsData, resultsData, getSingleProviderDraw } = this.props;

    this.setState((prevState) => {
      if (prevState.isResultsModalOpen) {
        resetResultsData();
      }

      if (prevState.isResultsModalOpen && resultsData.success) {
        getSingleProviderDraw(this.getID());
      }

      return {
        isResultsModalOpen: !prevState.isResultsModalOpen,
      };
    });
  };

  handleToggleResetResultModal = () => {
    const { resetResultsData, resultsData, getSingleProviderDraw } = this.props;

    this.setState((prevState) => {
      if (prevState.isResultsModalOpen) {
        resetResultsData();
      }

      if (prevState.isResetResultModalOpen && resultsData.reset.success) {
        getSingleProviderDraw(this.getID());
      }

      return {
        isResetResultModalOpen: !prevState.isResetResultModalOpen,
      };
    });
  };

  handleResultsFormSubmit = (results) => {
    const { postResults, providerDraw } = this.props;

    postResults(providerDraw.data.id, results, 'provider-draw');
  };

  handleResultsDoneClick = () => {
    const { resetResultsData, getSingleProviderDraw } = this.props;

    this.setState({
      isResultsModalOpen: false,
    });

    resetResultsData();
    getSingleProviderDraw(this.getID());
  };

  handleClearResultsClick = () => {
    const { deleteResults } = this.props;

    deleteResults(this.getID(), 'provider-draw');
  };

  getID() {
    const { match } = this.props;

    return match.params.id;
  }

  renderContent() {
    const { isLoading, data, error } = this.props.providerDraw;

    if (isLoading && !data) {
      return <Spinner />;
    }

    if (error && !data) {
      return <div className="alert alert-danger">Something went wrong...</div>;
    }

    if (!data) {
      return null;
    }

    return (
      <React.Fragment>
        <Grid.Col md={6}>
          <Card>
            <Card.Status color="blue" />
            <Card.Body>
              <h3>Details</h3>
              <ProviderDrawDetails providerDraw={data} />
            </Card.Body>
          </Card>
        </Grid.Col>
        <Grid.Col md={6}>
          <DrawNumbers
            selections={data.drawResults}
            title="Results"
            drawType="provider"
            onUpdateBtnClick={this.toggleResultsModal}
            onResetBtnClick={this.handleToggleResetResultModal}
            candidateSelectionData={{
              candidateProviderUser: data.drawResultsCandidateUser,
              candidateResultsProvidedAt: data.drawResultsCandidateProvidedAt,
              confirmationProviderUser: data.drawResultsConfirmingUser,
              resultsConfirmedAt: data.drawResultsConfirmedAt,
            }}
          />
        </Grid.Col>
      </React.Fragment>
    );
  }

  renderDraws() {
    const { draws, getDrawsForProviderDraw } = this.props;

    return (
      <TablePage
        getData={() => getDrawsForProviderDraw(this.getID())}
        data={draws.data}
        isLoading={draws.isLoading}
        error={draws.error}
        headers={{}}
        columns={DRAW_COLUMNS}
        allowedPagination={false}
        title="Draws"
      />
    );
  }

  render() {
    const { isResultsModalOpen, isResetResultModalOpen } = this.state;
    const { data } = this.props.providerDraw;
    const { resultsData } = this.props;

    return (
      <AccessControl
        roles={[
          userRoles.superAdmin,
          userRoles.admin,
          userRoles.brand,
          userRoles.resultsOperator,
          userRoles.reportsManager,
        ]}
        forbiddenRedirect={'/'}
      >
        <Page>
          <Page.Header>
            <Page.Title>Provider Draw</Page.Title>
          </Page.Header>
          <Grid.Row>{this.renderContent()}</Grid.Row>
          {this.renderDraws()}
        </Page>
        {isResultsModalOpen && (
          <ResultConfirmationModal
            isOpen={isResultsModalOpen}
            toggleModal={this.toggleResultsModal}
            data={data.gameResultAgencyConfig.winningSelectionStructure}
            onFormSubmit={this.handleResultsFormSubmit}
            drawResultsData={resultsData}
            onDoneClick={this.handleResultsDoneClick}
            drawDate={data.originalDrawTime}
            results={data.drawResults}
            userData={{
              candidateProviderUser: data.drawResultsCandidateUser,
              candidateResultsProvidedAt: data.drawResultsCandidateProvidedAt,
              confirmationProviderUser: data.drawResultsConfirmingUser,
              resultsConfirmedAt: data.drawResultsConfirmedAt,
            }}
            errorMessage="Selection structure not found. Please configure it Draw settings page."
          />
        )}
        {isResetResultModalOpen && (
          <ResultResetModal
            isOpen={isResetResultModalOpen}
            toggleModal={this.handleToggleResetResultModal}
            drawDate={data.originalDrawTime}
            resetStatus={resultsData.reset}
            onResetClick={this.handleClearResultsClick}
          />
        )}
      </AccessControl>
    );
  }
}

ProviderDraw.propTypes = {
  draws: propTypes.object,
  providerDraw: propTypes.object,
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.string,
    }),
  }).isRequired,

  getSingleProviderDraw: propTypes.func.isRequired,
  getDrawsForProviderDraw: propTypes.func,

  postResults: propTypes.func,
  resetResultsData: propTypes.func,
  deleteResults: propTypes.func,
  resultsData: propTypes.object,
};

export default connect(
  (state) => ({
    providerDraw: state.providerDraw,
    draws: state.providerDrawGameDraws,
    resultsData: state.drawResults,
  }),
  {
    getSingleProviderDraw,
    getDrawsForProviderDraw,
    postResults,
    resetResultsData,
    deleteResults,
  }
)(ProviderDraw);
