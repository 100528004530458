import { createLogic } from 'redux-logic';

import {
  LOBBY,
  LOBBY_LIST,
  LOBBY_INIT,
  LOBBY_UPDATE,
  LOBBY_PUBLISH,
  LOBBY_META,
  LOBBY_META_DELETE,
} from '../actions/lobbyActions/lobbyTypes';
import { NOTIFICATION } from '../actions/types';

export const getLobbies = createLogic({
  type: LOBBY_LIST.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page } = action.payload;
    const perPage = getState().lobbyList.entries;
    const adress = `/api/v1/lobbies?page=${page}&perPage=${perPage}`;

    return httpClient
      .get(adress)
      .then((res) => {
        dispatch({
          type: LOBBY_LIST.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_LIST.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getLobby = createLogic({
  type: LOBBY.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload.id;
    const adress = `/api/v1/lobbies/${id}`;

    return httpClient
      .get(adress)
      .then((res) => {
        dispatch({
          type: LOBBY.SUCCESS,
          payload: {
            data: res.data,
            isPublished: action.payload.isPublished,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const initLobby = createLogic({
  type: LOBBY_INIT.POST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { brandId } = action.payload;

    const address = `api/v1/lobbies/${brandId}`;

    return httpClient
      .post(address)
      .then((res) => {
        dispatch({
          type: LOBBY.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

function mergeVariantsLogic(currentVariants, newVariant) {
  if (!currentVariants) {
    currentVariants = [];
  }

  let swapped = false;

  const newVariantsList = currentVariants.map((variant) => {
    if (variant.language === newVariant.language) {
      swapped = true;
      return newVariant;
    }
    return variant;
  });

  if (!swapped) {
    newVariantsList.push(newVariant);
  }

  return newVariantsList;
}

export const mergeVariants = createLogic({
  type: LOBBY_UPDATE.MERGE,
  latest: true,

  process({ getState, action }, dispatch, done) {
    const { id, newVariant } = action.payload;
    const currentVariants = getState().lobby.variants;
    const newVariantsList = mergeVariantsLogic(currentVariants, newVariant);

    dispatch({
      type: LOBBY_UPDATE.UPDATE,
      payload: newVariantsList,
    });

    dispatch({
      type: LOBBY_UPDATE.POST,
      payload: { id },
    });

    done();
  },
});

export const updateLobby = createLogic({
  type: LOBBY_UPDATE.POST,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { id } = action.payload;
    const lobby = { variants: getState().lobby.variants };

    //removing lobbyVersion relation from variants - backend should do this in deserialisation
    for (const variant of lobby.variants) {
      delete variant.lobbyVersion;
      delete variant.id;
    }

    const address = `/api/v1/lobbies/${id}/versions`;

    return httpClient
      .post(address, lobby)
      .then((r) => {
        dispatch({
          type: LOBBY_UPDATE.SUCCESS,
          payload: r.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_UPDATE.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const publishLobby = createLogic({
  type: LOBBY_PUBLISH.POST,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { lobbyId, lobbyEntity } = action.payload;
    const currentVariants = getState().lobby.variants;
    const newVariantsList = mergeVariantsLogic(currentVariants, lobbyEntity);

    const address = `api/v1/lobbies/${lobbyId}/versions/publish`;
    return httpClient
      .post(address, { variants: newVariantsList })
      .then((res) => {
        dispatch({
          type: LOBBY.SUCCESS,
          payload: {
            data: res.data,
            isPublished: true,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const patchLobby = createLogic({
  type: LOBBY_META.UPDATE,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { lobbyId, data } = action.payload;

    const address = `api/v1/lobbies/${lobbyId}`;

    return httpClient
      .patch(address, data)
      .then(() => {
        dispatch({
          type: LOBBY_META.SUCCESS,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Lobby updated!',
          },
        });
        dispatch({
          type: LOBBY.FETCH,
          payload: {
            id: lobbyId,
            isPublished: true,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY.ERROR,
          payload: err.response,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: err.response,
          },
        });
      })
      .then(done);
  },
});

export const deleteLobby = createLogic({
  type: LOBBY_META_DELETE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const lobbyId = action.payload;

    const address = `api/v1/lobbies/${lobbyId}`;

    return httpClient
      .delete(address)
      .then(() => {
        dispatch({
          type: LOBBY_META_DELETE.SUCCESS,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Lobby deleted!',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOBBY_META_DELETE.ERROR,
          payload: err.response,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: err.response.data.message,
          },
        });
      })
      .then(done);
  },
});

export default {
  getLobby,
  getLobbies,
  updateLobby,
  mergeVariants,
  publishLobby,
  deleteLobby,
  patchLobby,
};
