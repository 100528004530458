import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../Reusable/Spinner/Spinner';
import { Card } from 'tabler-react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';

import { changeBaseCurrency } from '../../actions/currenciesActions';
import { changeBaseTicketCost } from '../../actions/lotteryActions';

const flexContainer = css`
  display: flex;
`;

const currency = css`
  width: 110px;
  margin-right: 30px;
`;

class EditTicketBaseCost extends React.Component {
  // onBaseCurrencyChange(){
  //   const { changeBaseCurrency, baseCurrency } = this.props;
  //   changeBaseCurrency({amount: this.state.amount, currency: baseCurrency});
  // }

  handleBaseTicketCostChange = (value) => {
    const { changeBaseTicketCost } = this.props;

    changeBaseTicketCost(value);
  };

  renderMainContent() {
    const { buildSelectOptions, currencies, baseCurrency } = this.props;

    if (!currencies) {
      return <Spinner class="mt-3 mb-3" />;
    }

    return (
      <div className={flexContainer}>
        <div className={currency}>
          <label>Currency:</label>
          <Select
            name="baseCurrency"
            options={buildSelectOptions(currencies)}
            className="react-select "
            onChange={(option) => this.setState({ currency: option }, this.onBaseCurrencyChange)}
            value={baseCurrency}
            placeholder={'Select..'}
            required
            isDisabled
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
        </div>
        <div>
          <label>Value:</label>
          <input
            type="text"
            name={'value'}
            className="form-control"
            value={this.props.baseTicketCost}
            onChange={(e) => this.handleBaseTicketCostChange(e.target.value)}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <Card.Status color="blue" />
          <Card.Header>Edit ticket base cost:</Card.Header>
          <Card.Body>{this.renderMainContent()}</Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

EditTicketBaseCost.propTypes = {
  baseTicketCost: PropTypes.number,
  isPending: PropTypes.bool,
  baseCurrency: PropTypes.object,
  currencies: PropTypes.array,
  buildSelectOptions: PropTypes.func,
  changeBaseCurrency: PropTypes.func,
  changeBaseTicketCost: PropTypes.func,
};

export default connect(
  (state) => ({
    baseCurrency: state.lottery.currency,
    baseTicketCost: state.lottery.baseTicketCost,
  }),
  {
    changeBaseCurrency,
    changeBaseTicketCost,
  }
)(EditTicketBaseCost);
