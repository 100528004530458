import { LOBBY_META, LOBBY_META_DELETE } from '../../actions/lobbyActions/lobbyTypes';

const initialState = {
  isPending: false,
  error: null,
  delete: {
    isPending: false,
    success: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOBBY_META.UPDATE:
      return {
        ...state,
        isPending: true,
        error: null,
      };
    case LOBBY_META.DELETE:
      return {
        ...state,
        isPending: true,
        error: null,
      };
    case LOBBY_META.SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
      };
    case LOBBY_META.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY_META_DELETE.START:
      return {
        ...state,
        delete: {
          ...state.delete,
          isPending: true,
        },
      };
    case LOBBY_META_DELETE.SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          success: true,
        },
      };
    case LOBBY_META_DELETE.ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          isPending: false,
          error: action.payload,
          success: false,
        },
      };
    case LOBBY_META.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
