import { PROGRESSIVE_JACKPOT } from './types';

export const changeInitialAmount = (amount) => ({
  type: PROGRESSIVE_JACKPOT.CHANGE.INITIAL_AMOUNT,
  payload: amount,
});

export const changeEnable = (enable) => ({
  type: PROGRESSIVE_JACKPOT.CHANGE.ENABLE,
  payload: enable,
});

export const changeIncludeCurrentDraw = (includeCurrentDraw) => ({
  type: PROGRESSIVE_JACKPOT.CHANGE.INCLUDE_CURRENT_DRAW,
  payload: includeCurrentDraw,
});

export const changeTTL = (ttl) => ({
  type: PROGRESSIVE_JACKPOT.CHANGE.TTL,
  payload: ttl,
});

export const changeSalePercentage = (salePercentage) => ({
  type: PROGRESSIVE_JACKPOT.CHANGE.SALE_PERCENTAGE,
  payload: salePercentage,
});

export const reset = () => ({
  type: PROGRESSIVE_JACKPOT.RESET,
});
