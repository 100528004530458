import React from 'react';
import propTypes from 'prop-types';
import { SearchLottery, SearchBrand } from '../FormFields';
import { connect } from 'react-redux';

import {
  changeBrandFilter,
  changeLotteryFilter,
  resetLotteryFilters,
  changeLotteryStatus,
} from '../../../actions/lotteryActions';
import { getLotteries } from '../../../actions/lotteryActions';
import CustomSelect from '../../Reusable/FormFields/CustomSelect';

import { LOTTERY_STATUSES } from '../../../utils/constans';

class LotteryFilter extends React.Component {
  render() {
    const {
      brand,
      lottery,
      status,
      changeLotteryFilter,
      changeBrandFilter,
      changeLotteryStatus,
      getLotteries,
      resetLotteryFilters,
    } = this.props;
    const brandId = brand ? brand.id : '';

    return (
      <React.Fragment>
        <div className="row w-100">
          <div className="col-lg-3">
            <div className="form-group">
              <SearchBrand labelText="Brand" value={brand} handleChange={(value) => changeBrandFilter(value)} />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <SearchLottery
                game
                searchByStructureName
                labelText="Lottery"
                brandId={brandId}
                value={lottery}
                handleChange={(value) => changeLotteryFilter(value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <CustomSelect
                label="Lottery status"
                name="status"
                options={LOTTERY_STATUSES}
                handleChange={(name, value) => changeLotteryStatus(value)}
                value={status}
              />
            </div>
          </div>
        </div>
        <div className="row w-100">
          <button className="btn btn-primary mx-5" onClick={() => getLotteries(1)}>
            Filter
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              resetLotteryFilters();
              getLotteries(1);
            }}
          >
            Reset
          </button>
        </div>
      </React.Fragment>
    );
  }
}

LotteryFilter.propTypes = {
  brand: propTypes.object,
  lottery: propTypes.object,
  status: propTypes.object,
  changeBrandFilter: propTypes.func,
  changeLotteryFilter: propTypes.func,
  changeLotteryStatus: propTypes.func,
  getLotteries: propTypes.func,
  resetLotteryFilters: propTypes.func,
};

export default connect(
  (state) => ({
    lottery: state.lottery.filters.lottery,
    brand: state.lottery.filters.brand,
    status: state.lottery.filters.status,
  }),
  {
    changeBrandFilter,
    changeLotteryFilter,
    changeLotteryStatus,
    getLotteries,
    resetLotteryFilters,
  }
)(LotteryFilter);
