import { DRAW } from '../../actions/types';

const initialState = {
  data: null,
  isPending: false,
  error: null,
  jackpotError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAW.FETCH:
      return {
        ...state,
        isPending: true,
        error: null,
        data: null,
      };
    case DRAW.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        data: null,
      };
    case DRAW.SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
        data: action.payload,
      };
    case DRAW.PUT_JACKPOT_ERROR:
      return {
        ...state,
        jackpotError: action.payload,
      };
    case DRAW.PUT_JACKPOT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          jackpotValue: { ...action.payload.responseData.jackpotValue },
        },
        jackpotError: null,
      };
    case DRAW.CLEAR:
      return initialState;
    default:
      return state;
  }
};
