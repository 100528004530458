import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

class ShadowPicker extends React.Component {
  render() {
    const { index } = this.props;

    return (
      <div className="d-flex align-items-center">
        <label className="mr-2">X:</label>
        <Field name={`sections.${index}.button.shadowProps.x`} className="form-control width-picker" type="number" />
        <label className="mx-2">Y:</label>
        <Field name={`sections.${index}.button.shadowProps.y`} className="form-control width-picker" type="number" />
        <label className="mx-2">B:</label>
        <Field name={`sections.${index}.button.shadowProps.b`} className="form-control width-picker" type="number" />
      </div>
    );
  }
}

ShadowPicker.propTypes = {
  index: PropTypes.number,
};

export default ShadowPicker;
