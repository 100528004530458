import React from 'react';
import PropTypes from 'prop-types';

class SearchPlayers extends React.Component {
  render() {
    const { type, value } = this.props;

    return (
      <React.Fragment>
        <label htmlFor="search-lottery">{type}</label>
        <div className="form-group d-flex">
          <input
            className="form-control"
            type="text"
            value={value ? value : ''}
            onChange={(e) => this.props.updateFilters(e.target.value)}
          />
        </div>
      </React.Fragment>
    );
  }
}

SearchPlayers.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  updateFilters: PropTypes.func,
};

export default SearchPlayers;
