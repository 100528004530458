import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { getReportSummary } from '../../../actions/reportsActions';

import Spinner from '../../Reusable/Spinner/Spinner';

import './Reports.scss';

class ReportSummary extends React.Component {
  generateProductMeta = (key) => {
    switch (key) {
      case 'totalSales':
        return { label: 'Total Sales', isMoney: true };
      case 'uniquePlayers':
        return { label: 'Unique Players', isMoney: false };
      case 'ticketsCount':
        return { label: 'Tickets Count', isMoney: false };
      case 'linesCount':
        return { label: 'Lines Count', isMoney: false };
      case 'totalSystemWinning':
        return { label: 'Winnings', isMoney: true };
      case 'totalSystemGgr':
        return { label: 'GGR', isMoney: true };
      case 'avgTicketValue':
        return { label: 'Average Ticket Value', isMoney: true };
      case 'newPlayersCount':
        return { label: 'New Players Count', isMoney: false };
      case 'total':
        return { label: 'Total', isMoney: true };
      case 'currentMonth':
        return { label: 'Current Month', isMoney: true };
      case 'lastMonth':
        return { label: 'Last Month', isMoney: true };
      case 'withholdingTax':
        return { label: 'Withholding Tax', isMoney: true };
      default:
        return '-';
    }
  };

  renderStatCard = (key, total) => {
    const productMeta = this.generateProductMeta(key);

    return (
      <div key={key} className="statContainer">
        <div className="statTitle">
          {productMeta.isMoney
            ? `${numeral(total.amount / 100).format('0,0.00')} ${total.currency === 'EUR' ? '€' : total.currency}`
            : total}
        </div>
        <div className="statSubtitle">{productMeta.label}</div>
      </div>
    );
  };

  render() {
    const { summaryData, summaryIsPending, summaryHasError } = this.props;
    return (
      <React.Fragment>
        <div className="statsContainer">
          {summaryIsPending && <Spinner class="mb-5" />}
          {summaryData &&
            !summaryHasError &&
            Object.entries(summaryData).map((item) => {
              const [key, value] = item;

              if (!value && typeof value !== 'number') {
                return null;
              }

              return this.renderStatCard(key, value);
            })}
        </div>
      </React.Fragment>
    );
  }
}

ReportSummary.propTypes = {
  summaryData: PropTypes.object,
  summaryError: PropTypes.object,
  summaryHasError: PropTypes.bool,
  summaryIsPending: PropTypes.bool,
};

export default connect(
  (state) => ({
    summaryData: state.summaries.data,
    summaryHasError: state.summaries.isError,
    summaryIsPending: state.summaries.isPending,
  }),
  {
    getReportSummary,
  }
)(ReportSummary);
