import React from 'react';
import { Button } from 'tabler-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';

import names from '../modals/names';

import { open } from '../../actions/modal';

import './GlobalContent.scss';

class GlobalContent extends React.Component {
  render() {
    const { open } = this.props;

    return (
      <div className="global-content">
        <div className="container">
          <div className="row d-flex justify-content-end">
            <Button color="primary" onClick={() => open(names.SEARCH, this.props.history)}>
              <i className="fa fa-search px-2" title="search" />
              Search
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

GlobalContent.propTypes = {
  open: propTypes.func,
  history: propTypes.object,
};

export default withRouter(
  connect(null, {
    open,
  })(GlobalContent)
);
