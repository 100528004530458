import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';

import './FieldStyles.scss';

class Field extends React.Component {
  render() {
    const {
      label,
      name,
      onChange,
      onBlur,
      value,
      disabled,
      touched,
      error,
      type,
      min,
      max,
      step,
      maxLength,
      placeholder,
      link,
      labelless,
      readonly,
      formGroupClassName,
      inputClassName,
      hint,
    } = this.props;
    const isFile = type === 'file';

    return (
      <div
        className={classNames(`form-group field ${formGroupClassName}`, {
          'custom-file': isFile,
        })}
      >
        {label && (
          <label className={classNames({ 'custom-file-label': isFile })} htmlFor={name}>
            {label}
          </label>
        )}

        {link ? (
          <Link to={link}>
            <div className="form-control input-link">{value}</div>
          </Link>
        ) : (
          <input
            type={type}
            id={name}
            className={classNames(`${inputClassName}`, {
              'form-control': !isFile,
              'custom-file-input': isFile,
              'is-invalid': touched && error,
              labelless: labelless,
            })}
            disabled={disabled}
            name={name}
            min={typeof min === 'number' ? min : undefined}
            max={max ? max : undefined}
            step={step ? step : undefined}
            maxLength={maxLength ? maxLength : undefined}
            onChange={(e) => {
              if (!readonly && !isFile) {
                return onChange(name, e.target.value);
              }
              if (!readonly && isFile) {
                return onChange(e.target.files[0]);
              }
              return null;
            }}
            onBlur={onBlur}
            value={value || ''}
            placeholder={placeholder}
            readOnly={readonly}
          />
        )}
        {hint && <p className="hint">{hint}</p>}
        {touched && error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
}

Field.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  link: PropTypes.string,
  hint: PropTypes.string,
  labelless: PropTypes.bool,
  readonly: PropTypes.bool,
  inputClassName: PropTypes.string,
  formGroupClassName: PropTypes.string,
};

export default Field;
