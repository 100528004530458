import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Textarea } from '../../../Reusable/FormFields';
import Spinner from '../../../Reusable/Spinner/Spinner';
import ErrorHandler from '../../../Reusable/ErrorHandler/ErrorHandler';
import Modal from '../../../Reusable/Modal/CustomModal';
import { Button, Alert } from 'tabler-react';
import {
  saveLanguage,
  getLanguage,
  clearLanguage,
  addLanguage,
  deleteLanguage,
} from '../../../../actions/lotteryI18nActions';

class LotteryI18nConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
    };
  }

  componentDidMount() {
    const { languageMeta, getLanguage } = this.props;
    if (languageMeta.id) {
      //we wont get any languageMeta if no languages have been set before
      getLanguage(languageMeta.id);
    }
  }

  componentWillUnmount() {
    this.props.clearLanguage();
  }

  handleModalToggle = () => {
    this.setState((state) => ({ isDeleteModalOpen: !state.isDeleteModalOpen }));
  };

  deleteLanguage() {
    const { deleteLanguage, languageMeta, lotteryId } = this.props;
    deleteLanguage(languageMeta.id, lotteryId);
  }

  submitForm = (language) => {
    const { saveLanguage, addLanguage, lotteryId, languageMeta } = this.props;
    if (languageMeta.id) {
      saveLanguage({ ...language, id: languageMeta.id, language: languageMeta.language }, lotteryId);
    } else {
      addLanguage({ ...language, language: languageMeta.language }, lotteryId);
    }
  };

  render() {
    const { languageFormData, languageMeta, isLanguagePending, error, success } = this.props;

    if (isLanguagePending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    const emptyForm = {
      displayedGameName: '',
      info: '',
      rules: '',
      supportEmail: '',
      supportUrl: '',
    };

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isDeleteModalOpen}
          toggleModal={this.handleModalToggle}
          handleSubmit={() => {
            this.deleteLanguage();
            this.handleModalToggle();
          }}
          title="Are you sure you want to delete this language?"
          classname="react-modal-custom__delete"
          statusColor="danger"
          btnConfirmText="Confirm"
          btnConfirmColor="danger"
        />
        <Formik
          onSubmit={this.submitForm}
          enableReinitialize
          initialValues={Object.keys(languageFormData).length ? languageFormData : emptyForm}
        >
          {(generalForm) => (
            <Form className="lotteryI18nConfig">
              {error && <ErrorHandler error={error} isDismissible />}

              {success && (
                <Alert type="success" icon="check">
                  Saved
                </Alert>
              )}

              <label>Lottery display name</label>
              <Field name={`displayedGameName`} className="form-control mr-2" />
              <label>Info page</label>
              <Textarea
                value={generalForm.values.info}
                name={`info`}
                className="form-control mr-2"
                onChange={(name, value) => {
                  generalForm.setFieldValue(name, value);
                }}
              />
              <label>Rules page</label>
              <Textarea
                value={generalForm.values.rules}
                name={`rules`}
                className="form-control mr-2"
                onChange={(name, value) => {
                  generalForm.setFieldValue(name, value);
                }}
              />
              <label>Support email</label>
              <Field name={`supportEmail`} className="form-control mr-2" />
              <label>Support URL</label>
              <Field name={`supportUrl`} className="form-control mr-2" />

              <Button loading={false} type="submit" color={languageMeta.id ? 'primary' : 'success'}>
                {languageMeta.id ? 'Save' : 'Add'}
              </Button>

              {languageMeta.id && (
                <Button
                  loading={false}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleModalToggle();
                  }}
                  color="danger"
                >
                  Delete
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

LotteryI18nConfig.propTypes = {
  lotteryId: PropTypes.string.isRequired,
  getLanguage: PropTypes.func,
  deleteLanguage: PropTypes.func,
  clearLanguage: PropTypes.func,
  saveLanguage: PropTypes.func,
  addLanguage: PropTypes.func,
  languageMeta: PropTypes.object,
  languageFormData: PropTypes.object,
  isLanguagePending: PropTypes.bool,
  error: PropTypes.object,
  success: PropTypes.object,
};

export default connect(
  (state) => ({
    languageFormData: state.i18n.language.formData,
    isLanguagePending: state.i18n.language.isPending,
    error: state.i18n.language.error,
    success: state.i18n.language.success,
  }),
  {
    saveLanguage,
    addLanguage,
    deleteLanguage,
    getLanguage,
    clearLanguage,
  }
)(LotteryI18nConfig);
