import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class Textarea extends React.Component {
  render() {
    const { label, name, onChange, onBlur, value, disabled, touched, error, type } = this.props;
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <textarea
          type={type}
          id={name}
          rows={5}
          className={classNames('form-control', {
            'is-invalid': touched && error,
          })}
          disabled={disabled}
          name={name}
          onChange={(e) => onChange(name, e.target.value)}
          onBlur={onBlur}
          value={value}
        />
        {touched && error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
}

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
};

export default Textarea;
