import React from 'react';
import { Card, Button } from 'tabler-react';
import { Formik, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import { CheckboxField, Field, Textarea, CustomSelect, SearchBrand } from '../Reusable/FormFields';

class IntegrationConfiguration extends React.Component {
  validateForm = (values) => {
    const { fields } = this.props;
    const errors = {};

    fields.forEach((elem) => {
      if (elem.type === 'int' || elem.type === 'float') {
        if (values[elem.name] < elem.min) {
          errors[elem.name] = `Value must be grater than ${elem.min}`;
        }

        if (elem.max && values[elem.name] > elem.max) {
          errors[elem.name] = `Value must be less than ${elem.max}`;
        }
      }

      if (elem.type === 'url') {
        if (values[elem.name] > elem.maxLength) {
          errors[elem.name] = `Url length must be less than ${elem.maxLength}`;
        }
      }

      if (elem.type === 'text') {
        if (elem.maxLength && values[elem.name] > elem.maxLength) {
          errors[elem.name] = `Url length must be less than ${elem.maxLength}`;
        }
        if (elem.minLength && values[elem.name] < elem.maxLength) {
          errors[elem.name] = `Url length must be more than ${elem.maxLength}`;
        }
      }
    });

    return errors;
  };

  onElemChange = (setFieldValue, name, value) => {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(name, value);
    }
    setFieldValue(name, value);
  };

  setInitialValues = () => {
    const { fields } = this.props;
    const initialValues = {};

    fields.forEach((elem) => {
      switch (elem.type) {
        case 'password':
        case 'text':
        case 'textarea':
        case 'url':
          initialValues[elem.name] = '';
          break;
        case 'int':
        case 'float':
          initialValues[elem.name] = 0;
          break;
        case 'bool':
          initialValues[elem.name] = false;
          break;
        case 'dropdown':
          initialValues[elem.name] = null;
          break;
        case 'search-brand':
          initialValues[elem.name] = null;
          break;
        default:
          initialValues[elem.name] = '';
          break;
      }
    });

    return initialValues;
  };

  renderField(formData) {
    const { elem, index, values, errors, touched, setFieldTouched, setFieldValue } = formData;
    const { onChange } = this.props;

    const onBlur = (e) => {
      const isNumber = elem.type === 'int' || elem.type === 'float';
      const value = isNumber ? parseInt(e.target.value) : e.target.value;

      if (typeof onChange === 'function') {
        onChange(elem.name, value);
      }

      setFieldTouched(elem.name, true);
    };

    switch (elem.type) {
      case 'password':
        return (
          <Field
            label={elem.title}
            type="password"
            name={elem.name}
            key={elem.name}
            onChange={(name, value) => this.onElemChange(setFieldValue, name, value)}
            onBlur={onBlur}
            value={values[elem.name]}
            disabled={false}
            touched={touched[elem.name]}
            error={errors[elem.name]}
          />
        );
      case 'int':
        return (
          <Field
            label={elem.title}
            type="number"
            name={elem.name}
            key={elem.name}
            onChange={(name, value) => this.onElemChange(setFieldValue, name, value)}
            onBlur={onBlur}
            value={values[elem.name]}
            disabled={false}
            touched={touched[elem.name]}
            error={errors[elem.name]}
            min={elem.min}
            max={elem.max}
          />
        );
      case 'float':
        return (
          <Field
            label={elem.title}
            type="number"
            name={elem.name}
            key={elem.name}
            onChange={(name, value) => this.onElemChange(setFieldValue, name, value)}
            onBlur={onBlur}
            value={values[elem.name]}
            disabled={false}
            touched={touched[elem.name]}
            error={errors[elem.name]}
            min={elem.min}
            max={elem.max}
            step={elem.step}
          />
        );
      case 'url':
        return (
          <Field
            label={elem.title}
            type="url"
            name={elem.name}
            key={elem.name}
            onChange={(name, value) => this.onElemChange(setFieldValue, name, value)}
            onBlur={onBlur}
            value={values[elem.name]}
            disabled={false}
            touched={touched[elem.name]}
            error={errors[elem.name]}
            maxLength={elem.maxLength}
          />
        );
      case 'text':
        return (
          <div key={index}>
            <Field
              label={elem.title}
              type="text"
              name={elem.name}
              key={elem.name}
              onChange={(name, value) => this.onElemChange(setFieldValue, name, value)}
              onBlur={onBlur}
              value={values[elem.name]}
              disabled={false}
              touched={touched[elem.name]}
              error={errors[elem.name]}
            />
            <ErrorMessage name={elem.name} />
          </div>
        );
      case 'textarea':
        return (
          <div key={index}>
            <Textarea
              label={elem.title}
              name={elem.name}
              key={elem.name}
              onChange={(name, value) => this.onElemChange(setFieldValue, name, value)}
              onBlur={onBlur}
              value={values[elem.name]}
              touched={touched[elem.name]}
              error={errors.description}
            />
            <ErrorMessage name={elem.name} />
          </div>
        );
      case 'bool':
        return (
          <CheckboxField
            name={elem.name}
            key={elem.name}
            label={elem.title}
            onChange={(name, checked) => this.onElemChange(setFieldValue, name, checked)}
            value={values[elem.name]}
            disabled={false}
            touched={touched[elem.name]}
            error={errors[elem.name]}
          />
        );
      case 'dropdown':
        return (
          <CustomSelect
            key={elem.name}
            label={elem.title}
            name={elem.name}
            options={elem.data}
            className="mb-2"
            isClearable={true}
            handleChange={(name, value) => this.onElemChange(setFieldValue, name, value)}
            value={
              typeof values[elem.name] === 'string'
                ? elem.data.find((option) => option.value === values[elem.name])
                : values[elem.name]
            }
          />
        );

      case 'search-brand':
        return (
          <p>
            <SearchBrand
              key={elem.name}
              labelText={elem.title}
              className="mb-2"
              isClearable={true}
              setFieldValue={setFieldValue}
              handleBlur={() => setFieldTouched(elem.name, true)}
              value={
                typeof values[elem.name] === 'string'
                  ? elem.data.find((option) => option.value === values[elem.name])
                  : values[elem.name]
              }
            />
          </p>
        );

      default:
        return null;
    }
  }

  renderForm() {
    const { fields, data, onSubmit, supportSubmit, onDelete, supportDeletion } = this.props;

    return (
      <Formik
        enableReinitialize
        validateOnMount={true}
        initialValues={data ? data : this.setInitialValues()}
        validate={(values) => this.validateForm(values)}
        onSubmit={(values) => onSubmit(values)}
        render={({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched, validateForm }) => (
          <form onSubmit={handleSubmit}>
            {fields.map((elem, index) => {
              return this.renderField({
                elem,
                index,
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                validateForm,
              });
            })}
            {supportSubmit && typeof onSubmit === 'function' && (
              <Button type="submit" color="primary" className="mx-2">
                Confirm
              </Button>
            )}
            {supportDeletion && typeof onDelete === 'function' && (
              <Button type="reset" color="danger" className="mx-2" onClick={onDelete}>
                Delete
              </Button>
            )}
          </form>
        )}
      />
    );
  }

  render() {
    if (this.props.formOnly) {
      return this.renderForm();
    }

    return (
      <Card>
        <Card.Status color="blue" />
        <Card.Header>{this.props.name}</Card.Header>
        <Card.Body>{this.renderForm()}</Card.Body>
      </Card>
    );
  }
}

IntegrationConfiguration.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  initConfig: PropTypes.func,
  isEdition: PropTypes.bool,
  formOnly: PropTypes.bool,
  supportSubmit: PropTypes.bool,
  supportDeletion: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default IntegrationConfiguration;
