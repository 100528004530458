import {
  LOBBY,
  LOBBY_LIST,
  LOBBY_INIT,
  LOBBY_UPDATE,
  LOBBY_PUBLISH,
  LOBBY_META,
  LOBBY_META_DELETE,
} from './lobbyTypes';
import {
  LOBBY_VERSION_CHANGE_ENTRIES,
  LOBBY_VERSIONS_GET,
  LOBBY_VERSION_GET,
  LOBBY_VERSION_CREATE,
  LOBBY_VERSION_PUBLISH,
  LOBBY_VERSION_DELETE,
} from '../types';

export const getLobbies = (page) => ({
  type: LOBBY_LIST.FETCH,
  payload: { page },
});

export const getLobby = (lobbyId, isPublished) => ({
  type: LOBBY.FETCH,
  payload: {
    id: lobbyId,
    isPublished,
  },
});

export const initLobby = (brandId) => ({
  type: LOBBY_INIT.POST,
  payload: brandId,
});

export const updateLobby = (newVariant, id) => ({
  type: LOBBY_UPDATE.MERGE,
  payload: { newVariant, id },
});

export const publishLobby = (lobbyEntity, lobbyId) => ({
  type: LOBBY_PUBLISH.POST,
  payload: {
    lobbyId,
    lobbyEntity,
  },
});

export const changeEntries = (entries) => ({
  type: LOBBY_LIST.CHANGE_ENTRIES,
  payload: entries,
});

export const getLobbiesVersions = (id, page) => ({
  type: LOBBY_VERSIONS_GET.START,
  payload: {
    id,
    page,
  },
});

export const getLobbyVersion = (lobbyId, versionId) => ({
  type: LOBBY_VERSION_GET.START,
  payload: {
    lobbyId,
    versionId,
  },
});

export const postLobbyVersion = (data, id) => ({
  type: LOBBY_VERSION_CREATE.START,
  payload: {
    data,
    id,
  },
});

export const publishLobbyVersion = (lobbyId, versionId) => ({
  type: LOBBY_VERSION_PUBLISH.START,
  payload: {
    lobbyId,
    versionId,
  },
});

export const changeVersionEntries = (entries) => ({
  type: LOBBY_VERSION_CHANGE_ENTRIES,
  payload: entries,
});

export const deleteLobbyVersion = (lobbyId, versionId) => ({
  type: LOBBY_VERSION_DELETE.START,
  payload: {
    lobbyId,
    versionId,
  },
});

export const deleteLobby = (lobbyId) => ({
  type: LOBBY_META_DELETE.START,
  payload: lobbyId,
});

export const patchLobbyMeta = (lobbyId, data) => ({
  type: LOBBY_META.UPDATE,
  payload: {
    lobbyId,
    data,
  },
});

export const resetLobbyMetaData = () => ({
  type: LOBBY_META.RESET,
});
