export const PLAYERS_TABLE_COLUMNS = [
  {
    header: 'Player ID',
    type: 'id',
    accessor: 'id',
    link: {
      shouldLink: true,
      target: '/players',
    },
  },
  {
    header: 'Player name',
    type: 'string',
    accessor: 'name',
  },
  {
    header: 'Player brand',
    type: 'string',
    accessor: 'brand.id',
  },
  {
    header: 'Player phone',
    type: 'string',
    accessor: 'phone',
  },
  {
    header: 'Test player',
    type: 'boolean',
    accessor: 'isTest',
  },
];

export const DRAW_SUMMARY_PRIZES_COLUMNS = [
  {
    header: 'Prize Name',
    accessor: 'name',
    type: 'string',
  },
  {
    header: 'Prize Type',
    accessor: 'type',
    type: 'string',
  },
  {
    header: 'Prize Value',
    accessor: 'jackpotAmount',
    type: 'reportPrice',
  },
  {
    header: 'Winners Count',
    accessor: 'winningPlayersCount',
    type: 'string',
  },
  {
    header: 'Won Lines',
    accessor: 'winningLinesCount',
    type: 'string',
  },
  {
    header: 'Prize Per Won Line',
    accessor: 'realAmount',
    type: 'reportPrice',
  },
  {
    header: 'Payout Cost',
    accessor: 'payoutsCost',
    type: 'reportPrice',
  },
  {
    header: 'Winning Reports',
    accessor: 'name',
    type: 'actionButton',
    linkTo: '/reports/winnings-report',
    action: null,
    text: 'Winning Reports',
    disableAccessor: 'winningPlayersCount',
    filterAccessors: [
      {
        name: 'possiblePrizeId',
        accessor: 'id',
      },
    ],
    hasUrlFilters: true,
  },
];

export const DRAW_PROTOCOL_WINNINGS = [
  {
    header: 'Prize distribution Winnings category',
    accessor: 'name',
    type: 'string',
  },
  {
    header: 'Number of wins',
    accessor: 'winningPlayersCount',
    type: 'number',
  },
  {
    header: 'Winning Size',
    accessor: 'realAmount',
    type: 'reportPrice',
  },
  {
    header: 'Amount of Winnings',
    accessor: 'realAmount.amount',
    secondAccessor: 'winningPlayersCount',
    currencyAccessor: 'realAmount.currency',
    type: 'multiplicationPrice',
  },
];

export const PROMOTIONS_COLUMNS = [
  {
    header: 'Name',
    accessor: 'name',
    type: 'string',
  },
  {
    header: 'Game Id',
    accessor: 'game.id',
    type: 'string',
  },
  {
    header: 'Start date',
    accessor: 'startDatetime',
    type: 'date',
  },
  {
    header: 'End date',
    accessor: 'endDatetime',
    type: 'date',
  },
  {
    header: 'Type',
    accessor: 'promoType',
    type: 'string',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    type: 'actions',
    id: 'id',
    elements: [
      {
        header: 'Actions',
        id: 'id',
        type: 'edit',
        linkto: '/promotions/promotion/:id',
        txt: 'Edit',
      },
    ],
  },
];

export const PROMOTIONS_DRAWS_COLUMNS = [
  {
    header: 'Lottery',
    accessor: 'game.name',
    id: 'game.id',
    type: 'event',
    linkto: '/lottery/lotteries',
  },
  {
    header: 'Brand',
    accessor: 'game.brand.name',
    id: 'game.brand.id',
    type: 'event',
    linkto: '/brands',
  },
  {
    header: 'Supplier',
    accessor: 'game.lottery.name',
    type: 'string',
  },
  {
    header: 'Lottery struct',
    accessor: 'game.lottery.structure.name',
    type: 'string',
  },
  {
    header: 'Status',
    accessor: 'status',
    type: 'string',
  },
  {
    header: 'Selections',
    accessor: 'wonBetSelection.asArray',
    type: 'drawSelection',
  },
  {
    header: 'Draw date',
    accessor: 'drawDatetime',
    secondAccessor: 'game.timezone',
    type: 'date',
  },
  {
    header: '',
    id: 'id',
    type: 'icons',
    elements: [
      {
        header: '',
        id: 'id',
        type: 'event',
        linkto: '/draws',
        icon: 'info',
      },
    ],
  },
];
