import { createLogic } from 'redux-logic';

import { DRAW, DRAWS, DRAWSTATS, DRAW_SUMMARY, RESULT_CONFIRM, RESULT_CONFIRM_DELETE } from '../actions/types';
import { getPrizeTypeKey } from '../utils/lottery';
import { PERCENTAGE_PRIZE_TYPE_KEY } from '../utils/constans';

import Draws from '../services/Draws';

export const getDraws = createLogic({
  type: DRAWS.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page } = action.payload;
    const perPage = getState().draws.entries;
    const searchTerm = getState().draws.searchTerm;
    const queryAddress = Draws.getfilterDrawsUrl(getState().drawsFilter);
    let address = '';

    if (searchTerm && searchTerm.length >= 3) {
      address = `/api/v1/game-draws?page=${page}&perPage=${perPage}&search[name][phrase]=${searchTerm}`;
    } else {
      address = `/api/v1/game-draws?page=${page}&perPage=${perPage}${queryAddress}`;
    }

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: DRAWS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: DRAWS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getDrawSummary = createLogic({
  type: DRAW_SUMMARY.FETCH.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .get(`api/v1/game-draws/${id}/summary`)
      .then((res) => {
        dispatch({
          type: DRAW_SUMMARY.FETCH.SUCCESS,
          payload: {
            data: {
              ...res.data,
              prizes: res.data.prizes.reverse(),
            },
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: DRAW_SUMMARY.FETCH.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

// export const deleteBrand = createLogic({
//   type: DRAWS.DELETE_START,
//   latest: true,
//
//   process({ httpClient, action }, dispatch, done) {
//     const id = action.payload;
//     return httpClient.delete(`/api/v1/brands/${id}`)
//       .then(() => {
//         dispatch({
//           type: BRANDS.DELETE_SUCCESS,
//           payload: id,
//         });
//       })
//       .catch((err) => {
//         dispatch({
//           type: BRANDS.DELETE_ERROR,
//           payload: err.response,
//         });
//       })
//       .then(done);
//   }
// });
//
export const getDraw = createLogic({
  type: DRAW.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;

    return httpClient
      .get(`/api/v1/game-draws/${id}`)
      .then((res) => {
        const updatedPrizes = res.data.possiblePrizes.map((elem) => {
          const key = getPrizeTypeKey(elem);
          let correctAmount, desiredAmount;

          if (!key) {
            return elem;
          }

          if (PERCENTAGE_PRIZE_TYPE_KEY.indexOf(key) !== -1) {
            correctAmount = elem[key];
            desiredAmount = { amount: correctAmount };
          } else {
            correctAmount = elem[key].amount / 100;
            desiredAmount = {
              amount: correctAmount,
              currency: elem[key].currency,
            };
          }

          return {
            ...elem,
            [key]: desiredAmount,
          };
        });

        const data = {
          ...res.data,
          possiblePrizes: updatedPrizes.reverse(),
        };

        dispatch({
          type: DRAW.SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: DRAW.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getDrawStats = createLogic({
  type: DRAWSTATS.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload.id;

    return httpClient
      .get(`/api/v1/game-draws/${id}/stats`)
      .then((res) => {
        dispatch({
          type: DRAWSTATS.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: DRAWSTATS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getDrawBets = createLogic({
  type: DRAW.GET_BETS_START,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { id, page, filters } = action.payload;
    const perPage = getState().drawBets.entries;
    const queryAddress = filters ? Draws.getBetFilterUrl(filters) : '';

    return httpClient
      .get(`/api/v1/games/${id}/bets?page=${page}&perPage=${perPage}${queryAddress}`)
      .then((res) => {
        dispatch({
          type: DRAW.GET_BETS_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: DRAW.GET_BETS_ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const putNewJackpot = createLogic({
  type: DRAW.PUT_JACKPOT_START,
  latest: true,

  processOptions: {
    successType: DRAW.PUT_JACKPOT_SUCCESS,
    failType: DRAW.PUT_JACKPOT_ERROR,
  },

  process({ httpClient, action }) {
    const id = action.payload.id;
    let payload;
    const newJackpot = {
      amount: action.payload.amount,
      currency: action.payload.currency,
    };

    if (action.payload.amount === `` || action.payload.amount === null) {
      payload = {};
    } else {
      payload = newJackpot;
    }

    return httpClient.put(`/api/v1/game-draws/${id}/jackpot`, payload).then((res) => ({
      responseData: res.data,
    }));
  },
});

export const putResults = createLogic({
  type: RESULT_CONFIRM.POST,
  latest: true,

  processOptions: {
    successType: RESULT_CONFIRM.SUCCESS,
    failType: RESULT_CONFIRM.ERROR,
  },

  process({ httpClient, action }) {
    const { data, id, drawType } = action.payload;
    const dataToSend = Object.keys(data)
      .map((elem) => {
        if (Array.isArray(data[elem])) {
          return data[elem].reduce((a, b, index) => {
            a[`${elem}${index + 1}`] = parseInt(b);
            return a;
          }, {});
        }

        return { [elem]: parseInt(data[elem]) };
      })
      .reduce((a, b) => {
        return { ...a, ...b };
      }, {});
    return httpClient.put(`/api/v1/${drawType}/${id}/result`, dataToSend).then((res) => ({
      responseData: res.data,
    }));
  },
});

export const deleteResults = createLogic({
  type: RESULT_CONFIRM_DELETE.START,
  latest: true,

  processOptions: {
    successType: RESULT_CONFIRM_DELETE.SUCCESS,
    failType: RESULT_CONFIRM_DELETE.ERROR,
  },

  process({ httpClient, action }) {
    const { id, drawType } = action.payload;

    return httpClient.delete(`/api/v1/${drawType}/${id}/result`).then((res) => ({
      responseData: res.data,
    }));
  },
});

export default {
  getDraws,
  getDraw,
  getDrawSummary,
  getDrawBets,
  getDrawStats,
  putNewJackpot,
  putResults,
  deleteResults,
};
