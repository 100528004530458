import { createLogic } from 'redux-logic';

import { BACKEND_USERS, BACKEND_USER } from '../actions/types';

export const getBackendUsers = createLogic({
  type: BACKEND_USERS.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page } = action.payload;
    const perPage = getState().backendUsers.entries;
    const searchTerm = getState().backendUsers.searchTerm;
    let address = '';

    if (searchTerm && searchTerm.length >= 3) {
      address = `/api/v1/users?page=${page}&perPage=${perPage}&search[name][phrase]=${searchTerm}`;
    } else {
      address = `/api/v1/users?page=${page}&perPage=${perPage}`;
    }

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: BACKEND_USERS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: BACKEND_USERS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const deleteBackendUser = createLogic({
  type: BACKEND_USERS.DELETE_START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;
    return httpClient
      .delete(`/api/v1/users/${id}`)
      .then(() => {
        dispatch({
          type: BACKEND_USERS.DELETE_SUCCESS,
          payload: id,
        });
      })
      .catch((err) => {
        dispatch({
          type: BACKEND_USERS.DELETE_ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getBackendUser = createLogic({
  type: BACKEND_USER.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;

    return httpClient
      .get(`/api/v1/users/${id}`)
      .then((res) => {
        dispatch({
          type: BACKEND_USER.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BACKEND_USER.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getBackendUsers,
  deleteBackendUser,
  getBackendUser,
};
