import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { Table } from 'tabler-react';
import Select from 'react-select';

import { getPrizeTypeKey } from '../../utils/lottery';

const Input = styled('input')`
  height: calc(2rem + 2px);
`;

const tdClass = css`
  border: none !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;

const selectClass = css`
  min-width: 150px;
`;
class AddWinningPriceSingle extends React.Component {
  handleChange = (e) => {
    this.props.onChange(this.props.index, e.target.name, e.target.value);
  };

  render() {
    const { price, readOnly, withPrizeType, prizeTypes, index, onChangePrizeType } = this.props;
    const correctPrice = withPrizeType ? price[getPrizeTypeKey(price)] : price.amount;
    const currency = correctPrice && correctPrice.currency ? correctPrice.currency : '';
    const amount = correctPrice && typeof correctPrice.amount !== 'undefined' ? correctPrice.amount : '';
    const prizeType = price && price.prizeType;

    return (
      <Table.Row>
        {withPrizeType && (
          <Table.Col className={tdClass}>
            <Select
              name="priceType"
              options={readOnly ? [] : prizeTypes}
              className={selectClass}
              onChange={(value) => onChangePrizeType('prizeType', value, index)}
              value={{ value: prizeType, label: prizeType }}
              placeholder={'select'}
              isDisabled={readOnly}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          </Table.Col>
        )}
        <Table.Col className={tdClass}>
          <Input
            type="text"
            name={'name'}
            className="form-control"
            disabled={true}
            placeholder={'name'}
            value={price.name}
            onChange={this.handleChange}
          />
        </Table.Col>
        <Table.Col className={tdClass}>
          <Input
            type={readOnly ? 'text' : 'number'}
            min={0}
            step="any"
            name={getPrizeTypeKey(price)}
            className="form-control"
            placeholder={'amount'}
            value={readOnly ? `${amount} ${currency}` : amount}
            onChange={this.handleChange}
            readOnly={readOnly}
            disabled={prizeType === 'integration' || prizeType === 'disabled'}
          />
        </Table.Col>
      </Table.Row>
    );
  }
}

AddWinningPriceSingle.propTypes = {
  onChange: PropTypes.func,
  index: PropTypes.number,
  price: PropTypes.object,
  readOnly: PropTypes.bool,
  withPrizeType: PropTypes.bool,
  prizeTypes: PropTypes.array,
  onChangePrizeType: PropTypes.func,
};

export default AddWinningPriceSingle;
