const initialState = { data: null, isLoading: false, error: null, isSuccess: false };

const systemSettingFactory = (asyncAction) => {
  return (state = initialState, action) => {
    switch (action.type) {
      case `SYSTEM_SETTINGS_${asyncAction}_START`:
        return { data: null, isLoading: true, error: null, isSuccess: false };
      case `SYSTEM_SETTINGS_${asyncAction}_SUCCESS`:
        return { data: action.payload, isLoading: false, error: null, isSuccess: true };
      case `SYSTEM_SETTINGS_${asyncAction}_ERROR`:
        return { data: null, isLoading: false, error: action.payload, isSuccess: false };
      case `SYSTEM_SETTINGS_RESET`:
        return initialState;
      default:
        return state;
    }
  };
};

const systemSettings = systemSettingFactory('FETCH');

export default {
  systemSettings,
};
