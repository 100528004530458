import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'tabler-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getDrawBets, changeBetsEntries } from '../../../actions/draws';

import CustomTable from '../../Reusable/Table/index';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import CheckboxField from '../../Reusable/FormFields/CheckboxField';
import Spinner from '../../Reusable/Spinner/Spinner';
import AccessControl from '../../AccessControl';
import withPageConfig from '../../hoc/withPageConfig';

import { BETS_PAGE } from '../../../utils/pagesConstans';
import { userRoles } from '../../../utils/roles';

class Bets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        showOnlyUnprocessedBets: false,
      },
    };
  }

  componentDidMount() {
    const { pagiNumber } = this.props;

    this.getDrawBets(pagiNumber);
  }

  getDrawBets(pageNumber) {
    const { drawId } = this.props;
    const { filters } = this.state;
    this.props.getDrawBets(drawId, pageNumber, filters);
  }

  handlePageChange = (pageNumber) => {
    const { onPaginationPageChange } = this.props;

    onPaginationPageChange(pageNumber);
    this.getDrawBets(pageNumber);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    this.props.changeBetsEntries(entries);
    this.getDrawBets(1);
  };

  handleChangeUnprocessedCheckbox = () => {
    this.setState(
      (prevState) => ({ filters: { showOnlyUnprocessedBets: !prevState.filters.showOnlyUnprocessedBets } }),
      () => this.getDrawBets(1)
    );
  };

  render() {
    const { betsError, betsPending, betsHeaders, betsData } = this.props;

    return (
      <AccessControl
        roles={[
          userRoles.superAdmin,
          userRoles.admin,
          userRoles.brand,
          userRoles.resultsOperator,
          userRoles.reportsManager,
        ]}
        forbiddenRedirect={'/'}
      >
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>
              <span>Bets</span>
            </Page.Title>
          </div>
        </Page.Header>
        {betsError && <ErrorHandler error={betsError} />}
        {betsPending && !betsHeaders && <Spinner class="mb-5" />}
        {betsHeaders && (
          <React.Fragment>
            <CheckboxField
              label="Show only unprocessed bets"
              name="onlyUnprocessed"
              value={this.state.filters.showOnlyUnprocessedBets}
              onChange={this.handleChangeUnprocessedCheckbox}
              disabled={false}
            />
            <CustomTable
              onChangeEntries={this.handleChangeEntriesNumber}
              onPageChanged={this.handlePageChange}
              deleteHandler={this.handleDeleteItem}
              paginHeaders={betsHeaders}
              loading={betsPending}
              data={betsData}
              columns={[
                {
                  header: 'Id',
                  accessor: 'id',
                  type: 'id',
                  link: {
                    shouldLink: true,
                    target: '/draws/bet',
                  },
                },
                {
                  header: 'Total real cost',
                  accessor: 'cachedStats.totalRealCost.amount',
                  currencyAccessor: 'cachedStats.totalRealCost.currency',
                  type: 'price',
                },
                {
                  header: 'Total system cost',
                  accessor: 'cachedStats.totalSystemCost.amount',
                  currencyAccessor: 'cachedStats.totalSystemCost.currency',
                  type: 'price',
                },
                {
                  header: 'Winnings',
                  accessor: 'cachedStats.totalRealWinnings.amount',
                  currencyAccessor: 'cachedStats.totalRealWinnings.currency',
                  type: 'price',
                },
                {
                  header: 'Status',
                  accessor: 'confirmedAt',
                  successIcon: 'check-square',
                  failIcon: 'alert-triangle',
                  noMoneyIcon: 'dollar-sign',
                  cancelledIcon: 'slash',
                  type: 'status',
                },
                {
                  header: 'Created at',
                  accessor: 'createdAt',
                  type: 'date',
                },
                {
                  header: 'Confirmed at',
                  accessor: 'confirmedAt',
                  type: 'date',
                },
                {
                  header: 'Agency notified at',
                  accessor: 'bettingAganecyNotifiedAt',
                  type: 'date',
                },
                {
                  header: 'Cancelled at',
                  accessor: 'cancelledAt',
                  type: 'date',
                },
                {
                  header: 'Rejected at',
                  accessor: 'rejectedAt',
                  type: 'date',
                },
                {
                  header: 'Insured at',
                  accessor: 'insuredAt',
                  type: 'date',
                },
                {
                  header: 'Win. confirmed at',
                  accessor: 'winningConfirmedAt',
                  type: 'date',
                },
                {
                  header: 'Payment sent at',
                  accessor: 'paymentNotifiedAt',
                  type: 'date',
                },
              ]}
            />
          </React.Fragment>
        )}
      </AccessControl>
    );
  }
}

Bets.propTypes = {
  betsData: PropTypes.array,
  betsPending: PropTypes.bool,
  betsHeaders: PropTypes.object,
  betsError: PropTypes.object,
  match: PropTypes.object,
  changeBetsEntries: PropTypes.func,
  getDrawBets: PropTypes.func,
  drawId: PropTypes.string,

  pagiNumber: PropTypes.number,
  onPaginationPageChange: PropTypes.func,
};

export default withRouter(
  connect(
    (state) => ({
      betsError: state.drawBets.error,
      betsPending: state.drawBets.isPending,
      betsData: state.drawBets.data,
      betsHeaders: state.drawBets.headers,
    }),
    {
      getDrawBets,
      changeBetsEntries,
    }
  )(withPageConfig(BETS_PAGE)(Bets))
);
