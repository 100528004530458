import React from 'react';
import { Card, Button } from 'tabler-react';
import propTypes from 'prop-types';
import FileSaver from 'file-saver';
import httpClient from '../../../services/httpClient';

import Modal from '../Modal/CustomModal';

import { STATUS_CANCELED, STATUS_CANCELLED } from '../../../utils/constans';

import './Management.scss';

class Management extends React.Component {
  state = {
    isModalOpen: false,
    actionName: null,
  };

  handleModalToggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleBtnClick = (name) => {
    this.handleModalToggle();
    this.setState({
      actionName: name,
    });
  };

  handleDownloadTickets = async () => {
    try {
      if (this.isDrawOpen()) {
        const confirmed = confirm(
          'The draw is not closed yet. Downloaded list will be incomplete. Are you sure to continue?'
        );
        if (!confirmed) {
          return false;
        }
      }

      const { draw } = this.props;

      //fetch the tickets response
      const objResponseResult = await httpClient.get(`/exporter/draws/${draw.id}/tickets-export`);

      if (objResponseResult.status == 200) {
        const fileData = objResponseResult.data;

        const blob = new Blob([fileData], { type: 'text/csv' });

        let fileName = `Draw_${draw.id}_tickets.csv`;
        if (this.isDrawOpen()) {
          fileName = 'incomplete_' + fileName;
        }

        FileSaver.saveAs(blob, fileName);

        alert('File downloaded successfully!');
      } else {
        alert(objResponseResult.message);
      }
    } catch (e) {
      if (e.response && e.response.data) {
        alert(JSON.stringify(e.response.data));
      }
    }
  };

  handleDelete = () => {
    const { actionName } = this.state;
    const { onButtonClick } = this.props;

    onButtonClick(actionName);
  };

  isDrawOpen = () => {
    const { draw } = this.props;
    const drawCloseDateTime = new Date(draw.closeDatetime);
    const currentDateTime = new Date();
    if (currentDateTime < drawCloseDateTime) return true;
    return false;
  };

  render() {
    const { status, voidHint, cancelHint, downloadHint, name } = this.props;
    const { isModalOpen } = this.state;

    return (
      <div className="management">
        <Card>
          <Card.Status color="blue" />
          <Card.Body>
            <div className="my-2 d-flex flex-column align-items-start">
              <Button
                onClick={() => this.handleBtnClick('cancel')}
                color="danger"
                disabled={status === STATUS_CANCELED || status === STATUS_CANCELLED}
              >
                Cancel {name}
              </Button>
              <label className="hint my-1">{cancelHint}</label>
            </div>
            <div className="my-2 d-flex flex-column align-items-start">
              <Button
                onClick={() => this.handleBtnClick('void')}
                color="danger"
                disabled={status === STATUS_CANCELED || status === STATUS_CANCELLED}
              >
                Void {name}
              </Button>
              <label className="hint my-1">{voidHint}</label>
            </div>

            <div className="my-2 d-flex flex-column align-items-start">
              <Button onClick={() => this.handleDownloadTickets()} color="success">
                Download Tickets CSV for {name}
              </Button>
              <label className="hint my-1">{downloadHint}</label>
            </div>
          </Card.Body>
        </Card>
        <Modal
          isOpen={isModalOpen}
          toggleModal={this.handleModalToggle}
          handleSubmit={() => {
            this.handleDelete();
            this.handleModalToggle();
          }}
          title="Are you sure?"
          classname="react-modal-custom__delete"
          statusColor="danger"
          btnConfirmText="Confirm"
          btnConfirmColor="danger"
        />
      </div>
    );
  }
}

Management.propTypes = {
  onButtonClick: propTypes.func.isRequired,
  status: propTypes.string.isRequired,
  draw: propTypes.object,
  cancelHint: propTypes.string,
  voidHint: propTypes.string,
  downloadHint: propTypes.string,
  name: propTypes.string,
};

export default Management;
