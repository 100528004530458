import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/lib/Async';

import httpClient from '../../../services/httpClient';

class SearchPlayerNames extends React.Component {
  componentDidMount() {
    this.getOptions();
  }

  getOptions = (text) => {
    const params = {
      'search[name][phrase]': text,
    };

    return httpClient
      .get(`/api/v1/players`, { params })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });
  };

  handleChange = (player) => {
    const { setFieldValue, handleChange } = this.props;

    if (setFieldValue && typeof setFieldValue === 'function') {
      setFieldValue('playerName', '');
    }

    if (handleChange && typeof handleChange === 'function') {
      handleChange(player);
    }
  };

  render() {
    const { className, disabled, handleBlur, value, labelText } = this.props;

    return (
      <React.Fragment>
        <label htmlFor="search-playerName">{labelText}</label>
        <Async
          id="search-playerName"
          className={`${className}`}
          defaultOptions
          loadOptions={this.getOptions}
          onChange={(player) => this.handleChange(player)}
          isDisabled={disabled}
          onBlur={handleBlur}
          value={value}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable={true}
          placeholder={'Type to search...'}
          styles={{ control: (styles) => ({ ...styles, backgroundColor: 'white' }) }}
        />
      </React.Fragment>
    );
  }
}

SearchPlayerNames.defaultProps = {
  labelText: 'Parent brand',
};

SearchPlayerNames.propTypes = {
  setFieldValue: PropTypes.func,
  className: PropTypes.string,
  handleBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  labelText: PropTypes.string,
};

export default SearchPlayerNames;
