/**
 * action creators builder, for simple functions
 * @example //Use makeActionCreator in /actions files like this:
 * const getFoo = makeActionCreator(FOO_BAR, 'id', 'data', 'foo');
 * //And the output is the function which accepts values for id, data, foo (in this case, you can pass whatever key you want).
 * //Usage:
 * getFoo(1, 123, 'bar');
 * //And then the return value is
 * action = {
 *  type: FOO_BAR,
 *  payload: {
 *    id: 1,
 *    data: 123,
 *    foo: 'bar'
 *  },
 * };
 * @param {string} type - action type,
 * @param  {...string} argNames - variables passed to payload
 */

export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type, payload: {} };

    argNames.forEach((elem, index) => {
      action.payload[argNames[index]] = args[index];
    });

    return action;
  };
}
