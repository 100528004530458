import { createLogic } from 'redux-logic';
import { LOTTERY_SCHEDULE_POST, NOTIFICATION, LOTTERY } from '../../actions/types';

export const postSchedule = createLogic({
  type: LOTTERY_SCHEDULE_POST.START,

  process({ httpClient, action }, dispatch, done) {
    const { id, schedule } = action.payload;
    const postData =
      schedule.length !== 0
        ? {
            drawTimeOverrideElements: [...schedule],
          }
        : 'null';

    return httpClient
      .post(`/api/v1/games/${id}/draw-times-override`, postData)
      .then((res) => {
        dispatch({
          type: LOTTERY_SCHEDULE_POST.SUCCESS,
          payload: res,
        });
        dispatch({
          type: LOTTERY.UPDATE,
          payload: {
            path: 'drawTimesOverride',
            value: res.data.drawTimesOverride,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Schedule override successfully!',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOTTERY_SCHEDULE_POST.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: err.message,
          },
        });
      })
      .then(done);
  },
});

export default {
  postSchedule,
};
