import React from 'react';
import propTypes from 'prop-types';
import './IdClipper.scss';
import { Link } from 'react-router-dom';
import Popup from './Popup/Popup';

class IdClipper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupVisible: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isPopupVisible: true });
  };
  handleMouseLeave = () => {
    this.setState({ isPopupVisible: false });
  };

  render() {
    const { isPopupVisible } = this.state;

    const { id, linkUrl } = this.props;

    if (linkUrl) {
      return (
        <div className="id-clipper" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          <Popup id={id} isPopupVisible={isPopupVisible}></Popup>

          <Link to={linkUrl}>
            <div>... {id.substring(14, 23)} ...</div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="id-clipper" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          <Popup id={id} isPopupVisible={isPopupVisible}></Popup>

          <div>... {id.substring(14, 23)} ...</div>
        </div>
      );
    }
  }
}

IdClipper.propTypes = {
  id: propTypes.string,
  linkUrl: propTypes.string,
};

export default IdClipper;
