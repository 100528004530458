import { createLogic } from 'redux-logic';

import { PLAYERS, SINGLE_PLAYER, NOTIFICATION } from '../actions/types';

export const getPlayers = createLogic({
  type: PLAYERS.GET.START,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page, perPage } = action.payload;
    const stateHeaders = getState().players.headers;
    const filters = getState().playersFilters;
    const defaultPerPage = 25;

    const entries = stateHeaders ? stateHeaders['x-per-page'] : defaultPerPage;
    const desiredPerPage = perPage || entries;

    const params = {
      page,
      perPage: desiredPerPage,
      'search[name][phrase]': filters.name || null,
      'search[phone][value]': filters.phone || null,
    };

    return httpClient
      .get('/api/v1/players', { params })
      .then((res) => {
        dispatch({
          type: PLAYERS.GET.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PLAYERS.GET.ERROR,
          payload: e,
        });
      })
      .then(done);
  },
});

export const getSinglePlayer = createLogic({
  type: SINGLE_PLAYER.GET.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .get(`/api/v1/players/${action.payload}`)
      .then((res) => {
        dispatch({
          type: SINGLE_PLAYER.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: SINGLE_PLAYER.GET.ERROR,
          payload: e,
        });
      })
      .then(done);
  },
});

export const putPlayer = createLogic({
  type: SINGLE_PLAYER.PUT.START,

  process({ httpClient, action }, dispatch, done) {
    const { id, isTestPlayer } = action.payload;

    return httpClient
      .put(`/api/v1/players/${id}`, {
        isTest: isTestPlayer,
      })
      .then((res) => {
        dispatch({
          type: SINGLE_PLAYER.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
        dispatch({
          type: SINGLE_PLAYER.PUT.SUCCESS,
          payload: {
            data: res.data,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Player updated successfully',
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: SINGLE_PLAYER.PUT.ERROR,
          payload: e,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

export default {
  getPlayers,
  getSinglePlayer,
  putPlayer,
};
