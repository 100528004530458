import { MANAGEMENT_ACTION } from './types';

export const postManagementAction = (id, actionName, name) => ({
  type: MANAGEMENT_ACTION.START,
  payload: {
    id,
    actionName,
    name,
  },
});
