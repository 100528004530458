import React from 'react';
import { Page, Button } from 'tabler-react';
import CustomTable from '../../../Reusable/Table/CustomTable';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import withPageConfig from '../../../hoc/withPageConfig';

import { OUTBOUND_CHANNELS_PAGE } from '../../../../utils/pagesConstans';

import { getChannels } from '../../../../actions/outboundChannelsActions';

class OutboundChannels extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      entries: 25,
    };
  }

  componentDidMount() {
    const { entries } = this.state;
    const { getChannels, pagiNumber } = this.props;

    getChannels(pagiNumber, entries);
  }

  handlePageChange = (pageNumber) => {
    const { getChannels, onPaginationPageChange } = this.props;

    getChannels(pageNumber);
    onPaginationPageChange(pageNumber);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    this.setState({ entries: entries });
    this.props.getChannels(1);
  };

  render() {
    const { channels, headers } = this.props;
    return (
      <React.Fragment>
        <Page>
          <Page.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Page.Title>
                <span>Outbound channels</span>
              </Page.Title>
              <Link to="/configuration/channels/add">
                <Button color="primary">Configure new channel</Button>
              </Link>
            </div>
          </Page.Header>

          <div className="row">
            <div className="col-md-12">
              {headers && (
                <CustomTable
                  onChangeEntries={this.handleChangeEntriesNumber}
                  onPageChanged={this.handlePageChange}
                  paginHeaders={headers}
                  loading={false}
                  data={channels}
                  columns={[
                    {
                      header: 'Name',
                      accessor: 'name',
                      type: 'string',
                    },
                    {
                      header: 'Id',
                      accessor: 'id',
                      type: 'id',
                    },
                    {
                      header: 'Implementation',
                      accessor: 'channelImplementationIdentifier',
                      type: 'string',
                    },
                    {
                      header: '',
                      id: 'id',
                      type: 'icons',
                      elements: [
                        {
                          header: '',
                          id: 'id',
                          type: 'event',
                          linkto: '/configuration/channels',
                          icon: 'info',
                        },
                      ],
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </Page>
      </React.Fragment>
    );
  }
}

OutboundChannels.propTypes = {
  getChannels: propTypes.func,
  channels: propTypes.array,
  headers: propTypes.object,

  pagiNumber: propTypes.number,
  onPaginationPageChange: propTypes.func,
};

export default connect(
  (state) => ({
    channels: state.outboundChannels.channels,
    headers: state.outboundChannels.headers,
  }),
  {
    getChannels,
  }
)(withPageConfig(OUTBOUND_CHANNELS_PAGE)(OutboundChannels));
