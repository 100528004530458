import { PRICES } from '../../actions/types';

const initialState = {
  prices: [],
  priceType: { value: 'fixes', label: 'fixed' },
  currency: { value: 'EUR', label: 'EUR' },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRICES.SET:
      return {
        ...state,
        prices: action.payload,
      };
    case PRICES.CHANGE_CURRENCY:
      return {
        ...state,
        prices: action.payload.prices,
        currency: action.payload.currency,
      };
    case PRICES.CHANGE_PRICE_TYPE:
      return {
        ...state,
        prices: action.payload.prices,
        priceType: action.payload.priceType,
      };
    case PRICES.CLEAR:
      return initialState;
    default:
      return state;
  }
};
