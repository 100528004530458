const COMMUNAL_REPORTS_COLUMNS_NAMES = [
  'salePriceSum',
  'winningsSum',
  'ggr',
  'withholdingTax',
  'insuranceCosts',
  'realTickets',
  'fees',
  'tax',
  'bonus',
];

export const SALES_REPORTS_COLUMNS_NAMES = ['period', 'gameName', 'brandName', ...COMMUNAL_REPORTS_COLUMNS_NAMES];
export const DRAWS_REPORTS_COLUMNS_NAMES = [
  'drawDate',
  'gameDrawId',
  'gameName',
  'brandName',
  'jackpotBalance',
  ...COMMUNAL_REPORTS_COLUMNS_NAMES,
];
export const PLAYERS_REPORTS_COLUMNS_NAMES = [
  'period',
  'playerName',
  'playerBrandName',
  ...COMMUNAL_REPORTS_COLUMNS_NAMES,
];
export const TRANSACTIONS_REPORTS_COLUMNS_NAMEs = [
  'betId',
  'systemCost',
  'realCost',
  'withholdingTax',
  'playerName',
  'createdAt',
  'confirmedAt',
  'gameName',
  'brandName',
  'drawId',
  'drawDate',
  'status',
];
export const WINNINGS_REPORTS_COLUMNS_NAMES = [
  'betId',
  'drawDate',
  'gameName',
  'brandName',
  'playerName',
  'systemWithholdingTax',
  'netSystemWinning',
];

export const REPORTS_ALL_FILTERS = ['winnings', 'instantWinnings'];
export const REPORTS_PLAYER_FILTERS = [...REPORTS_ALL_FILTERS, 'players', 'transactions'];

export const INSTANT_WINNINGS = 'instantWinnings';
