import { LOBBY, LOBBY_UPDATE, LOBBY_PUBLISH } from '../../actions/lobbyActions/lobbyTypes';
import { LOBBY_VERSION_CREATE, LOBBY_VERSION_GET, LOBBY_VERSION_PUBLISH } from '../../actions/types';

const initialState = {
  data: null,
  variants: null,
  defaultLanguage: '',
  isPending: true,
  error: null,
  versionId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOBBY.FETCH:
      return {
        ...state,
        isPending: true,
        error: null,
      };
    case LOBBY.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY.SUCCESS: {
      const { defaultLanguage, lastVersion, publishedVersion } = action.payload.data;
      const lobby = action.payload.isPublished ? publishedVersion : lastVersion;

      return {
        ...state,
        isPending: false,
        data: action.payload.data,
        error: null,
        variants: lobby ? lobby.variants : null,
        defaultLanguage,
        versionId: lobby ? lobby.id : null,
      };
    }
    case LOBBY_VERSION_GET.START:
      return {
        ...state,
        isPending: true,
        error: null,
      };
    case LOBBY_VERSION_GET.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY_VERSION_GET.SUCCESS: {
      const { variants, id } = action.payload.data;

      return {
        ...state,
        isPending: false,
        error: null,
        variants: variants ? variants : null,
        defaultLanguage: 'EN',
        versionId: id,
      };
    }
    case LOBBY_VERSION_CREATE.START:
      return {
        ...state,
        isPending: true,
        variants: action.payload,
      };
    case LOBBY_VERSION_CREATE.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY_VERSION_CREATE.SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
      };
    case LOBBY_VERSION_PUBLISH.START:
      return {
        ...state,
        isPending: true,
      };
    case LOBBY_VERSION_PUBLISH.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY_VERSION_PUBLISH.SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
      };
    case LOBBY_UPDATE.UPDATE:
      return {
        ...state,
        variants: action.payload,
        isPending: true,
      };
    case LOBBY_UPDATE.SUCCESS:
      return {
        ...state,
        isPending: false,
        versionId: action.payload.id,
        error: null,
      };
    case LOBBY_UPDATE.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY_PUBLISH.POST:
      return {
        ...state,
        isPending: true,
      };

    default:
      return state;
  }
};
