const ALL_API_ACTIONS = ['get', 'post', 'put', 'delete'];
const RESET = 'RESET';

/**
 * action type creator, no need to pass RESET types because it's added by default
 * @param {string} name - name of the action, for example 'lottery'.
 * @param {string[] | null} apiActions - declare what actions types to API we want, default = all, for others pass array, for example: ['get', 'delete'], or pass null or falsy value for nothing
 * @param {string[]} additionalTypes - pass array with additional types names for example ['change_name', 'change_value']
 * @example // Use actionTypesCreator on /actions/types.js file like this:
 * // RESET type is always added to types.
 * // Case 1: Pass just name, return will be types object with all default API actions (get, post, put, delete)
 * const FOO_BAR = actionTypesCreator('FOO_BAR');
 * // return value: (see /actionsTypeCreator/data.js file and const API_TYPES variable)
 * // Case 2: Pass name, pass array with api types:
 * const FOO_BAR = actionTypesCreator('FOO_BAR', ['get', 'delete']);
 * //return value: (see /actionsTypeCreator/data.js file and const SOME_API_TYPES variable)
 * // Case 3: Pass name and some API types and array with additional Types:
 * const FOO_BAR = actionTypesCreator('FOO_BAR', ['get', 'post', 'put', 'delete'], ['foo', 'bar']');
 * // return value: (see /actionsTypeCreator/data.js file and const API_WITH_ADDITIONAL_TYPES variable)
 * // Case 4: Pass name and falsy for API types, and array with additionalTypes:
 * const FOO_BAR = actionTypesCreator('FOO_BAR', null, ['foo', 'bar']');
 * //return value: (see /actionsTypeCreator/data.js file and const ADDITIONAL_TYPES variable)
 * //Now you have a idea how to use it. Of course you can nest additionalTypes like this: ['foo', ['bar', 'fooBar']] return: (see /actionsTypeCreator/data.js file and const NESTED_ADDITIONAL_TYPES variable)
 */

export function actionTypesCreator(name, apiActions = ALL_API_ACTIONS, additionalTypes) {
  let desiredAdditionalTypes = {};
  let desiredApiTypes = {};

  if (typeof name !== 'string') {
    return null;
  }

  const nameUpperCase = nameToUpperCase(name);

  if (!apiActions && !additionalTypes) {
    return null;
  }

  if (additionalTypes) {
    desiredAdditionalTypes = prepareTypeObject(nameUpperCase, additionalTypes);
  }

  if (apiActions) {
    desiredApiTypes = prepareTypeObject(nameUpperCase, apiActions, true);
  }

  return {
    ...desiredApiTypes,
    ...desiredAdditionalTypes,
    [RESET]: `${nameUpperCase}_${RESET}`,
  };
}

function prepareTypeObject(name, types, isApiType = false) {
  return types.reduce((result, item, index) => {
    // If we want to go deeper with naming. Just pass a proper array for example ['foo', ['bar', 'foobar']]
    if (typeof item === 'object') {
      const fieldName = findLastKeyName(types, index - 1);

      if (!fieldName) {
        return;
      }

      return {
        ...result,
        [fieldName]: { ...prepareTypeObject(`${name}_${fieldName}`, item, isApiType) },
      };
    }

    const itemUpperCase = nameToUpperCase(item);

    return {
      ...result,
      [itemUpperCase]: isApiType ? apiActionType(name, itemUpperCase) : `${name}_${itemUpperCase}`,
    };
  }, {});
}

function findLastKeyName(keys, index) {
  if (keys[index] === undefined) {
    return null;
  }

  if (typeof keys[index] === 'object') {
    const newIndex = index - 1;

    return findLastKeyName(keys, newIndex);
  }

  return nameToUpperCase(keys[index]);
}

function nameToUpperCase(name) {
  return name.toUpperCase();
}

function apiActionType(name, actionType) {
  return {
    START: `${name}_${actionType}_START`,
    ERROR: `${name}_${actionType}_ERROR`,
    SUCCESS: `${name}_${actionType}_SUCCESS`,
  };
}
