export default function getSelections(selection) {
  if (!selection || selection === '-') {
    return '-';
  }

  return Object.keys(selection)
    .sort((selection) => (selection === 'main' ? -1 : 1))
    .map((elem) => {
      if (typeof selection[elem] === 'object' && selection[elem].length) {
        return selection[elem].join(', ');
      }

      if (typeof selection[elem] === 'number') {
        return selection[elem];
      }
    })
    .join('; ');
}
