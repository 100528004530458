import React from 'react';
import { Formik } from 'formik';
import { Field } from '../FormFields';
import { Button } from 'tabler-react';
import propTypes from 'prop-types';

class ResultConfirmationForm extends React.Component {
  submitTimeout = null;

  state = {
    timer: 6,
    isSubmitting: false,
    submitted: false,
  };

  componentWillUnmount() {
    clearInterval(this.submitTimeout);
  }

  handleFormSubmit = (values) => {
    const { onFormSubmit } = this.props;
    this.submitTimeout = setInterval(() => {
      this.setState((prevState) => ({
        timer: prevState.timer - 1,
        isSubmitting: true,
      }));

      if (this.state.timer <= 0) {
        onFormSubmit(values);
        this.clearCountdown();
      }
    }, 1000);
  };

  handleReset = () => {
    const { onCancel } = this.props;
    this.clearCountdown();
    onCancel();
  };

  clearCountdown = () => {
    clearInterval(this.submitTimeout);
    this.setState({
      timer: 6,
      isSubmitting: false,
    });
  };

  getInitialValues() {
    const { winningStructure, results } = this.props;

    return winningStructure
      .map((elem) => {
        if (elem.type === 'range') {
          return { [elem.name]: results ? results[elem.name] : [...Array(elem.maxNumberCount)] };
        }

        if (elem.type === 'integer') {
          return { [elem.name]: results ? results[elem.name] : [null] };
        }
      })
      .reduce((a, b) => {
        const key = Object.keys(b)[0];
        a[key] = b[key];
        return a;
      }, {});
  }

  renderContent({ values, errors, touched, setFieldValue, setFieldTouched }) {
    const { winningStructure, success } = this.props;
    return winningStructure.map((elem, index) => {
      if (elem.type === 'range') {
        return (
          <div className="form-group d-flex flex-column" key={index}>
            <label>
              "<span className="text-capitalize font-weight-bold">{elem.name}</span>" numbers
            </label>
            <div className="d-flex w-100 flex-wrap">
              {[...Array(elem.maxNumberCount)].map((structureElem, structureIndex) => {
                return (
                  <div className="mr-2 flex-row" key={structureIndex}>
                    <Field
                      type="number"
                      formGroupClassName="p-1 text-secondary"
                      label={`#${structureIndex + 1}:`}
                      min={elem.range.start}
                      max={elem.range.end}
                      name={`${elem.name}[${structureIndex}]`}
                      onChange={setFieldValue}
                      onBlur={() => setFieldTouched(`${elem.name}[${structureIndex}]`, true)}
                      value={parseInt(values[elem.name][structureIndex])}
                      touched={touched.firstName}
                      error={errors.firstName}
                      disabled={this.state.isSubmitting || success}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      if (elem.type === 'integer') {
        return (
          <div className="form-group d-flex" key={index}>
            <div className="mr-2">
              <Field
                type="number"
                min={elem.range.start}
                max={elem.range.end}
                label={`${elem.name}`}
                name={`${elem.name}`}
                onChange={setFieldValue}
                onBlur={() => setFieldTouched('firstName', true)}
                value={values[elem.name][0]}
                disabled={this.state.isSubmitting || success}
                touched={touched.firstName}
                error={errors.firstName}
              />
            </div>
          </div>
        );
      }
    });
  }

  render() {
    const { success, onDoneClick } = this.props;

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={this.getInitialValues()}
          onSubmit={(values, actions) => this.handleFormSubmit(values, actions)}
          render={({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => (
            <form className="add-brand-form" onSubmit={handleSubmit}>
              {this.renderContent({ values, errors, touched, setFieldValue, setFieldTouched })}
              <div className="d-flex justify-content-center">
                {!success ? (
                  <React.Fragment>
                    <div className="btn btn-primary btn-gray mr-2" onClick={this.handleReset}>
                      Cancel
                    </div>
                    <Button color={'success'} onClick={handleSubmit} type="submit">
                      {this.state.isSubmitting ? this.state.timer : 'Submit'}
                    </Button>
                  </React.Fragment>
                ) : (
                  <div className="btn btn-success" onClick={onDoneClick}>
                    Done!
                  </div>
                )}
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

ResultConfirmationForm.propTypes = {
  onFormSubmit: propTypes.func,
  winningStructure: propTypes.array,
  success: propTypes.bool,
  onDoneClick: propTypes.func,
  onCancel: propTypes.func,
  results: propTypes.object,
};

export default ResultConfirmationForm;
