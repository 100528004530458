import React from 'react';
import { Grid, Page } from 'tabler-react';

import { AddUser } from '../../Users/index';

import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';

class AddBackendUserPage extends React.Component {
  render() {
    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin]} forbiddenRedirect={'/'}>
        <Page>
          <Page.Header>
            <Page.Title>Add user</Page.Title>
          </Page.Header>
          <Grid.Row>
            <Grid.Col md={6}>
              <AddUser />
            </Grid.Col>
          </Grid.Row>
        </Page>
      </AccessControl>
    );
  }
}

export default AddBackendUserPage;
