export const TRANSACTION_TYPES = [
  {
    label: 'Deposit',
    value: 1,
  },
  {
    label: 'Withdraw',
    value: 2,
  },
];
