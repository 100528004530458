import { BET, BET_LINES, BET_PERMS } from './types';

export const getBet = (id, type) => ({
  type: BET.FETCH,
  payload: {
    id,
    type,
  },
});

export const getBetLines = (id, page = 1, entries) => ({
  type: BET_LINES.FETCH,
  payload: {
    id,
    page,
    entries,
  },
});

export const getBetPerms = (id, page = 1, entries) => ({
  type: BET_PERMS.FETCH,
  payload: {
    id,
    page,
    entries,
  },
});

export const resetBetData = () => ({
  type: BET.RESET,
});
