import { createLogic } from 'redux-logic';

import { DASHBOARD } from '../actions/types';

export const getDashboardData = createLogic({
  type: DASHBOARD.FETCH,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/dashboard')
      .then((res) => {
        dispatch({
          type: DASHBOARD.SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: DASHBOARD.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getDashboardData,
};
