import genericAsyncReducer from '../generic/genericAsyncReducer';

const DRAW_SUMMARY = 'DRAW_SUMMARY';
const FETCH = 'FETCH';

const drawSummary = genericAsyncReducer(DRAW_SUMMARY, FETCH);

export default {
  drawSummary,
};
