import { LOBBY_VERSIONS_GET, LOBBY_VERSION_CHANGE_ENTRIES, LOBBY_VERSION_DELETE } from '../../actions/types';

const initialState = {
  lobbiesVersions: null,
  isPending: false,
  error: null,
  headers: null,
  entries: 50,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOBBY_VERSIONS_GET.START:
      return {
        ...state,
        isPending: true,
        error: null,
      };
    case LOBBY_VERSIONS_GET.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY_VERSIONS_GET.SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
        lobbiesVersions: action.payload.data,
        headers: action.payload.headers,
      };
    case LOBBY_VERSION_DELETE.START:
      return {
        ...state,
        isPending: true,
      };
    case LOBBY_VERSION_DELETE.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case LOBBY_VERSION_DELETE.SUCCESS: {
      const newData = state.lobbiesVersions.filter((elem) => elem.id !== action.payload);

      return {
        ...state,
        isPending: false,
        error: null,
        lobbiesVersions: newData,
      };
    }
    case LOBBY_VERSION_CHANGE_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };
    default:
      return state;
  }
};
