import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Page, Button, Dropdown } from 'tabler-react';
import { omit } from 'lodash';

import {
  LotteryForm,
  EditTicketCost,
  EditTicketBaseCost,
  EditWinningPrice,
  IntegrationInfo,
  LotteryAgencySelection,
} from '../../Lottery/index';
import ProgressiveJackpot from '../../Lottery/ProgressiveJackpot';

import {
  getLottery,
  clearLottery,
  changeLotteryType,
  updateLottery,
  getBettingAgencies,
  getBettingAgency,
  changeLotteryMainCurrency,
  resetCurrency,
} from '../../../actions/lotteryActions';
import { changeLotteryType as changeLotteryFilter } from '../../../actions/draws';
import { changeType, changeProvider } from '../../../actions/providerDraws';
import { clearPrices } from '../../../actions/prices';
import { getPrizeTypes } from '../../../actions/systemConsts';
import httpClient from '../../../services/httpClient';

import { getPrizeTypeKey, getApiPrizeTypeAmount } from '../../../utils/lottery';

import TicketExportEmail from './TicketExportEmail';

class LotteryEdit extends React.Component {
  constructor() {
    super();

    this.state = {
      addLotterySuccess: false,
      addLotteryError: null,
    };
  }

  componentDidMount() {
    this.getLottery();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getLottery();
    }
  }

  componentWillUnmount() {
    this.props.resetCurrency();
  }

  getLottery() {
    const { id, clearLottery, clearPrices, getBettingAgencies, getPrizeTypes, getLottery } = this.props;

    clearLottery();
    clearPrices();
    getBettingAgencies();
    getPrizeTypes();

    if (id) {
      getLottery(id);
    }
  }

  preparePriceObject(price) {
    const data = [];

    price.map((p) => {
      const key = getPrizeTypeKey(p);

      if (!key) {
        if (p.id) {
          data.push({
            ...p,
            id: p.id,
            name: p.name,
            prizeType: p.prizeType,
          });
        } else {
          data.push({
            ...p,
            name: p.name,
            prizeType: p.prizeType,
          });
        }

        return;
      }

      if (p.id) {
        data.push({
          ...p,
          id: p.id,
          name: p.name,
          prizeType: p.prizeType,
          [key]: getApiPrizeTypeAmount(p[key], p.prizeType),
        });
      } else {
        data.push({
          ...p,
          name: p.name,
          prizeType: p.prizeType,
          amount: getApiPrizeTypeAmount(p[key], p.prizeType),
        });
      }
    });

    return data;
  }

  handleLotteryDrawsBtnClick = () => {
    const { data, changeLotteryFilter, history } = this.props;

    changeLotteryFilter(data);
    history.push('/draws');
  };

  handleProgressiveJackpotFundTransactionsBtnClick = () => {
    const { data, history } = this.props;
    history.push(`/progressive-jackpot-fund-transactions/${data.id}`);
  };

  handleManualDrawBtnClick = () => {
    const { data, history } = this.props;
    history.push(`/draws-manual/${data.id}`);
  };

  handleProviderDrawBtnClick = () => {
    const { data, changeProvider, history } = this.props;
    const provider = {
      label: data.gameResultAgencyConfig.name,
      value: data.gameResultAgencyConfig.name,
      id: data.gameResultAgencyConfig.id,
      name: data.gameResultAgencyConfig.name,
    };

    changeProvider(provider);
    history.push('/provider-draws');
  };

  handleSubmitLottery = (values, actions) => {
    const { data, currenciesToChange, id, salesSumPrizePoolPercentage, progressiveJackpot } = this.props;
    actions.setSubmitting(true);
    const newBaseCurrency = this.props.baseCurrency;
    const currencies = this.props.currencies.filter((item) => item.currency !== data.betLineBasePrice.currency);
    const betLineAdditionalPrices = currencies
      .filter((item) => currenciesToChange.includes(item.currency))
      .filter((item) => item.amount !== '')
      .map((item) => ({
        ...item,
        amount: item.amount ? item.amount * 100 : null,
      }));
    const oldBetLineAdditionalPrices = data.betLineAdditionalPrices
      ? data.betLineAdditionalPrices.filter((item) => !currenciesToChange.includes(item.currency))
      : [];
    const newBetLineAdditionalPrices = [...oldBetLineAdditionalPrices, ...betLineAdditionalPrices];
    const stakes = values.stakes
      ? values.stakes
          .trim()
          .split(',')
          .map((elem) => parseInt(elem))
      : [];

    const existingLottery = {
      ...data,
      displayedName: values.displayedName,
      bettingAgencyConfig: { id: data.bettingAgencyConfig.id },
      gameResultAgencyConfig: { id: data.gameResultAgencyConfig.id },
      betLineBasePrice: newBaseCurrency
        ? { amount: newBaseCurrency * 100, currency: values.currency.value }
        : {
            amount: data.betLineBasePrice.amount,
            currency: values.currency.value,
          },
      name: values.name,
      maxLinesPerBet: values.maxLinesPerBet,
      ticketsTtlDays: values.ticketsTtlDays,
      futureDrawsCount: Number(values.futureDrawsCount),
      active: values.active,
      useProviderDrawLogic: values.useProviderDrawLogic,
      isStakesRangeModeEnabled: values.isStakesRangeModeEnabled,
      availableFrom: values.availableFrom.format(),
      availableTo: values.availableTo ? values.availableTo.format() : null,
      stakes,
      status: values.status ? values.status.value : null,
      brand: {
        ...values.brand,
      },
      lottery: {
        ...values.lottery,
      },
      salesSumPrizePoolPercentage,
      timezone: values.defaultTimezone.value !== 'default' ? values.defaultTimezone.value : null,
      defaultJackpotValue: {
        amount: values.defaultJackpotValue * 100,
        currency: values.currency.value,
      },
      defaultLanguage: values.defaultLanguage.value,
      description: values.description,
      possiblePrizes: this.preparePriceObject(this.props.prices),
      betLineAdditionalPrices: newBetLineAdditionalPrices,
      image: values.image,
      permutationsEnabled: values.permutationsEnabled,
      prizeResolverClass: values.prizeResolverClass.value,
      currency: values.currency.value,
      shouldRoundPrizes: values.shouldRoundPrizes,
      allowInstantBets: values.allowInstantBets,
      progressiveJackpotFundEnabled: progressiveJackpot.isEnabled,
      progressiveJackpotFundSalePercent: progressiveJackpot.salePercentage,
      progressiveJackpotFundTTL: progressiveJackpot.TTL,
      progressiveJackpotIncludeCurrentDraw: progressiveJackpot.includeCurrentDraw,
      progressiveJackpotInitialAmount: progressiveJackpot.initialAmount
        ? {
            amount: progressiveJackpot.initialAmount ? progressiveJackpot.initialAmount : 0,
            currency: values.currency.value,
          }
        : null,
    };

    const stakesToDelete = data.lottery.structure.hasStakes ? [] : ['stakes', 'isStakesRangeModeEnabled'];
    // usage of lodash omit for future deletion of keys which we dont need]
    const lotteryToSend = omit(existingLottery, ['jackpotBalance', ...stakesToDelete]);

    return httpClient
      .put(`/api/v1/games/${id}`, { ...lotteryToSend })
      .then(() => {
        actions.resetForm();
        this.setState(
          {
            addLotterySuccess: true,
            addLotteryError: null,
          },
          () => {
            this.props.getLottery(id);
          }
        );
      })
      .catch((err) => {
        actions.setSubmitting(false);
        this.setState({
          addLotterySuccess: false,
          addLotteryError: err.response,
        });
      });
  };

  handleBettingAgencyChange = (option) => {
    const { getBettingAgency, updateLottery } = this.props;

    getBettingAgency(option.label);
    updateLottery('bettingAgencyType', option.label);
  };

  buildSelectOptions = (optionsList) => {
    return optionsList ? optionsList.map((option) => ({ value: option, label: option })) : [];
  };

  render() {
    const {
      data,
      isPending,
      error,
      getLottery,
      changeLotteryType,
      id,
      bettingAgencies,
      updateLottery,
      prizeTypes,
      mainCurrency,
      changeLotteryMainCurrency,
      currencies,
    } = this.props;
    const { addLotteryError, addLotterySuccess } = this.state;
    const hasDynamicPrizeValues = data && data.lottery && data.lottery.structure.hasDynamicPrizeValues;

    return (
      <Page>
        <Page.Header>
          <div className="w-100 d-flex">
            <div className=" w-50 justify-content-left">
              <Page.Title>Edit existing lottery</Page.Title>
            </div>

            <div className=" w-50 pt-2 justify-content-end">
              <div className=" d-flex flex-row-reverse">
                <div className="pl-2">
                  {data && (
                    <Button.Dropdown value="Go to draws" className="btn btn-primary">
                      <Dropdown.Item onClick={() => this.handleLotteryDrawsBtnClick(data)}>Lottery draws</Dropdown.Item>
                      {data.useProviderDrawLogic && (
                        <Dropdown.Item onClick={this.handleProviderDrawBtnClick}>Provider draws</Dropdown.Item>
                      )}
                    </Button.Dropdown>
                  )}
                </div>

                {data &&
                  data.lottery &&
                  data.lottery.structure &&
                  data.lottery.structure.allowsManualDrawsManagement && (
                    <div className="pl-2">
                      <Button onClick={() => this.handleManualDrawBtnClick(data)} className="btn btn-primary">
                        Create Draw
                      </Button>
                    </div>
                  )}

                {data && data.progressiveJackpotFundEnabled && (
                  <div className="pl-2">
                    <Button
                      onClick={() => this.handleProgressiveJackpotFundTransactionsBtnClick(data)}
                      className="btn btn-primary"
                    >
                      Go to Progressive Jackpot Fund Transactions
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Page.Header>
        <div className="row">
          <div className="col-md-6">
            <LotteryForm
              data={data}
              isPending={isPending}
              error={error}
              sendLotterySuccess={addLotterySuccess}
              sendLotteryError={addLotteryError}
              lotteryId={id}
              getLottery={getLottery}
              handleSubmit={this.handleSubmitLottery}
              changeLotteryType={changeLotteryType}
              buildSelectOptions={this.buildSelectOptions}
              bettingAgencies={bettingAgencies}
              onChangeBettingAgency={this.handleBettingAgencyChange}
              currency={mainCurrency}
              changeMainCurrency={changeLotteryMainCurrency}
              editMode
            />
          </div>
          <div className="col-md-6">
            <ProgressiveJackpot editMode />
            <TicketExportEmail data={data} />

            <EditWinningPrice
              data={data}
              isPending={isPending}
              error={error}
              getLottery={getLottery}
              lotteryId={id}
              showMessage={hasDynamicPrizeValues}
              prizeTypes={prizeTypes}
              buildSelectOptions={this.buildSelectOptions}
              message="This lottery supplier provides prizes data automatically"
              type="other"
            />
            {data && (
              <EditTicketBaseCost
                currencies={currencies}
                isPending={isPending}
                buildSelectOptions={this.buildSelectOptions}
              />
            )}
            {data && data.lottery && (
              <LotteryAgencySelection
                bettingAgencyName={data.bettingAgencyType}
                gameConfigName={data.lottery.structure.gameResultAgencyName}
                selectedBettingAgency={data.bettingAgencyConfig}
                selectedGameConfig={data.gameResultAgencyConfig}
                onChangeGameConfig={(selected) => updateLottery(`gameResultAgencyConfig.id`, selected.value)}
                onChangeBettingAgency={(selected) => updateLottery(`bettingAgencyConfig.id`, selected.value)}
              />
            )}
            <EditTicketCost data={data} isPending={isPending} />
            <IntegrationInfo data={data} isPending={isPending} />
          </div>
        </div>
      </Page>
    );
  }
}

LotteryEdit.propTypes = {
  currencies: PropTypes.array,
  baseCurrency: PropTypes.object,
  match: PropTypes.object,
  isPending: PropTypes.bool,
  error: PropTypes.object,
  data: PropTypes.object,
  clearLottery: PropTypes.func,
  getLottery: PropTypes.func,
  clearPrices: PropTypes.func,
  prices: PropTypes.array,
  changeLotteryType: PropTypes.func,
  currenciesToChange: PropTypes.array,
  updateLottery: PropTypes.func.isRequired,
  id: PropTypes.string,
  getBettingAgencies: PropTypes.func,
  bettingAgencies: PropTypes.array,
  getBettingAgency: PropTypes.func,
  changeLotteryFilter: PropTypes.func,
  bettingAgency: PropTypes.object,
  getPrizeTypes: PropTypes.func,
  prizeTypes: PropTypes.array,
  currency: PropTypes.object,
  salesSumPrizePoolPercentage: PropTypes.number,

  changeLotteryMainCurrency: PropTypes.func,
  resetCurrency: PropTypes.func,
  mainCurrency: PropTypes.object,
  history: PropTypes.object,
  changeProvider: PropTypes.func,
  progressiveJackpot: PropTypes.object,
};

export default withRouter(
  connect(
    (state) => ({
      data: state.lottery.data,
      isPending: state.lottery.isPending,
      error: state.lottery.error,
      prices: state.prices.prices,
      currencies: state.currencies.allCurrencies,
      baseCurrency: state.lottery.baseTicketCost,
      currenciesToChange: state.currencies.currenciesToChange,
      bettingAgencies: state.bettingAgency.agencies.data,
      bettingAgency: state.bettingAgency.singleAgency.data,
      prizeTypes: state.prizeTypes.data,
      currency: state.prices.currency,
      salesSumPrizePoolPercentage: state.lottery.salesSumPrizePoolPercentage,
      mainCurrency: state.lottery.currency,
      progressiveJackpot: state.progressiveJackpot,
    }),
    {
      getLottery,
      clearLottery,
      clearPrices,
      changeLotteryType,
      updateLottery,
      getBettingAgencies,
      getBettingAgency,
      changeLotteryFilter,
      getPrizeTypes,
      changeLotteryMainCurrency,
      resetCurrency,
      changeType,
      changeProvider,
    }
  )(LotteryEdit)
);
