import userRoles from './roles';

export const DRAW_COLUMNS = [
  {
    header: 'Lottery',
    accessor: 'game.name',
    id: 'game.id',
    type: 'event',
    linkto: '/lottery/lotteries',
    roles: [userRoles.superAdmin, userRoles.admin],
  },
  {
    header: 'Brand',
    accessor: 'game.brand.name',
    id: 'game.brand.id',
    type: 'event',
    linkto: '/brands',
  },
  {
    header: 'Supplier',
    accessor: 'game.lottery.name',
    type: 'string',
  },
  {
    header: 'Lottery struct',
    accessor: 'game.lottery.structure.name',
    type: 'string',
  },
  {
    header: 'Status',
    accessor: 'status',
    type: 'string',
  },
  {
    header: 'Selections',
    accessor: 'wonBetSelection.asArray',
    type: 'drawSelection',
  },
  {
    header: 'Draw date',
    accessor: 'drawDatetime',
    secondAccessor: 'game.timezone',
    type: 'date',
  },
  {
    header: '',
    accessor: 'drawDatetime',
    type: 'countdown',
  },
  {
    header: '',
    id: 'id',
    type: 'icons',
    elements: [
      {
        header: '',
        id: 'id',
        type: 'event',
        linkto: '/draws',
        icon: 'info',
      },
    ],
  },
];
