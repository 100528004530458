import { OUTBOUND_CHANNELS } from '../../actions/types';

const initialState = {
  integrations: {},
  template: {},
  channels: [],
  channel: {},
  headers: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OUTBOUND_CHANNELS.AVAILABLE_INTEGRATIONS.SUCCESS:
      return {
        ...state,
        integrations: action.payload,
      };
    case OUTBOUND_CHANNELS.TEMPLATE.SUCCESS:
      return {
        ...state,
        template: action.payload,
      };
    case OUTBOUND_CHANNELS.SUCCESS:
      return {
        ...state,
        channels: action.payload.data,
        headers: action.payload.headers,
      };
    case OUTBOUND_CHANNELS.CHANNEL.SUCCESS:
      return {
        ...state,
        channel: action.payload,
      };
    case OUTBOUND_CHANNELS.CHANNEL.CLEAR:
      return {
        ...state,
        channel: {},
      };
    default:
      return state;
  }
};
