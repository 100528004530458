import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Alert } from 'tabler-react';
import { connect } from 'react-redux';

import { resetNotificationData } from '../../../actions/notification';
import { getSystemSettings } from '../../../actions/systemSettings';

import './Notification.scss';

class Notification extends React.Component {
  timeout = null;
  state = {
    openAnim: false,
  };

  componentDidMount() {
    const { resetNotificationData, getSystemSettings, time, isSystemNotification } = this.props;
    this.setState({ openAnim: true });

    this.timeout = setInterval(() => {
      if (isSystemNotification) {
        getSystemSettings();
        return;
      }

      this.setState({ openAnim: false });
      resetNotificationData();
    }, time || 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    const { message, isError } = this.props;
    const { openAnim } = this.state;

    return (
      <Alert
        className={classnames('alert w-100 position-fixed notification', {
          'notification-active': openAnim,
        })}
        type={isError ? 'danger' : 'success'}
        icon={isError ? 'alert-triangle' : 'check'}
      >
        {message}
      </Alert>
    );
  }
}

Notification.propTypes = {
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.string,
  resetNotificationData: PropTypes.func,
  getSystemSettings: PropTypes.func,
  time: PropTypes.number,
  isSystemNotification: PropTypes.bool,
};

export default connect(
  (state) => ({
    isError: state.notification.isError,
    isSuccess: state.notification.isSuccess,
    message: state.notification.message,
    time: state.notification.time,
    isSystemNotification: state.notification.isSystemNotification,
  }),
  {
    resetNotificationData,
    getSystemSettings,
  }
)(Notification);
