import { hasAccess } from '../../services/Guardian';
import { INSTANT_WINNINGS } from '../reports/consts';

/* Move all logic from Reports component here. If it comes to "table builder" i think its better to just declare whole build as const like in ./tableConstants.js file */
/* As i can see now it works like this:
  1. get Data from API
  2. declare array with columns names that you don't want to display.
  3. delete column names that we dont want from main array
  4. Map through arr with column names and return object with necessary data

  If we want to leave logic like this i think its better to unify it for all tables, but the problem is that in some tables we have more complicated logic than just display data.

  For now I will do some changes in this logic. For example we dont need an extra array with unwanted columns. I think it's better to declare array with names of columns that we want to display.
*/

// function removeUnwantedColumns(colection,columnNames) {
//   for ( const columnName of columnNames ){
//     _.remove(colection, (n) => n === columnName );
//   }
//   return colection;
// }

// Hmm.. for now i don't know how to resolve this. I mean if we want to use it for all tables in project, there will be like a shitload of cases.
function getHeaderType(header) {
  switch (header) {
    case 'sales':
    case 'ggr':
    case 'salePriceSum':
    case 'winningsSum':
    case 'ticketCost':
    case 'insuranceCosts':
    case 'fees':
    case 'tax':
    case 'systemCost':
    case 'realCost':
    case 'withholdingTax':
    case 'jackpotBalance':
    case 'systemWithholdingTax':
    case 'netSystemWinning':
    case 'realTickets':
    case 'bonus':
      return 'reportPrice';
    case 'period':
      return 'period';
    case 'periodFrom':
    case 'confirmedAt':
    case 'createdAt':
    case 'drawDate':
    case 'periodTo':
      return 'date';
    case 'gameId':
    case 'brandId':
    case 'playerId':
    case 'gameDrawId':
    case 'playerBrandId':
    case 'betId':
    case 'drawId':
      return 'id';
    case 'gameName':
    case 'brandName':
    case 'playerBrandName':
    case 'playerName':
      return 'link';
    default:
      return 'string';
  }
}

// same here, for now we have some number of possible links but in the future it can be higher.
// in shouldLink i will leave true for now, cause component should decided if it shouldLink cause components have access to user roles.
function buildLink(elem, userRoles, type) {
  switch (elem) {
    case 'betId': {
      const properLink = type === INSTANT_WINNINGS ? '/draws/instant-bet' : '/draws/bet';

      return {
        shouldLink: true,
        target: properLink,
      };
    }
    case 'drawId':
      return {
        shouldLink: true,
        target: '/draws',
      };
    case 'gameDrawId':
      return {
        shouldLink: true,
        target: '/draws',
      };
    case 'playerId':
      return {
        shouldLink: hasAccess(userRoles, ['super_admin']),
        target: '/players',
      };
    case 'brandName':
      return {
        shouldLink: hasAccess(userRoles, ['super_admin', 'admin']),
        target: `/brands`,
        accessor: 'brand.id',
      };
    case 'playerBrandName':
      return {
        shouldLink: hasAccess(userRoles, ['super_admin', 'admin']),
        target: `/brands`,
        accessor: 'playerBrandId',
      };
    case 'gameName':
      return {
        shouldLink: hasAccess(userRoles, ['super_admin', 'brand_operator', 'admin']),
        target: `/lottery/lotteries`,
        accessor: 'gameId',
      };
    case 'playerName':
      return {
        shouldLink: hasAccess(userRoles, ['super_admin', 'admin']),
        target: `/players`,
        accessor: 'playerId',
      };
    default:
      return false;
  }
}

export function createColumnsConfig(columnNames, userRoles, type = '') {
  if (!Array.isArray(columnNames)) {
    return [];
  }

  // We have to refactor TableElement component, cause for now we're passing a lot of data to it. And after refactor it would be more reusable
  return columnNames.map((elem) => {
    return {
      header: elem.replace(/([A-Z])/g, ' $1'),
      accessor: elem,
      type: getHeaderType(elem),
      link: buildLink(elem, userRoles, type),
    };
  });
}

// function getAccessor(elem){
//   switch (elem){
//     case 'betId':
//       return 'id';
//     case 'playerId':
//       return 'player.id';
//     case 'drawDate':
//       return 'gameDraw.drawDatetime';
//     case 'brandName':
//       return 'brand.name';
//     default :
//       return elem;
//   }
// }
