import { combineReducers } from 'redux';

import login from './auth/login';
import logout from './auth/logout';
import user from './auth/user';
import brands from './brands';
import account from './auth/account';
import brand from './brand';
import backendUsers from './backendUsers';
import backendUser from './user';
import lottery from './lottery/lottery';
import lotteries from './lottery/lotteries';
import bettingAgency from './lottery/bettingAgency';
import prices from './lottery/prices';
import addLottery from './lottery/addLottery';
import currencies from './lottery/currency';
import progressiveJackpot from './lottery/progressiveJackpot';
import draws from './draws/draws';
import drawsFilter from './draws/drawsFilter';
import draw from './draws/draw';
import drawResults from './draws/drawResults';
import drawBets from './draws/drawBets';
import reportsFilters from './reports/reportsFilters';
import reports from './reports/reports';
import summaries from './reports/summaries';
import dashboard from './dashboard';
import tickets from './tickets/tickets';
import ticketsFilters from './tickets/ticketsFilters';
import bet from './bet';
import betLines from './betLines';
import betPerms from './betPerms';
import drawStats from './draws/drawStats';
import player from './player';
import bets from './bets';
import lobbyList from './lobby/lobbyList';
import lobbyMeta from './lobby/lobbyMeta';
import lobby from './lobby/lobby';
import i18n from './lottery/i18n';
import outboundChannels from './lottery/outboundChannels';
import lobbyVersions from './lobby/versions';
import schedule from './lottery/schedule';
import notification from './notification';
import systemConsts from './systemConsts';
import exchangeRate from './exchangeRates';
import providers from './providers';
import systemSettings from './systemSettings';
import pageConfig from './pageConfig';
import players from './players';
import taxes from './taxes';
import providerDraws from './providerDraws';
import drawSummary from './draws/drawSummary';
import promotions from './promotions/promotions';
import modals from './modals';
import search from './search';

const rootReducer = (state, action) => {
  if (action.type === 'STATE_RESET') {
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  login,
  logout,
  user,
  brands,
  brand,
  account,
  backendUsers,
  backendUser,
  currencies,
  lottery,
  lotteries,
  prices,
  addLottery,
  draws,
  drawsFilter,
  draw,
  drawBets,
  reportsFilters,
  reports,
  dashboard,
  tickets,
  ticketsFilters,
  bet,
  betLines,
  betPerms,
  drawStats,
  player,
  bets,
  lobbyList,
  lobbyMeta,
  lobby,
  i18n,
  drawResults,
  lobbyVersions,
  bettingAgency,
  schedule,
  notification,
  summaries,
  outboundChannels,
  pageConfig,
  modals,
  search,
  progressiveJackpot,
  ...systemConsts,
  ...players,
  ...exchangeRate,
  ...providers,
  ...systemSettings,
  ...taxes,
  ...providerDraws,
  ...drawSummary,
  ...promotions,
});

export default rootReducer;
