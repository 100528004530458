import React from 'react';

import Reports from './Reports';

import { PLAYERS_REPORTS_COLUMNS_NAMES } from '../../../utils/reports/consts';

class PlayersReport extends React.Component {
  render() {
    return <Reports reportsType="players" tableColumns={PLAYERS_REPORTS_COLUMNS_NAMES} />;
  }
}

export default PlayersReport;
