import React from 'react';
import { Card, Table } from 'tabler-react';
import propTypes from 'prop-types';

import AddWinningPriceSingle from '../Lottery/AddWinningPriceSingle';

class DrawPrizes extends React.Component {
  renderContent() {
    const { prizes } = this.props;

    if (!prizes) {
      return null;
    }

    return prizes.map((prize, i) => {
      return <AddWinningPriceSingle key={i} index={i} price={prize} id={prize.id} readOnly withPrizeType />;
    });
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <Card.Status color="blue" />
          <Card.Body>
            <Table hasOutline responsive highlightRowOnHover className="table-vcenter">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Prize type</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader>
                  <Table.ColHeader>Amount</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.renderContent()}</Table.Body>
            </Table>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

DrawPrizes.propTypes = {
  prizes: propTypes.array,
};

export default DrawPrizes;
