const getFilterStatus = (state) => {
  return {
    lotteryType: state.drawsFilter.lotteryType,
    parentBrand: state.drawsFilter.brand,
    isFutureDrawsChecked: state.drawsFilter.showFutureDraws,
    isShowOnlyNotProcessedDrawsChecked: state.drawsFilter.showOnlyNotProcessedDraws,
  };
};

export default {
  getFilterStatus,
};
