import { TAXES } from './types';

export const getBrandTaxes = (id) => ({
  type: TAXES.GET.START,
  payload: id,
});

export const putBrandTaxes = (data, id) => ({
  type: TAXES.PUT.START,
  payload: {
    data,
    id,
  },
});

export const deleteBrandTaxes = (id, brandId) => ({
  type: TAXES.DELETE.START,
  payload: {
    id,
    brandId,
  },
});
