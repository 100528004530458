import { createLogic } from 'redux-logic';

import { SYSTEM_SETTINGS, NOTIFICATION } from '../actions/types';

import { MAINTENANCE_MODE } from '../utils/constans';

export const getSystemSettings = createLogic({
  type: SYSTEM_SETTINGS.FETCH.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get('/api/v1/global-settings')
      .then((res) => {
        const maintenance = res.data.find((elem) => elem.name === MAINTENANCE_MODE);
        if (maintenance.value === 'true') {
          dispatch({
            type: NOTIFICATION.OPEN_ERROR,
            payload: {
              message: 'Maintenance mode is ON',
              time: 60000,
              isSystemNotification: true,
            },
          });
        } else {
          dispatch({
            type: NOTIFICATION.RESET,
          });
        }
        dispatch({
          type: SYSTEM_SETTINGS.FETCH.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SYSTEM_SETTINGS.FETCH.ERROR,
          payload: err.response,
        });
        dispatch({
          type: NOTIFICATION.RESET,
        });
      })
      .then(done);
  },
});

export const putSystemSettings = createLogic({
  type: SYSTEM_SETTINGS.PUT.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { name, value } = action.payload;

    const data = {
      name,
      value,
    };

    return httpClient
      .put(`/api/v1/global-settings/${action.payload.name}`, data)
      .then((res) => {
        if (res.data.value === 'true' && res.data.name === MAINTENANCE_MODE) {
          dispatch({
            type: NOTIFICATION.OPEN_ERROR,
            payload: {
              message: 'Maintenance mode is ON',
              time: 60000,
              isSystemNotification: true,
            },
          });
        } else {
          dispatch({
            type: NOTIFICATION.RESET,
          });
        }
        dispatch({
          type: SYSTEM_SETTINGS.FETCH.START,
        });
        dispatch({
          type: SYSTEM_SETTINGS.PUT.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SYSTEM_SETTINGS.PUT.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getSystemSettings,
  putSystemSettings,
};
