/* eslint-disable jsx-quotes */
import React from 'react';
import propTypes from 'prop-types';
import { TabbedCard, Tab } from 'tabler-react';
import { connect } from 'react-redux';

import Draw from './Draw';
import ManagementTab from './ManagementTab';
import PrizesTab from './PrizesTab';
import DrawSummary from './DrawSummary';
import DrawProtocol from './DrawProtocol';
import DrawPJPFundsTab from './DrawPJPFundsTab';

import { getDraw, getDrawStats, putNewJackpot, getDrawSummary } from '../../../actions/draws';
import { getAllowedCurrencies } from '../../../actions/systemConsts';
import { postManagementAction } from '../../../actions/management';

import config from 'appConfig';

class DrawPage extends React.Component {
  componentDidMount() {
    const { getDraw, getDrawStats, getDrawSummary, getAllowedCurrencies } = this.props;
    const id = this.getId();

    getAllowedCurrencies();
    getDraw(id);
    getDrawStats(id);
    getDrawSummary(id);
  }

  getId = () => {
    return this.props.match && this.props.match.params.id ? this.props.match.params.id : null;
  };

  render() {
    const { draw, drawSummary, jackpotError, drawStats, putNewJackpot, postManagementAction, currencies } = this.props;

    return (
      <div className="mt-5">
        <TabbedCard initialTab={'General'} className={'mt-5'}>
          <Tab title={'General'}>
            <Draw id={this.getId()} {...{ draw, currencies, drawStats, jackpotError, putNewJackpot }} />
          </Tab>
          <Tab title={'Summary'}>
            <DrawSummary id={this.getId()} {...{ drawSummary }} />
          </Tab>
          <Tab title={'Management'}>
            {/* passing whole draw.data object for future development*/}
            <ManagementTab draw={draw.data} {...{ postManagementAction }} />
          </Tab>
          <Tab title="Prizes">
            <PrizesTab draw={draw.data} />
          </Tab>
          {draw.data && (config.SHOW_DRAW_PROTOCOL === true || config.SHOW_DRAW_PROTOCOL === 'true') && (
            <Tab title="Protocol">
              <DrawProtocol
                {...{ drawSummary }}
                drawNumber={draw.data.drawNumber}
                drawStructure={draw.data.game.selectionsStructures.betLine}
                wonBetSelection={draw.data.wonBetSelection}
              />
            </Tab>
          )}
          {draw.data && draw.data.game && draw.data.game.progressiveJackpotFundEnabled && (
            <Tab title="PJP Transactions">{<DrawPJPFundsTab draw={draw.data} />}</Tab>
          )}
        </TabbedCard>
      </div>
    );
  }
}

DrawPage.propTypes = {
  draw: propTypes.shape({
    data: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
    isPending: propTypes.bool,
    error: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
  }),
  currencies: propTypes.arrayOf(propTypes.string),
  drawStats: propTypes.shape({
    data: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.array]),
    isPending: propTypes.bool,
    error: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
  }),
  drawSummary: propTypes.shape({
    data: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.array]),
    isLoading: propTypes.bool,
    isSuccess: propTypes.bool,
    headers: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
    error: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
  }),
  match: propTypes.object,
  jackpotError: propTypes.object,

  getDraw: propTypes.func,
  getDrawStats: propTypes.func,
  getAllowedCurrencies: propTypes.func,
  getDrawSummary: propTypes.func,
  putNewJackpot: propTypes.func,
  postManagementAction: propTypes.func,
};

export default connect(
  (state) => ({
    draw: state.draw,
    jackpotError: state.draw.jackpotError,
    drawStats: state.drawStats,
    drawSummary: state.drawSummary,
    currencies: state.allowedCurrencies.data,
  }),
  {
    getDraw,
    getDrawStats,
    getAllowedCurrencies,
    putNewJackpot,
    postManagementAction,
    getDrawSummary,
  }
)(DrawPage);
