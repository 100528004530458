import React from 'react';
import { connect } from 'react-redux';
import { Grid, Page } from 'tabler-react';
import PropTypes from 'prop-types';

import { ErrorHandler } from '../Reusable/ErrorHandler';
import Spinner from '../Reusable/Spinner';
import CustomTable from '../Reusable/Table';

import withPageConfig from '../hoc/withPageConfig';

import { LOBBIES_VERSIONS_PAGE } from '../../utils/pagesConstans';

import {
  getLobbiesVersions,
  changeVersionEntries,
  deleteLobbyVersion,
  getLobby,
} from '../../actions/lobbyActions/lobbyActions';

class LobbyVersions extends React.Component {
  componentDidMount() {
    const { getLobbiesVersions, getLobby, pagiNumber } = this.props;

    getLobbiesVersions(this.getID(), pagiNumber);
    getLobby(this.getID(), true);
  }

  handlePageChange = (pageNumber) => {
    const { getLobbiesVersions, onPaginationPageChange } = this.props;

    getLobbiesVersions(this.getID(), pageNumber);
    onPaginationPageChange(pageNumber);
  };

  handleDeleteVersion = (versionId) => {
    this.props.deleteLobbyVersion(this.getID(), versionId);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    this.props.changeVersionEntries(entries);
    this.props.getLobbiesVersions(this.getID(), 1);
  };

  getID = () => this.props.match.params.id;

  setVersionType = (version, isActive) => {
    if (version.publishedAt && isActive) return 'Active';
    if (version.publishedAt) return 'Published';
    return 'Draft';
  };

  getHeaderType(header) {
    switch (header) {
      case 'createdAt':
        return {
          type: 'date/user',
          accessor: 'createdAt',
          secondAccessor: 'createdBy',
          flex: 1,
        };
      case 'draftSavedAt':
        return {
          type: 'date/user',
          accessor: 'createdAt',
          secondAccessor: 'createdBy',
          flex: 1,
        };
      case 'publishedAt':
        return {
          type: 'date/user',
          accessor: 'publishedAt',
          secondAccessor: 'publishedBy',
          flex: 1,
        };
      case 'type':
        return {
          type: 'string',
          accessor: 'status',
          flex: 1,
        };
      case 'actions':
        return {
          type: 'actions',
          accessor: '',
          flex: 1,
        };
      case '':
        return {
          type: 'string',
          accessor: '',
        };
    }
  }

  getTableData = (versionsData) => {
    const titles = ['createdAt', 'publishedAt', 'type', 'actions'];
    const headers = this.props.headers;
    const { lobby } = this.props;

    const finalVersionsData = versionsData.map((version) => {
      version.status = this.setVersionType(version, version.id === lobby.versionId);
      return version;
    });

    headers['cache-control'] = 'no-cache, private';
    headers['content-type'] = 'application/json';
    headers['x-report-column-names'] = titles;

    const columns = titles.map((title) => {
      if (this.getHeaderType(title).type === 'actions') {
        return {
          header: 'Actions',
          accessor: 'actions',
          type: 'actions',
          id: 'id',
          elements: [
            {
              header: 'Actions',
              lobbyId: this.getID(),
              versionId: 'id',
              type: 'edit',
              linkto: '/lobbies/:id/version/:versionId',
              txt: 'Edit',
            },
            {
              header: '',
              id: 'id',
              type: 'event',
              handler: this.handleDeleteVersion,
              txt: 'Delete',
            },
          ],
        };
      }
      return {
        header: title.replace(/([A-Z])/g, ' $1'),
        accessor: this.getHeaderType(title).accessor,
        secondAccessor: this.getHeaderType(title).secondAccessor,
        type: this.getHeaderType(title).type,
      };
    });

    const preparedData = {
      headers,
      columns,
      data: finalVersionsData,
    };

    return preparedData;
  };

  getTableProps(data) {
    return {
      paginHeaders: data.headers,
      onPageChanged: this.handlePageChange,
      onChangeEntries: this.handleChangeEntriesNumber,
      loading: data.data.pending,
      data: data.data,
      columns: data.columns,
    };
  }

  render() {
    const { lobbyVersions, error, pending } = this.props;
    const preparedData = lobbyVersions ? this.getTableData(lobbyVersions) : null;
    const tableProps = preparedData ? this.getTableProps(preparedData) : null;

    return (
      <Page>
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>
              <span>Lobbies Versions</span>
            </Page.Title>
          </div>
        </Page.Header>
        <Grid.Row>
          <div style={{ width: `100%` }}>
            {error && <ErrorHandler error={error.status === 400 ? error : ''} />}
            {pending && <Spinner />}
            {lobbyVersions && !pending && <CustomTable {...tableProps} />}
          </div>
        </Grid.Row>
      </Page>
    );
  }
}

LobbyVersions.propTypes = {
  lobbyVersions: PropTypes.object,
  getLobby: PropTypes.func,
  headers: PropTypes.object,
  match: PropTypes.object,
  getLobbiesVersions: PropTypes.func,
  changeVersionEntries: PropTypes.func,
  deleteLobbyVersion: PropTypes.func,
  pending: PropTypes.bool,
  error: PropTypes.object,
  lobby: PropTypes.object,
  pagiNumber: PropTypes.number,
  onPaginationPageChange: PropTypes.func,
};

export default connect(
  (state) => ({
    lobby: state.lobby,
    lobbyVersions: state.lobbyVersions.lobbiesVersions,
    headers: state.lobbyVersions.headers,
    error: state.lobbyVersions.error,
    pending: state.lobbyVersions.isPending,
  }),
  {
    getLobbiesVersions,
    changeVersionEntries,
    deleteLobbyVersion,
    getLobby,
  }
)(withPageConfig(LOBBIES_VERSIONS_PAGE)(LobbyVersions));
