import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Formik } from 'formik';
import Modal from 'react-modal';
import { Button, Card, Grid } from 'tabler-react';

import { ErrorHandler } from '../../Reusable/ErrorHandler';

import AuthService from '../../../services/AuthService';

import { login, cancelCurrentLogin, resetPassword, resetPasswordState } from '../../../actions/authActions';

import './Login.scss';

import logo from '../../../assets/images/logo-21.png';

Modal.setAppElement('#root');

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.cancelCurrentLogin();
    if (AuthService.isLogged()) {
      this.props.history.push('/');
    }
  }

  submitLogin(values) {
    this.props.login(values);
  }

  validateLogin(values) {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  }

  toggleForgotPasswordModal = () => {
    this.setState(
      (prevState) => ({
        isModalOpen: !prevState.isModalOpen,
      }),
      () => {
        if (this.state.isModalOpen) {
          this.props.resetPasswordState();
        }
      }
    );
  };

  handleForgotPasswordSubmit(values) {
    this.props.resetPassword(values.email);
  }

  render() {
    const { loginPending, loginError } = this.props;
    return (
      <div className="login-page">
        <Grid.Row>
          <Grid.Col>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validate={(values) => this.validateLogin(values)}
              onSubmit={(values) => this.submitLogin(values)}
              render={({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <Card>
                  <Card.Status color="blue" />
                  <Card.Header>
                    <Card.Title className="text-center mx-auto">
                      <img src={logo} alt="" />
                    </Card.Title>
                  </Card.Header>
                  <form className="login-form" onSubmit={handleSubmit}>
                    {loginError && <ErrorHandler error={loginError} />}
                    <div className="form-group">
                      <label htmlFor="login-email">Email</label>
                      <input
                        id="login-email"
                        className={classNames('form-control', {
                          'is-invalid': touched.email && errors.email,
                        })}
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {touched.email && errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="login-password">Password</label>
                      <input
                        id="login-password"
                        className={classNames('form-control', {
                          'is-invalid': touched.password && errors.password,
                        })}
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      {touched.password && errors.password && <div className="invalid-feedback">{errors.password}</div>}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div onClick={this.toggleForgotPasswordModal} className="forgot-password-click">
                        I forgot my password
                      </div>
                      <Button loading={loginPending} type="submit" color="primary">
                        Submit
                      </Button>
                    </div>
                  </form>
                </Card>
              )}
            />
          </Grid.Col>
        </Grid.Row>
        <Modal
          isOpen={this.state.isModalOpen}
          closeTimeoutMS={200}
          className={'react-modal-custom'}
          onRequestClose={this.toggleForgotPasswordModal}
          contentLabel="Forgot Password"
        >
          <Button className="react-modal-close" color="danger" onClick={this.toggleForgotPasswordModal}>
            X
          </Button>
          <Formik
            initialValues={{
              email: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={(e) => this.handleForgotPasswordSubmit(e)}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Grid.Row>
                <Grid.Col>
                  <Card>
                    <Card.Status color="blue" />
                    <Card.Header>
                      <Card.Title>Forgot Password</Card.Title>
                    </Card.Header>
                    <form className="forgot-password-form" onSubmit={handleSubmit}>
                      <p>Enter your email address and your password will be reset and emailed to you.</p>
                      {this.props.resetError && <ErrorHandler error={this.props.resetError} />}
                      {this.props.resetSuccess && (
                        <div className="alert alert-success">
                          If your email exists in the database, a password reset link will be sent to you
                        </div>
                      )}
                      <div className="form-group">
                        <label htmlFor="forgot-password__email">Email Address</label>
                        <input
                          id="forgot-password__email"
                          className={classNames('form-control', {
                            'is-invalid': touched.email && errors.email,
                          })}
                          type="text"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {touched.email && errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                      <Button loading={this.props.resetPending} type="submit" color="primary">
                        Submit
                      </Button>
                    </form>
                  </Card>
                </Grid.Col>
              </Grid.Row>
            )}
          />
        </Modal>
      </div>
    );
  }
}

Login.propTypes = {
  loginError: PropTypes.object,
  loginPending: PropTypes.bool,
  loginSuccess: PropTypes.bool,
  login: PropTypes.func,
  cancelCurrentLogin: PropTypes.func,
  history: PropTypes.object,
  resetError: PropTypes.object,
  resetPending: PropTypes.bool,
  resetSuccess: PropTypes.bool,
  resetPasswordState: PropTypes.func,
  resetPassword: PropTypes.func,
};

export default connect(
  (state) => ({
    loginError: state.login.error,
    loginPending: state.login.isPending,
    loginSuccess: state.login.success,
    resetError: state.account.reset.error,
    resetPending: state.account.reset.isPending,
    resetSuccess: state.account.reset.success,
  }),
  {
    login,
    cancelCurrentLogin,
    resetPassword,
    resetPasswordState,
  }
)(Login);
