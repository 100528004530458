import React from 'react';
import { Page, Card } from 'tabler-react';
import Spinner from '../../../Reusable/Spinner/Spinner';
import ChannelMessages from './ChannelMessages';
import Notification from '../../../Reusable/Notification/Notification';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import { editMessageTemplates } from '../../../../actions/lotteryActions';

class Channels extends React.PureComponent {
  createChannel = (data) => {
    const { messageChannels, editMessageTemplates } = this.props;
    if (messageChannels) {
      messageChannels.forEach((messageChannel) => delete messageChannel.id);
    }
    editMessageTemplates({ notificationGameConfigs: [...messageChannels, data] });
  };

  editChannel = (data, i) => {
    const { messageChannels, editMessageTemplates } = this.props;
    const finalChannels = [...messageChannels];
    finalChannels[i] = data;
    editMessageTemplates({ notificationGameConfigs: finalChannels });
  };

  deleteChannel = (i) => {
    const { messageChannels, editMessageTemplates } = this.props;
    const finalChannels = messageChannels.splice(1, i);
    editMessageTemplates({ notificationGameConfigs: finalChannels });
  };

  render() {
    const { messageChannels, messageChannelsPending, openNotification } = this.props;
    return (
      <Page>
        <Page.Header>
          <Page.Title>Lottery message templates:</Page.Title>
        </Page.Header>

        {messageChannelsPending ? (
          <Spinner class="mb-5" />
        ) : (
          <div className="row">
            {openNotification && <Notification />}

            {messageChannels.length > 0 && (
              <div className="col-md-6">
                {messageChannels.map((messageChannel, i) => {
                  return (
                    <Card
                      key={`Channel ${i + 1}`}
                      title={`Channel language: ${messageChannel.language}`}
                      isCollapsed
                      isCollapsible
                    >
                      <Card.Body>
                        <ChannelMessages
                          previousMessages={messageChannel}
                          onSubmit={(data) => this.editChannel(data, i)}
                          onDelete={() => this.deleteChannel(i)}
                        />
                      </Card.Body>
                    </Card>
                  );
                })}
              </div>
            )}

            <div className="col-md-6">
              <Card title="Create new channel" isCollapsed isCollapsible>
                <Card.Status color="green" side />
                <Card.Body>
                  <ChannelMessages onSubmit={(data) => this.createChannel(data)} />
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

Channels.propTypes = {
  messageChannels: propTypes.array,
  messageChannelsError: propTypes.object,
  editMessageTemplates: propTypes.func,
  messageChannelsPending: propTypes.bool,
  openNotification: propTypes.bool,
};

export default connect(
  (state) => ({
    messageChannels: state.lottery.data ? state.lottery.data.notificationGameConfigs : [],
    messageChannelsError: state.lottery.data ? state.lottery.data.gameConfigsError : null,
    messageChannelsPending: state.lottery.data ? state.lottery.data.gameConfigsisPending : false,
    openNotification: state.notification.isOpen,
  }),
  {
    editMessageTemplates,
  }
)(Channels);
