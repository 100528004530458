import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'tabler-react';
import styled, { css } from 'react-emotion';

import Spinner from '../Reusable/Spinner/Spinner';

const Input = styled('input')`
  height: calc(2rem + 2px);
`;

const tdClass = css`
  border: none !important;
`;

class IntegrationInfo extends React.Component {
  renderMainContent() {
    const { isPending, data } = this.props;

    if (isPending) {
      return <Spinner />;
    }

    if (!isPending && data) {
      return this.renderContent();
    }
  }

  renderContent() {
    const { id } = this.props.data;

    return (
      <Table hasOutline responsive highlightRowOnHover className="table-vcenter">
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Name</Table.ColHeader>
            <Table.ColHeader>Value</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Col className={tdClass}>GameId</Table.Col>
            <Table.Col className={tdClass}>
              <Input type="text" name={'gameId'} className="form-control" value={id} disabled />
            </Table.Col>
          </Table.Row>
          <Table.Row>
            <Table.Col className={tdClass}>GameCode</Table.Col>
            <Table.Col className={tdClass}>
              <Input type="text" name={'gameCode'} className="form-control" value={id} disabled />
            </Table.Col>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        <Card.Header>Integration data</Card.Header>
        <Card.Body>{this.renderMainContent()}</Card.Body>
      </Card>
    );
  }
}

IntegrationInfo.propTypes = {
  data: PropTypes.object,
  isPending: PropTypes.bool,
};

export default IntegrationInfo;
