import { SYSTEM_SETTINGS } from './types';

export const getSystemSettings = () => ({
  type: SYSTEM_SETTINGS.FETCH.START,
});

export const putSystemSettings = (name, value) => ({
  type: SYSTEM_SETTINGS.PUT.START,
  payload: {
    name,
    value,
  },
});
