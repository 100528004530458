import React from 'react';
import { Page } from 'tabler-react';
import propTypes from 'prop-types';

import AccessControl from '../../AccessControl';
import Management from '../../Reusable/Management/Management';

import { userRoles } from '../../../utils/roles';

class ManagementTab extends React.Component {
  render() {
    const { bet, postManagementAction } = this.props;

    return (
      <Page>
        <Page.Header>
          <Page.Title>Management</Page.Title>
        </Page.Header>
        <div className="row">
          <AccessControl roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand]}>
            <Management
              onButtonClick={(actionName) => postManagementAction(bet.id, actionName, 'Bet')}
              status={bet.status}
              cancelHint="Cancels not resolved bet, ticket cost is returned to user"
              voidHint="Voids resolved bet, winnings from bet are canceled from user"
              type="bet"
              name="Bet"
            />
          </AccessControl>
        </div>
      </Page>
    );
  }
}

ManagementTab.propTypes = {
  bet: propTypes.object,
  postManagementAction: propTypes.func,
};

export default ManagementTab;
