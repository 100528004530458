import React from 'react';
import PropTypes from 'prop-types';

import LabeledPicker from './common/LabeledPicker';
import FileUploader from './common/FileUploader';

import { Field } from 'formik';

import './SectionStyles.scss';

class SectionBackground extends React.Component {
  renderBackgroundSection = (title, type, index) => (
    <div className="border-bottom">
      <div className="flexRow pt-1">
        <label className="title">{title}</label>
        <Field name={`sections.${index}.${type}.fillColor`} component={LabeledPicker} label="Fill" />
        <Field name={`sections.${index}.${type}.borderColor`} component={LabeledPicker} label="Border" />
        <Field name={`sections.${index}.${type}.borderWidth`} className="form-control width-picker" type="number" />
      </div>
      {/* <Field name={`sections.${index}.${type}.image`} placeholder="Image Url" className="form-control urlInput"/> */}
      <Field name={`sections.${index}.${type}.image`} component={FileUploader} />
    </div>
  );

  renderLargeSection = (index) => (
    <React.Fragment>
      {this.renderBackgroundSection('Background: ', 'background', index)}
      <div className="border-bottom">
        <label className="title">Logo:</label>
        {/* <Field name={`sections.${index}.logo.image`} placeholder="Logo Url" className="form-control"/> */}
        <Field name={`sections.${index}.logo.image`} component={FileUploader} />
      </div>
    </React.Fragment>
  );

  renderMediumSection = (section, index) => (
    <React.Fragment>
      {this.renderBackgroundSection('Background: ', 'background', index)}
      {this.renderBackgroundSection('Logo Area: ', 'logo', index)}
    </React.Fragment>
  );

  renderSmallSection = (section, index) => {
    return (
      <div>
        {this.renderBackgroundSection('Background: ', 'background', index)}
        {this.renderBackgroundSection('Logo Area: ', 'logo', index)}
      </div>
    );
  };

  render() {
    const { section, index } = this.props;

    return (
      <div>
        <h3>{this.props.title}</h3>

        {section.type === 'BIG' && this.renderLargeSection(index)}
        {section.type === 'MEDIUM' && this.renderMediumSection(section, index)}
        {section.type === 'SMALL' && this.renderSmallSection(section, index)}
      </div>
    );
  }
}
SectionBackground.propTypes = {
  title: PropTypes.string,
  section: PropTypes.object,
  index: PropTypes.number,
};

export default SectionBackground;
