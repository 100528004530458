/**
 * Function that transforms Object with iterable values to array of objects
 * @param {object} data - object to transform
 */
export function objectToObjectArray(data) {
  if (typeof data !== 'object' || Array.isArray(data) || !data) {
    return null;
  }

  return Object.keys(data).map((elem) => {
    return {
      name: elem,
      data: data[elem],
    };
  });
}
