const getSortedVariants = (state) => {
  return state ? state.sort((sectionA, sectionB) => sectionA.position - sectionB.position) : null;
};

const getLobbyMetaData = (lobbyData) => {
  if (!lobbyData) return null;

  const lang = lobbyData.defaultLanguage;
  return {
    name: lobbyData.name,
    defaultLanguage: { value: lang, label: lang },
    brand: lobbyData.brand,
  };
};

export default {
  getSortedVariants,
  getLobbyMetaData,
};
