import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { Table, Grid, Card } from 'tabler-react';

import Spinner from '../Spinner/index';
import TableTopBar from './TableTopBar';
import TableElement from './TableElement';
import DataTable from './DataTable';

import './CustomTable.scss';

const mobileWidth = 415;

class CustomTable extends React.Component {
  static defaultProps = {
    allowedPagination: true,
    initialPagiPage: 1,
  };

  state = {
    paginationMarginPages: 1,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onPageResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onPageResize);
  }

  onPageResize = () => {
    if (window.innerWidth > mobileWidth) {
      this.setState({
        paginationMarginPages: 3,
      });
    }
  };

  onPageChanged = (data) => {
    this.props.onPageChanged(data.selected + 1);
  };

  renderTableRow() {
    return this.props.columns.map((col, i) => {
      return <Table.ColHeader key={i}>{col.header}</Table.ColHeader>;
    });
  }

  getTableHeaders() {
    return this.props.columns.map((col, index) => {
      return { name: col.header, key: index };
    });
  }

  getTableRows() {
    return this.props.data.map((item, index) => {
      return this.props.columns.map((col, i) => {
        return <TableElement index={index} key={i} item={item} col={col} toggleDeleteModal={this.toggleDeleteModal} />;
      });
    });
  }

  renderTableBody() {
    return this.props.data.map((item, index) => {
      return (
        <Table.Row key={index}>
          {this.props.columns.map((col, i) => {
            return (
              <Table.Col key={i}>
                <TableElement index={index} item={item} col={col} toggleDeleteModal={this.toggleDeleteModal} />
              </Table.Col>
            );
          })}
        </Table.Row>
      );
    });
  }

  renderEmptyData() {
    return <div className="alert alert-primary mt-4 mb-4">There is no data</div>;
  }

  renderSimpleTable() {
    const columns = this.getTableHeaders();
    const data = this.getTableRows();

    return <DataTable columns={columns} data={data} />;
  }

  renderTableWithPagination() {
    const { paginHeaders, onChangeEntries } = this.props;
    const { marginPagesDisplayed } = this.state;
    const columns = this.getTableHeaders();
    const data = this.getTableRows();

    return (
      <React.Fragment>
        <TableTopBar paginHeaders={paginHeaders} onChangeEntries={onChangeEntries} />
        <DataTable columns={columns} data={data} />
        {/* <Table
          cards
          striped
          hasOutline
          responsive
          highlightRowOnHover
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              {this.renderTableRow()}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderTableBody()}
          </Table.Body>
        </Table> */}
        <div className="d-flex mt-5 justify-content-center">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={paginHeaders['x-last-page'] ? parseInt(paginHeaders['x-last-page']) : 1}
            forcePage={paginHeaders['x-page'] ? parseInt(paginHeaders['x-page'] - 1) : 0}
            pageRangeDisplayed={marginPagesDisplayed}
            marginPagesDisplayed={window.innerWidth > 400 ? 2 : 1}
            disableInitialCallback={true}
            onPageChange={this.onPageChanged}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            breakClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
      </React.Fragment>
    );
  }

  renderContent() {
    const { data, loading, allowedPagination } = this.props;

    if (loading) {
      return <Spinner class={'mt-5'} />;
    }

    if (data && !data.length) {
      return this.renderEmptyData();
    }

    if (data && !!data.length && allowedPagination) {
      return this.renderTableWithPagination();
    }

    if (data && !!data.length) {
      return this.renderSimpleTable();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid.Row cards deck>
          <Grid.Col width={12}>
            <Card>{this.renderContent()}</Card>
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

CustomTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  paginHeaders: PropTypes.object,
  onPageChanged: PropTypes.func,
  deleteHandler: PropTypes.func,
  onPhraseSearched: PropTypes.func,
  onChangeEntries: PropTypes.func,
  handleSearchTermChange: PropTypes.func,
  loading: PropTypes.bool,
  allowedPagination: PropTypes.bool,
  initialPagiPage: PropTypes.number,
};

export default CustomTable;
