import { PLAYERSTATS } from '../actions/types';

const initialState = {
  data: null,
  isPending: false,
  isError: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PLAYERSTATS.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
      };
    case PLAYERSTATS.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
        data: null,
      };
    case PLAYERSTATS.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: action.payload.data,
      };
    case PLAYERSTATS.CLEAR:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: null,
      };
    default:
      return state;
  }
};
