import { MAINTENANCE_MODE } from '../utils/constans';

const getMaintenanceMode = (state) => {
  if (!state.systemSettings.data) {
    return {};
  }

  return state.systemSettings.data.find((elem) => elem.name === MAINTENANCE_MODE);
};

export default {
  getMaintenanceMode,
};
