import { CURRENCY } from './types';

export const changeCurrencyAmount = (id, value) => ({
  type: CURRENCY.EDIT.START,
  payload: {
    id,
    value,
  },
});

export const addCurrencyToChange = (currency) => ({
  type: CURRENCY.CHANGE.ADD,
  payload: currency,
});

export const removeCurrencyToChange = (currency) => ({
  type: CURRENCY.CHANGE.REMOVE,
  payload: currency,
});

export const changeBaseCurrency = (currency) => ({
  type: CURRENCY.CHANGE_BASE,
  payload: currency,
});

export const resetCurrencies = () => ({
  type: CURRENCY.RESET,
});
