class Draws {
  static joinFilters(preprocessedFilters) {
    return preprocessedFilters
      .filter((elem) => elem != '')
      .map((elem) => '&' + elem)
      .join('');
  }

  static getfilterDrawsUrl(filterData) {
    const gameId = filterData.lotteryType.id || '';
    const brandId = filterData.brand.id || '';
    const showFutureDraws = filterData.showFutureDraws;
    const showOnlyNotProcessedDraws = filterData.showOnlyNotProcessedDraws;

    const gameIdUrl = gameId ? `search[game][uuid]=${gameId}` : '';
    const brandIdUrl = brandId ? `search[brand][uuid]=${brandId}` : '';
    const futureDrawsUrl = showFutureDraws
      ? 'search[withoutFuture][withoutFuture]=true'
      : 'search[withoutFuture][withoutFuture]=false';
    const resolvedUrl = showOnlyNotProcessedDraws
      ? 'search[processed][ignoreProcessed]=true'
      : 'search[processed][ignoreProcessed]=false';

    return this.joinFilters([gameIdUrl, brandIdUrl, futureDrawsUrl, resolvedUrl]);
  }

  static getBetFilterUrl(filter) {
    const showOnlyUnprocessedBets = filter.showOnlyUnprocessedBets
      ? 'search[bets][ignoreProcessed]=true'
      : 'search[bets][ignoreProcessed]=false';
    return this.joinFilters([showOnlyUnprocessedBets]);
  }
}

export default Draws;
