import { RESULT_CONFIRM, RESULT_CONFIRM_DELETE } from '../../actions/types';

const initialState = {
  success: false,
  error: null,
  isError: false,
  isLoading: false,
  reset: {
    success: false,
    error: null,
    isError: false,
    isLoading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESULT_CONFIRM.POST:
      return {
        ...state,
        isLoading: true,
      };
    case RESULT_CONFIRM.ERROR:
      return {
        ...state,
        error: action.payload.response.data,
        isError: true,
        isLoading: false,
      };
    case RESULT_CONFIRM.SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        isError: false,
        error: null,
      };
    case RESULT_CONFIRM.RESET:
      return initialState;
    case RESULT_CONFIRM_DELETE.START:
      return {
        ...state,
        reset: {
          ...state.reset,
          isLoading: true,
        },
      };
    case RESULT_CONFIRM_DELETE.SUCCESS:
      return {
        ...state,
        reset: {
          isLoading: false,
          isError: false,
          error: null,
          success: true,
        },
      };
    case RESULT_CONFIRM_DELETE.ERROR:
      return {
        ...state,
        reset: {
          ...state.reset,
          isLoading: false,
          isError: true,
          error: action.payload.response.data,
        },
      };
    default:
      return state;
  }
};
