import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Page, Button } from 'tabler-react';
import { withRouter } from 'react-router-dom';

import DrawEdit from '../../Draws/DrawEdit';
import DrawNumbers from '../../Draws/DrawNumbers';
import DrawFinancials from '../../Draws/DrawFinancials';
import DrawStats from '../../Draws/DrawStats';
import DrawJackpot from '../../Draws/DrawJackpot';
import Bets from '../Bets';

import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';

class Draw extends React.Component {
  handleProviderBtnClick = () => {
    const { history, draw } = this.props;

    history.push(`/provider-draws/${draw.data.providerDraw.id}`);
  };

  handlePromotionBtnClick = () => {
    const { history, draw } = this.props;

    history.push(`/promotions/promotion/${draw.data.promotion.id}`);
  };

  render() {
    const { id, draw, drawStats, putNewJackpot, jackpotError, currencies } = this.props;

    return (
      <React.Fragment>
        <AccessControl
          roles={[
            userRoles.superAdmin,
            userRoles.admin,
            userRoles.brand,
            userRoles.resultsOperator,
            userRoles.reportsManager,
          ]}
          forbiddenRedirect={'/'}
        >
          <Page>
            <Page.Header>
              <div className="d-flex justify-content-between w-100">
                <Page.Title>Draw</Page.Title>
                <div>
                  <Button
                    className="draw-protocol-btn mx-5"
                    color="primary"
                    disabled={draw.data && !draw.data.promotion}
                    onClick={this.handlePromotionBtnClick}
                  >
                    Go to Promotion
                  </Button>
                  <Button
                    className="draw-protocol-btn mx-5"
                    color="primary"
                    disabled={draw.data && !draw.data.providerDraw}
                    onClick={this.handleProviderBtnClick}
                  >
                    Go to Provider Draw
                  </Button>
                </div>
              </div>
            </Page.Header>
            <Grid.Row>
              <Grid.Col md={4}>
                <DrawEdit draw={draw.data} error={draw.error} isPending={draw.isPending} drawId={id} title="Details" />
              </Grid.Col>
              {
                <Grid.Col md={4}>
                  {draw.data && draw.data.wonBetSelection && (
                    <DrawNumbers
                      selections={draw.data.wonBetSelection.asArray}
                      title="Results"
                      candidateSelectionData={draw.data.candidateSelectionData}
                    />
                  )}

                  {drawStats.data && draw.data && (
                    <DrawFinancials
                      drawStats={drawStats.data}
                      jackpotBalance={draw.data.jackpotBalance}
                      title="Financials"
                      status={draw.data.status}
                    />
                  )}

                  {draw.data && draw.data.jackpot && draw.data.status !== 'processed' && (
                    <AccessControl
                      roles={[
                        userRoles.superAdmin,
                        userRoles.admin,
                        userRoles.brand,
                        userRoles.jackpotOperator,
                        userRoles.reportsManager,
                      ]}
                    >
                      <DrawJackpot
                        drawId={id}
                        currenciesList={currencies}
                        jackpotAmount={draw.data.jackpotOverrideValue ? draw.data.jackpotOverrideValue.amount : null}
                        jackpotCurrency={draw.data.jackpotOverrideValue && draw.data.jackpotOverrideValue.currency}
                        jackpotError={jackpotError}
                        onJackpotOverride={putNewJackpot}
                        defaultJackpotAmount={draw.data.game.defaultJackpotValue.amount}
                      />
                    </AccessControl>
                  )}
                </Grid.Col>
              }
              {drawStats.data && draw.data && (
                <Grid.Col md={4}>
                  <DrawStats
                    drawStats={drawStats.data}
                    drawData={draw.data}
                    error={drawStats.error}
                    isPending={drawStats.isPending}
                    drawStatus={draw.data.status}
                    drawId={id}
                    title="Stats"
                  />
                </Grid.Col>
              )}
            </Grid.Row>
          </Page>
        </AccessControl>
        <Bets drawId={id} />
      </React.Fragment>
    );
  }
}

Draw.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  history: PropTypes.object,
  draw: PropTypes.object,
  drawStats: PropTypes.object,
  putNewJackpot: PropTypes.func,
  jackpotError: PropTypes.object,
};

export default withRouter(Draw);
