import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Card, Alert } from 'tabler-react';

import Spinner from '../../Reusable/Spinner/index';
import AuthService from '../../../services/AuthService';
import { confirmEmail } from '../../../actions/authActions';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';

class EmailConfirmation extends React.Component {
  componentDidMount() {
    const { match } = this.props;

    AuthService.logout();

    this.props.confirmEmail(match.params.token);
  }

  renderSuccessMessage() {
    return (
      <Grid.Row>
        <Grid.Col>
          <Card>
            <Card.Status color="green" />
            <Card.Body>
              <Alert type="success" icon="check">
                Success! Your account has been activated.
              </Alert>
              <Link to={'/login'} className="btn btn-primary mt-3">
                Go to login page
              </Link>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }

  renderErrorMessage() {
    const { confirmError } = this.props;

    return (
      <Grid.Row>
        <Grid.Col>
          <Card>
            <Card.Status color="red" />
            <Card.Body>
              <ErrorHandler error={confirmError} />
              <Link to={'/login'} className="btn btn-primary mt-3">
                Go to login page
              </Link>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }

  render() {
    const { confirmPending, confirmSuccess, confirmError } = this.props;
    return (
      <div className="container email-confirmation text-center pt-5">
        {confirmPending && <Spinner />}
        {!confirmPending && confirmSuccess && this.renderSuccessMessage()}
        {!confirmPending && !confirmSuccess && confirmError && this.renderErrorMessage()}
      </div>
    );
  }
}

EmailConfirmation.propTypes = {
  match: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func,
  confirmError: PropTypes.object,
  confirmPending: PropTypes.bool,
  confirmSuccess: PropTypes.bool,
};

export default connect(
  (state) => ({
    confirmPending: state.account.activate.isPending,
    confirmSuccess: state.account.activate.success,
    confirmError: state.account.activate.error,
  }),
  {
    confirmEmail,
  }
)(EmailConfirmation);
