import React from 'react';
import PropTypes from 'prop-types';

class ClickOutside extends React.Component {
  componentDidMount() {
    document.addEventListener('click', this.onClickOutSide);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutSide);
  }

  onClickOutSide = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    if (this.isContainException(e.target)) {
      return;
    }

    this.props.action();
  };

  isContainException(target) {
    const { except } = this.props;

    const exceptions = except.filter((node) => node && node.contains(target));

    return exceptions.length > 0;
  }

  render() {
    const { htmlTag } = this.props;
    const Tag = htmlTag;

    return <Tag ref={(node) => (this.node = node)}>{this.props.children}</Tag>;
  }
}

ClickOutside.propTypes = {
  children: PropTypes.element.isRequired,
  action: PropTypes.func.isRequired,
  htmlTag: PropTypes.string,
  except: PropTypes.arrayOf(PropTypes.element),
};

ClickOutside.defaultProps = {
  htmlTag: 'div',
  except: [],
};

export default ClickOutside;
