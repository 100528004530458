import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Table } from 'tabler-react';
import Select from 'react-select';

import Spinner from '../Reusable/Spinner/Spinner';
import AddWinningPriceSingle from './AddWinningPriceSingle';
import { setPrices, changePriceType, changePriceCurrency, handlePriceChange } from '../../actions/prices';
import { changePrizePoolPercentage } from '../../actions/lotteryActions';

import { Field } from '../Reusable/FormFields';

import './Lottery.scss';

class EditWinningPrice extends React.Component {
  handleSelectChange = (name, text, id = '') => {
    if (name === 'prizeType') {
      return this.props.changePriceType(text, id);
    }

    return this.props.changePriceCurrency(text);
  };

  handleChange = (id, name, text) => {
    this.props.handlePriceChange(id, name, text);
  };

  preparePrices() {
    const { prizeTypes, buildSelectOptions, priceCurrency, changePrizePoolPercentage, salesSumPrizePoolPercentage } =
      this.props;
    const currenciesList = this.props.data.currenciesList;

    return (
      <React.Fragment>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="">Currency</label>
            <Select
              options={buildSelectOptions(currenciesList)}
              className="react-select "
              onChange={(value) => this.handleSelectChange('currency', value)}
              placeholder={'Type to search...'}
              value={priceCurrency}
              isDisabled
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            ></Select>
          </div>
        </div>
        <div className="content-overflow-visible">
          <Table hasOutline highlightRowOnHover className="table-vcenter">
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Prize type</Table.ColHeader>
                <Table.ColHeader>Name</Table.ColHeader>
                <Table.ColHeader>Amount</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.prices.map((price, i) => {
                return (
                  <AddWinningPriceSingle
                    key={i}
                    index={i}
                    price={price}
                    id={price.id ? price.id : null}
                    onChange={this.handleChange}
                    onChangePrizeType={this.handleSelectChange}
                    withPrizeType
                    prizeTypes={buildSelectOptions(prizeTypes)}
                  />
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <Field
              value={salesSumPrizePoolPercentage}
              name="salesSumPrizePoolPercentage"
              label="Sales Sum Prize Pool"
              type="number"
              onChange={(name, value) => changePrizePoolPercentage(value)}
              min={0}
              max={100}
              step={1}
            />
            <label className="hint">
              Sets the % value of draw sales that will be used as base for `sales_percentage` based prizes.
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { isPending, data, showMessage, message } = this.props;

    return (
      <React.Fragment>
        <Card>
          <Card.Status color="blue" />
          <Card.Header>Edit prizes</Card.Header>
          <Card.Body>
            {isPending && <Spinner />}
            {showMessage && message && <p>{message}</p>}
            {!isPending && !showMessage && data && this.preparePrices()}
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

EditWinningPrice.propTypes = {
  isPending: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  setPrices: PropTypes.func,
  changePriceType: PropTypes.func,
  changePriceCurrency: PropTypes.func,
  handlePriceChange: PropTypes.func,
  priceType: PropTypes.object,
  priceCurrency: PropTypes.object,
  prices: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  buildSelectOptions: PropTypes.func,
  prizeTypes: PropTypes.array,
  salesSumPrizePoolPercentage: PropTypes.number,
  changePrizePoolPercentage: PropTypes.func,
};

export default connect(
  (state) => ({
    prices: state.prices.prices,
    priceType: state.prices.priceType,
    priceCurrency: state.lottery.currency,
    salesSumPrizePoolPercentage: state.lottery.salesSumPrizePoolPercentage,
  }),
  {
    setPrices,
    changePriceType,
    changePriceCurrency,
    handlePriceChange,
    changePrizePoolPercentage,
  }
)(EditWinningPrice);
