import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page, Grid, Card } from 'tabler-react';

import { LotteryForm, AddWinningPrice, AddTicketCost, LotteryAgencySelection } from '../../Lottery/index';
import ProgressiveJackpot from '../../Lottery/ProgressiveJackpot';

import {
  changeLotteryType,
  resetLotteryStruct,
  getLotteryStructure,
  changeLotteryStructure,
  resetLotteryStructure,
  getBettingAgencies,
  getBettingAgency,
  changeLotteryMainCurrency,
  resetCurrency,
} from '../../../actions/lotteryActions';
import {
  changeCurrencyAmount,
  resetCurrencies,
  addCurrencyToChange,
  removeCurrencyToChange,
} from '../../../actions/currenciesActions';
import { getAllowedCurrencies } from '../../../actions/systemConsts';
import { clearPrices } from '../../../actions/prices';
import httpClient from '../../../services/httpClient';

class AddLottery extends React.Component {
  constructor() {
    super();

    this.state = {
      addLotterySuccess: false,
      addLotteryError: null,
      bettingAgency: '',
      basePrice: {
        amount: '',
        currency: '',
      },
    };
  }

  componentDidMount() {
    this.props.clearPrices();
    this.props.resetCurrencies();
    this.props.getBettingAgencies();
    this.props.getAllowedCurrencies();
  }

  handleBettingAgencyChange = (option) => {
    this.props.getBettingAgency(option.label);

    this.setState({ bettingAgency: option.label });
  };

  handleChangeBasePrice = (amount, currency) => {
    this.setState({
      basePrice: {
        amount,
        currency,
      },
    });
  };

  handleSubmitLottery = (values, actions) => {
    const { prices, lotteryConfig, currency, progressiveJackpot } = this.props;
    const { basePrice } = this.state;

    const stakes = values.stakes
      ? values.stakes
          .trim()
          .split(',')
          .map((elem) => parseInt(elem))
      : [];
    const correctCurrency = currency ? currency.value : '';

    actions.setSubmitting(true);

    const newLottery = {
      displayedName: values.displayedName,
      bettingAgencyConfig: { id: lotteryConfig.bettingAgencyConfig.id },
      gameResultAgencyConfig: { id: lotteryConfig.gameResultAgencyConfig.id },
      betLineBasePrice: { ...basePrice, amount: basePrice.amount * 100 },
      name: values.name,
      maxLinesPerBet: values.maxLinesPerBet,
      ticketsTtlDays: values.ticketsTtlDays,
      active: values.active,
      useProviderDrawLogic: values.useProviderDrawLogic,
      availableFrom: values.availableFrom.format(),
      availableTo: values.availableTo ? values.availableTo.format() : null,
      bettingAgencyType: values.bettingAgency.value,
      stakes,
      brand: {
        id: values.brand.id,
      },
      lottery: {
        id: values.lottery.id,
      },
      description: values.description,
      image: values.image,
      possiblePrizes: this.preparePriceObject(prices),
      permutationsEnabled: values.permutationsEnabled,
      prizeResolverClass: values.prizeResolverClass.value,
      currency: correctCurrency,
      shouldRoundPrizes: values.shouldRoundPrizes,
      allowInstantBets: values.allowInstantBets,
      progressiveJackpotFundEnabled: progressiveJackpot.isEnabled,
      progressiveJackpotFundSalePercent: progressiveJackpot.salePercentage,
      progressiveJackpotFundTTL: progressiveJackpot.TTL,
      progressiveJackpotIncludeCurrentDraw: progressiveJackpot.includeCurrentDraw,
      progressiveJackpotInitialAmount: progressiveJackpot.initialAmount
        ? {
            amount: progressiveJackpot.initialAmount,
            currency: values.currency.value,
          }
        : null,
    };

    return httpClient
      .post('/api/v1/games', newLottery)
      .then(() => {
        actions.resetForm();
        this.setState({
          addLotterySuccess: true,
          addLotteryError: null,
        });
      })
      .then(() => {
        this.props.resetCurrencies();
        this.props.clearPrices();
        this.props.resetLotteryStructure();
        this.props.resetCurrency();
      })
      .catch((err) => {
        actions.setSubmitting(false);
        this.setState({
          addLotterySuccess: false,
          addLotteryError: err.response,
        });
      });
  };

  buildSelectOptions = (optionsList) => {
    return optionsList.map((option) => ({ value: option, label: option }));
  };

  preparePriceObject(price) {
    const data = [];

    price.map((p) => {
      if (p.name && p.prizeType && p.amount.amount && p.amount.currency) {
        data.push({
          name: p.name,
          prizeType: p.prizeType,
          amount: {
            amount: p.amount.amount * 100,
            currency: p.amount.currency,
          },
        });
      }
    });

    return data;
  }

  render() {
    const {
      lottery,
      getLotteryStructure,
      lotteryStructure,
      lotteryConfig,
      changeLotteryStructure,
      changeLotteryMainCurrency,
      currency,
    } = this.props;
    const { bettingAgency } = this.state;
    const hasAutoFatchedResults = lottery && lottery.data && lottery.data.hasAutoFatchedResults;

    return (
      <Page>
        <Page.Header>
          <Page.Title>Add new lottery</Page.Title>
        </Page.Header>
        <Grid.Row>
          <Card>
            <Card.Header>Add new lottery</Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-md-6">
                  <LotteryForm
                    sendLotterySuccess={this.state.addLotterySuccess}
                    sendLotteryError={this.state.addLotteryError}
                    handleSubmit={this.handleSubmitLottery}
                    prices={this.props.prices}
                    currency={currency}
                    changeLotteryType={this.props.changeLotteryType}
                    resetLotteryStruct={this.props.resetLotteryStruct}
                    changeMainCurrency={changeLotteryMainCurrency}
                    onChangeBettingAgency={this.handleBettingAgencyChange}
                    clearPrices={this.props.clearPrices}
                    getLotteryStructure={getLotteryStructure}
                    buildSelectOptions={this.buildSelectOptions}
                    bettingAgencies={this.props.bettingAgencies}
                  />
                </div>
                <div className="col-md-6">
                  <ProgressiveJackpot editMode={false} />
                  <AddWinningPrice
                    showMessage={hasAutoFatchedResults}
                    message="This lottery supplier provides prizes data automatically"
                  />
                  <AddTicketCost
                    currency={currency ? currency.value : ''}
                    onBasePriceChange={this.handleChangeBasePrice}
                  />
                  {lotteryStructure && bettingAgency && (
                    <LotteryAgencySelection
                      gameConfigName={lotteryStructure.structure.gameResultAgencyName}
                      bettingAgencyName={this.state.bettingAgency}
                      selectedBettingAgency={lotteryConfig.bettingAgencyConfig}
                      selectedGameConfig={lotteryConfig.gameResultAgencyConfig}
                      onChangeGameConfig={(selected) =>
                        changeLotteryStructure('gameResultAgencyConfig.id', selected.value)
                      }
                      onChangeBettingAgency={(selected) =>
                        changeLotteryStructure('bettingAgencyConfig.id', selected.value)
                      }
                    />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Grid.Row>
      </Page>
    );
  }
}

AddLottery.propTypes = {
  prices: PropTypes.array,
  clearPrices: PropTypes.func,
  changeLotteryType: PropTypes.func,
  changeCurrencyAmount: PropTypes.func,
  resetCurrencies: PropTypes.func,
  currencies: PropTypes.array,
  currenciesToChange: PropTypes.array,
  addCurrencyToChange: PropTypes.func,
  removeCurrencyToChange: PropTypes.func,
  resetLotteryStruct: PropTypes.func,
  lottery: PropTypes.object,
  getLotteryStructure: PropTypes.func,
  lotteryStructure: PropTypes.object,
  lotteryConfig: PropTypes.object,
  changeLotteryStructure: PropTypes.func,
  resetLotteryStructure: PropTypes.func,
  getBettingAgencies: PropTypes.func,
  bettingAgencies: PropTypes.array,
  getBettingAgency: PropTypes.func,
  getAllowedCurrencies: PropTypes.func,
  bettingAgency: PropTypes.object,
  allowedCurrencies: PropTypes.array,
  changeLotteryMainCurrency: PropTypes.func,
  currency: PropTypes.object,
  resetCurrency: PropTypes.func,
  progressiveJackpot: PropTypes.object,
};

export default connect(
  (state) => ({
    typePending: state.lottery.lotteryStruct.isPending,
    typeData: state.lottery.lotteryStruct.data,
    typeError: state.lottery.lotteryStruct.error,
    prices: state.prices.prices,
    currencies: state.currencies.allCurrencies,
    currenciesToChange: state.currencies.currenciesToChange,
    lottery: state.lottery.lotteryStruct,
    lotteryStructure: state.lottery.lotteryStructure.data,
    lotteryConfig: state.lottery.lotteryStructure.newData,
    bettingAgencies: state.bettingAgency.agencies.data,
    bettingAgency: state.bettingAgency.singleAgency.data,
    allowedCurrencies: state.allowedCurrencies,
    currency: state.lottery.currency,
    progressiveJackpot: state.progressiveJackpot,
  }),
  {
    clearPrices,
    changeLotteryType,
    changeCurrencyAmount,
    resetCurrencies,
    addCurrencyToChange,
    removeCurrencyToChange,
    resetLotteryStruct,
    getLotteryStructure,
    changeLotteryStructure,
    resetLotteryStructure,
    getBettingAgencies,
    getBettingAgency,
    getAllowedCurrencies,
    changeLotteryMainCurrency,
    resetCurrency,
  }
)(AddLottery);
