import React from 'react';

import SearchModal from './SearchModal';

class AllModals extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SearchModal />
      </React.Fragment>
    );
  }
}

export default AllModals;
