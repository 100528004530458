import { createLogic } from 'redux-logic';

import { PROVIDERS, SINGLE_PROVIDER, NOTIFICATION, LOTTERY_PROVIDERS } from '../actions/types';

import { getProperApiUrl } from '../utils/providers/providers';

const getProperDataToSend = (data) => {
  const newData = { ...data };
  delete newData.drawTimes;

  if (newData.timezone && typeof newData.timezone === 'object') {
    newData.timezone = newData.timezone.value;
  }

  if (newData.editecClient && typeof newData.editecClient === 'object') {
    newData.editecClient = newData.editecClient.value;
  }

  if (newData.brand && typeof newData.brand === 'object') {
    newData.brand = newData.brand.id;
  }

  if (newData.winningSelectionClass && typeof newData.winningSelectionClass === 'object') {
    newData.winningSelectionClass = newData.winningSelectionClass.value;
  }

  return newData;
};

export const getFewProviderConfig = createLogic({
  type: LOTTERY_PROVIDERS.GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return Promise.all(action.payload.map((elem) => httpClient.get(getProperApiUrl(elem.toLowerCase()))))
      .then((values) => {
        dispatch({
          type: LOTTERY_PROVIDERS.GET.SUCCESS,
          payload: {
            data: values.map((elem) => elem.data),
            names: action.payload,
          },
        });
      })
      .then(done);

    // return httpClient.get(getProperApiUrl(name), {params})
    //   .then(res => {
    //     dispatch({
    //       type: PROVIDERS.GET.SUCCESS,
    //       payload: {
    //         list: res.data,
    //         headers: res.headers,
    //         name,
    //       },
    //     });
    //   })
    //   .catch(err => {
    //     dispatch({
    //       type: PROVIDERS.GET.ERROR,
    //       payload: err,
    //     });
    //   })
    // .then(done);
  },
});

export const getProviderConfig = createLogic({
  type: PROVIDERS.GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { name, page, perPage } = action.payload;
    const params = { page, perPage };

    if (!name) {
      return;
    }

    return httpClient
      .get(getProperApiUrl(name), { params })
      .then((res) => {
        dispatch({
          type: PROVIDERS.GET.SUCCESS,
          payload: {
            list: res.data,
            headers: res.headers,
            name,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: PROVIDERS.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const getSingleProviderConfig = createLogic({
  type: SINGLE_PROVIDER.GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { name, id } = action.payload;

    return httpClient
      .get(getProperApiUrl(name, id))
      .then((res) => {
        dispatch({
          type: SINGLE_PROVIDER.GET.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SINGLE_PROVIDER.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const postProviderConfig = createLogic({
  type: SINGLE_PROVIDER.POST.START,

  process({ httpClient, action }, dispatch, done) {
    const { name, data } = action.payload;
    const newData = getProperDataToSend(data);

    return httpClient
      .post(getProperApiUrl(name), {
        ...newData,
      })
      .then((res) => {
        dispatch({
          type: SINGLE_PROVIDER.POST.SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Provider configuration successfully added',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SINGLE_PROVIDER.POST.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: err.response.data.message,
          },
        });
      })
      .then(done);
  },
});

export const putProviderConfig = createLogic({
  type: SINGLE_PROVIDER.PUT.START,

  process({ httpClient, action }, dispatch, done) {
    const { name, id, data } = action.payload;
    const newData = getProperDataToSend(data);

    return httpClient
      .put(getProperApiUrl(name, id), {
        ...newData,
      })
      .then((res) => {
        dispatch({
          type: SINGLE_PROVIDER.PUT.SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Provider configuration successfully updated',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SINGLE_PROVIDER.PUT.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: err.response.data.message,
          },
        });
      })
      .then(done);
  },
});

export const deleteSingleProviderConfig = createLogic({
  type: SINGLE_PROVIDER.DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    const { name, id } = action.payload;

    return httpClient
      .delete(getProperApiUrl(name, id))
      .then((res) => {
        dispatch({
          type: SINGLE_PROVIDER.DELETE.SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Provider configuration successfully deleted',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SINGLE_PROVIDER.DELETE.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: err.response.data.message,
          },
        });
      })
      .then(done);
  },
});

export const postProviderSchedule = createLogic({
  type: SINGLE_PROVIDER.SCHEDULE.POST.START,

  process({ httpClient, action }, dispatch, done) {
    const { name, id, schedule } = action.payload;

    return httpClient
      .post(getProperApiUrl(name, id) + '/draw-times', {
        drawTimeOverrideElements: [...schedule],
      })
      .then((res) => {
        dispatch({
          type: SINGLE_PROVIDER.SCHEDULE.POST.SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Provider schedule successfully updated',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SINGLE_PROVIDER.SCHEDULE.POST.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: err.response.data.message,
          },
        });
      })
      .then(done);
  },
});

export default {
  getProviderConfig,
  getSingleProviderConfig,
  postProviderConfig,
  putProviderConfig,
  deleteSingleProviderConfig,
  getFewProviderConfig,
  postProviderSchedule,
};
