import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Grid, Card, Alert } from 'tabler-react';
import classNames from 'classnames';

import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import { setNewPassword } from '../../../actions/authActions';

import logo from '../../../assets/images/logo-21.png';

class ChangePassword extends React.Component {
  renderContent() {
    const { changeError, changePending } = this.props;
    return (
      <Formik
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validate={(values) => this.validatePassword(values)}
        onSubmit={(values) => this.handleSubmitPasswordChange(values)}
        render={({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Grid.Row>
            <Grid.Col>
              <Card>
                <Card.Status color="blue" />
                <Card.Header>
                  <Card.Title className="text-center mx-auto">
                    <img src={logo} alt="" />
                  </Card.Title>
                </Card.Header>
                <form className="login-form" onSubmit={handleSubmit}>
                  {changeError && <ErrorHandler error={changeError} />}
                  <Alert type="primary">Please set new password</Alert>
                  <div className="form-group">
                    <label htmlFor="password">New password</label>
                    <input
                      id="password"
                      className={classNames('form-control', {
                        'is-invalid': touched.password && errors.password,
                      })}
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {touched.password && errors.password && <div className="invalid-feedback">{errors.password}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="repeat-password">New password repeat</label>
                    <input
                      id="repeat-password"
                      className={classNames('form-control', {
                        'is-invalid': touched.repeatPassword && errors.repeatPassword,
                      })}
                      type="password"
                      name="repeatPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.repeatPassword}
                    />
                    {touched.repeatPassword && errors.repeatPassword && (
                      <div className="invalid-feedback">{errors.repeatPassword}</div>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button loading={changePending} type="submit" color="primary">
                      Submit
                    </Button>
                  </div>
                </form>
              </Card>
            </Grid.Col>
          </Grid.Row>
        )}
      />
    );
  }

  renderSuccessContent() {
    return (
      <Grid.Row>
        <Grid.Col>
          <Card>
            <Card.Status color="blue" />
            <Card.Body>
              <div className="alert alert-success">
                <h2>Your password has been changed, you can log in now</h2>
                <Link to="/login">Go to log in page</Link>
              </div>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    );
  }

  validatePassword(values) {
    const errors = {};

    if (!values.password) {
      errors.password = 'Password cannot be empty';
    } else if (values.password.length > 0 && values.password.length < 8) {
      errors.password = 'Password must have at least 8 digits';
    }

    if (!values.repeatPassword) {
      errors.repeatPassword = 'Please repeat the password';
    } else if (values.repeatPassword !== values.password) {
      errors.repeatPassword = 'Passwords must be the same';
    }

    return errors;
  }

  handleSubmitPasswordChange(values) {
    const { match } = this.props;
    const { password } = values;

    const data = {
      password,
      passwordToken: match.params.token,
    };

    this.props.setNewPassword(data);
  }

  render() {
    const { changeSuccess } = this.props;
    return (
      <div className="change-password">
        {!changeSuccess && this.renderContent()}
        {changeSuccess && this.renderSuccessContent()}
      </div>
    );
  }
}

ChangePassword.propTypes = {
  match: PropTypes.object.isRequired,
  token: PropTypes.string,
  history: PropTypes.object,
  changeError: PropTypes.object,
  changePending: PropTypes.bool,
  setNewPassword: PropTypes.func,
  changeSuccess: PropTypes.bool,
};

export default connect(
  (state) => ({
    changePending: state.account.setNew.isPending,
    changeSuccess: state.account.setNew.success,
    changeError: state.account.setNew.error,
  }),
  {
    setNewPassword,
  }
)(ChangePassword);
