import { TICKETS } from './types';

export const getTickets = (page = 1, type) => ({
  type: TICKETS.FETCH,
  payload: {
    page,
    type,
  },
});

export const changeEntries = (entries) => ({
  type: TICKETS.CHANGE_ENTRIES,
  payload: entries,
});

export const changePage = (page) => ({
  type: TICKETS.CHANGE_PAGE,
  payload: page,
});

export const changeTicketsType = (type) => ({
  type: TICKETS.CHANGE_TICKETS_TYPE,
  payload: type,
});

export const changeDateFrom = (dateFrom) => ({
  type: TICKETS.CHANGE_DATE_FROM,
  payload: dateFrom,
});

export const changeDateTo = (dateTo) => ({
  type: TICKETS.CHANGE_DATE_TO,
  payload: dateTo,
});

export const changeDateCreatedAtFrom = (dateFrom) => ({
  type: TICKETS.CHANGE_CREATED_AT_FROM,
  payload: dateFrom,
});

export const changeDateCreatedAtTo = (dateTo) => ({
  type: TICKETS.CHANGE_CREATED_AT_TO,
  payload: dateTo,
});

export const changeTicketId = (ticketId) => ({
  type: TICKETS.CHANGE_TICKET_ID,
  payload: ticketId,
});

export const changePlayerId = (playerId) => ({
  type: TICKETS.CHANGE_PLAYER,
  payload: playerId,
});

export const changeBrand = (brand) => ({
  type: TICKETS.CHANGE_BRAND,
  payload: brand,
});

export const changeDraw = (draw) => ({
  type: TICKETS.CHANGE_DRAW,
  payload: draw,
});

export const changeLottery = (lottery) => ({
  type: TICKETS.CHANGE_LOTTERY,
  payload: lottery,
});

export const changeStatus = (status) => ({
  type: TICKETS.CHANGE_STATUS,
  payload: status,
});

export const resetFilters = () => ({
  type: TICKETS.RESET_FILTERS,
});

export const resetTickets = () => ({
  type: TICKETS.RESET,
});

export const getTicketsCSV = (type) => ({
  type: TICKETS.CSV.FETCH,
  payload: {
    type,
  },
});
