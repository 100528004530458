import React from 'react';
import { Page } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import SystemSettingsForm from '../../System/SystemSettingsForm';
import Spinner from '../../Reusable/Spinner/Spinner';

import { getSystemSettings, putSystemSettings } from '../../../actions/systemSettings';

import systemSettingsSelectors from '../../../selectors/systemSettings';

import { MAINTENANCE_MODE } from '../../../utils/constans';

// In the future if there will be more system settings, we'll need to create new component like SystemSettingsForm, but for now
// for one setting it is unnecessary
class SystemPage extends React.Component {
  componentDidMount() {
    const { getSystemSettings } = this.props;

    getSystemSettings();
  }

  handleSubmitForm = (value) => {
    const { putSystemSettings } = this.props;

    putSystemSettings(MAINTENANCE_MODE, value);
  };

  render() {
    const { maintenanceMode, isLoading, isError } = this.props;
    const maintenanceValue = maintenanceMode.value === 'true';

    if (!maintenanceMode.value && isLoading) {
      return <Spinner />;
    }

    if (isError) {
      return <div className="alert alert-danger">Something went wrong. Please try again later.</div>;
    }

    if (!maintenanceMode.value) {
      return null;
    }

    return (
      <Page>
        <Page.Header>System configuration</Page.Header>
        <SystemSettingsForm maintenanceMode={maintenanceValue} onSubmit={this.handleSubmitForm} />
      </Page>
    );
  }
}

SystemPage.propTypes = {
  getSystemSettings: propTypes.func.isRequired,
  putSystemSettings: propTypes.func.isRequired,

  maintenanceMode: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]).isRequired,
  isLoading: propTypes.bool.isRequired,
  isError: propTypes.object,
};

export default connect(
  (state) => ({
    maintenanceMode: systemSettingsSelectors.getMaintenanceMode(state),
    isLoading: state.systemSettings.isLoading,
    isError: state.systemSettings.error,
  }),
  {
    getSystemSettings,
    putSystemSettings,
  }
)(SystemPage);
