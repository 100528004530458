import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'tabler-react';
import Modal from 'react-modal';

import { ErrorHandler } from '../ErrorHandler';

class CustomModal extends React.Component {
  render() {
    const {
      isOpen,
      toggleModal,
      reqSuccess,
      reqError,
      title,
      successMessage,
      statusColor,
      handleSubmit,
      btnCancelText,
      btnCancelColor,
      btnConfirmText,
      icon,
      btnConfirmColor,
    } = this.props;
    const classname = this.props.classname ? `react-modal-custom ${this.props.classname}` : 'react-modal-custom';

    return (
      <Modal isOpen={isOpen} onRequestClose={toggleModal} className={classname}>
        <Card>
          <Card.Status color={statusColor} />
          <Card.Header>
            <Card.Title>
              {title}
              <Button className="react-modal-close" color="danger" onClick={toggleModal}>
                X
              </Button>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="">
              {reqSuccess && <div className="alert-success py-3 px-2 mb-3">{successMessage}</div>}
              {reqError && (
                <div className="alert-danger py-3 px-2 mb-3">
                  <ErrorHandler error={reqError} />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Button color={btnCancelColor} className="mr-2" onClick={toggleModal}>
                {btnCancelText}
              </Button>
              <Button color={btnConfirmColor} icon={icon} onClick={handleSubmit}>
                {btnConfirmText}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Modal>
    );
  }
}

CustomModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  reqSuccess: PropTypes.bool,
  reqError: PropTypes.object,
  successMessage: PropTypes.string,
  title: PropTypes.string,
  classname: PropTypes.string,
  statusColor: PropTypes.string,
  btnCancelText: PropTypes.string,
  btnCancelColor: PropTypes.string,
  btnConfirmText: PropTypes.string,
  btnConfirmColor: PropTypes.string,
  icon: PropTypes.string,
};

CustomModal.defaultProps = {
  btnConfirmColor: 'danger',
  btnCancelColor: 'primary',
  btnCancelText: 'Cancel',
  reqSuccess: false,
  reqError: null,
};

export default CustomModal;
