import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'tabler-react';
import { connect } from 'react-redux';
import moment from 'moment';

import Selection from '../Reusable/Selection/Selection';

class DrawNumbers extends React.Component {
  getAreResultsProvided() {
    const { candidateSelectionData, user } = this.props;

    if (!candidateSelectionData) {
      return false;
    }

    if (
      (candidateSelectionData.candidateProviderUser && candidateSelectionData.candidateProviderUser.id === user.id) ||
      (candidateSelectionData.confirmationProviderUser && candidateSelectionData.confirmationProviderUser === user.id)
    ) {
      return true;
    }

    return false;
  }

  renderResetBtn() {
    const { onResetBtnClick } = this.props;
    const areResultsProvided = this.getAreResultsProvided();

    if (!areResultsProvided) {
      return null;
    }

    return (
      <div className="d-flex justify-content-end my-2">
        <Button color="danger" onClick={onResetBtnClick}>
          Reset Results
        </Button>
      </div>
    );
  }

  renderBtn() {
    const { drawType, onUpdateBtnClick } = this.props;

    if (drawType !== 'provider') {
      return null;
    }

    return (
      <div className="d-flex justify-content-end">
        <Button color="warning" onClick={onUpdateBtnClick}>
          Update results
        </Button>
      </div>
    );
  }

  renderNumbersEdit() {
    const { selections, title } = this.props;

    return (
      <React.Fragment>
        {title && <h3>{title}</h3>}
        <Selection selections={selections} />
      </React.Fragment>
    );
  }

  renderSingleUser(user, confirmedDate, isCandidate = false) {
    if (!user) {
      return null;
    }

    return (
      <div className="my-2">
        <p className="m-0 font-weight-bold">
          {isCandidate ? 'Results candidate provided by:' : 'Result confirmation provided by:'}
        </p>
        <div>
          <p className="m-0 ml-2">
            {user.firstName} {user.lastName} ({user.email})
          </p>
          {confirmedDate ? <p className="m-0 ml-2">at {moment(confirmedDate).format('DD-MM-YYYY HH:mm')}</p> : null}
        </div>
      </div>
    );
  }

  renderCandidateUsers() {
    const { candidateSelectionData } = this.props;

    if (!candidateSelectionData) {
      return null;
    }

    return (
      <div>
        {this.renderSingleUser(
          candidateSelectionData.candidateProviderUser,
          candidateSelectionData.candidateResultsProvidedAt,
          true
        )}
        {this.renderSingleUser(
          candidateSelectionData.confirmationProviderUser,
          candidateSelectionData.resultsConfirmedAt
        )}
      </div>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        <Card.Body>
          {this.renderNumbersEdit()}
          {this.renderCandidateUsers()}
          {this.renderBtn()}
          {this.renderResetBtn()}
        </Card.Body>
      </Card>
    );
  }
}

DrawNumbers.propTypes = {
  selections: PropTypes.shape({
    main: PropTypes.arrayOf(PropTypes.number).isRequired,
    bonus: PropTypes.number,
  }).isRequired,
  candidateSelectionData: PropTypes.object,
  title: PropTypes.string,
  drawType: PropTypes.string,
  onUpdateBtnClick: PropTypes.func,
  onResetBtnClick: PropTypes.func,
  user: PropTypes.object,
};

export default connect(
  (state) => ({
    user: state.user.data,
  }),
  null
)(DrawNumbers);
