import React from 'react';

import Reports from './Reports';

import { DRAWS_REPORTS_COLUMNS_NAMES } from '../../../utils/reports/consts';

class DrawsReport extends React.Component {
  render() {
    return <Reports reportsType="draws" tableColumns={DRAWS_REPORTS_COLUMNS_NAMES} />;
  }
}

export default DrawsReport;
