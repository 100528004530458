import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';

import SectionBackground from './SectionBackground';
import SectionButton from './SectionButton';
import SectionText from './SectionText';
import SectionPreview from './SectionPreview';
import SectionVisibility from './SectionVisibility';
import SectionCheckbox from './SectionCheckbox';

class Section extends React.Component {
  render() {
    const { section, index, setFieldValue, onSectionDelete, sectionsLength, onSwapUp, onSwapDown } = this.props;

    return (
      <section className="section">
        <Grid.Col md={4} className="flexColumn justified">
          <SectionPreview section={section} index={index} />
        </Grid.Col>
        <Grid.Col md={8}>
          <SectionBackground section={section} index={index} />
          <SectionText section={section} index={index} />
          <SectionButton section={section} index={index} />
          <SectionVisibility section={section} index={index} />
          <SectionCheckbox
            label={'Make whole section a link'}
            onCheckboxChange={setFieldValue}
            value={section.linkOnWholeSection}
            name={`sections.${index}.linkOnWholeSection`}
          />
          <div className="d-flex justify-content-end">
            <div className="btn btn-danger mr-5" onClick={onSectionDelete}>
              DELETE SECTION
            </div>
            <div className="d-flex flex-column justify-content-center align-content-center">
              {index > 0 && <i onClick={onSwapUp} className="chevron fa fa-chevron-up" title="move section up" />}
              {index < sectionsLength - 1 && (
                <i onClick={onSwapDown} className="chevron fa fa-chevron-down" title="move section down" />
              )}
            </div>
          </div>
        </Grid.Col>
      </section>
    );
  }
}

Section.propTypes = {
  section: PropTypes.object,
  index: PropTypes.number,
  sectionsLength: PropTypes.number,
  onSectionDelete: PropTypes.func,
  onSwapUp: PropTypes.func,
  onSwapDown: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default Section;
