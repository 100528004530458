import React from 'react';
import { connect as reduxConnect } from 'react-redux';

import { getTimezones } from '../../actions/lotteryActions';
import { getAllowedCurrencies } from '../../actions/systemConsts';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Card, Button } from 'tabler-react';
import { Formik } from 'formik';
import { css } from 'react-emotion';
import Select from 'react-select';

import Spinner from '../Reusable/Spinner/Spinner';
import { ErrorHandler } from '../Reusable/ErrorHandler';
import AccessControl from '../AccessControl';
import { userRoles } from '../../utils/roles';
import { setProperMoneyValue } from '../../utils/money';
import { languagesList, LOTTERY_STATUSES } from '../../utils/constans';
import { getPrizeResolverName } from '../../utils/prizeResolver';

import { DateField, Field, Textarea, CheckboxField, SearchBrand, SearchLottery } from '../Reusable/FormFields';

import 'react-datepicker/dist/react-datepicker.css';

const imagePreviewClass = css`
  max-width: 150px;
`;

class LotteryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addLotterySuccess: false,
      addLotteryError: null,
      imagePreviewUrl: '',
      imageError: '',
    };
  }

  componentDidMount() {
    this.props.getTimezones();
    this.props.getAllowedCurrencies();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.data && this.props.data && this.props.data.image) {
      this.setState({
        imagePreviewUrl: this.props.data.image.url,
        imageError: '',
      });
    }
  }

  handleLotterySearchChange = (type, setFieldValue) => {
    if (!type) {
      return;
    }

    setFieldValue('prizeResolverClass', {});

    this.props.changeLotteryType(type.structure);
    this.props.getLotteryStructure(type);
  };

  validateLottery(values) {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.maxLinesPerBet) {
      errors.maxLinesPerBet = 'Required';
    }

    if (!values.brand || values.brand.length === 0) {
      errors.brand = 'Required';
    }

    if (!values.lottery || values.lottery.length === 0) {
      errors.lottery = 'Required';
    }

    if (values.maxLinesPerBet && values.maxLinesPerBet < 0) {
      errors.maxLinesPerBet = 'The number must be greater than 0';
    }

    return errors;
  }

  onImageChange = (e, setFieldValue, data) => {
    const [fileType] = e.type.split('/');

    if (fileType !== 'image' && (!data || !data.image)) {
      return this.setState({
        imagePreviewUrl: '',
        imageError: 'Invalid file',
      });
    }

    if (fileType !== 'image' && data.image) {
      return this.setState({
        imageError: 'Invalid file',
      });
    }

    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      this.setState({
        imagePreviewUrl: reader.result,
        imageError: '',
      });
      const encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
      setFieldValue('image', {
        id: data && data.id,
        base64: encoded,
      });
    };
  };

  handleMainCurrencyChange = (option, setFieldValue) => {
    const { changeMainCurrency } = this.props;

    setFieldValue('currency', option);
    changeMainCurrency(option);
  };

  handleBettingAgencyChange = (option, setFieldValue) => {
    this.props.onChangeBettingAgency(option);
    setFieldValue('bettingAgency', option);
  };

  handleBrandBlur = (value, setFieldTouched) => {
    if (!value) {
      this.props.clearPrices();
      this.props.resetLotteryStruct();
      setFieldTouched('lottery', true);
    }
  };

  buildTimezoneOptions = () => {
    const { timezones } = this.props;
    if (timezones) {
      //we need to add an option to use default system timezone
      return [...timezones, 'default'];
    }
    return [];
  };

  renderLotteryForm() {
    const {
      data,
      isPending,
      error,
      lotteryId,
      sendLotterySuccess,
      sendLotteryError,
      buildSelectOptions,
      bettingAgencies,
      editMode,
      allowedCurrencies,
      currency,
    } = this.props;

    const { imagePreviewUrl, imageError } = this.state;
    const imagePreviewDefault = 'http://via.placeholder.com/150';
    const imageSrc = imagePreviewUrl ? imagePreviewUrl : imagePreviewDefault;
    const imagePreview = <img src={imageSrc} className={`${imagePreviewClass} rounded-circle mt-2 mb-4`} />;

    const isStakesAreRangeCheckboxActive = data && data.lottery ? data.lottery.structure.hasStakesRange : false;
    const isPermutationStructure = data && data.permutationsStructure;

    if (isPending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    if (error) {
      return (
        <div className="mt-3 mr-3 ml-3">
          <ErrorHandler error={error} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          {data && `Edit: ${data.name}`}
          {!data && 'Fill data'}
        </Card.Header>
        <Card.Body>
          {sendLotterySuccess && !data && <div className="alert alert-success">New lottery is added succesfully</div>}
          {sendLotterySuccess && data && <div className="alert alert-success">Lottery is edited succesfully</div>}
          {sendLotteryError && <ErrorHandler error={sendLotteryError} />}
          <Formik
            initialValues={{
              name: data && lotteryId ? data.name : '',
              displayedName: data && lotteryId ? data.displayedName : '',
              description: data && lotteryId ? data.description : '',
              brand: data && lotteryId ? data.brand : '',
              lottery: data && lotteryId ? data.lottery : '',
              prizeResolverClass:
                data && data.lottery && lotteryId
                  ? {
                      value: data.prizeResolverClass,
                      label: getPrizeResolverName(
                        data.prizeResolverClass,
                        data.lottery.structure.supportedBetResolvers
                      ),
                    }
                  : {},
              maxLinesPerBet: data && lotteryId ? data.maxLinesPerBet : 0,
              logo: '',
              status: data && data.status ? { value: data.status, label: data.status } : null,
              active: data && lotteryId ? data.active : false,
              disableSelfCheck: data && lotteryId ? data.disableSelfCheck : false,
              availableFrom: data && lotteryId && data.availableFrom ? moment(data.availableFrom) : moment(),
              availableTo: data && lotteryId && data.availableTo ? moment(data.availableTo) : '',
              defaultTimezone: data && data.timezone ? { value: data.timezone, label: data.timezone } : {},
              defaultLanguage:
                data && data.defaultLanguage ? { value: data.defaultLanguage, label: data.defaultLanguage } : {},
              defaultJackpotValue: data && data.defaultJackpotValue ? data.defaultJackpotValue.amount / 100 : 0,
              defaultJackpotCurrency:
                data && data.defaultJackpotValue
                  ? { value: data.defaultJackpotValue.currency, label: data.defaultJackpotValue.currency }
                  : {},
              stakes: data && data.stakes ? data.stakes.join(', ') : '',
              ticketsTtlDays: data && data.ticketsTtlDays ? data.ticketsTtlDays : null,
              currenciesList: data && data.currenciesList ? data.currenciesList : [],
              bettingAgency:
                data && data.bettingAgencyType ? { value: data.bettingAgencyType, label: data.bettingAgencyType } : {},
              futureDrawsCount: data && data.futureDrawsCount ? data.futureDrawsCount : 0,
              isStakesRangeModeEnabled:
                data && data.lottery && data.isStakesRangeModeEnabled ? data.isStakesRangeModeEnabled : false,
              permutationsEnabled: data && isPermutationStructure ? data.permutationsEnabled : false,
              useProviderDrawLogic: data && data.useProviderDrawLogic,
              jackpotBalance: data && data.jackpotBalance && setProperMoneyValue(data.jackpotBalance.value),
              currency: currency ? currency : '',
              shouldRoundPrizes: data && data.shouldRoundPrizes,
              allowInstantBets: data && data.allowInstantBets,
            }}
            validate={(values) => this.validateLottery(values)}
            onSubmit={(values, actions) => {
              this.props.handleSubmit(values, actions);
            }}
            render={({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, setFieldTouched }) => (
              <form className="lottery-form" onSubmit={handleSubmit}>
                <Field
                  label="Name"
                  type="text"
                  name="name"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('name', true)}
                  value={values.name}
                  disabled={false}
                  touched={touched.name}
                  error={errors.name}
                />
                <Field
                  label="Display name"
                  type="text"
                  name="displayedName"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('displayedName', true)}
                  value={values.displayedName}
                  disabled={false}
                  touched={touched.displayedName}
                  error={errors.displayedName}
                />
                <Textarea
                  label="Description"
                  type="text"
                  name="description"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('name', true)}
                  value={values.description}
                  touched={touched.description}
                  error={errors.description}
                />
                <div className="form-group">
                  <SearchBrand
                    labelText="Brand"
                    value={values.brand}
                    handleBlur={() => setFieldTouched('brand', true)}
                    setFieldValue={setFieldValue}
                    className={classNames('react-select ', {
                      'react-select-is-invalid': touched.brand && errors.brand,
                    })}
                  />
                  {touched.brand && errors.brand && <div className="invalid-feedback">{errors.brand}</div>}
                </div>
                <div className="form-group">
                  <SearchLottery
                    value={values.lottery}
                    handleBlur={() => this.handleBrandBlur(values.lottery, setFieldTouched)}
                    handleChange={(type) => this.handleLotterySearchChange(type, setFieldValue)}
                    setFieldValue={setFieldValue}
                    disabled={lotteryId ? true : false}
                    className={classNames('react-select ', {
                      'react-select-is-invalid': touched.lottery && errors.lottery,
                    })}
                  />
                  {touched.lottery && errors.lottery && <div className="invalid-feedback">{errors.lottery}</div>}
                </div>
                {values.lottery && (
                  <div className="form-group">
                    <label>Lottery resolver</label>
                    <Select
                      name="prizeResolverClass"
                      options={values.lottery.structure.supportedBetResolvers
                        .map((elem) => ({ label: elem.name, value: elem.service }))
                        .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))}
                      className="react-select form-group "
                      onChange={(selected) => setFieldValue('prizeResolverClass', selected)}
                      value={values.prizeResolverClass}
                      placeholder={'Select..'}
                      required
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </div>
                )}
                {bettingAgencies && (
                  <div className="form-group">
                    <label>Betting Agency</label>
                    <Select
                      name="bettingAgency"
                      options={buildSelectOptions(bettingAgencies)}
                      className="react-select form-group "
                      onChange={(option) => this.handleBettingAgencyChange(option, setFieldValue)}
                      value={values.bettingAgency}
                      placeholder={'Select..'}
                      required
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </div>
                )}
                {allowedCurrencies && (
                  <div className="form-group">
                    <label>Main lottery currency</label>
                    <Select
                      name="currency"
                      options={buildSelectOptions(allowedCurrencies)}
                      className="react-select form-group "
                      onChange={(option) => this.handleMainCurrencyChange(option, setFieldValue)}
                      value={values.currency}
                      placeholder={'Select..'}
                      required
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </div>
                )}
                <Field
                  label="Max lines per bet"
                  type="number"
                  name="maxLinesPerBet"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('maxLinesPerBet', true)}
                  value={values.maxLinesPerBet}
                  disabled={false}
                  touched={touched.maxLinesPerBet}
                  error={errors.maxLinesPerBet}
                />
                <Field
                  label="Future draws count"
                  type="number"
                  name="futureDrawsCount"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('futureDrawsCount', true)}
                  value={values.futureDrawsCount}
                  disabled={false}
                  touched={touched.futureDrawsCount}
                  error={errors.futureDrawsCount}
                />
                <Field
                  label="Logo"
                  type="file"
                  name="logo"
                  onChange={(e) => this.onImageChange(e, setFieldValue, data)}
                  disabled={false}
                />
                {imageError && <div className="invalid-feedback d-block">{imageError}</div>}
                {imagePreview}
                <DateField
                  value={values.availableFrom}
                  onChange={setFieldValue}
                  name="availableFrom"
                  label="Available from"
                />
                <DateField
                  value={values.availableTo}
                  onChange={setFieldValue}
                  name="availableTo"
                  label="Available to"
                />
                {editMode && (
                  <React.Fragment>
                    <Field
                      label="Default Jackpot Value"
                      type="number"
                      name="defaultJackpotValue"
                      onChange={setFieldValue}
                      value={values.defaultJackpotValue}
                      disabled={false}
                    />
                    <label>Jackpot currency</label>
                    <Select
                      name="defaultJackpotCurrency"
                      options={buildSelectOptions(values.currenciesList)}
                      className="react-select form-group "
                      onChange={(option) => setFieldValue('defaultJackpotCurrency', option)}
                      value={values.currency}
                      placeholder={'Select..'}
                      required
                      isDisabled
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                    <Field
                      label="Jackpot balance"
                      type="string"
                      name="jackpotBalance"
                      value={values.jackpotBalance}
                      readonly
                    />
                  </React.Fragment>
                )}
                {values.lottery && values.lottery.structure.hasStakes && (
                  <React.Fragment>
                    <Field
                      label="Stakes"
                      type="text"
                      name="stakes"
                      onChange={setFieldValue}
                      value={values.stakes}
                      disabled={false}
                    />
                    <CheckboxField
                      label="Stakes are range"
                      name="isStakesRangeModeEnabled"
                      value={values.isStakesRangeModeEnabled}
                      onChange={setFieldValue}
                      disabled={!(editMode && isStakesAreRangeCheckboxActive)}
                    />
                  </React.Fragment>
                )}
                <Field
                  label="Retail ticket expire days"
                  type="number"
                  name="ticketsTtlDays"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('ticketsTtlDays', true)}
                  value={values.ticketsTtlDays}
                  disabled={false}
                  touched={touched.ticketsTtlDays}
                  error={errors.ticketsTtlDays}
                  hint={
                    'Number of days for which retail tickets are valid after purchase. Leave empty for no expiration.'
                  }
                />
                <label>Default language</label>
                <Select
                  name="defaultLanguage"
                  options={languagesList}
                  className="react-select form-group "
                  onChange={(option) => setFieldValue('defaultLanguage', option)}
                  value={values.defaultLanguage}
                  placeholder={'Select..'}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
                <label>Default timezone</label>
                <Select
                  name="defaultTimezone"
                  options={buildSelectOptions(this.buildTimezoneOptions())}
                  className="react-select form-group "
                  onChange={(option) => setFieldValue('defaultTimezone', option)}
                  value={values.defaultTimezone}
                  placeholder={'Select...'}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
                <label>Status</label>
                <Select
                  name="status"
                  options={buildSelectOptions(LOTTERY_STATUSES)}
                  className="react-select form-group "
                  onChange={(option) => setFieldValue('status', option)}
                  value={values.status}
                  placeholder={'Select...'}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
                <CheckboxField
                  label="Use Provider Draw Logic"
                  name="useProviderDrawLogic"
                  value={values.useProviderDrawLogic}
                  onChange={setFieldValue}
                  disabled={!editMode}
                />
                <CheckboxField
                  label="Allow Instant Bets"
                  name="allowInstantBets"
                  value={values.allowInstantBets}
                  onChange={setFieldValue}
                  disabled={!editMode}
                />
                <CheckboxField
                  label="Rounding winnings (RUB)"
                  name="shouldRoundPrizes"
                  value={values.shouldRoundPrizes}
                  onChange={setFieldValue}
                  disabled={!editMode}
                />
                <CheckboxField
                  label="Active"
                  name="active"
                  value={values.active}
                  onChange={setFieldValue}
                  disabled={!editMode}
                />
                <CheckboxField
                  label="Disable self check"
                  name="disableSelfCheck"
                  value={values.disableSelfCheck}
                  onChange={setFieldValue}
                  disabled={!editMode}
                />
                {isPermutationStructure && (
                  <CheckboxField
                    label="Enable permutations"
                    name="permutationsEnabled"
                    value={values.permutationsEnabled}
                    onChange={setFieldValue}
                    disabled={!editMode}
                  />
                )}
                <AccessControl roles={[userRoles.superAdmin]}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button loading={isSubmitting} type="submit" color="primary">
                      Send
                    </Button>
                  </div>
                </AccessControl>
              </form>
            )}
          />
        </Card.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        {this.renderLotteryForm()}
      </Card>
    );
  }
}

LotteryForm.propTypes = {
  lotteryId: PropTypes.string,
  isPending: PropTypes.bool,
  error: PropTypes.object,
  data: PropTypes.object,
  clearLottery: PropTypes.func,
  getLottery: PropTypes.func,
  prices: PropTypes.array,
  sendLotterySuccess: PropTypes.bool,
  sendLotteryError: PropTypes.object,
  handleSubmit: PropTypes.func,
  changeLotteryType: PropTypes.func,
  resetLotteryStruct: PropTypes.func,
  clearPrices: PropTypes.func,
  getLotteryStructure: PropTypes.func,
  buildSelectOptions: PropTypes.func,
  timezones: PropTypes.array,
  getTimezones: PropTypes.func,
  bettingAgencies: PropTypes.array,
  onChangeBettingAgency: PropTypes.func,
  editMode: PropTypes.bool,
  changeMainCurrency: PropTypes.func,
  currency: PropTypes.object,

  getAllowedCurrencies: PropTypes.func,
  allowedCurrencies: PropTypes.array,
};

export default reduxConnect(
  (state) => ({
    timezones: state.lottery.timezonesData.timezones,
    allowedCurrencies: state.allowedCurrencies.data,
  }),
  {
    getTimezones,
    getAllowedCurrencies,
  }
)(LotteryForm);
