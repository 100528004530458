import { v4 as uuidv4 } from 'uuid';

const PERCENTAGE = 'percentage';
const EXACT = 'exact';

const setMoneyValue = (money) => {
  return money / 100;
};

const setPercentageValue = (percentage) => {
  return percentage * 100;
};

export const getData = (data, additionalData, type) => {
  if (!data) {
    return;
  }

  if (type === 'rates') {
    return data.map((elem) => ({
      name: elem.brand.name,
      exchangeCurrency: elem.source,
      exchange: `${elem.source} -> ${elem.target}`,
      id: elem.id,
      brandId: elem.brand.id,
      input: {
        name: 'value',
        value: elem.rate,
        type: 'number',
        onChange: (id, value) => additionalData.handleInputChange(id, value, 'input'),
      },
      disabled: additionalData.brandId !== elem.brand.id,
    }));
  }

  if (type === 'taxes') {
    return data.map((elem) => ({
      name: elem.brand.name,
      minimumWinning: {
        name: 'minimumWinning',
        value: setMoneyValue(elem.minimumWinning.amount),
        type: 'number',
        onChange: (id, value) => additionalData.handleInputChange(id, value, 'minimumWinning'),
      },
      maximumWinning: {
        name: 'maximumWinning',
        value: setMoneyValue(elem.maximumWinning.amount),
        type: 'number',
        onChange: (id, value) => additionalData.handleInputChange(id, value, 'maximumWinning'),
      },
      percentageValue:
        elem.type === PERCENTAGE
          ? {
              name: 'percentageValue',
              value: setPercentageValue(elem.percentageValue),
              type: 'number',
              min: 0,
              max: 100,
              onChange: (id, value) => additionalData.handleInputChange(id, value, 'percentageValue'),
            }
          : null,
      moneyValue:
        elem.type === EXACT
          ? {
              name: 'moneyValue',
              value: setMoneyValue(elem.moneyValue.amount),
              type: 'number',
              onChange: (id, value) => additionalData.handleInputChange(id, value, 'moneyValue'),
            }
          : null,
      type: elem.type,
      currency: elem.minimumWinning.currency,
      id: elem.id,
      brandId: elem.brand.id,
      disabled: additionalData.brandId !== elem.brand.id,
    }));
  }
};

export const getNewItem = (type, additionalData) => {
  if (type === 'rates') {
    return {
      name: additionalData.brandName,
      exchangeCurrency: additionalData.selectedCurrency.value,
      exchange: `${additionalData.selectedCurrency.value} -> EUR`,
      id: uuidv4(),
      brandId: additionalData.brandId,
      input: {
        name: 'value',
        value: '',
        type: 'number',
        onChange: (id, value) => additionalData.handleInputChange(id, value, 'input'),
      },
      disabled: false,
    };
  }

  if (type === 'taxes') {
    return {
      name: additionalData.brandName,
      minimumWinning: {
        name: 'minimumWinning',
        value: '',
        type: 'number',
        onChange: (id, value) => additionalData.handleInputChange(id, value, 'minimumWinning'),
      },
      maximumWinning: {
        name: 'maximumWinning',
        value: '',
        type: 'number',
        onChange: (id, value) => additionalData.handleInputChange(id, value, 'maximumWinning'),
      },
      percentageValue:
        additionalData.selectedType.value === PERCENTAGE
          ? {
              name: 'percentageValue',
              value: '',
              type: 'number',
              onChange: (id, value) => additionalData.handleInputChange(id, value, 'percentageValue'),
            }
          : null,
      moneyValue:
        additionalData.selectedType.value === EXACT
          ? {
              name: 'moneyValue',
              value: '',
              type: 'number',
              onChange: (id, value) => additionalData.handleInputChange(id, value, 'moneyValue'),
            }
          : null,
      type: additionalData.selectedType.value,
      currency: additionalData.selectedCurrency.value,
      id: uuidv4(),
      brandId: additionalData.brandId,
      disabled: false,
    };
  }
};

export const getColumns = (type, additionalData) => {
  if (type === 'rates') {
    return [
      {
        header: 'Owner',
        type: 'string',
        accessor: 'name',
      },
      {
        header: 'Exchange',
        type: 'string',
        accessor: 'exchange',
      },
      {
        header: 'Value',
        type: 'input',
        accessor: 'input',
      },
      {
        header: '',
        id: 'id',
        accessor: 'delete',
        type: 'delete',
        handler: additionalData.handleToggleModal,
      },
    ];
  }

  if (type === 'taxes') {
    return [
      {
        header: 'Owner',
        type: 'string',
        accessor: 'name',
      },
      {
        header: 'Minimum Winning',
        type: 'input',
        accessor: 'minimumWinning',
      },
      {
        header: 'Maximum Winning',
        type: 'input',
        accessor: 'maximumWinning',
      },
      {
        header: 'Percentage',
        type: 'input',
        accessor: 'percentageValue',
      },
      {
        header: 'Money Value',
        type: 'input',
        accessor: 'moneyValue',
      },
      {
        header: 'Currency',
        type: 'string',
        accessor: 'currency',
      },
      {
        header: '',
        id: 'id',
        accessor: 'delete',
        type: 'delete',
        handler: additionalData.handleToggleModal,
      },
    ];
  }
};
