import React from 'react';
import propTypes from 'prop-types';
import { TabbedCard, Tab } from 'tabler-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ManagementTab from './ManagementTab';
import Bet from './Bet';

import { getBet, getBetLines, getBetPerms, resetBetData } from '../../../actions/bet';
import { postManagementAction } from '../../../actions/management';
import { DEFAULT_TICKETS_TYPES } from '../../../utils/tickets/consts';

class BetPage extends React.Component {
  componentDidMount() {
    this.getBet();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id != this.props.match.params.id) {
      this.getBet();
    }
  }

  componentWillUnmount() {
    const { resetBetData } = this.props;

    resetBetData();
  }

  getBet() {
    const { bet, getBetLines, getBet, getBetPerms, isDefaultType, type } = this.props;
    const id = this.getId();

    if ((!bet.isPending && !bet.data) || bet.data.id != id) {
      getBet(id, type);

      if (isDefaultType) {
        getBetLines(id);
        getBetPerms(id);
      }
    }
  }

  getId = () => {
    const { match, searchBetId } = this.props;

    if (searchBetId) {
      return searchBetId;
    }

    return match.params.id;
  };

  renderManagementTab() {
    const { isDefaultType, postManagementAction, bet } = this.props;

    if (!isDefaultType) {
      return null;
    }

    return (
      <Tab title={'Management'}>
        {/* passing whole bet.data object for future development*/}
        <ManagementTab bet={bet.data} {...{ postManagementAction }} />
      </Tab>
    );
  }

  render() {
    const { bet, lines, perms, getBetLines, isDefaultType, pageTitle } = this.props;

    return (
      <div className="mt-5">
        <TabbedCard initialTab={'General'} className={'mt-5'}>
          <Tab title={'General'}>
            <Bet id={this.getId()} {...{ bet, lines, perms, getBetLines, pageTitle, isDefaultType }} />
          </Tab>
          {this.renderManagementTab()}
        </TabbedCard>
      </div>
    );
  }
}

BetPage.propTypes = {
  searchBetId: propTypes.string,
  pageTitle: propTypes.string,
  type: propTypes.string.isRequired,
  bet: propTypes.shape({
    data: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
    isPending: propTypes.bool,
    error: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
  }),
  lines: propTypes.shape({
    data: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.array]),
    isPending: propTypes.bool,
    error: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
  }),
  perms: propTypes.shape({
    data: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.array]),
    isPending: propTypes.bool,
    error: propTypes.oneOfType([propTypes.oneOf([null]), propTypes.object]),
  }),
  match: propTypes.object,
  isDefaultType: propTypes.bool,

  getBet: propTypes.func,
  getBetLines: propTypes.func,
  getBetPerms: propTypes.func,
  resetBetData: propTypes.func,
  postManagementAction: propTypes.func,
};

export default withRouter(
  connect(
    (state, ownProps) => ({
      searchBetId: state.search.bet.id,
      bet: state.bet,
      lines: state.betLines,
      perms: state.betPerms,
      isDefaultType: DEFAULT_TICKETS_TYPES.indexOf(ownProps.type) !== -1,
    }),
    {
      getBet,
      getBetPerms,
      getBetLines,
      postManagementAction,
      resetBetData,
    }
  )(BetPage)
);
