import React from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import propTypes from 'prop-types';

import { setLocationKey, setPageData, setPagiNumber } from '../../actions/pageConfigActions';

const withPageSettings = (pageName) => (WrappedComponent) => {
  class Page extends React.Component {
    componentDidMount() {
      const { config, history, setLocationKey, setPageData } = this.props;
      const { location } = history;

      if (!config.key) {
        setLocationKey(pageName, location.key);
      }

      if (config.key !== location.key) {
        setPageData(pageName, location.key, 1);
      }
    }

    onPaginationPageChange = (page) => {
      const { setPagiNumber } = this.props;

      setPagiNumber(pageName, page);
    };

    render() {
      const { config, history } = this.props;
      const { location } = history;

      return (
        <WrappedComponent
          {...omit(this.props, ['setLocationKey', 'setPageData', 'setPagiNumber', 'config'])}
          pagiNumber={config.key !== location.key ? 1 : config.pagiNumber}
          onPaginationPageChange={this.onPaginationPageChange}
        />
      );
    }
  }

  Page.propTypes = {
    config: propTypes.object.isRequired,
    history: propTypes.object.isRequired,

    setLocationKey: propTypes.func.isRequired,
    setPageData: propTypes.func.isRequired,
    setPagiNumber: propTypes.func.isRequired,
  };

  return connect(
    (state) => ({
      config: state.pageConfig[pageName],
    }),
    {
      setLocationKey,
      setPageData,
      setPagiNumber,
    }
  )(Page);
};

export default withPageSettings;
