import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Page, Button } from 'tabler-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReportSummary from './ReportSummary';
import ReportsFilters from '../../Reusable/ReportsFilter/ReportsFilter';
import CustomTable from '../../Reusable/Table/CustomTable';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import Spinner from '../../Reusable/Spinner/Spinner';

import withPageConfig from '../../hoc/withPageConfig';

import ReportSelectors from '../../../selectors/reportFilters';

import { createColumnsConfig } from '../../../utils/table/tableBuild';
import { REPORTS } from '../../../utils/pagesConstans';

import { getReports, changeEntries, getReportsCSV } from '../../../actions/reportsActions';

import './Reports.scss';
import 'react-datetime/css/react-datetime.css';

class Report extends React.Component {
  componentDidMount() {
    this.getReports();
  }

  getReports = (page) => {
    const { getReports, reportsType, pagiNumber } = this.props;
    const pageNum = page || pagiNumber;

    getReports(reportsType, pageNum);
  };

  handleButtonExportClick = () => {
    const { reportsType, getReportsCSV } = this.props;

    getReportsCSV(reportsType);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    const { changeEntries } = this.props;

    changeEntries(entries);
    this.getReports();
  };

  handlePageChange = (pageNumber) => {
    const { onPaginationPageChange } = this.props;

    this.getReports(pageNumber);
    onPaginationPageChange(pageNumber);
  };

  renderContent() {
    const { reportsError, reportsPending, reportsHeaders, reportsData, user, tableColumns, reportsType } = this.props;

    if (reportsPending && !reportsData && !reportsHeaders) {
      return <Spinner class="mb-5" />;
    }

    if (reportsError) {
      return <ErrorHandler error={reportsError} />;
    }

    if (!reportsData && !reportsHeaders) {
      return null;
    }

    return (
      <CustomTable
        paginHeaders={reportsHeaders}
        onPageChanged={this.handlePageChange}
        onChangeEntries={this.handleChangeEntriesNumber}
        loading={reportsPending}
        data={reportsData}
        columns={createColumnsConfig(tableColumns, user.roles, reportsType)}
      />
    );
  }

  render() {
    const { reportsType, reportsTitle, reportsCSV } = this.props;
    const title = reportsTitle ? reportsTitle : reportsType;

    return (
      <Page>
        <Page.Header>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Page.Title>
              <p className="reports__header">{title} report</p>
              {reportsType == 'sales' && (
                <span style={{ fontSize: '16px', color: 'red' }}>
                  Note: Report includes only fully processed bets (tickets). It is refreshed about 2 AM UTC every day
                  and at 1 PM UTC for newest bets. Don&apos;t rely on report for today&apos;s and future draws - data
                  will be not accurate.
                </span>
              )}
            </Page.Title>
          </div>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={12}>
            <ReportsFilters getReports={this.getReports} reportsType={reportsType} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={12}>
            <Button
              className="reports__button"
              color="primary"
              onClick={this.handleButtonExportClick}
              disabled={reportsCSV.isLoading}
            >
              Export to CSV
            </Button>
          </Grid.Col>
        </Grid.Row>
        <ReportSummary reportsType={reportsType} />
        {this.renderContent()}
      </Page>
    );
  }
}

Report.propTypes = {
  user: PropTypes.object,
  reportsError: PropTypes.object,
  reportsData: PropTypes.array,
  reportsPending: PropTypes.bool,
  reportsHeaders: PropTypes.object,
  reportsType: PropTypes.string,
  reportsTitle: PropTypes.string,
  reportsCSV: PropTypes.object,
  filterStatus: PropTypes.object,
  pagiNumber: PropTypes.number,
  tableColumns: PropTypes.arrayOf(PropTypes.string).isRequired,

  onPaginationPageChange: PropTypes.func,
  getReports: PropTypes.func.isRequired,
  getReportsCSV: PropTypes.func.isRequired,
  changeEntries: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state, ownProps) => ({
      user: state.user.data,
      reportsError: state.reports.error,
      reportsPending: state.reports.isPending,
      reportsData: state.reports.data,
      reportsHeaders: state.reports.headers,

      reportsCSV: state.reports.csv,
      filterStatus: ReportSelectors.getFilterStatus(state, ownProps.reportsType),
    }),
    {
      getReports,
      getReportsCSV,
      changeEntries,
    }
  )(withPageConfig(REPORTS)(Report))
);
