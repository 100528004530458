import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Table, Card } from 'tabler-react';

import Spinner from '../Reusable/Spinner/Spinner';
import { ErrorHandler } from '../Reusable/ErrorHandler';
import AddWinningPriceSingle from './AddWinningPriceSingle';

import { handlePriceChange, setPrices, changePriceCurrency, changePriceType } from '../../actions/prices';

class AddWinningPrice extends React.Component {
  handleChange = (id, name, text) => {
    this.props.handlePriceChange(id, name, text);
  };

  handleSelectChange(name, text) {
    if (name === 'prizeType') {
      return this.props.changePriceType(text);
    }

    return this.props.changePriceCurrency(text);
  }

  renderForm() {
    const { isPending, data, error } = this.props;

    if (isPending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    if (error) {
      return (
        <div className="mt-3 mr-3 ml-3">
          <ErrorHandler error={error} />
        </div>
      );
    }

    if (data && data.length > 0) {
      return (
        <React.Fragment>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="">Prize type</label>
              <Select
                name="priceType"
                options={[{ value: 'fixed', label: 'fixed' }]}
                className="react-select "
                onChange={(value) => this.handleSelectChange('prizeType', value)}
                value={this.props.priceType}
                placeholder={'Type to search...'}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              ></Select>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="">Currency</label>
              <Select
                options={[
                  { value: 'EUR', label: 'EUR' },
                  { value: 'USD', label: 'USD' },
                ]}
                className="react-select "
                onChange={(value) => this.handleSelectChange('currency', value)}
                placeholder={'Type to search...'}
                value={this.props.priceCurrency}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              ></Select>
            </div>
          </div>
          <Table hasOutline responsive highlightRowOnHover className="table-vcenter">
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Name</Table.ColHeader>
                <Table.ColHeader>Amount</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((price, i) => {
                return (
                  <AddWinningPriceSingle price={this.props.data[i]} key={i} index={i} onChange={this.handleChange} />
                );
              })}
            </Table.Body>
          </Table>
        </React.Fragment>
      );
    }
  }

  render() {
    const { data, showMessage, message } = this.props;

    return (
      <React.Fragment>
        {data.length > 0 && (
          <Card>
            <Card.Status color="blue" />
            <Card.Header>Fill prizes</Card.Header>
            <Card.Body>
              {showMessage && message && <p>{message}</p>}
              {!showMessage && this.renderForm()}
            </Card.Body>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

AddWinningPrice.propTypes = {
  handlePriceChange: PropTypes.func,
  changePriceCurrency: PropTypes.func,
  changePriceType: PropTypes.func,
  isPending: PropTypes.bool,
  data: PropTypes.array,
  error: PropTypes.object,
  priceType: PropTypes.object,
  priceCurrency: PropTypes.object,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
};

export default connect(
  (state) => ({
    data: state.prices.prices,
    priceType: state.prices.priceType,
    priceCurrency: state.prices.currency,
  }),
  {
    handlePriceChange,
    setPrices,
    changePriceCurrency,
    changePriceType,
  }
)(AddWinningPrice);
