export const EDITEC = 'editec';

export const EDITEC_COLUMNS = [
  {
    header: 'Name',
    type: 'string',
    accessor: 'name',
  },
  {
    header: 'Authorizer Server',
    type: 'string',
    accessor: 'authorizerServerNb',
  },
  {
    header: 'Game key',
    type: 'string',
    accessor: 'gameKey',
  },
  {
    header: 'Game type',
    type: 'string',
    accessor: 'gameType',
  },
  {
    header: 'URL',
    type: 'string',
    accessor: 'url',
  },
  {
    header: 'Proxy URL',
    type: 'string',
    accessor: 'proxy',
  },
  {
    header: 'Active',
    type: 'boolean',
    accessor: 'active',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    type: 'actions',
    id: 'id',
    elements: [
      {
        header: 'Actions',
        type: 'edit',
        linkto: `/providers/${EDITEC}/:id`,
        txt: 'Edit',
      },
    ],
  },
];

export const EDITEC_FORM_CONFIG = [
  {
    name: 'brand',
    title: 'Brand',
    type: 'search-brand',
    data: [],
  },
  {
    name: 'name',
    title: 'Name',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'authorizerServerNb',
    title: 'Authorizer server number',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'gameKey',
    title: 'Game key',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'gameType',
    title: 'Game type',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'url',
    title: 'URL',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'proxy',
    title: 'Proxy url',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'registrationTimeInMinutes',
    title: 'Registration time in minutes',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'fetchResultsTimeInMinutes',
    title: 'Fetch results time in minutes',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'futureDrawsCount',
    title: 'Future draws count',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'allowedTimeMarginInMinutes',
    title: 'Allowed time margin in minutes',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'editecClient',
    title: 'Editec client',
    type: 'dropdown',
    data: [
      {
        label: 'Super4',
        value: 'super4',
      },
      {
        label: '5/90',
        value: '590',
      },
    ],
  },
  {
    name: 'winningSelectionClass',
    title: 'Winning selection class',
    type: 'dropdown',
    data: [],
  },
  {
    name: 'timezone',
    title: 'Timezone',
    type: 'dropdown',
    data: [],
  },
  {
    name: 'areDrawsCancelledOnFailedRegistration',
    title: 'Cancel draws when provider draw is not registered',
    type: 'bool',
    isRequired: true,
  },
  {
    name: 'active',
    title: 'Enable Active',
    type: 'bool',
    isRequired: true,
  },
];
