import { createLogic } from 'redux-logic';
import { isEqual } from 'lodash';

import { TAXES, NOTIFICATION } from '../actions/types';

export const getBrandTaxes = createLogic({
  type: TAXES.GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const params = {
      'search[brand][uuid]': action.payload,
    };

    return httpClient
      .get('/api/v1/withholding-taxes', {
        params,
      })
      .then((res) => {
        dispatch({
          type: TAXES.GET.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: TAXES.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const deleteTax = createLogic({
  type: TAXES.DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .delete(`/api/v1/withholding-taxes/${action.payload.id}`)
      .then((res) => {
        dispatch({
          type: TAXES.DELETE.SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Tax deleted successfully',
          },
        });
        dispatch({
          type: TAXES.GET.START,
          payload: action.payload.brandId,
        });
      })
      .catch((err) => {
        dispatch({
          type: TAXES.DELETE.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

const getProperMoneyValue = (money) => {
  return money * 100;
};

const getProperPercentageValue = (percentage) => {
  return percentage / 100;
};

const getDataToSend = (data) => {
  return {
    brand: {
      id: data.brandId,
    },
    id: data.id,
    minimumWinning: {
      amount: getProperMoneyValue(data.minimumWinning.value),
      currency: data.currency,
    },
    maximumWinning: {
      amount: getProperMoneyValue(data.maximumWinning.value),
      currency: data.currency,
    },
    type: data.type,
    moneyValue: data.moneyValue
      ? {
          amount: getProperMoneyValue(data.moneyValue.value),
          currency: data.currency,
        }
      : {
          amount: 0,
          currency: 'USD',
        },
    percentageValue: data.percentageValue ? getProperPercentageValue(data.percentageValue.value) : '0',
  };
};

const getTaxesToSend = (httpClient, data, currentState) => {
  const promiseChain = [];

  data.forEach((elem) => {
    const fromAPI = currentState.find((rate) => rate.id === elem.id);
    const elemFromState = getDataToSend(elem);

    if (fromAPI) {
      if (!isEqual(fromAPI, elemFromState)) {
        promiseChain.push(httpClient.put(`/api/v1/withholding-taxes/${elem.id}`, { ...elemFromState }));

        return;
      } else {
        return;
      }
    } else {
      delete elemFromState.id;

      promiseChain.push(
        httpClient.post(`/api/v1/withholding-taxes`, {
          ...elemFromState,
        })
      );
      return;
    }
  });

  return promiseChain;
};

export const putTaxes = createLogic({
  type: TAXES.PUT.START,

  process({ httpClient, action, getState }, dispatch, done) {
    const taxes = getState().taxesList.data;

    return Promise.all(getTaxesToSend(httpClient, action.payload.data, taxes))
      .then((res) => {
        dispatch({
          type: TAXES.PUT.SUCCESS,
          payload: res,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Taxes updated successfully',
          },
        });
        dispatch({
          type: TAXES.GET.START,
          payload: action.payload.id,
        });
      })
      .catch((err) => {
        dispatch({
          type: TAXES.PUT.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

export default {
  getBrandTaxes,
  deleteTax,
  putTaxes,
};
