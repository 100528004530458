import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Page, Grid } from 'tabler-react';

import CustomModal from '../../Reusable/Modal/CustomModal';
import CustomTable from '../../Reusable/Table/index';
import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import Spinner from '../../Reusable/Spinner/Spinner';

import withPageConfig from '../../hoc/withPageConfig';

import httpClient from '../../../services/httpClient';
import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';
import { DRAWS_PAGE } from '../../../utils/pagesConstans';

import {
  getDraws,
  deleteDraw,
  changeEntries,
  changeSearchTerm,
  changeLotteryType,
  changeParentBrand,
  changeShowFutureDraws,
  changeShowNotProcessedDraws,
  clearDrawsFilters,
} from '../../../actions/draws';
import { postResults, resetResultsData, deleteResults } from '../../../actions/resultConfirmationActions';

import DrawsSelectors from '../../../selectors/drawsFilters';

import DrawsFilter from '../../Reusable/DrawsFilter';
import { ResultConfirmationModal, ResultResetModal } from '../../Reusable/Modal';

class Draws extends React.Component {
  constructor() {
    super();

    this.state = {
      isResultModalOpen: false,
      isResetResultModalOpen: false,
      isConfirmModalOpen: false,
      drawId: null,
      singleDraw: null,
      confirmSuccess: false,
      confirmError: null,
    };

    this.timer = null;
  }

  componentDidMount() {
    const { getDraws, pagiNumber } = this.props;

    getDraws(pagiNumber);
  }

  componentDidUpdate(prevProps) {
    const { reset, pagiNumber } = this.props.drawResults;
    const { getDraws } = this.props;

    if (prevProps.drawResults.reset.success !== reset.success && reset.success) {
      getDraws(pagiNumber);
      this.setState({ isResetResultModalOpen: false });
    }

    clearTimeout(this.timer);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getSingleDraw = (id) => {
    const { drawsData } = this.props;
    return drawsData.find((elem) => elem.id === id);
  };

  handlePageChange = (pageNumber) => {
    const { getDraws, onPaginationPageChange } = this.props;

    getDraws(pageNumber);
    onPaginationPageChange(pageNumber);
    this.setState({ page: pageNumber });
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    this.props.changeEntries(entries);
    this.props.getDraws(1);
  };

  handleDeleteItem = (id) => {
    this.props.deleteDraw(id);
  };

  handlePhraseSearch = (phrase) => {
    this.props.getDraws(1, phrase);
  };

  confirmDrawResult = () => {
    httpClient
      .put(`/api/v1/game-draws/${this.state.drawId}/result/confirm`)
      .then(() => {
        this.props.getDraws(this.props.drawsHeaders['x-page']);
        this.setState(
          {
            ...this.state,
            confirmSuccess: true,
            confirmError: null,
          },
          () => {
            setTimeout(() => {
              this.handleModalToggle(null);
            }, 2000);
          }
        );
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          confirmSuccess: false,
          confirmError: err.response,
        });
      });
  };

  handleResultsDoneClick = () => {
    const { resetResultsData, getDraws } = this.props;

    this.setState({
      isResultModalOpen: false,
      drawId: null,
      singleDraw: null,
    });

    resetResultsData();
    getDraws(1);
  };

  handlePostResults = (data) => {
    const { postResults } = this.props;
    const { drawId } = this.state;
    postResults(drawId, data);
  };

  handleToggleResetResultModal = (id) => {
    this.setState((prevState) => ({
      isResetResultModalOpen: !prevState.isResetResultModalOpen,
      drawId: id,
      singleDraw: id ? this.getSingleDraw(id) : null,
    }));
  };

  handleClearResultsClick = () => {
    const { deleteResults } = this.props;
    const { drawId } = this.state;

    deleteResults(drawId);
  };

  handleToggleResultsModal = (id) => {
    const { resetResultsData, getDraws, drawResults } = this.props;
    this.setState((prevState) => {
      if (prevState.isResultModalOpen) {
        resetResultsData();
      }

      if (prevState.isResultModalOpen && drawResults.success) {
        getDraws(1);
      }

      return {
        isResultModalOpen: !prevState.isResultModalOpen,
        drawId: id,
        singleDraw: id ? this.getSingleDraw(id) : null,
      };
    });
  };

  handleModalToggle = (id) => {
    this.setState((prevState) => ({
      isConfirmModalOpen: !prevState.isConfirmModalOpen,
      drawId: id,
      confirmSuccess: false,
      confirmError: null,
    }));
  };

  render() {
    const {
      user,
      getDraws,
      drawsData,
      drawsPending,
      drawsError,
      drawsHeaders,
      changeLotteryType,
      changeParentBrand,
      changeShowFutureDraws,
      changeShowNotProcessedDraws,
      drawResults,
      filterStatus,
      clearDrawsFilters,
    } = this.props;
    const { singleDraw, isResultModalOpen, isResetResultModalOpen } = this.state;

    return (
      <AccessControl
        roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator]}
        forbiddenRedirect={'/'}
      >
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>
              <span>Draws</span>
            </Page.Title>
          </div>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={12}>
            <DrawsFilter
              filterStatus={filterStatus}
              trigger={getDraws}
              reset={clearDrawsFilters}
              changeLotteryType={changeLotteryType}
              changeParentBrand={changeParentBrand}
              changeShowFutureDraws={changeShowFutureDraws}
              changeShowNotProcessedDraws={changeShowNotProcessedDraws}
            />
          </Grid.Col>
        </Grid.Row>
        {drawsError && <ErrorHandler error={drawsError} />}
        {drawsPending && !drawsHeaders && <Spinner class="mb-5" />}
        {drawsHeaders && (
          <CustomTable
            onChangeEntries={this.handleChangeEntriesNumber}
            onPageChanged={this.handlePageChange}
            onPhraseSearched={this.handlePhraseSearch}
            handleSearchTermChange={this.props.changeSearchTerm}
            deleteHandler={this.handleDeleteItem}
            paginHeaders={drawsHeaders}
            loading={drawsPending}
            data={drawsData}
            columns={[
              {
                header: 'Lottery',
                accessor: 'game.name',
                id: 'game.id',
                type: 'event',
                linkto: '/lottery/lotteries',
                roles: [userRoles.superAdmin, userRoles.admin],
              },
              {
                header: 'Brand',
                accessor: 'game.brand.name',
                id: 'game.brand.id',
                type: 'event',
                linkto: '/brands',
              },
              {
                header: 'Supplier',
                accessor: 'game.lottery.name',
                type: 'string',
              },
              {
                header: 'Lottery struct',
                accessor: 'game.lottery.structure.name',
                type: 'string',
              },
              {
                header: 'Status',
                accessor: 'status',
                type: 'string',
              },
              {
                header: 'Selections',
                accessor: 'wonBetSelection.asArray',
                type: 'drawSelection',
              },
              {
                header: 'Draw date',
                accessor: 'drawDatetime',
                secondAccessor: 'game.timezone',
                type: 'date',
              },
              {
                header: '',
                accessor: 'drawDatetime',
                type: 'countdown',
              },
              // {
              //   header: '',
              //   id: 'id',
              //   type: 'event',
              //   linkto: '/draws/bets',
              //   icon: 'list',
              // },
              // {
              //   header: '',
              //   id: 'id',
              //   type: 'event',
              //   linkto: '/draws',
              //   icon: 'edit',
              // },
              // {
              //   header: '',
              //   id: 'id',
              //   type: 'accept',
              //   icon: 'check',
              //   handler: this.handleModalToggle,
              // },
              {
                header: '',
                id: 'id',
                type: 'icons',
                elements: [
                  // {
                  //   header: '',
                  //   id: 'id',
                  //   type: 'event',
                  //   linkto: '/draws/bets',
                  //   icon: 'list',
                  // },
                  {
                    header: '',
                    id: 'id',
                    type: 'event',
                    linkto: '/draws',
                    icon: 'info',
                  },
                  {
                    header: '',
                    id: 'promotion.id',
                    type: 'event',
                    linkto: '/promotions/promotion',
                    icon: 'dollar-sign',
                    tooltip: 'Link to Promotion',
                  },
                  {
                    header: '',
                    id: 'id',
                    type: 'accept',
                    icon: 'check',
                    roles: [userRoles.superAdmin, userRoles.resultsOperator, userRoles.brand],
                    handler: this.handleToggleResultsModal,
                    secondaryHandler: this.handleToggleResetResultModal,
                    user,
                  },
                ],
              },
              // {
              //   header: 'Actions',
              //   id: 'game.id',
              //   type: 'dropdown',
              //   dropdownElements: {
              //     1: {
              //       handler: this.confirmDrawResult.bind(this),
              //       value: 'Confirm result',
              //       icon: 'check',
              //     },
              //     2: {
              //       handler: this.changeDrawResult.bind(this),
              //       data: 'wonBetSelection',
              //       value: 'Change draw result',
              //       icon: 'upload',
              //     },
              //   }
              // }
            ]}
          />
        )}
        {isResultModalOpen && singleDraw && (
          <ResultConfirmationModal
            isOpen={isResultModalOpen}
            toggleModal={this.handleToggleResultsModal}
            lotteryName={singleDraw.game.lottery.name}
            drawDate={singleDraw.drawDatetime}
            data={singleDraw.game.selectionsStructures.winning}
            userData={singleDraw.candidateSelectionData}
            drawResultsData={drawResults}
            onFormSubmit={this.handlePostResults}
            onDoneClick={this.handleResultsDoneClick}
            errorMessage="Selection structure not found. Please configure it Draw settings page."
          />
        )}

        {isResetResultModalOpen && (
          <ResultResetModal
            isOpen={isResetResultModalOpen}
            toggleModal={this.handleToggleResetResultModal}
            lotteryName={singleDraw.game.lottery.name}
            drawDate={singleDraw.drawDatetime}
            resetStatus={drawResults.reset}
            onResetClick={this.handleClearResultsClick}
          />
        )}

        <CustomModal
          isOpen={this.state.isConfirmModalOpen}
          toggleModal={this.handleModalToggle}
          handleSubmit={this.confirmDrawResult}
          reqSuccess={this.state.confirmSuccess}
          reqError={this.state.confirmError}
          successMessage="Draw has been confirmed"
          title="Are you sure you want to confirm this?"
          classname="react-modal-custom__delete"
          statusColor="green"
          btnConfirmText="Confirm"
          btnConfirmColor="success"
        />
      </AccessControl>
    );
  }
}

Draws.propTypes = {
  getDraws: PropTypes.func,
  drawsData: PropTypes.array,
  drawsPending: PropTypes.bool,
  drawsHeaders: PropTypes.object,
  drawsError: PropTypes.object,
  changeEntries: PropTypes.func,
  deleteBrand: PropTypes.func,
  changeSearchTerm: PropTypes.func,
  deleteDraw: PropTypes.func,
  changeLotteryType: PropTypes.func,
  changeParentBrand: PropTypes.func,
  changeShowFutureDraws: PropTypes.func,
  changeShowNotProcessedDraws: PropTypes.func,
  clearDrawsFilters: PropTypes.func,
  user: PropTypes.object,
  postResults: PropTypes.func,
  resetResultsData: PropTypes.func,
  drawResults: PropTypes.object,
  deleteResults: PropTypes.func,
  filterStatus: PropTypes.object,
  onPaginationPageChange: PropTypes.func,
  pagiNumber: PropTypes.number,
};

export default connect(
  (state) => ({
    drawsError: state.draws.error,
    drawsPending: state.draws.isPending,
    drawsData: state.draws.data,
    drawsHeaders: state.draws.headers,
    drawResults: state.drawResults,
    user: state.user,
    filterStatus: DrawsSelectors.getFilterStatus(state),
  }),
  {
    getDraws,
    deleteDraw,
    changeEntries,
    changeSearchTerm,
    changeLotteryType,
    changeParentBrand,
    changeShowFutureDraws,
    changeShowNotProcessedDraws,
    clearDrawsFilters,
    postResults,
    resetResultsData,
    deleteResults,
  }
)(withPageConfig(DRAWS_PAGE)(Draws));
