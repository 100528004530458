import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button } from 'tabler-react';
import { Card } from 'tabler-react';

import { ErrorHandler } from '../Reusable/ErrorHandler';
import httpClient from '../../services/httpClient';
import { SearchBrand, Field, CheckboxField } from '../Reusable/FormFields';
import Spinner from '../Reusable/Spinner/Spinner';

import './Brands.scss';

class AddBrand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addBrandSuccess: false,
      addBrandError: null,
    };
  }

  validateAddBrand(values) {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.name) {
      errors.name = 'Required';
    }

    return errors;
  }

  submitAddBrand(values, actions) {
    const { brandId, getBrand } = this.props;

    this.setState({
      addBrandSuccess: false,
      addBrandError: null,
    });

    actions.setSubmitting(true);

    const newBrandValue = {
      name: values.name,
      active: values.active,
      email: values.email,
      parentBrand: values.brand
        ? {
            id: values.brand.id,
          }
        : null,
    };

    if (brandId) {
      return httpClient
        .put(`/api/v1/brands/${brandId}`, newBrandValue)
        .then(() => {
          actions.resetForm();
          this.setState(
            {
              addBrandSuccess: true,
              addBrandError: null,
            },
            () => {
              getBrand(brandId);
            }
          );
        })
        .catch((err) => {
          actions.setSubmitting(false);
          this.setState({
            addBrandSuccess: false,
            addBrandError: err.response,
          });
        });
    }

    return httpClient
      .post('/api/v1/brands', newBrandValue)
      .then(() => {
        actions.resetForm();
        this.setState({
          addBrandSuccess: true,
          addBrandError: null,
        });
      })
      .catch((err) => {
        actions.setSubmitting(false);
        this.setState({
          addBrandSuccess: false,
          addBrandError: err.response,
        });
      });
  }

  renderBrandsForm() {
    const { addBrandError, addBrandSuccess } = this.state;
    const { brand, isPending, error, brandId } = this.props;

    if (isPending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    if (error) {
      return (
        <div className="mt-3 mr-3 ml-3">
          <ErrorHandler error={error} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Card.Title>
            {brand && `Brand: ${brand.name}`}
            {!brand && 'Add new brand'}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {addBrandError && <ErrorHandler error={addBrandError} />}
          {addBrandSuccess && !brand && <div className="alert alert-success">Brand added successfully</div>}
          {addBrandSuccess && brand && <div className="alert alert-success">Brand edited successfully</div>}
          <Formik
            initialValues={{
              name: brandId && brand ? brand.name : '',
              email: brandId && brand ? brand.email : '',
              brand: brandId && brand ? brand.parentBrand : '',
              active: brandId && brand ? brand.active : false,
            }}
            validate={(values) => this.validateAddBrand(values)}
            onSubmit={(values, actions) => this.submitAddBrand(values, actions)}
            render={({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, setFieldTouched }) => (
              <form className="add-brand-form" onSubmit={handleSubmit}>
                <Field
                  label="Brand name"
                  type="text"
                  name="name"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('name', true)}
                  value={values.name}
                  disabled={false}
                  touched={touched.name}
                  error={errors.name}
                />
                <Field
                  label="Email"
                  type="text"
                  name="email"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('email', true)}
                  value={values.email}
                  disabled={false}
                  touched={touched.email}
                  error={errors.email}
                />
                <div className="form-group">
                  <SearchBrand
                    value={values.brand}
                    setFieldValue={setFieldValue}
                    handleBlur={() => setFieldTouched('brand', true)}
                    className={classNames('react-select', {
                      'react-select-is-invalid': touched.brand && errors.brand,
                    })}
                  />
                  {touched.brand && errors.brand && <div className="invalid-feedback">{errors.brand}</div>}
                </div>
                <CheckboxField value={values.active} onChange={setFieldValue} name="active" label="Active" />
                <div className="d-flex justify-content-between align-items-center">
                  <Button loading={isSubmitting} type="submit" color="primary">
                    Submit
                  </Button>
                </div>
              </form>
            )}
          />
        </Card.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        {this.renderBrandsForm()}
      </Card>
    );
  }
}

AddBrand.propTypes = {
  getBrand: PropTypes.func,
  brandId: PropTypes.string,
  brand: PropTypes.object,
  isPending: PropTypes.bool,
  error: PropTypes.object,
  setSubmitting: PropTypes.func,
};

export default AddBrand;
