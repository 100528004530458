export const TOLA = 'tola';

export const TOLA_COLUMNS = [
  {
    header: 'Name',
    type: 'string',
    accessor: 'name',
  },
  {
    header: 'User',
    type: 'string',
    accessor: 'authUser',
  },
  {
    header: 'Disbursements',
    type: 'string',
    accessor: 'paybillForDisbursements',
  },
  {
    header: 'Lodgements',
    type: 'string',
    accessor: 'paybillForLodgements',
  },
  {
    header: 'URL',
    type: 'string',
    accessor: 'url',
  },
  {
    header: 'Proxy URL',
    type: 'string',
    accessor: 'proxy',
  },
  // {
  //   header: 'SSL',
  //   type: 'boolean',
  //   accessor: 'verifySSL',
  // },
  // {
  //   header: 'Auth',
  //   type: 'boolean',
  //   accessor: 'enableBasicAuth',
  // },
  {
    header: 'Actions',
    accessor: 'actions',
    type: 'actions',
    id: 'id',
    elements: [
      {
        header: 'Actions',
        type: 'edit',
        linkto: `/providers/${TOLA}/:id`,
        txt: 'Edit',
      },
    ],
  },
];

export const TOLA_FORM_CONFIG = [
  {
    name: 'name',
    title: 'Name',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'authUser',
    title: 'User',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'authPassword',
    title: 'Password',
    type: 'password',
    isRequired: true,
    minLength: 0,
    maxLength: null,
  },
  {
    name: 'paybillForDisbursements',
    title: 'Disbursements',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'paybillForLodgements',
    title: 'Lodgements',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'secret',
    title: 'Secret',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'url',
    title: 'URL',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'proxy',
    title: 'Proxy url',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'verifySSL',
    title: 'Enable Verify SSL',
    type: 'bool',
    isRequired: true,
  },
  {
    name: 'enableBasicAuth',
    title: 'Enable Basic Auth',
    type: 'bool',
    isRequired: true,
  },
];
