import { PROVIDER_DRAWS, PROVIDER_DRAW } from './types';

export const getProviderDraws = (page = 1, perPage = 25) => ({
  type: PROVIDER_DRAWS.FETCH.START,
  payload: {
    page,
    perPage,
  },
});

export const getDrawsForProviderDraw = (id) => ({
  type: PROVIDER_DRAWS.GAME_DRAWS.FETCH.START,
  payload: {
    id,
  },
});

export const getSingleProviderDraw = (id) => ({
  type: PROVIDER_DRAW.FETCH.START,
  payload: {
    id,
  },
});

export const changeDateFrom = (dateFrom) => ({
  type: PROVIDER_DRAWS.FILTERS.DATE_FROM,
  payload: dateFrom,
});

export const changeDateTo = (dateTo) => ({
  type: PROVIDER_DRAWS.FILTERS.DATE_TO,
  payload: dateTo,
});

export const changeStatus = (status) => ({
  type: PROVIDER_DRAWS.FILTERS.STATUS,
  payload: status,
});

export const changeType = (type) => ({
  type: PROVIDER_DRAWS.FILTERS.TYPE,
  payload: type,
});

export const changeProvider = (provider) => ({
  type: PROVIDER_DRAWS.FILTERS.PROVIDER,
  payload: provider,
});

export const changeHideFutureDraws = (hideFutureDraws) => ({
  type: PROVIDER_DRAWS.FILTERS.HIDE_FUTURE_DRAWS,
  payload: hideFutureDraws,
});

export const changeHideProccessedDraws = (hideProccessedDraws) => ({
  type: PROVIDER_DRAWS.FILTERS.HIDE_PROCESSED_DRAWS,
  payload: hideProccessedDraws,
});

export const resetFilters = () => ({
  type: PROVIDER_DRAWS.FILTERS.RESET,
});
