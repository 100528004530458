import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import { getMe } from '../../actions/authActions';
import { resetCurrentUser } from '../../actions/authActions';

class PrivateRoute extends React.Component {
  render() {
    const isAuthenticated = AuthService.isLogged();
    const { component, ...rest } = this.props;
    const Component = component;

    if (isAuthenticated && rest.userError) {
      AuthService.logout();
      rest.resetCurrentUser();
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }

    if (isAuthenticated) {
      AuthService.setHeaders();
    }

    if (isAuthenticated && !rest.user) {
      rest.getMe();
      return null;
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  location: PropTypes.object,
};

export default connect(
  (state) => ({
    user: state.user.data,
    userError: state.user.isError,
  }),
  {
    getMe,
    resetCurrentUser,
  }
)(PrivateRoute);
