import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Page, Card } from 'tabler-react';

import Dater from './../../Reusable/Dater/Dater';
import { Field } from '../../Reusable/FormFields';
import { ErrorHandler } from '../../Reusable/ErrorHandler';
import LabeledValue from '../../Reusable/LabeledValue';
import TicketLines from './TicketLines';

import { setProperMoneyValue } from '../../../utils/money';
import getSelections from '../../../utils/selections/selections';

import './Bet.scss';

class Bet extends React.Component {
  getResults() {
    const { bet } = this.props;
    const draw = bet.data.gameDraw;

    if (!draw.wonBetLineSelection) {
      return 'Results not yet available';
    }

    return getSelections(draw.wonBetLineSelection.asArray);
  }

  renderBet(bet) {
    const {
      cachedStats: { totalSystemCost, totalRealCost, totalRealWinnings, totalRealWithholdingTax },
      id,
      brand,
      status,
      player,
    } = this.props.bet.data;
    const { isDefaultType } = this.props;
    const draw = bet.gameDraw;
    const game = isDefaultType ? draw.game : bet.game;

    return (
      <React.Fragment>
        <Card.Body>
          <Formik
            initialValues={{
              id: id,
              lotteryId: game?.id,
              brandName: brand.name,
              gameDrawId: draw?.id,
              status: status,
              playerId: player.id,
              totalSystemCost: setProperMoneyValue(totalSystemCost),
              totalRealCost: setProperMoneyValue(totalRealCost),
              totalRealWinnings: setProperMoneyValue(totalRealWinnings),
              totalRealWithholdingTax: setProperMoneyValue(totalRealWithholdingTax),
            }}
            render={({ values }) => (
              <form className="add-brand-form">
                <Field
                  value={values.id}
                  name="id"
                  label="Id"
                  readonly={true}
                  hint="Internal ID of bet in ILS database"
                />
                <Field
                  value={values.lotteryId}
                  name="lotteryId"
                  label="Lottery id"
                  link={`/lottery/lotteries/${values.lotteryId}`}
                  readonly={true}
                  hint="ID of the lottery"
                />
                <Field value={values.brandName} name="brandName" label="Brand" readonly={true} hint="Brand name" />
                {values.gameDrawId && (
                  <Field
                    value={values.gameDrawId}
                    name="gameDrawId"
                    label="Game draw id"
                    link={`/draws/${values.gameDrawId}`}
                    readonly={true}
                    hint="ID of the draw for which ticket was submitted"
                  />
                )}
                <label className="mainLabel">Confirmed at</label>
                <div className="fieldWrapper">
                  <Dater time={bet.confirmedAt} />
                </div>
                <label className="hint">Time of Bet purchase confirmation in Betting Agency.</label>
                <label className="mainLabel">Betting agency notified at</label>
                <div className="fieldWrapper">
                  <Dater time={bet.bettingAganecyNotifiedAt} />
                </div>
                <label className="hint">
                  Time at which information about win/loss of this bet was sent to the betting agency. (For some
                  Lotteries this also is the time of notifying insurance agency about winning).
                </label>
                <Field
                  value={values.playerId}
                  name="playerId"
                  label="Player id"
                  link={`/players/${values.playerId}`}
                  readonly={true}
                  hint="ID of the player in ILS database."
                />
                <Field value={values.status} name="status" label="Status" readonly={true} />
                {/* hint="Current status of the bet."  */}
                <label className="hint">{bet ? bet.selfExplanatoryStatus : ''}</label>

                <label className="mainLabel">Cancelled at</label>
                <div className="fieldWrapper">
                  <Dater time={bet.cancelledAt} />
                </div>
                <label className="hint">Time of bet cancellation.</label>
                <label className="mainLabel">Rejected at</label>
                <div className="fieldWrapper">
                  <Dater time={bet.rejectedAt} />
                </div>
                <label className="hint">Time of bet rejection.</label>
                <label className="mainLabel">Insured at</label>
                <div className="fieldWrapper">
                  <Dater time={bet.insuredAt} />
                </div>
                <label className="hint">
                  A time when the bet was submitted to an insurance agency. For some lotteries (like real machines) this
                  is real machine owner.
                </label>
                <Field
                  value={values.totalSystemCost}
                  name="systemCost"
                  label="Total system Cost"
                  readonly={true}
                  hint="Cost of bet in system currency (calculated using exchange rates)."
                />
                <Field
                  value={values.totalRealCost}
                  name="totalRealCost"
                  label="Total real cost"
                  readonly={true}
                  hint="Cost of bet in original transaction currency."
                />
                <Field
                  value={values.totalRealWinnings}
                  name="totalRealWinnings"
                  label="Total wins ammount"
                  readonly={true}
                  hint="Amount of money won in this bet, in Jackpot currency."
                />
                <Field
                  value={values.totalRealWithholdingTax}
                  name="totalRealWithholdingTax"
                  label="Total withholding tax"
                  readonly={true}
                  hint=""
                />
                <label className="mainLabel">Winning confirmed at</label>
                <div className="fieldWrapper">
                  <Dater time={bet.winningConfirmedAt} />
                </div>
                <label className="hint">
                  Time at which win/lost information of this bet was confirmed by the system (either from 3rd party APIs
                  or manually)
                </label>
                <label className="mainLabel">Payment sent at</label>
                <div className="fieldWrapper">
                  <Dater time={bet.paymentNotifiedAt} />
                </div>
                <label className="hint">
                  Time of payment confirmation sent to the game provider and/or betting agency
                </label>
              </form>
            )}
          />
        </Card.Body>
      </React.Fragment>
    );
  }

  renderDrawDetails() {
    const { bet } = this.props;
    const draw = bet.data.gameDraw;

    if (!draw) {
      return null;
    }

    return (
      <React.Fragment>
        <Page.Header>
          <Page.Title>Draw Details</Page.Title>
        </Page.Header>
        <Card>
          <Card.Status color="blue" />
          <Card.Body>
            <LabeledValue value={draw.game.name} label="Game Name" />
            <LabeledValue value={<Dater time={draw.originaldrawDatetime} />} label="Draw Date" />
            <LabeledValue value={this.getResults()} label="Draw Results" />
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }

  renderLines() {
    const { bet, lines, getBetLines, perms, id, isDefaultType } = this.props;
    const betData = bet.data;
    const properLines = isDefaultType ? lines : betData.lines;

    return (
      <TicketLines
        cachedStats={betData.cachedStats}
        lineCost={betData.realLineCost}
        stake={betData.stake}
        lines={properLines}
        isDefaultBet={isDefaultType}
        {...{ perms, getBetLines, id }}
      />
    );
  }

  render() {
    const { bet, pageTitle } = this.props;
    const betData = bet.data;

    if (bet.error) {
      return (
        <div className="bet__error">
          <ErrorHandler error={bet.error} />
        </div>
      );
    }

    if (!betData) {
      return null;
    }

    return (
      <div className="bet__flex">
        <div className="flex--item">
          <Page.Header>
            <Page.Title>{pageTitle}</Page.Title>
          </Page.Header>
          <Card>
            <Card.Status color="blue" />
            {this.renderBet(betData)}
          </Card>
        </div>
        <div className="flex--item">
          {this.renderDrawDetails()}
          {this.renderLines()}
        </div>
      </div>
    );
  }
}

Bet.propTypes = {
  isDefaultType: PropTypes.bool,
  pageTitle: PropTypes.string,
  id: PropTypes.string,
  bet: PropTypes.object,
  lines: PropTypes.object,
  perms: PropTypes.object,
  getBetLines: PropTypes.func,
  draw: PropTypes.object,
  game: PropTypes.object,
};

export default Bet;
