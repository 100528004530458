import { LOGIN, LOGOUT, GET_CURRENT_USER, AUTH, ACCOUNT } from './types';

export const login = (data) => ({
  type: LOGIN.START,
  payload: data,
});

export const saveToken = (data) => ({
  type: AUTH.GET,
  payload: data,
});

export const logout = () => ({
  type: LOGOUT.START,
});

export const cancelCurrentLogin = () => ({
  type: LOGIN.CANCEL_CURRENT,
});

export const resetCurrentUser = () => ({
  type: GET_CURRENT_USER.CANCEL_CURRENT,
});

export const getMe = () => ({
  type: GET_CURRENT_USER.START,
});

export const setNewPassword = (data) => ({
  type: ACCOUNT.SETNEWPASSWORD.START,
  payload: data,
});

export const confirmEmail = (token) => ({
  type: ACCOUNT.ACTIVATE.START,
  payload: token,
});

export const resetPassword = (email) => ({
  type: ACCOUNT.RESET.START,
  payload: email,
});

export const resetPasswordState = () => ({
  type: ACCOUNT.RESET.RESET,
});
