import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Page } from 'tabler-react';
import { getBackendUser } from '../../../actions/backendUsersActions';

import { AddUser } from '../../Users/index';

import AccessControl from '../../AccessControl';
import { userRoles } from '../../../utils/roles';

class BackendUser extends React.Component {
  componentDidMount() {
    const userId = this.props.match && this.props.match.params.id ? this.props.match.params.id : null;

    this.props.getBackendUser(userId);
  }

  render() {
    const userId = this.props.match && this.props.match.params.id ? this.props.match.params.id : null;
    const { error, data, isPending, getBackendUser } = this.props;

    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin]} forbiddenRedirect={'/'}>
        <Page>
          <Page.Header>
            <Page.Title>Backend user</Page.Title>
          </Page.Header>
          <Grid.Row>
            <Grid.Col md={6}>
              <AddUser
                user={data}
                error={error}
                isPending={isPending}
                userId={userId}
                getBackendUser={getBackendUser}
              />
            </Grid.Col>
          </Grid.Row>
        </Page>
      </AccessControl>
    );
  }
}

BackendUser.propTypes = {
  match: PropTypes.object,
  data: PropTypes.object,
  isPending: PropTypes.bool,
  error: PropTypes.object,
  getBackendUser: PropTypes.func,
};

export default connect(
  (state) => ({
    isPending: state.backendUser.isPending,
    error: state.backendUser.error,
    data: state.backendUser.data,
  }),
  {
    getBackendUser,
  }
)(BackendUser);
