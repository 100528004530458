import React from 'react';
import PropTypes from 'prop-types';
import { Page, Card, TabbedCard, Tab } from 'tabler-react';

import BetList from '../../BetList/BetList';
import LabeledValue from '../../Reusable/LabeledValue';

import './Bet.scss';

class TicketLines extends React.Component {
  render() {
    const { cachedStats, stake, lineCost, lines, getBetLines, perms, id, isDefaultBet } = this.props;
    const properLines = isDefaultBet ? lines.data : lines;

    return (
      <React.Fragment>
        <Page.Header>
          <Page.Title>Lines</Page.Title>
        </Page.Header>
        <Card>
          <Card.Status color="blue" />
          <Card.Body>
            <LabeledValue
              className="stake"
              value={`${lineCost.amount / 100} ${lineCost.currency} ${stake ? `(${stake}%)` : ''}`}
              label={'Per line stake'}
            />
            <LabeledValue className="stake" value={cachedStats.linesCount} label={'Lines count'} />
            <TabbedCard initialTab={'All lines'} className="mx-0 px-0">
              <Tab title={'All lines'} className="mx-0 px-0">
                <BetList
                  {...{ id }}
                  isDefaultBet={isDefaultBet}
                  data={properLines}
                  error={lines.error}
                  headers={lines.headers}
                  pending={lines.isPending}
                  asyncAction={getBetLines}
                />
              </Tab>
              <Tab title={'Permutations'}>
                <BetList
                  {...{ id }}
                  data={perms.data}
                  error={perms.error}
                  headers={perms.headers}
                  pending={perms.isPending}
                  asyncAction={getBetLines}
                />
              </Tab>
            </TabbedCard>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

TicketLines.propTypes = {
  id: PropTypes.string,
  lines: PropTypes.object,
  perms: PropTypes.object,
  getBetLines: PropTypes.func,

  lineCost: PropTypes.object,
  stake: PropTypes.number,
  cachedStats: PropTypes.object,
  isDefaultBet: PropTypes.bool,
};

export default TicketLines;
