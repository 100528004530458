import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page, Button, Alert } from 'tabler-react';
import { Link } from 'react-router-dom';

import { ErrorHandler } from '../../Reusable/ErrorHandler/index';
import CustomTable from '../../Reusable/Table/index';
import Spinner from '../../Reusable/Spinner/Spinner';
import CustomModal from '../../Reusable/Modal/CustomModal';

import withPageConfig from '../../hoc/withPageConfig';

import {
  getBackendUsers,
  deleteBackendUser,
  changeEntries,
  changeSearchTerm,
} from '../../../actions/backendUsersActions';

import httpClient from '../../../services/httpClient';
import AccessControl from '../../AccessControl';

import { userRoles } from '../../../utils/roles';
import { BACKEND_USERS_PAGE } from '../../../utils/pagesConstans';

class BackendUsers extends React.Component {
  constructor() {
    super();

    this.state = {
      isDeleteModalOpen: false,
      deleteId: undefined,
      confirmSuccess: false,
      confirmError: null,
    };
  }

  componentDidMount() {
    const { pagiNumber, getBackendUsers } = this.props;

    getBackendUsers(pagiNumber);
  }

  toggleDeleteModal = (id) => {
    this.setState((prevState) => ({
      isDeleteModalOpen: !prevState.isDeleteModalOpen,
      deleteId: id,
      // confirmSuccess: false,
      // confirmError: null,
    }));
  };

  handlePageChange = (pageNumber) => {
    const { getBackendUsers, onPaginationPageChange } = this.props;

    getBackendUsers(pageNumber);
    onPaginationPageChange(pageNumber);
  };

  handleChangeEntriesNumber = (pageNumber, entries) => {
    this.props.changeEntries(entries);
    this.props.getBackendUsers(1);
  };

  handleDeleteItem = () => {
    httpClient
      .delete(`/api/v1/users/${this.state.deleteId}`)
      .then(() => {
        this.setState(
          {
            ...this.state,
            confirmSuccess: true,
            confirmError: null,
          },
          () => {
            this.props.getBackendUsers(1);
            this.toggleDeleteModal(null);
          }
        );
      })
      .catch((err) => {
        this.setState(
          {
            ...this.state,
            confirmSuccess: false,
            confirmError: err.response,
          },
          () => this.toggleDeleteModal(null)
        );
      });
  };

  handlePhraseSearch = (phrase) => {
    this.props.getBackendUsers(1, phrase);
  };

  render() {
    const { backendUsersData, backendUsersPending, backendUsersError, backendUsersHeaders } = this.props;

    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin]} forbiddenRedirect={'/'}>
        <div>
          <Page.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Page.Title>
                <span>Backend users</span>
              </Page.Title>
              <Link to="/backend-users/add">
                <Button color="primary">Add backend user</Button>
              </Link>
            </div>
          </Page.Header>
          {this.state.confirmSuccess && (
            <Alert type="success" isDismissible>
              User has been deleted
            </Alert>
          )}
          {this.state.confirmError && (
            <Alert type="danger" isDismissible>
              {this.state.confirmError.data.message}
            </Alert>
          )}
          {backendUsersError && <ErrorHandler error={backendUsersError} />}
          {backendUsersPending && !backendUsersHeaders && <Spinner />}
          {backendUsersHeaders && (
            <CustomTable
              onChangeEntries={this.handleChangeEntriesNumber}
              onPageChanged={this.handlePageChange}
              onPhraseSearched={this.handlePhraseSearch}
              handleSearchTermChange={this.props.changeSearchTerm}
              paginHeaders={backendUsersHeaders}
              loading={backendUsersPending}
              data={backendUsersData}
              deleteHandler={this.handleDeleteItem}
              columns={[
                {
                  header: 'First Name',
                  accessor: 'firstName',
                  id: 'id',
                  linkto: '/backend-users',
                  type: 'event',
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
                {
                  header: 'Last Name',
                  accessor: 'lastName',
                  id: 'id',
                  linkto: '/backend-users',
                  type: 'event',
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
                {
                  header: 'Role',
                  accessor: 'roles',
                  id: 'id',
                  type: 'roles',
                },
                {
                  header: 'E-mail',
                  accessor: 'email',
                  type: 'string',
                },
                {
                  header: 'Phone',
                  accessor: 'phone',
                  type: 'string',
                },
                {
                  header: 'Active',
                  accessor: 'active',
                  type: 'boolean',
                },
                {
                  header: 'Brands',
                  id: 'brands',
                  type: 'brands',
                  accessor: 'brands',
                },
                {
                  header: '',
                  id: 'id',
                  accessor: 'delete',
                  type: 'delete',
                  handler: this.toggleDeleteModal,
                  roles: [userRoles.superAdmin],
                },
              ]}
            />
          )}

          <CustomModal
            isOpen={this.state.isDeleteModalOpen}
            toggleModal={this.toggleDeleteModal}
            handleSubmit={this.handleDeleteItem}
            reqSuccess={this.state.confirmSuccess}
            reqError={this.state.confirmError}
            successMessage="User has been deleted"
            title="Are you sure you want to delete this?"
            classname="react-modal-custom__delete"
            statusColor="red"
            btnConfirmText="Delete"
            icon="trash"
          />
        </div>
      </AccessControl>
    );
  }
}

BackendUsers.propTypes = {
  getBackendUsers: PropTypes.func,
  changeEntries: PropTypes.func,
  deleteBackendUser: PropTypes.func,
  backendUsersData: PropTypes.array,
  backendUsersPending: PropTypes.bool,
  backendUsersError: PropTypes.object,
  backendUsersHeaders: PropTypes.object,
  changeSearchTerm: PropTypes.func,
  onPaginationPageChange: PropTypes.func,
  pagiNumber: PropTypes.number,
};

export default connect(
  (state) => ({
    backendUsersError: state.backendUsers.error,
    backendUsersPending: state.backendUsers.isPending,
    backendUsersData: state.backendUsers.data,
    backendUsersHeaders: state.backendUsers.headers,
  }),
  {
    getBackendUsers,
    deleteBackendUser,
    changeSearchTerm,
    changeEntries,
  }
)(withPageConfig(BACKEND_USERS_PAGE)(BackendUsers));
