import React from 'react';
import propTypes from 'prop-types';
import { Card, Grid, Page, Button } from 'tabler-react';

import AccessControl from '../../AccessControl';
import DrawPrizes from '../../Draws/DrawPrizes';
import { Field } from '../../Reusable/FormFields';

import { userRoles } from '../../../utils/roles';
import { getMoneyWithCurrency } from '../../../utils/money';
import httpClient from '../../../services/httpClient';

class PrizesTab extends React.Component {
  renderSalesContent() {
    const { draw } = this.props;

    if (!draw.possiblePrizes) {
      return null;
    }

    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand]}>
        <Card>
          <Card.Status color="blue" />
          <Card.Body>
            <h3>Sales Sum</h3>
            <Field label={'Sales sum prize pool percentage'} value={draw.salesSumPrizePoolPercentage} readonly />
            <Field label={'Sales sum'} value={getMoneyWithCurrency(draw.salesSum)} readonly />
          </Card.Body>
        </Card>
      </AccessControl>
    );
  }

  renderContent() {
    const { draw } = this.props;

    if (!draw.possiblePrizes) {
      return null;
    }

    return (
      <AccessControl roles={[userRoles.superAdmin, userRoles.admin, userRoles.brand]}>
        <DrawPrizes prizes={draw.possiblePrizes} />
      </AccessControl>
    );
  }

  handleCopyGamePrizesToDraw = async () => {
    try {
      if (!confirm('Are you sure you want to copy the game prizes to draw?')) {
        return false;
      }

      const { draw } = this.props;

      //fetch the lottery
      const objResponseResult = await httpClient.put(
        `/api/v1/game/${draw.game.id}/draws/${draw.id}/prizes/copy-from-game`
      );
      if (objResponseResult.status == 200) {
        alert('Game prizes copied successfully!');
        window.location.reload();
      } else {
        alert(objResponseResult.message);
      }
    } catch (e) {
      if (e.response && e.response.data) {
        alert(JSON.stringify(e.response.data));
      }
    }
  };

  isDrawOpen = () => {
    const { draw } = this.props;
    const drawCloseDateTime = new Date(draw.closeDatetime);
    const currentDateTime = new Date();
    if (currentDateTime < drawCloseDateTime) return true;
    return false;
  };

  render() {
    return (
      <Page>
        <Page.Header>
          <div className="d-flex justify-content-between w-100">
            <Page.Title>Prizes</Page.Title>
            <AccessControl roles={[userRoles.superAdmin]}>
              {this.isDrawOpen() ? (
                <div>
                  <Button className="draw-protocol-btn mx-5" color="primary" onClick={this.handleCopyGamePrizesToDraw}>
                    Copy game prizes to draw
                  </Button>
                </div>
              ) : (
                ''
              )}
            </AccessControl>
          </div>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={6}>{this.renderContent()}</Grid.Col>
          <Grid.Col md={6}>{this.renderSalesContent()}</Grid.Col>
        </Grid.Row>
      </Page>
    );
  }
}

PrizesTab.propTypes = {
  draw: propTypes.object,
};

export default PrizesTab;
