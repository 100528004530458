import { createLogic } from 'redux-logic';

import { PROVIDER_DRAWS, PROVIDER_DRAW } from '../actions/types';

import formatDate from '../services/TimeFilterFormatter';

export const getProviderDraws = createLogic({
  type: PROVIDER_DRAWS.FETCH.START,
  latest: true,

  process({ httpClient, action, getState }, dispatch, done) {
    const { page, perPage } = action.payload;
    const filters = getState().providerDrawFilters;

    const params = {
      page,
      perPage,
      'search[withoutFuture][withoutFuture]': filters.hideFutureDraws,
      'search[processed][ignoreProcessed]': filters.hideProcessedDraws,
      'search[gameResultAgencyConfig][uuid]': filters.provider ? filters.provider.id : null,
      'search[configurationType][value]': filters.type ? filters.type.value : null,
      'search[status][value]': filters.status ? filters.status.value : null,
      'search[drawTime][datetime-range][after]': filters.dateFrom ? formatDate(filters.dateFrom) : null,
      'search[drawTime][datetime-range][before]': filters.dateTo ? formatDate(filters.dateTo, 'to') : null,
    };

    return httpClient
      .get('/api/v1/provider-draw', { params })
      .then((res) => {
        dispatch({
          type: PROVIDER_DRAWS.FETCH.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROVIDER_DRAWS.FETCH.ERROR,
          payload: e,
        });
      })
      .then(done);
  },
});

export const getGameDraws = createLogic({
  type: PROVIDER_DRAWS.GAME_DRAWS.FETCH.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .get(`/api/v1/provider-draw/${id}/draws`)
      .then((res) => {
        dispatch({
          type: PROVIDER_DRAWS.GAME_DRAWS.FETCH.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: PROVIDER_DRAWS.GAME_DRAWS.FETCH.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const getSingleProviderDraw = createLogic({
  type: PROVIDER_DRAW.FETCH.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .get(`/api/v1/provider-draw/${id}`)
      .then((res) => {
        dispatch({
          type: PROVIDER_DRAW.FETCH.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: PROVIDER_DRAW.FETCH.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export default {
  getProviderDraws,
  getSingleProviderDraw,
  getGameDraws,
};
