import { BACKEND_USERS, BACKEND_USER } from './types';

export const getBackendUsers = (page, phrase) => ({
  type: BACKEND_USERS.FETCH,
  payload: {
    page,
    phrase,
  },
});

export const deleteBackendUser = (id) => ({
  type: BACKEND_USERS.DELETE_START,
  payload: id,
});

export const changeEntries = (entries) => ({
  type: BACKEND_USERS.CHANGE_ENTRIES,
  payload: entries,
});

export const changeSearchTerm = (term) => ({
  type: BACKEND_USERS.CHANGE_SEARCH_TERM,
  payload: term,
});

export const getBackendUser = (id) => ({
  type: BACKEND_USER.FETCH,
  payload: id,
});
