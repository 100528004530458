import { ACCOUNT } from '../../actions/types';

const initialState = {
  activate: {
    isPending: false,
    success: false,
    error: null,
  },
  setNew: {
    isPending: false,
    success: false,
    error: null,
  },
  reset: {
    isPending: false,
    success: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT.ACTIVATE.START:
      return {
        ...state,
        activate: {
          ...state.activate,
          isPending: true,
        },
      };
    case ACCOUNT.ACTIVATE.SUCCESS:
      return {
        ...state,
        activate: {
          ...state.activate,
          isPending: false,
          success: true,
          error: null,
        },
      };
    case ACCOUNT.ACTIVATE.ERROR:
      return {
        ...state,
        activate: {
          ...state.activate,
          isPending: false,
          error: action.payload,
          success: false,
        },
      };
    case ACCOUNT.ACTIVATE.RESET:
      return {
        ...state,
        activate: initialState.activate,
      };
    case ACCOUNT.RESET.START:
      return {
        ...state,
        reset: {
          ...state.reset,
          isPending: true,
        },
      };
    case ACCOUNT.RESET.SUCCESS:
      return {
        ...state,
        reset: {
          ...state.reset,
          isPending: false,
          success: true,
          error: null,
        },
      };
    case ACCOUNT.RESET.ERROR:
      return {
        ...state,
        reset: {
          ...state.reset,
          isPending: false,
          error: action.payload,
          success: false,
        },
      };
    case ACCOUNT.RESET.RESET:
      return {
        ...state,
        reset: initialState.reset,
      };
    case ACCOUNT.SETNEWPASSWORD.START:
      return {
        ...state,
        setNew: {
          ...state.setNew,
          isPending: true,
        },
      };
    case ACCOUNT.SETNEWPASSWORD.SUCCESS:
      return {
        ...state,
        setNew: {
          ...state.setNew,
          isPending: false,
          success: true,
          error: null,
        },
      };
    case ACCOUNT.SETNEWPASSWORD.ERROR:
      return {
        ...state,
        setNew: {
          ...state.setNew,
          isPending: false,
          error: action.payload,
          success: false,
        },
      };
    case ACCOUNT.SETNEWPASSWORD.RESET:
      return {
        ...state,
        setNew: initialState.setNew,
      };
    default:
      return state;
  }
};
