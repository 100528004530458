import { DRAWS, DRAW, DRAWS_FILTER, DRAWSTATS, DRAW_SUMMARY } from './types';

export const getDraws = (page = 1, phrase) => ({
  type: DRAWS.FETCH,
  payload: {
    page,
    phrase,
  },
});

export const getDrawStats = (id) => ({
  type: DRAWSTATS.FETCH,
  payload: {
    id,
  },
});

export const getDrawSummary = (id) => ({
  type: DRAW_SUMMARY.FETCH.START,
  payload: {
    id,
  },
});

export const deleteDraw = (id) => ({
  type: DRAWS.DELETE_START,
  payload: id,
});

export const changeEntries = (entries) => ({
  type: DRAWS.CHANGE_ENTRIES,
  payload: entries,
});

export const changeSearchTerm = (term) => ({
  type: DRAWS.CHANGE_SEARCH_TERM,
  payload: term,
});

export const getDraw = (id) => ({
  type: DRAW.FETCH,
  payload: id,
});

export const getDrawBets = (id, page = 1, filters) => ({
  type: DRAW.GET_BETS_START,
  payload: {
    id,
    page,
    filters,
  },
});

export const changeBetsEntries = (entries) => ({
  type: DRAW.CHANGE_BETS_ENTRIES,
  payload: entries,
});

export const clearBrand = () => ({
  type: DRAW.CLEAR,
});

export const changeLotteryType = (text) => ({
  type: DRAWS_FILTER.LOTTERY_TYPE,
  payload: text,
});

export const changeParentBrand = (text) => ({
  type: DRAWS_FILTER.PARENT_BRAND,
  payload: text,
});

export const changeShowFutureDraws = (text) => ({
  type: DRAWS_FILTER.FUTURE_DRAWS,
  payload: text,
});

export const changeShowNotProcessedDraws = (text) => ({
  type: DRAWS_FILTER.NOT_PROCESSED,
  payload: text,
});

export const clearDrawsFilters = () => ({
  type: DRAWS_FILTER.CLEAR,
});

export const putNewJackpot = (id, amount, currency) => ({
  type: DRAW.PUT_JACKPOT_START,
  payload: {
    id,
    amount,
    currency,
  },
});
