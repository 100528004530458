import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Alert } from 'tabler-react';
import Modal from 'react-modal';
import moment from 'moment';

class ResultResetModal extends React.Component {
  render() {
    const { isOpen, toggleModal, onResetClick, resetStatus, lotteryName, drawDate } = this.props;

    return (
      <Modal isOpen={isOpen} onRequestClose={() => toggleModal(null)} className={'react-modal-custom h-auto'}>
        <Card>
          <Card.Status color={'blue'} />
          <Card.Header>
            <Card.Title>
              <p className="m-0">Submit Results</p>
              <Button className="react-modal-close" color="danger" onClick={() => toggleModal(null)}>
                X
              </Button>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {resetStatus.isError && (
              <Alert type="danger" icon="alert-triangle">
                {resetStatus.error.message}
              </Alert>
            )}
            {resetStatus.success && <Alert type="success">Reset results successfully</Alert>}
            <div className="d-flex justify-content-center flex-column align-content-center align-items-center">
              <div className="d-flex mb-5 flex-column align-items-center align-content-center">
                <div className="d-flex flex-column justify-content-start w-100">
                  {lotteryName && <p className="font-weight-bold mb-0">{lotteryName}</p>}
                  <p className="font-weight-bold">{moment(drawDate).format('DD-MM-YYYY HH:mm')}</p>
                </div>
                <p className="text-center">
                  Results were already provided by you. If you like to correct them, please clear current results and
                  submit them again
                </p>
                <div className="btn btn-danger" onClick={onResetClick}>
                  Clear results
                </div>
              </div>
              <div className="btn btn-success mt-5" onClick={() => toggleModal(null)}>
                Close
              </div>
            </div>
          </Card.Body>
        </Card>
      </Modal>
    );
  }
}

ResultResetModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  lotteryName: PropTypes.string,
  drawDate: PropTypes.string,
  resetStatus: PropTypes.object,
  onResetClick: PropTypes.func,
};

export default ResultResetModal;
