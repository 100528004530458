import { EXCHANGE_RATES } from './types';

export const getExchangeRates = (id) => ({
  type: EXCHANGE_RATES.GET.START,
  payload: id,
});

export const putExchangeRates = (data, id) => ({
  type: EXCHANGE_RATES.PUT.START,
  payload: {
    data,
    id,
  },
});

export const postExchangeRates = (id, data) => ({
  type: EXCHANGE_RATES.POST.START,
  payload: {
    data,
  },
});

export const deleteExchangeRates = (id, brandId) => ({
  type: EXCHANGE_RATES.DELETE.START,
  payload: {
    id,
    brandId,
  },
});
