export const LOBBIES_PAGE = 'lobbies';
export const LOBBIES_VERSIONS_PAGE = 'lobbiesVersions';
export const BETS_PAGE = 'bets';
export const BRANDS_PAGE = 'brands';
export const OUTBOUND_CHANNELS_PAGE = 'outboundChannels';
export const DRAWS_PAGE = 'draws';
export const PROVIDERS_PAGE = 'providers';
export const REPORTS = 'reports';
export const BACKEND_USERS_PAGE = 'backendUsers';
export const PLAYERS_PAGE = 'players';
export const LOTTERIES_PAGE = 'lotteries';
export const WINNINGS_PAGE = 'winnings';
export const PROVIDER_DRAWS_PAGE = 'providerDraws';
export const PROMOTIONS = 'promotions';
export const TICKETS_PAGE = 'tickets';

const ALL_PAGES = [
  LOBBIES_PAGE,
  LOBBIES_VERSIONS_PAGE,
  BETS_PAGE,
  BRANDS_PAGE,
  OUTBOUND_CHANNELS_PAGE,
  DRAWS_PAGE,
  PROVIDERS_PAGE,
  REPORTS,
  BACKEND_USERS_PAGE,
  PLAYERS_PAGE,
  LOTTERIES_PAGE,
  WINNINGS_PAGE,
  PROVIDER_DRAWS_PAGE,
  PROMOTIONS,
  TICKETS_PAGE,
];

export const initialState = ALL_PAGES.reduce((acc, cur) => {
  return {
    ...acc,
    [cur]: {
      key: '',
      pagiNumber: 1,
    },
  };
}, {});
