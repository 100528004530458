import React from 'react';
import propTypes from 'prop-types';
import { Card, Button } from 'tabler-react';
import { Formik, FieldArray } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';

import { Field, DateField, SearchLottery, CustomSelect, SearchBrand } from '../Reusable/FormFields';

import { buildSelectOptions, makeSelectObject } from '../../utils/select/buildSelectOptions';
import { PROMOTION_TYPES } from '../../utils/constans';
import { objectToObjectArray } from '../../utils/object/object';

import { getLottery } from '../../actions/lotteryActions';
import { getHumanAmount } from '../../utils/money';
import { checkIsDuringPeriod } from '../../utils/date';

class PromotionForm extends React.Component {
  state = {
    isPromotionActive: false,
  };

  componentDidMount() {
    const { isEditMode, getLottery, data } = this.props;

    if (isEditMode) {
      getLottery(data.game.id);
    }

    if (data) {
      this.setState({
        isPromotionActive: checkIsDuringPeriod(data.startDatetime, data.endDatetime),
      });
    }
  }

  getPrizesFromType(type) {
    const { data } = this.props;

    if (type === 'percentage') {
      return objectToObjectArray(data.prizesMultipliers);
    }

    return objectToObjectArray(data.prizesOverride).map((elem) => {
      return {
        ...elem,
        data: {
          ...elem.data,
          amount: getHumanAmount(elem.data.amount),
        },
      };
    });
  }

  getInitialValues() {
    const { data, isEditMode, lottery } = this.props;

    if (!isEditMode || !data) {
      return {
        name: '',
        lottery: '',
        startDate: '',
        endDate: '',
        promotionType: '',
        selectedPrizes: null,
        brand: null,
      };
    }

    return {
      name: data.name,
      lottery: lottery.data,
      startDate: moment(data.startDatetime),
      endDate: moment(data.endDatetime),
      promotionType: makeSelectObject(data.promoType, data.promoType),
      selectedPrizes: buildSelectOptions(this.getPrizesFromType(data.promoType, data), 'name', 'name', true),
      brand: lottery.data?.brand,
    };
  }

  prepareSinglePrize(prize, data) {
    return {
      value: prize.name,
      label: prize.name,
      name: prize.name,
      data,
    };
  }

  preparePrizesList(prizes, promoType, lottery) {
    return prizes.map((elem) => {
      if (typeof elem.data !== 'undefined') {
        return elem;
      }

      if (promoType === 'precise') {
        const data = {
          amount: 0,
          currency: lottery && lottery.currency,
        };

        return this.prepareSinglePrize(elem, data);
      }

      return this.prepareSinglePrize(elem, 0);
    });
  }

  validateForm(values) {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.lottery) {
      errors.lottery = 'Required';
    }

    if (!values.startDate) {
      errors.startDate = 'Required';
    }

    if (!values.endDate) {
      errors.startDate = 'Required';
    }

    if (!values.promotionType) {
      errors.promotionType = 'Required';
    }

    if (!values.selectedPrizes) {
      errors.selectedPrizes = 'Required';
    }

    return errors;
  }

  handleBrandChange(value, setFieldValue) {
    setFieldValue('brand', value);
    setFieldValue('lottery', null);
  }

  handleLotteryChange(value, setFieldValue) {
    setFieldValue('selectedPrizes', []);
    setFieldValue('lottery', value);
  }

  handleChangePreciseValue(name, value, currency, setFieldValue) {
    setFieldValue(`${name}.amount`, value);
    setFieldValue(`${name}.currency`, currency);
  }

  handlePromotionTypeChange(value, setFieldValue) {
    const { isEditMode } = this.props;

    setFieldValue('promotionType', value);

    if (isEditMode) {
      const prizes = buildSelectOptions(objectToObjectArray(this.getPrizesFromType(value.value)), 'name', 'name', true);
      setFieldValue('selectedPrizes', prizes);
      return;
    }

    setFieldValue('selectedPrizes', null);
  }

  renderPrizesSelect(lottery, selectedValue, onChange) {
    if (!lottery || !lottery.possiblePrizes) {
      return null;
    }

    return (
      <CustomSelect
        name="selectedPrizes"
        label="Prizes"
        className="react-select"
        options={lottery.possiblePrizes}
        handleChange={(name, value) => onChange(name, value)}
        value={selectedValue}
        optionLabel="name"
        optionValue="name"
        saveOptionData
        isMulti
        disabled={this.state.isPromotionActive}
      />
    );
  }

  render() {
    const { isEditMode, onDelete, onSubmit } = this.props;
    const { isPromotionActive } = this.state;

    return (
      <React.Fragment>
        <div className="col-md-6">
          <Card>
            <Card.Status color="blue" />
            <Card.Body>
              <Formik
                initialValues={this.getInitialValues()}
                enableReinitialize
                validate={(values) => this.validateForm(values)}
                onSubmit={(values) => onSubmit(values)}
                render={({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => (
                  <form className="add-brand-form drawStyles" onSubmit={handleSubmit}>
                    <Field
                      label="Promotion name"
                      name="name"
                      className="form-control"
                      onChange={setFieldValue}
                      onBlur={() => setFieldTouched('name', true)}
                      value={values.name}
                      touched={touched.name}
                      error={errors.name}
                      type="text"
                      disabled={isPromotionActive}
                    />
                    <SearchBrand
                      value={values.brand}
                      setFieldValue={(name, value) => this.handleBrandChange(value, setFieldValue)}
                      className="react-select"
                      labelText="User's brand"
                      disabled={isPromotionActive}
                    />
                    {values.brand && (
                      <SearchLottery
                        setFieldValue={(name, value) => this.handleLotteryChange(value, setFieldValue)}
                        value={values.lottery}
                        labelText="Lottery"
                        className="form-group"
                        game
                        brandId={values.brand.id}
                        disabled={isPromotionActive}
                      />
                    )}
                    <DateField
                      label="Start date"
                      name="startDate"
                      value={values.startDate}
                      onChange={setFieldValue}
                      showTimeSelect
                      displayTime
                      disabled={isPromotionActive}
                    />
                    <DateField
                      label="End date"
                      name="endDate"
                      value={values.endDate}
                      onChange={setFieldValue}
                      showTimeSelect
                      displayTime
                      disabled={isPromotionActive}
                    />
                    <CustomSelect
                      name="promotionType"
                      label="Promotion type"
                      className="react-select"
                      options={PROMOTION_TYPES}
                      handleChange={(name, value) => this.handlePromotionTypeChange(value, setFieldValue)}
                      value={values.promotionType}
                      disabled={isPromotionActive}
                    />
                    {this.renderPrizesSelect(values.lottery, values.selectedPrizes, setFieldValue)}
                    {values.selectedPrizes && values.promotionType && (
                      <div className="form-group">
                        <div>
                          {values.promotionType.value === 'percentage' ? 'Prize Multipliers' : 'Prize Override'}
                        </div>
                        <div className="d-flex border-bottom my-3">
                          <div className="col-6">Name</div>
                          <div className="col-6">Value {values.promotionType.value === 'percentage' ? '(%)' : ''}</div>
                        </div>
                        <FieldArray
                          name="prizes"
                          render={() => (
                            <div>
                              {this.preparePrizesList(
                                values.selectedPrizes,
                                values.promotionType.value,
                                values.lottery
                              ).map((elem, index) => {
                                return (
                                  <div className="d-flex justify-content-center" key={elem.label}>
                                    <div className="col-6">
                                      <Field
                                        name="label"
                                        className="col-6 form-control"
                                        onChange={setFieldValue}
                                        value={elem.label}
                                        type="text"
                                        readonly
                                      />
                                    </div>
                                    <div className="col-6">
                                      {values.promotionType.value === 'percentage' ? (
                                        <Field
                                          name={`selectedPrizes[${index}].data`}
                                          className="col-6 form-control"
                                          onChange={setFieldValue}
                                          onBlur={() => setFieldTouched(`selectedPrizes[${index}].data`, true)}
                                          value={elem.data}
                                          type="number"
                                          disabled={isPromotionActive}
                                        />
                                      ) : (
                                        <div className="d-flex align-content-center">
                                          <Field
                                            name={`selectedPrizes[${index}].data.amount`}
                                            className="col-4 form-control"
                                            formGroupClassName="m-0"
                                            onChange={(name, value) =>
                                              this.handleChangePreciseValue(
                                                `selectedPrizes[${index}].data`,
                                                value,
                                                values.lottery.currency,
                                                setFieldValue
                                              )
                                            }
                                            onBlur={() => setFieldTouched(`selectedPrizes[${index}].data.amount`, true)}
                                            value={elem.data.amount}
                                            type="number"
                                            disabled={isPromotionActive}
                                          />
                                          <div className="d-flex align-items-center mx-1">{elem.data.currency}</div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        />
                      </div>
                    )}
                    <div className="d-flex justify-content-end">
                      <Button className="btn btn-primary" type="submit" color="primary" disabled={isPromotionActive}>
                        Save
                      </Button>
                      {isEditMode && (
                        <div className="btn btn-danger ml-4" onClick={onDelete}>
                          Delete
                        </div>
                      )}
                    </div>
                  </form>
                )}
              />
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

PromotionForm.propTypes = {
  isEditMode: propTypes.bool,
  data: propTypes.object,
  lottery: propTypes.object,

  getLottery: propTypes.func,
  onDelete: propTypes.func,
  onSubmit: propTypes.func,
};

export default connect(
  (state) => ({
    lottery: state.lottery,
  }),
  {
    getLottery,
  }
)(PromotionForm);
