import { SYSTEM_CONSTS } from './types';

export const getAllowedCurrencies = () => ({
  type: SYSTEM_CONSTS.CURRENCIES.GET.START,
});

export const getGameResultAgencies = () => ({
  type: SYSTEM_CONSTS.GAME_RESULT_AGENCIES.GET.START,
});

export const getProviderDrawStatuses = () => ({
  type: SYSTEM_CONSTS.PROVIDER_DRAW_STATUSES.GET.START,
});

export const getAvailableWinningSelections = () => ({
  type: SYSTEM_CONSTS.AVAILABLE_WINNING_SELECTIONS.GET.START,
});

export const getPrizeTypes = () => ({
  type: SYSTEM_CONSTS.PRIZE_TYPES.GET.START,
});
