import React from 'react';
import { Page } from 'tabler-react';
import propTypes from 'prop-types';

import Spinner from '../Spinner/Spinner';
import { ErrorHandler } from '../ErrorHandler/index';
import CustomTable from './CustomTable';

/* For now its component for 'simple' Tables */
/* in the future i'll try to expand it for more complicated Tables */

class TablePage extends React.Component {
  componentDidMount() {
    const { getData } = this.props;

    getData();
  }

  renderTable() {
    const { data, error, isLoading, headers, allowedPagination, columns } = this.props;

    if (isLoading) {
      return <Spinner class="mb-5" />;
    }

    if (error) {
      return <ErrorHandler {...{ error }} />;
    }

    return (
      <CustomTable
        allowedPagination={allowedPagination}
        paginHeaders={headers}
        loading={isLoading}
        data={data}
        columns={columns}
      />
    );
  }

  render() {
    const { title } = this.props;

    return (
      <React.Fragment>
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>
              <span>{title}</span>
            </Page.Title>
          </div>
        </Page.Header>
        {this.renderTable()}
      </React.Fragment>
    );
  }
}

TablePage.propTypes = {
  getData: propTypes.func.isRequired,

  data: propTypes.array.isRequired,
  isLoading: propTypes.bool,
  error: propTypes.object,
  headers: propTypes.object,

  columns: propTypes.array.isRequired,
  allowedPagination: propTypes.bool,
  title: propTypes.string,
};

export default TablePage;
