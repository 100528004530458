import React from 'react';
import { connect } from 'react-redux';
import { Page, Grid } from 'tabler-react';
import propTypes from 'prop-types';

import AccessControl from '../../AccessControl/AccessControl';
import TableContainer from '../../Reusable/Table/TableContainer';
import PlayersFilters from '../../Reusable/PlayersFilter/PlayersFilters';

import { userRoles } from '../../../utils/roles';
import { PLAYERS_TABLE_COLUMNS } from '../../../utils/tableConstans';

import { getPlayers } from '../../../actions/players';

class PlayersPage extends React.Component {
  componentDidMount() {
    const { getPlayers } = this.props;

    getPlayers();
  }

  onPlayedId = (id) => {
    const { history } = this.props;

    history.push(`/players/${id}`);
  };

  render() {
    const { players, getPlayers } = this.props;

    return (
      <AccessControl roles={[userRoles.superAdmin]}>
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>Players</Page.Title>
          </div>
        </Page.Header>
        <PlayersFilters onFilterSubmit={getPlayers} onPlayerIdSubmit={this.onPlayedId} />
        <Grid.Row>
          <div className="w-100">
            <TableContainer
              data={players.data}
              paginHeaders={players.headers}
              loading={players.isLoading}
              columns={PLAYERS_TABLE_COLUMNS}
              asyncAction={getPlayers}
            />
          </div>
        </Grid.Row>
      </AccessControl>
    );
  }
}

PlayersPage.propTypes = {
  players: propTypes.object.isRequired,
  headers: propTypes.object.isRequired,
  history: propTypes.object,

  getPlayers: propTypes.func.isRequired,
};

export default connect(
  (state) => ({
    players: state.players,
  }),
  {
    getPlayers,
  }
)(PlayersPage);
