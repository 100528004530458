import React from 'react';
import { TabbedCard, Tab } from 'tabler-react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Brand from './Brand';
import BrandAdditionalData from './BrandAdditionalData';

import { getBrand } from '../../../actions/brandsActions';
import { getExchangeRates, putExchangeRates, deleteExchangeRates } from '../../../actions/exchangeRates';
import { getBrandTaxes, putBrandTaxes, deleteBrandTaxes } from '../../../actions/taxesActions';
import { getAllowedCurrencies } from '../../../actions/systemConsts';

class BrandPage extends React.Component {
  componentDidMount() {
    const { getBrand, getExchangeRates, getAllowedCurrencies, getBrandTaxes } = this.props;

    getBrand(this.getID());
    getAllowedCurrencies();
    getExchangeRates(this.getID());
    getBrandTaxes(this.getID());
  }

  getParentBrandID() {
    const { brand } = this.props;

    if (!brand || !brand.parentBrand) {
      return '';
    }

    return brand.parentBrand.id;
  }

  getID() {
    return this.props.match.params.id;
  }

  loadBrand = () => {
    this.props.getBrand(this.getID());
  };

  render() {
    const {
      exchangeRates,
      currencies,
      brand,
      taxes,
      deleteExchangeRates,
      putExchangeRates,
      deleteBrandTaxes,
      putBrandTaxes,
    } = this.props;
    const brandName = brand.data ? brand.data.name : '';

    return (
      <div className="mt-5">
        <TabbedCard initialTab={'General'} className={'mt-5'}>
          <Tab title={'General'}>
            <Brand brand={brand} getBrand={() => this.loadBrand()} brandId={this.getID()} />
          </Tab>
          <Tab title={'Exchange rates'}>
            <BrandAdditionalData
              brandId={this.getID()}
              parenBrandId={this.getParentBrandID()}
              type="rates"
              putData={putExchangeRates}
              deleteItem={deleteExchangeRates}
              data={exchangeRates}
              displayName="exchange rates"
              {...{ currencies, brandName }}
            />
          </Tab>
          <Tab title={'Taxes'}>
            <BrandAdditionalData
              brandId={this.getID()}
              parenBrandId={this.getParentBrandID()}
              type="taxes"
              putData={putBrandTaxes}
              deleteItem={deleteBrandTaxes}
              data={taxes}
              displayName="taxes"
              {...{ currencies, brandName }}
            />
          </Tab>
        </TabbedCard>
      </div>
    );
  }
}

BrandPage.propTypes = {
  brand: propTypes.object,
  match: propTypes.object,
  currencies: propTypes.object,
  taxes: propTypes.object,
  exchangeRates: propTypes.object,
  deletedRate: propTypes.object,
  updatedRates: propTypes.object,

  getAllowedCurrencies: propTypes.func,
  getBrand: propTypes.func,

  getExchangeRates: propTypes.func,
  deleteExchangeRates: propTypes.func,
  putExchangeRates: propTypes.func,

  getBrandTaxes: propTypes.func,
  putBrandTaxes: propTypes.func,
  deleteBrandTaxes: propTypes.func,
};

export default connect(
  (state) => ({
    brand: state.brand,

    exchangeRates: state.exchangeRatesList,
    deletedRate: state.deletedExchangeRate,
    updatedRates: state.puttedExchangeRate,

    taxes: state.taxesList,
    deletedTax: state.deletedTaxes,
    updatedTax: state.puttedTaxes,

    currencies: state.allowedCurrencies,
  }),
  {
    getAllowedCurrencies,
    getBrand,
    getExchangeRates,
    putExchangeRates,
    deleteExchangeRates,
    getBrandTaxes,
    putBrandTaxes,
    deleteBrandTaxes,
  }
)(BrandPage);
