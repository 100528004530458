import { LOGOUT } from '../../actions/types';

const initialState = {
  isPending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT.START:
      return {
        ...state,
        isPending: true,
      };
    case LOGOUT.END:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};
