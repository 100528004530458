import { BETTING_AGENCY, BETTING_AGENCIES } from '../../actions/types';

const initialState = {
  singleAgency: {
    isError: false,
    isLoading: false,
    err: null,
    data: null,
  },
  agencies: {
    isError: false,
    isLoading: false,
    err: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BETTING_AGENCIES.START:
      return {
        ...state,
        agencies: {
          ...state.agencies,
          isLoading: true,
        },
      };
    case BETTING_AGENCIES.ERROR:
      return {
        ...state,
        agencies: {
          ...state.agencies,
          isError: true,
          error: action.payload,
        },
      };
    case BETTING_AGENCIES.SUCCESS:
      return {
        ...state,
        agencies: {
          ...state.agencies,
          isLoading: false,
          isError: false,
          err: null,
          data: action.payload,
        },
      };
    case BETTING_AGENCY.START:
      return {
        ...state,
        singleAgency: {
          ...state.singleAgency,
          isLoading: true,
        },
      };
    case BETTING_AGENCY.ERROR:
      return {
        ...state,
        singleAgency: {
          ...state.singleAgency,
          isError: true,
          error: action.payload,
        },
      };
    case BETTING_AGENCY.SUCCESS:
      return {
        ...state,
        singleAgency: {
          ...state.singleAgency,
          isLoading: false,
          isError: false,
          err: null,
          data: action.payload,
        },
      };
    case BETTING_AGENCY.RESET:
      return initialState;
    default:
      return state;
  }
};
