import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

class SectionVisibility extends React.Component {
  render() {
    const { index } = this.props;

    return (
      <div className="pb-2 pt-1">
        <label className="title">Visibility: </label>
        <Field name={`sections.${index}.visibility`} className="mx-1 selector" component="select">
          <option value="both" label="Both">
            Both
          </option>
          <option value="desktop" label="Desktop">
            Desktop
          </option>
          <option value="mobile" label="Mobile">
            Mobile
          </option>
        </Field>
      </div>
    );
  }
}
SectionVisibility.propTypes = {
  lineCount: PropTypes.number,
  section: PropTypes.object,
  index: PropTypes.number,
};

export default SectionVisibility;
