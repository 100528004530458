import genericAsyncReducer from '../generic/genericAsyncReducer';

import { GET, DELETE } from '../../utils/constans';

const PROMOTIONS = 'PROMOTIONS';
const PROMOTION = 'PROMOTION';
const PROMOTION_DRAWS = 'PROMOTION_DRAWS';
const PROMOTION_DRAWS_DATES = 'PROMOTION_DRAWS_DATES';

const promotions = genericAsyncReducer(PROMOTIONS, GET);
const promotion = genericAsyncReducer(PROMOTION, GET);
const deletedPromotion = genericAsyncReducer(PROMOTION, DELETE);
const promotionDraws = genericAsyncReducer(PROMOTION_DRAWS, GET);
const promotionDrawsDates = genericAsyncReducer(PROMOTION_DRAWS_DATES, GET);

export default {
  promotions,
  promotion,
  promotionDraws,
  promotionDrawsDates,
  deletedPromotion,
};
