import { DRAW } from '../../actions/types';

const initialState = {
  entries: 25,
  data: null,
  isPending: false,
  error: null,
  headers: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAW.CHANGE_BETS_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };
    case DRAW.GET_BETS_START:
      return {
        ...state,
        isPending: true,
        error: null,
        data: null,
      };
    case DRAW.GET_BETS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        data: null,
        headers: null,
      };
    case DRAW.GET_BETS_SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
        data: action.payload.data,
        headers: action.payload.headers,
      };
    default:
      return state;
  }
};
