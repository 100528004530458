import { RESULT_CONFIRM, RESULT_CONFIRM_DELETE } from './types';

export const postResults = (id, data, drawType = 'game-draws') => ({
  type: RESULT_CONFIRM.POST,
  payload: {
    id,
    data,
    drawType,
  },
});

export const deleteResults = (id, drawType = 'game-draws') => ({
  type: RESULT_CONFIRM_DELETE.START,
  payload: {
    id,
    drawType,
  },
});

export const resetResultsData = () => ({
  type: RESULT_CONFIRM.RESET,
});
