import React from 'react';
import PropTypes from 'prop-types';
import { StampCard } from 'tabler-react';
import { css } from 'react-emotion';

const topBarClass = css`
  .stamp-md {
    min-width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    margin-right: 1.75rem !important;
    margin-left: 1rem;
  }
`;

class TopBar extends React.Component {
  renderFooterWithAmount(currentMonth, lastMonth, total) {
    return (
      <div>
        <div>Current month: {currentMonth}</div>
        <div>Last month: {lastMonth}</div>
        <div>Total: {total}</div>
      </div>
    );
  }

  render() {
    const { ggr, players, sales } = this.props;

    return (
      <div className={topBarClass}>
        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex">
            <StampCard
              className="d-flex justify-content-center"
              color="blue"
              icon="dollar-sign"
              header="Sales"
              footer={this.renderFooterWithAmount(sales.currentMonth, sales.lastMonth, sales.total)}
            />
          </div>
          <div className="col-lg-4 col-md-6 d-flex">
            <StampCard
              className="d-flex justify-content-center"
              color="orange"
              icon="dollar-sign"
              header="GGR"
              footer={this.renderFooterWithAmount(ggr.currentMonth, ggr.lastMonth, ggr.total)}
            />
          </div>
          <div className="col-lg-4 col-md-6 d-flex">
            <StampCard
              className="d-flex justify-content-center"
              color="green"
              icon="user"
              header="Players"
              footer={
                <div>
                  <div>Current month: {`${players.currentMonth}`}, </div>
                  <div>Last month: {`${players.lastMonth}`}, </div>
                  <div>Total: {`${players.total}`}</div>
                </div>
              }
            />
          </div>
          {/* <div className="col-lg-3 col-md-6 d-flex">
            <StampCard
              className="d-flex justify-content-center"
              color="yellow"
              icon="briefcase"
              header="Balance"
              footer={this.renderFooterWithAmount(0, 0, 0)}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  ggr: PropTypes.shape({
    currentMonth: PropTypes.number,
    lastMonth: PropTypes.number,
    total: PropTypes.number,
  }),
  players: PropTypes.shape({
    currentMonth: PropTypes.number,
    lastMonth: PropTypes.number,
    total: PropTypes.number,
  }),
  balance: PropTypes.number,
  sales: PropTypes.shape({
    currentMonth: PropTypes.number,
    lastMonth: PropTypes.number,
    total: PropTypes.number,
  }),
};

export default TopBar;
