import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import LotteryScheduleForm from '../Lottery/LotteryScheduleForm';

import { postProviderSchedule } from '../../../actions/providersActions';

import Schedule from '../../../services/Schedule';

class ProvidersSchedule extends React.Component {
  handleScheduleSubmit = (schedule) => {
    const { postProviderSchedule, name, id } = this.props;

    postProviderSchedule(name, id, schedule);
  };

  getCurrentSchedule = () => {
    const { drawTimes } = this.props.provider.data;

    if (!drawTimes) {
      return null;
    }

    return drawTimes.map((elem) => {
      return Schedule.getNewSchedule({ ...elem.timeData }, elem.dayOfWeek);
    });
  };

  getCurrentSchedule() {
    return null;
  }

  renderContent() {
    const { provider, id } = this.props;

    if (!provider.data) {
      return null;
    }

    return (
      <LotteryScheduleForm
        id={id}
        timezone={provider.data.timezone || 'UTC'}
        onScheduleSubmit={(schedule) => this.handleScheduleSubmit(schedule)}
        currentSchedule={this.getCurrentSchedule()}
        isSubmitting={false}
      />
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

ProvidersSchedule.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  provider: propTypes.shape({
    isLoading: propTypes.bool,
    error: propTypes.object,
    data: propTypes.object,
  }).isRequired,
  postProviderSchedule: propTypes.func.isRequired,
};

export default connect(null, {
  postProviderSchedule,
})(ProvidersSchedule);
