import { createLogic } from 'redux-logic';

import { getPrizeTypeKey } from '../../utils/lottery';
import { PERCENTAGE_PRIZE_TYPE_KEY } from '../../utils/constans';

import FormService from '../../services/FormService';

import {
  LOTTERY,
  LOTTERIES,
  PRICES,
  CURRENCY,
  TIMEZONES,
  BETTING_AGENCIES,
  NOTIFICATION,
  BETTING_AGENCY,
  SYSTEM_CONSTS,
  PROGRESSIVE_JACKPOT,
} from '../../actions/types';

export const getLotteries = createLogic({
  type: LOTTERIES.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    // brandId and lotteryname are null if they're falsy. Why? Because
    //i've noticed that if the param will be null, the query param won't be send at all
    const { page } = action.payload;
    const perPage = getState().lotteries.entries;
    const searchTerm = getState().lotteries.searchTerm;
    const brand = getState().lottery.filters.brand;
    const filteredLottery = getState().lottery.filters.lottery;
    const status = getState().lottery.filters.status;
    const brandId = brand ? brand.id : null;
    const filteredLotteryName = filteredLottery ? filteredLottery.lottery.name : null;
    const address = '/api/v1/games';

    const params = {
      'search[lottery.name][phrase]': filteredLotteryName,
      'search[name][phrase]': searchTerm,
      'search[status][value]': status ? status.value : null,
      page: page,
      perPage: perPage,
      'search[brand][uuid]': brandId,
    };

    return httpClient
      .get(address, { params })
      .then((res) => {
        dispatch({
          type: LOTTERIES.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOTTERIES.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getLottery = createLogic({
  type: LOTTERY.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;

    dispatch({
      type: SYSTEM_CONSTS.CURRENCIES.GET.START,
    });

    return httpClient
      .get(`/api/v1/games/${id}`)
      .then((res) => {
        const { betLineAdditionalPrices, betLineBasePrice } = res.data;
        const possiblePrizes = res.data.possiblePrizes;
        const currency = res.data.currency;

        const updatedPrizes = possiblePrizes.reverse().map((elem) => {
          const key = getPrizeTypeKey(elem);
          let correctAmount, desiredAmount;

          if (!key) {
            return elem;
          }

          if (PERCENTAGE_PRIZE_TYPE_KEY.indexOf(key) !== -1) {
            correctAmount = elem[key];
            desiredAmount = { amount: correctAmount };
          } else {
            correctAmount = elem[key].amount / 100;
            desiredAmount = {
              amount: correctAmount,
              currency: elem[key].currency,
            };
          }

          return {
            ...elem,
            [key]: desiredAmount,
          };
        });

        dispatch({
          type: LOTTERY.CHANGE_MAIN_CURRENCY,
          payload: {
            label: currency,
            value: currency,
          },
        });

        dispatch({
          type: LOTTERY.SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: LOTTERY.CHANGE_BASE_TICKET_COST,
          payload: res.data.betLineBasePrice.amount / 100,
        });

        dispatch({
          type: BETTING_AGENCY.START,
          payload: res.data.bettingAgencyType,
        });

        dispatch({
          type: PROGRESSIVE_JACKPOT.SAVE_INITIAL_DATA,
          payload: res.data,
        });

        dispatch({
          type: PRICES.SET,
          payload: updatedPrizes,
        });

        dispatch({
          type: LOTTERY.CHANGE_PRIZE_POOL_PERCENTAGE,
          payload: res.data.salesSumPrizePoolPercentage,
        });

        dispatch({
          type: CURRENCY.SET.START,
          payload: {
            betLineBasePrice,
            betLineAdditionalPrices,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOTTERY.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getCurrencies = createLogic({
  type: CURRENCY.SET.START,

  process({ action, getState }, dispatch, done) {
    const { betLineBasePrice, betLineAdditionalPrices } = action.payload;
    const newBetLineBasePrice = {
      ...betLineBasePrice,
      amount: betLineBasePrice.amount / 100,
    };
    const allowedCurrencies = getState().allowedCurrencies.data;

    if (betLineAdditionalPrices) {
      const newBetLineAdditionalPrices = betLineAdditionalPrices.map((item) => {
        const newAmount = item.amount / 100;
        return {
          ...item,
          amount: newAmount,
        };
      });

      const betLineAdditionalCurrencies = betLineAdditionalPrices.map((item) => item.currency);
      const additionalCurrencies = allowedCurrencies.filter((item) => !betLineAdditionalCurrencies.includes(item));
      const currencies = additionalCurrencies.map((elem) => {
        return {
          amount: '',
          currency: elem,
        };
      });

      dispatch({
        type: CURRENCY.SET.SUCCESS,
        payload: {
          allCurrencies: [newBetLineBasePrice, ...newBetLineAdditionalPrices, ...currencies],
          additionalCurrencies: newBetLineAdditionalPrices,
          baseCurrency: newBetLineBasePrice,
        },
      });

      done();
    }

    const allCurrencies = [
      newBetLineBasePrice,
      ...allowedCurrencies.map((elem) => {
        return {
          amount: '',
          currency: elem,
        };
      }),
    ];

    dispatch({
      type: CURRENCY.SET.SUCCESS,
      payload: {
        allCurrencies,
        additionalCurrencies: [],
        baseCurrency: newBetLineBasePrice,
      },
    });
    done();
  },
});

export const updateCurrencies = createLogic({
  type: CURRENCY.EDIT.START,

  process({ getState, action }, dispatch, done) {
    const { value, id } = action.payload;
    const currencies = getState().currencies.allCurrencies;
    const newCurrenciesData = currencies.map((elem, index) => {
      if (index == id) {
        return {
          amount: value,
          currency: elem.currency,
        };
      }

      return elem;
    });

    dispatch({
      type: CURRENCY.SET.ALL,
      payload: newCurrenciesData,
    });

    done();
  },
});

export const prepareEdit = createLogic({
  type: PRICES.PREPARE_EDIT,
  latest: true,

  process({ action }, dispatch, done) {
    const { lotteryStruct, lotteryPrices } = action.payload;

    let priceType = '';
    let priceCurrency = '';
    let winningArray = [];

    if (lotteryPrices && lotteryPrices.length > 0) {
      priceType = {
        value: lotteryPrices[0].prizeType || null,
        label: lotteryPrices[0].prizeType || null,
      };

      priceCurrency = {
        value: lotteryPrices[0].amount.currency,
        label: lotteryPrices[0].amount.currency,
      };

      winningArray = lotteryPrices.map((p) => {
        return p.name;
      });
    }

    const preparedWinningArray = lotteryPrices.map((p) => {
      return {
        id: p.id,
        name: p.name,
        amount: {
          amount: parseFloat(p.amount.amount / 100).toFixed(2),
          currency: p.amount.currency,
        },
        prizeType: p.prizeType,
      };
    });

    const intersection = lotteryStruct
      .filter((x) => !winningArray.includes(x))
      .concat(winningArray.filter((x) => !lotteryStruct.includes(x)));

    const emptyPrices = intersection.map((name) => {
      return {
        name: name,
        prizeType: '',
        amount: {
          amount: 0,
          currency: '',
        },
      };
    });

    const allPrices = [...preparedWinningArray, ...emptyPrices];

    dispatch({
      type: PRICES.SET,
      payload: allPrices,
    });

    dispatch({
      type: PRICES.CHANGE_PRICE_TYPE_START,
      payload: {
        text: priceType,
      },
    });
    dispatch({
      type: PRICES.CHANGE_CURRENCY_START,
      payload: {
        text: priceCurrency,
      },
    });

    done();
  },
});

export const getLotteryType = createLogic({
  type: LOTTERY.CHANGE_STRUCT_START,
  latest: true,

  process({ action }, dispatch, done) {
    const type = action.payload;

    if (!type.matchedPrizeNames) {
      return;
    }

    const pricesArray = [];

    type.matchedPrizeNames.map((price) => {
      pricesArray.push({
        name: price,
        amount: {
          amount: 0,
          currency: 'EUR',
        },
        prizeType: 'fixed',
      });
    });

    dispatch({
      type: LOTTERY.CHANGE_STRUCT_SUCCESS,
      payload: type,
    });

    dispatch({
      type: PRICES.CLEAR,
    });

    dispatch({
      type: PRICES.SET,
      payload: pricesArray,
    });
    done();
  },
});

export const editAddingPrices = createLogic({
  type: PRICES.EDIT_START,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const { id, text, name } = action.payload;
    const prevPrices = getState().prices.prices;

    let pricesArray = [];

    let textValue;

    if (text !== null && typeof text === 'object') {
      textValue = text.value ? text.value : '';
    } else {
      textValue = text;
    }

    pricesArray = [
      ...prevPrices.slice(0, id),
      {
        ...prevPrices[id],
        [name]: {
          ...prevPrices[id][name],
          amount: textValue,
        },
      },
      ...prevPrices.slice(id + 1),
    ];

    dispatch({
      type: PRICES.SET,
      payload: pricesArray,
    });
    done();
  },
});

export const changePriceType = createLogic({
  type: PRICES.CHANGE_PRICE_TYPE_START,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const { text, id } = action.payload;

    const prevPrices = getState().prices.prices;
    const currency = getState().lottery.currency.value;

    const pricesArray = prevPrices;

    if (typeof id === 'number') {
      pricesArray[id].prizeType = text.value;
      if (text.value !== 'integration') {
        pricesArray[id][getPrizeTypeKey(pricesArray[id])] = {
          amount: 0,
          currency,
        };
      }
    } else {
      for (let i = 0; i < pricesArray.length; i++) {
        pricesArray[i].prizeType = text.value;
        pricesArray[i][getPrizeTypeKey(pricesArray[i])] = {
          amount: 0,
          currency,
        };
      }
    }

    dispatch({
      type: PRICES.CHANGE_PRICE_TYPE,
      payload: {
        prices: pricesArray,
        priceType: text,
      },
    });
    done();
  },
});

export const changeMainCurrency = createLogic({
  type: LOTTERY.CHANGE_MAIN_CURRENCY,
  latest: true,

  process({ action }, dispatch, done) {
    dispatch({
      type: PRICES.CHANGE_CURRENCY_START,
      payload: {
        text: action.payload,
      },
    });

    done();
  },
});

export const changePriceCurrency = createLogic({
  type: PRICES.CHANGE_CURRENCY_START,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const { text } = action.payload;

    const pricesArray = getState().prices.prices;
    const currency = !text.value ? { value: 'EUR', label: 'EUR' } : text;

    for (let i = 0; i < pricesArray.length; i++) {
      const key = getPrizeTypeKey(pricesArray[i]);

      if (!key || PERCENTAGE_PRIZE_TYPE_KEY.indexOf(key) !== -1) {
        continue;
      } else {
        pricesArray[i][key] = {
          amount: pricesArray[i][key] ? pricesArray[i][key].amount : '0',
          currency: text.value,
        };
      }
    }

    dispatch({
      type: PRICES.CHANGE_CURRENCY,
      payload: {
        prices: pricesArray,
        currency,
      },
    });

    done();
  },
});

export const deleteLottery = createLogic({
  type: LOTTERIES.DELETE_START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;

    return httpClient
      .delete(`/api/v1/games/${id}`)
      .then(() => {
        dispatch({
          type: LOTTERIES.DELETE_SUCCESS,
          payload: id,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOTTERIES.DELETE_ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getTimezones = createLogic({
  type: TIMEZONES.FETCH,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`/api/v1/system-consts/timezones`)
      .then((r) => {
        dispatch({
          type: TIMEZONES.SUCCESS,
          payload: r.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: LOTTERIES.ERROR,
          payload: e.response,
        });
      })
      .then(done);
  },
});

export const getBettingAgencies = createLogic({
  type: BETTING_AGENCIES.START,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get(`/api/v1/system-consts/betting-agencies`)
      .then((r) => {
        dispatch({
          type: BETTING_AGENCIES.SUCCESS,
          payload: r.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: BETTING_AGENCIES.ERROR,
          payload: e.response,
        });
      })
      .then(done);
  },
});

export const getBettingAgency = createLogic({
  type: BETTING_AGENCY.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .get(`/api/v1/system-consts/betting-agencies/${action.payload}/config`)
      .then((r) => {
        dispatch({
          type: BETTING_AGENCY.SUCCESS,
          payload: r.data,
        });
        // initialising default betting agency form
        dispatch({
          type: LOTTERY.STRUCTURE_CHANGE,
          payload: {
            path: 'bettingAgencyConfig',
            value: FormService.prepareEmptyDynamicForm(r.data.configProperties),
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: BETTING_AGENCY.ERROR,
          payload: e.response,
        });
      })
      .then(done);
  },
});

export const editLotteryMessageTemplates = createLogic({
  type: LOTTERY.MESSAGE_TEMPLATE_START,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { data } = action.payload;
    const lotteryId = getState().lottery.data.id;
    return httpClient
      .patch(`/api/v1/games/${lotteryId}`, { id: lotteryId, ...data })
      .then((r) => {
        dispatch({
          type: LOTTERY.MESSAGE_TEMPLATE_SUCCESS,
          payload: r.data,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Configuration saved',
          },
        });
        dispatch({
          type: LOTTERY.FETCH,
          payload: lotteryId,
        });
      })
      .catch((e) => {
        dispatch({
          type: LOTTERY.MESSAGE_TEMPLATE_ERROR,
          payload: e.response,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'There was an error',
          },
        });
      })
      .then(done);
  },
});

export default {
  deleteLottery,
  getCurrencies,
  updateCurrencies,
  getLottery,
  getLotteries,
  getLotteryType,
  editAddingPrices,
  changePriceType,
  changePriceCurrency,
  prepareEdit,
  getTimezones,
  getBettingAgencies,
  getBettingAgency,
  editLotteryMessageTemplates,
  changeMainCurrency,
};
