export const LTECH = 'ltech';

export const LTECH_COLUMNS = [
  {
    header: 'Name',
    type: 'string',
    accessor: 'name',
  },
  {
    header: 'Betting method',
    type: 'string',
    accessor: 'bettingMethod',
  },
  {
    header: 'URL',
    type: 'string',
    accessor: 'url',
  },
  {
    header: 'Proxy URL',
    type: 'string',
    accessor: 'proxy',
  },
  {
    header: 'Active',
    type: 'boolean',
    accessor: 'active',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    type: 'actions',
    id: 'id',
    elements: [
      {
        header: 'Actions',
        type: 'edit',
        linkto: `/providers/${LTECH}/:id`,
        txt: 'Edit',
      },
    ],
  },
];

export const LTECH_FORM_CONFIG = [
  {
    name: 'brand',
    title: 'Brand',
    type: 'search-brand',
    data: [],
  },
  {
    name: 'name',
    title: 'Name',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'apiKey',
    title: 'API key',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'bettingMethod',
    title: 'Betting method',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'url',
    title: 'URL',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'proxy',
    title: 'Proxy url',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'registrationTimeInMinutes',
    title: 'Registration time in minutes',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'fetchResultsTimeInMinutes',
    title: 'Fetch results time in minutes',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'futureDrawsCount',
    title: 'Future draws count',
    type: 'int',
    isRequired: true,
  },
  {
    name: 'gameType',
    title: 'Game type',
    type: 'text',
    isRequired: true,
    minLength: 0,
    maxLength: null,
  },
  {
    name: 'winningSelectionClass',
    title: 'Winning selection class',
    type: 'dropdown',
    data: [],
  },
  {
    name: 'useOfflineRegistration',
    title: 'Use offline registration',
    type: 'bool',
    isRequired: true,
  },
  {
    name: 'areDrawsCancelledOnFailedRegistration',
    title: 'Cancel draws when provider draw is not registered',
    type: 'bool',
    isRequired: true,
  },
  {
    name: 'active',
    title: 'Enable Active',
    type: 'bool',
    isRequired: true,
  },
];
