import { BRANDS, BRAND } from './types';

export const getBrands = (page, phrase) => ({
  type: BRANDS.FETCH,
  payload: {
    page,
    phrase,
  },
});

export const deleteBrand = (id) => ({
  type: BRANDS.DELETE_START,
  payload: id,
});

export const changeEntries = (entries) => ({
  type: BRANDS.CHANGE_ENTRIES,
  payload: entries,
});

export const changeSearchTerm = (term) => ({
  type: BRANDS.CHANGE_SEARCH_TERM,
  payload: term,
});

export const getBrand = (id) => ({
  type: BRAND.FETCH,
  payload: id,
});

export const addBrand = (data) => ({
  type: BRANDS.ADD_START,
  payload: data,
});

export const updateBrand = (id, data) => ({
  type: BRANDS.UPDATE_START,
  payload: {
    id,
    data,
  },
});

export const modifyBrand = (id = null, data) => ({
  type: BRANDS.MODIFY_START,
  payload: {
    id,
    data,
  },
});

export const clearBrand = () => ({
  type: BRAND.CLEAR,
});
