import React from 'react';
import { Page, Button, Form } from 'tabler-react';
import Modal from '../../../Reusable/Modal/CustomModal';
import Spinner from '../../../Reusable/Spinner/Spinner';
import { IntegrationConfiguration } from '../../../Lottery/index';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import { getTemplate, getChannels } from '../../../../actions/outboundChannelsActions';

const initialState = {
  messages: {},
  isDeleteModalOpen: false,
};

class ChannelMessages extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.props.getTemplate();
    this.props.getChannels(1, 25);
  }

  createChannelMessages = () => {
    const { template } = this.props;

    let finalMessages = {};

    template.map((field) => {
      finalMessages = {
        ...finalMessages,
        [field.name]: this.state.messages[field.name]
          ? this.state.messages[field.name]
          : field.type === 'bool'
            ? false
            : '',
      };
    });

    this.props.onSubmit({ ...finalMessages, channelConfiguration: { id: this.state.selectedChannel } });
  };

  editChannelMessages = () => {
    const { previousMessages, onSubmit } = this.props;
    onSubmit({ ...previousMessages, ...this.state.messages });
  };

  handleModalToggle = () => {
    this.setState((state) => ({ isDeleteModalOpen: !state.isDeleteModalOpen }));
  };

  getConfigurationTitle = () => {
    const { previousMessages, channels } = this.props;
    const configurations = channels.filter((channel) => channel.id === previousMessages.channelConfiguration.id);
    return configurations.length ? configurations[0].name : 'untitled';
  };

  render() {
    const { template, previousMessages, channels, onDelete } = this.props;

    return (
      <Page>
        {previousMessages && onDelete && (
          <Modal
            isOpen={this.state.isDeleteModalOpen}
            toggleModal={this.handleModalToggle}
            handleSubmit={() => {
              onDelete();
              this.handleModalToggle();
            }}
            title="Are you sure you want to delete this channel?"
            classname="react-modal-custom__delete"
            statusColor="danger"
            btnConfirmText="Confirm"
            btnConfirmColor="danger"
          />
        )}

        {!template ? (
          <Spinner class="mb-5" />
        ) : (
          <React.Fragment>
            {previousMessages && channels && <h5>Channel configuration: {this.getConfigurationTitle()}</h5>}
            {!previousMessages && template && channels && (
              <React.Fragment>
                <h5>Select configured outbound channel: </h5>
                <Form.SelectGroup pills>
                  {channels.map((channel, i) => {
                    return (
                      <Form.SelectGroupItem
                        key={`pill-${i}`}
                        label={channel.name}
                        name="integration"
                        value={channel.id}
                        onChange={(e) => {
                          this.setState({ selectedChannel: e.target.value });
                        }}
                      />
                    );
                  })}
                </Form.SelectGroup>
              </React.Fragment>
            )}

            {template && (
              <IntegrationConfiguration
                name={'Outbound channel messages:'}
                fields={template}
                onChange={(name, value) =>
                  this.setState((state) => ({
                    messages: {
                      ...state.messages,
                      [name]: value,
                    },
                  }))
                }
                data={previousMessages}
                formOnly
              />
            )}

            {previousMessages ? (
              <React.Fragment>
                <Button onClick={this.editChannelMessages} block color="primary">
                  Save channel messages
                </Button>
                <Button onClick={this.handleModalToggle} block color="danger">
                  Delete channel messages
                </Button>
              </React.Fragment>
            ) : (
              <Button onClick={this.createChannelMessages} block color="green">
                Create channel messages
              </Button>
            )}
          </React.Fragment>
        )}
      </Page>
    );
  }
}

ChannelMessages.propTypes = {
  getTemplate: propTypes.func,
  template: propTypes.array,
  getChannels: propTypes.func,
  channels: propTypes.array,
  previousMessages: propTypes.object,
  onSubmit: propTypes.func,
  onDelete: propTypes.func,
};

export default connect(
  (state) => ({
    template: state.outboundChannels.template.data,
    channels: state.outboundChannels.channels,
  }),
  {
    getTemplate,
    getChannels,
  }
)(ChannelMessages);
