import numeral from 'numeral';

export const setProperMoneyValue = (moneyObject, divide = true) => {
  if (!moneyObject || moneyObject.amount === '-' || !moneyObject.amount) {
    return '-';
  }

  if (moneyObject.amount === 'N/A') {
    return moneyObject.amount;
  }

  const price = divide
    ? numeral(moneyObject.amount / 100).format('0,0.00')
    : numeral(moneyObject.amount).format('0,0.00');
  const correctCurrency = moneyObject.currency === 'EUR' ? '€' : moneyObject.currency;
  return price + ' ' + correctCurrency;
};

export const getMoneyWithCurrency = (moneyObject) => {
  if (!moneyObject) {
    return null;
  }

  if (typeof moneyObject === 'string') {
    return moneyObject;
  }

  return `${getHumanAmount(moneyObject.amount)} ${moneyObject.currency}`;
};

export const getHumanAmount = (value) => {
  if (!value) {
    return '';
  }

  return value / 100;
};
