import React from 'react';
import propTypes from 'prop-types';

import Spinner from '../../Reusable/Spinner/Spinner';

import CustomTable from './CustomTable';

class TableContainer extends React.Component {
  handleChangeEntries = (pageNumber, entries) => {
    const { asyncAction } = this.props;

    asyncAction(1, entries);
  };

  handlePageChange = (pageNumber) => {
    const { asyncAction, onPageChange, paginHeaders } = this.props;

    if (typeof onPageChange === 'function') {
      onPageChange(pageNumber);
    }

    asyncAction(pageNumber, paginHeaders['x-per-page']);
  };

  render() {
    const { data, paginHeaders, columns, loading } = this.props;

    if (loading) {
      return <Spinner />;
    }

    if (!data) {
      return null;
    }

    return (
      <CustomTable
        onPageChanged={this.handlePageChange}
        onChangeEntries={this.handleChangeEntries}
        {...{ data, paginHeaders, columns, loading }}
      />
    );
  }
}

TableContainer.propTypes = {
  asyncAction: propTypes.func,
  onPageChange: propTypes.func,

  paginHeaders: propTypes.object,
  loading: propTypes.bool,
  error: propTypes.object,
  columns: propTypes.array.isRequired,
  data: propTypes.oneOfType([propTypes.array.isRequired, propTypes.oneOf([null])]),
};

export default TableContainer;
