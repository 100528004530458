import { BACKEND_USER } from '../actions/types';

const initialState = {
  data: null,
  isPending: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BACKEND_USER.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
        data: null,
      };
    case BACKEND_USER.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
        headers: null,
        data: null,
      };
    case BACKEND_USER.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: action.payload,
      };
    case BACKEND_USER.CLEAR:
      return initialState;
    default:
      return state;
  }
};
