import httpClient from './httpClient';
import AuthService from './AuthService';

import { LOGOUT } from '../actions/types';

let isAlreadyFetchingAccessToken = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export default {
  setupInterceptors: (store) => {
    httpClient.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        const {
          config,
          response: { status },
        } = error;
        const originalRequest = config;

        if (error.response.data.error_description === 'Invalid refresh token') {
          return store.dispatch({ type: LOGOUT.START });
        }

        if (status === 401) {
          if (isAlreadyFetchingAccessToken) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return httpClient(originalRequest);
              })
              .catch((err) => {
                return err;
              });
          }

          isAlreadyFetchingAccessToken = true;
          const formData = new FormData();
          formData.append('grant_type', 'refresh_token');
          formData.append('client_id', 'random');
          formData.append('client_secret', 'secret');
          formData.append('refresh_token', AuthService.getAuth().refresh_token);

          return new Promise((resolve, reject) => {
            httpClient
              .post('/oauth/v2/token', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((res) => {
                AuthService.saveAuth(res.data);
                originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access_token;
                processQueue(null, res.data.access_token);
                resolve(httpClient(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
              })
              .then(() => {
                isAlreadyFetchingAccessToken = false;
              });
          });
        }
        return Promise.reject(error);
      }
    );
  },
};
