import { OUTBOUND_CHANNELS } from './types';

export const getIntegrations = () => ({
  type: OUTBOUND_CHANNELS.AVAILABLE_INTEGRATIONS.FETCH,
});

export const getTemplate = () => ({
  type: OUTBOUND_CHANNELS.TEMPLATE.FETCH,
});

export const getChannels = (page, perPage) => ({
  type: OUTBOUND_CHANNELS.FETCH,
  payload: { page, perPage },
});

export const getChannel = (id) => ({
  type: OUTBOUND_CHANNELS.CHANNEL.FETCH,
  payload: { id },
});

export const clearChannel = () => ({
  type: OUTBOUND_CHANNELS.CHANNEL.CLEAR,
});

export const createChannel = (channel) => ({
  type: OUTBOUND_CHANNELS.CHANNEL.POST,
  payload: { channel },
});

export const patchChannel = (id, channel) => ({
  type: OUTBOUND_CHANNELS.CHANNEL.PATCH,
  payload: { channel, id },
});

export const deleteChannel = (id) => ({
  type: OUTBOUND_CHANNELS.CHANNEL.DELETE,
  payload: { id },
});
