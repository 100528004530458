import { FROM, TO, DAY } from '../utils/constans/timeConsts';

import TimeFormatter from './TimeFilterFormatter';

class TicketsService {
  static getWinningsReportFilters(filters) {
    // docs says that endpoiint only supports these filters
    const brandId = filters.brand?.id;
    const gameId = filters.lottery?.id;
    const playerId = filters?.playerId;
    const ticketId = filters?.ticketId;
    const drawId = filters?.draw;
    const fromDate = filters.dateFrom ? TimeFormatter(filters.dateFrom, FROM, DAY) : null;
    const toDate = filters.dateTo ? TimeFormatter(filters.dateTo, TO, DAY) : null;
    const createdAtFrom = filters.dateCreatedAtFrom ? TimeFormatter(filters.dateCreatedAtFrom, FROM, DAY) : null;
    const createdAtTo = filters.dateCreatedAtTo ? TimeFormatter(filters.dateCreatedAtTo, TO, DAY) : null;
    const status = filters?.status?.value;

    const params = {
      'search[game][game-uuid]': gameId,
      'search[brand][brand-uuid]': brandId,
      'search[gameDraw][uuid]': drawId,
      'search[id][uuid]': ticketId,
      'search[player][uuid]': playerId,
      'search[createdAt][datetime-range][before]': createdAtTo,
      'search[createdAt][datetime-range][after]': createdAtFrom,
      'search[drawDatetime][draw-datetime][before]': toDate,
      'search[drawDatetime][draw-datetime][after]': fromDate,
      'search[status][status]': status,
    };

    return params;
  }

  static getApiType(type) {
    return type === 'default' ? '' : `${type}-`;
  }
}

export default TicketsService;
