import { PRICES } from './types';

export const handlePriceChange = (id, name, text) => ({
  type: PRICES.EDIT_START,
  payload: { id, name, text },
});

export const setPrices = (prices) => ({
  type: PRICES.SET,
  payload: prices,
});

export const prepareEditPrice = (lotteryStruct, lotteryPrices) => ({
  type: PRICES.PREPARE_EDIT,
  payload: { lotteryPrices, lotteryStruct },
});

export const clearPrices = () => ({
  type: PRICES.CLEAR,
});

export const changePriceType = (text, id) => ({
  type: PRICES.CHANGE_PRICE_TYPE_START,
  payload: { text, id },
});

export const changePriceCurrency = (text) => ({
  type: PRICES.CHANGE_CURRENCY_START,
  payload: { text },
});
