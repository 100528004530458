import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class AccessControl extends React.Component {
  renderContentBasedOnRole() {
    const { userRoles, roles, forbiddenRender, forbiddenRedirect } = this.props;
    const isUserRoleInRoles = roles && roles.length > 0 && userRoles.filter((role) => roles.indexOf(role) !== -1);
    const isAllowedRole = roles && roles.length > 0 ? isUserRoleInRoles.length > 0 : true;

    if (!userRoles || userRoles.length === 0) {
      return null;
    }

    if (!isAllowedRole) {
      if (forbiddenRender) {
        return forbiddenRender;
      }

      if (forbiddenRedirect) {
        return <Redirect to={forbiddenRedirect} />;
      }

      return null;
    }

    return this.props.children;
  }

  render() {
    return <React.Fragment>{this.renderContentBasedOnRole()}</React.Fragment>;
  }
}

AccessControl.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.array,
  forbiddenRender: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  forbiddenRedirect: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  user: PropTypes.object,
};

export default connect((state) => ({
  userRoles: state.user.data.roles,
}))(AccessControl);
