const roles = [
  { id: 'super_admin', name: 'Super Admin' },
  { id: 'admin', name: 'Admin' },
  { id: 'brand_operator', name: 'Brand Operator' },
  { id: 'support_operator', name: 'Support Operator' },
  { id: 'jackpot_operator', name: 'Jackpot Operator' },
  { id: 'results_operator', name: 'Results Operator' },
  { id: 'lobby_manager', name: 'Lobby Manager' },
  { id: 'reports_manager', name: 'Reports Manager' },
];

export const userRoles = {
  superAdmin: 'super_admin',
  admin: 'admin',
  brand: 'brand_operator',
  support: 'support_operator',
  jackpotOperator: 'jackpot_operator',
  resultsOperator: 'results_operator',
  lobbyManager: 'lobby_manager',
  reportsManager: 'reports_manager',
};

export default roles;
