import { TICKETS } from '../../actions/types';

const initialState = {
  entries: 25,
  page: 1,
  data: null,
  isPending: false,
  isError: false,
  error: null,
  headers: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TICKETS.CHANGE_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };
    case TICKETS.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
        data: null,
      };
    case TICKETS.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
        headers: null,
        data: null,
      };
    case TICKETS.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: action.payload.data,
        headers: action.payload.headers,
      };
    case TICKETS.CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case TICKETS.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
