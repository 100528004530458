import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { Icon } from 'tabler-react';
import './Dater.scss';

class Dater extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupVisible: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isPopupVisible: true });
  };
  handleMouseLeave = () => {
    this.setState({ isPopupVisible: false });
  };

  render() {
    const { isPopupVisible } = this.state;
    const { time, timezone } = this.props;

    if (!time || !moment(time).isValid()) {
      return <div>-</div>;
    }

    return (
      <div className="dater" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <div className={classNames('hidden__window', { popped__window: isPopupVisible })}>
          <label className="hint">Your local time</label>
          <div>
            <Icon prefix="fa" name="user" />
            <label>{moment(time).format('DD/MM/YY HH:mm:ss Z')}</label>
          </div>

          {timezone && (
            <React.Fragment>
              <label className="hint">Time of the lottery</label>
              <div>
                <Icon prefix="fa" name="map" />
                <label>{moment(time).tz(timezone).format('DD/MM/YY HH:mm:ss Z')}</label>
              </div>
            </React.Fragment>
          )}

          <label className="hint">Global UTC time</label>
          <div>
            <Icon prefix="fa" name="globe" />
            <label>{moment.utc(time).format('DD/MM/YY HH:mm:ss Z')}</label>
          </div>
        </div>

        <div className="displayed">
          <Icon prefix="fa" name="user" />
          <label>{moment(time).format('DD/MM/YY HH:mm:ss')}</label>
        </div>
      </div>
    );
  }
}

Dater.propTypes = {
  time: propTypes.string,
  timezone: propTypes.string,
};

export default Dater;
