import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'tabler-react';

class ErrorHandler extends React.Component {
  renderConditionalErrorMessage(error) {
    if (error && error.data.error_description) {
      return <FormattedMessage id={'OAUTH_ERROR'} defaultMessage={error.data.error_description} />;
    }

    if (error && error.data.code && error.data.message) {
      return <FormattedMessage id={error.data.code} defaultMessage={error.data.message} />;
    }

    if (error && !error.data.code && error.data.message) {
      return error.data.message;
    }

    return 'An unexpected error occurred. Please try again later.';
  }

  render() {
    const { error, isDismissible } = this.props;

    return (
      <Alert type="danger" icon="alert-triangle" isDismissible={isDismissible}>
        {this.renderConditionalErrorMessage(error)}
      </Alert>
    );
  }
}

ErrorHandler.propTypes = {
  error: PropTypes.object.isRequired,
  isDismissible: PropTypes.bool,
};

export default ErrorHandler;
