import { DRAWS } from '../../actions/types';

const initialState = {
  entries: 25,
  searchTerm: '',
  data: null,
  isPending: false,
  isError: false,
  error: null,
  headers: null,
  delete: {
    isPending: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAWS.CHANGE_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };
    case DRAWS.CHANGE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case DRAWS.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
        data: null,
      };
    case DRAWS.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
        headers: null,
        data: null,
      };
    case DRAWS.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: action.payload.data,
        headers: action.payload.headers,
      };
    case DRAWS.DELETE_START:
      return {
        ...state,
        delete: {
          isPending: true,
          error: null,
        },
      };
    case DRAWS.DELETE_SUCCESS: {
      const newData = state.data.filter((item) => item.id !== action.payload);
      return {
        ...state,
        delete: {
          isPending: false,
          error: null,
        },
        data: newData,
      };
    }
    case DRAWS.DELETE_ERROR:
      return {
        ...state,
        delete: {
          isPending: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
