import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LotteryI18nConfig from './LotteryI18nConfig';
import Select from 'react-select';
import { TabbedCard, Tab, Button } from 'tabler-react';
import Spinner from '../../../Reusable/Spinner/Spinner';
import { getLanguages, clearLanguages } from '../../../../actions/lotteryI18nActions';
import { languagesList } from '../../../../utils/constans';

import './LotteryI18nConfig.scss';

class I18Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addLanguageModeActive: false,
      newLanguage: languagesList[0],
    };
  }

  componentDidMount() {
    const { getLanguages, id } = this.props;
    getLanguages(id);
  }

  componentWillUnmount() {
    this.props.clearLanguages();
  }

  onLanguageAdd(value) {
    this.setState({ newLanguage: value });
  }

  toggleAddLanguageMode = () => {
    const isActive = this.state.addLanguageModeActive;
    this.setState({ addLanguageModeActive: !isActive });
  };

  render() {
    const { languages, id } = this.props;
    const { addLanguageModeActive, newLanguage } = this.state;

    if (!languages) {
      return <Spinner class="mt-3 mb-3" />;
    }

    return (
      <React.Fragment>
        <div className="flexbox">
          <Button onClick={this.toggleAddLanguageMode} className="addLanguageButton" color="success">
            Add new language
          </Button>
          <Select
            className="react-select  "
            options={languagesList}
            onChange={(value) => this.onLanguageAdd(value)}
            value={newLanguage}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
        </div>
        <TabbedCard initialTab="EN" className={'mt-5'}>
          {(languages.length == 0 || !languages.find((language) => language.language === 'EN')) && (
            <Tab title="EN">
              <LotteryI18nConfig lotteryId={id} languageMeta={{ language: 'EN' }} />
            </Tab>
          )}
          {languages.length > 0 &&
            languages.map((language, index) => (
              <Tab key={index} title={language.language}>
                <LotteryI18nConfig lotteryId={id} languageMeta={language} />
              </Tab>
            ))}
          {addLanguageModeActive && !languages.find((language) => language.language === newLanguage.value) && (
            <Tab title={newLanguage.value}>
              <LotteryI18nConfig lotteryId={id} languageMeta={{ language: newLanguage.value }} />
            </Tab>
          )}
        </TabbedCard>
      </React.Fragment>
    );
  }
}

I18Container.propTypes = {
  id: PropTypes.string.isRequired,
  getLanguages: PropTypes.func,
  clearLanguages: PropTypes.func,
  languages: PropTypes.array,
};

export default connect(
  (state) => ({
    languages: state.i18n.languages,
  }),
  {
    getLanguages,
    clearLanguages,
  }
)(I18Container);
