import React from 'react';
import PropTypes from 'prop-types';
import LobbyService from '../../../../services/LobbyService';

class FileUploader extends React.Component {
  state = {
    fileName: '',
  };

  onInputChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        fileName: e.target.value,
      });
    }

    LobbyService.getBase64(e.currentTarget.files[0], (v) => {
      this.props.form.setFieldValue(this.props.field.name, { base64: v });
    });
  };

  onDeletionClick = () => {
    const { field, form } = this.props;

    this.setState({
      fileName: '',
    });

    form.setFieldValue(field.name, null);
  };

  render() {
    const { fileName } = this.state;

    return (
      <div className="flexRow py-2 col-lg-12">
        <input
          name="file"
          type="file"
          value={fileName}
          onChange={this.onInputChange}
          className="form-control-file w-50 p-0"
        />

        <div title="delete image" className="btn btn-danger btn-icon" onClick={this.onDeletionClick}>
          <i className="fe fe-trash" />
        </div>
      </div>
    );
  }
}

FileUploader.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default FileUploader;
