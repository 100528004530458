import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import './DataTable.scss';

class DataTable extends React.Component {
  element = null;
  touchStart = null;

  constructor(props) {
    super(props);

    this.state = {
      parentWidth: null,
      windowWidth: null,
      scrollLeft: 0,
      actualPage: 0,
      columnAmount: 0,
      isMobile: false,
    };
  }

  static defaultProps = {
    oneFlexMinWidth: 120,
  };

  getMobileColumnAmount = (parentWidth, oneFlexMinWidth) => {
    const amount = Math.floor(parentWidth / oneFlexMinWidth);

    return amount <= 0 ? 1 : amount;
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const { getMobileColumnAmount, oneFlexMinWidth } = this.props;

    const windowWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
    const parentWidth = this.element.parentNode.clientWidth;

    const columnAmount = getMobileColumnAmount
      ? getMobileColumnAmount(windowWidth, parentWidth)
      : this.getMobileColumnAmount(parentWidth, oneFlexMinWidth);

    this.setState(
      {
        parentWidth,
        windowWidth,
        columnAmount,
        actualPage: 0,
        isMobile: windowWidth < 4200,
      },
      () => {
        this.scrollTo(0, 100);
      }
    );
  };

  onScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;

    this.setState({
      scrollLeft,
    });
  };

  onTouchStart = (e) => {
    const touch = e.touches[0];

    this.touchStart = {
      x: touch.clientX,
      y: touch.clientY,
    };
  };

  onTouchEnd = (e) => {
    const minOffset = 30;
    const touch = e.changedTouches[0];

    const dX = touch.clientX - this.touchStart.x;
    const dY = touch.clientY - this.touchStart.y;

    const isScrollVertical = Math.abs(dY) > 30;

    if (isScrollVertical) {
      return false;
    }

    if (dX < -minOffset) {
      this.scrollNext();
    } else if (dX > minOffset) {
      this.scrollPrev();
    }
  };

  getVisibleColumns() {
    const { columns } = this.props;

    return columns.filter((col, index) => this.isVisibleColumn(index));
  }

  isVisibleColumn(columnIndex) {
    const { isMobile } = this.state;
    const col = this.props.columns[columnIndex];

    if (isMobile) {
      return col.isVisibleOnMobile !== undefined ? col.isVisibleOnMobile : true;
    } else {
      return col.isVisibleOnDesktop !== undefined ? col.isVisibleOnDesktop : true;
    }
  }

  scrollTo(to, duration) {
    if (duration <= 0) return;
    const difference = to - this.element.scrollLeft;
    const perTick = (difference / duration) * 5;

    setTimeout(() => {
      this.element.scrollLeft = this.element.scrollLeft + perTick;
      if (this.element.scrollLeft === to) return;
      this.scrollTo(to, duration - 5);
    }, 5);
  }

  scrollToPage(page) {
    if (page === this.state.actualPage) {
      return;
    }
    if (page < 0) {
      return;
    }
    if (page > Math.ceil(this.getVisibleColumns().length / this.state.columnAmount) - 1) {
      return;
    }

    const offset = this.state.parentWidth;

    this.setState(
      {
        actualPage: page,
      },
      () => {
        this.scrollTo(offset * page, 50);
      }
    );
  }

  scrollPrev() {
    this.scrollToPage(this.state.actualPage - 1);
  }

  scrollNext() {
    this.scrollToPage(this.state.actualPage + 1);
  }

  getColumnWidth(index, columnAmount) {
    const { parentWidth } = this.state;
    const columns = this.getVisibleColumns();

    const startedFlexIndex = Math.floor(index / columnAmount) * columnAmount;
    const flexIndexes = Array.from(new Array(columnAmount), (val, index) => startedFlexIndex + index);

    const flex = columns[index].flex || 1;
    const flexSum = flexIndexes
      .map((flexIndex) => {
        const col = columns[flexIndex];

        if (!col) {
          const lastIndex = columns.length - 1;
          const offsetIndex = lastIndex - (flexIndex - lastIndex);
          const prevCol = columns[offsetIndex];

          return (prevCol && prevCol.flex) || 1;
        }

        return col.flex || 1;
      })
      .reduce((a, b) => a + b, 0);

    return (flex / flexSum) * parentWidth;
  }

  renderColGroup() {
    const { columnAmount, isMobile } = this.state;

    if (!isMobile) {
      return null;
    }

    return (
      <colgroup>
        {this.getVisibleColumns().map((col, index) => {
          const columnWidth = this.getColumnWidth(index, columnAmount);
          return (
            <col
              span={1}
              key={col.key || col.name}
              style={{
                width: columnWidth,
              }}
            />
          );
        })}
      </colgroup>
    );
  }

  renderHeader(columnAmount) {
    const visibleColumns = this.getVisibleColumns();
    return (
      <thead className="thead-light">
        <tr>
          {visibleColumns.map((col, index) => {
            const isFirstOfScreen = index % columnAmount === 0;
            const isLastOfScreen = (index + 1) % columnAmount === 0;

            return (
              <th
                scope="col"
                key={col.key || col.name}
                className={classnames({
                  'first-on-screen': isFirstOfScreen,
                  'last-on-screen': isLastOfScreen,
                })}
              >
                {columnAmount < visibleColumns.length && index === 0 ? this.renderHeaderNav() : null}
                <div>{col.name}</div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  renderHeaderNav() {
    const { scrollLeft } = this.state;

    return (
      <div className={'data-table-nav'} style={{ width: this.state.parentWidth, left: scrollLeft }}>
        <i className="data-table-nav-left fa fa-caret-left" onClick={() => this.scrollPrev()} />
        <i className="data-table-nav-right fa fa-caret-right" onClick={() => this.scrollNext()} />
      </div>
    );
  }

  renderBody() {
    const { data, columns } = this.props;
    const onClick = this.props.onRowClick || (() => {});
    return (
      <tbody>
        {data.map((row, i) => {
          const rowData = row.data ? row.data : row;
          const className = row.className ? row.className : '';

          return (
            <tr key={i} onClick={() => onClick(i)} className={className}>
              {rowData.map((item, j) => {
                return this.isVisibleColumn(j) ? (
                  <td key={`${i}${j}`} className={classnames(columns[j].className)}>
                    <div className={columns[j].divClassName}>{item}</div>
                  </td>
                ) : null;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }

  render() {
    const { windowWidth, columnAmount } = this.state;
    const { className } = this.props;

    if (windowWidth === null) {
      return <div className="data-table" ref={(elem) => (this.element = elem)} />;
    }

    return (
      <div
        className={classnames('data-table', className)}
        ref={(elem) => (this.element = elem)}
        onScroll={this.onScroll}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
      >
        <table className="table table-striped table-hover">
          {this.renderColGroup()}
          {this.renderHeader(columnAmount)}
          {this.renderBody()}
        </table>
      </div>
    );
  }
}

DataTable.propTypes = {
  getMobileColumnAmount: propTypes.func,
  oneFlexMinWidth: propTypes.number,
  columns: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      key: propTypes.any,
      flex: propTypes.number,
      isVisibleOnMobile: propTypes.bool,
      isVisibleOnDesktop: propTypes.bool,
      className: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]),
      divClassName: propTypes.string,
    })
  ),
  className: propTypes.string,
  onRowClick: propTypes.func,
  data: propTypes.array.isRequired,
};

export default DataTable;
