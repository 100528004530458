import React from 'react';
import propTypes from 'prop-types';

import LabeledValue from '../LabeledValue';

class Selection extends React.Component {
  renderSelections() {
    const { selections } = this.props;

    if (!selections) {
      return null;
    }

    return Object.keys(selections)
      .sort((selection) => (selection === 'main' ? -1 : 1))
      .map((selection) => {
        const label = selection;
        const values = selections[selection];

        return (
          <LabeledValue key={label} label={`${label[0].toUpperCase()}${label.slice(1)}`} value={values.toString()} />
        );
      });
  }

  render() {
    return <React.Fragment>{this.renderSelections()}</React.Fragment>;
  }
}

Selection.propTypes = {
  selections: propTypes.object,
};

export default Selection;
