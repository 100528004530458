import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';

import Login from './pages/Login/Login';
import LoggedLayout from './LoggedLayout';

import PrivateRoute from './PrivateRoute';
import EmailConfirmation from './pages/Login/EmailConfirmation';
import ChangePassword from './pages/Login/ChangePassword';

class App extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <div>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/activate/:token" component={EmailConfirmation} />
            <Route path="/reset_password/:token" component={ChangePassword} />
            <PrivateRoute path="/" component={LoggedLayout} />
          </Switch>
        </div>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  history: PropTypes.object,
};

export default hot(module)(App);
