import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'tabler-react';
import Select from 'react-select';

import { Field } from '../Reusable/FormFields';

class DrawJackpot extends React.Component {
  timeoutNotify = null;

  state = {
    amount: this.props.jackpotAmount,
    currency: {
      value: this.props.jackpotCurrency,
      label: this.props.jackpotCurrency,
    },
    displayNotify: false,
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutNotify);
  }

  onJackpotOverrideSubmit = (e) => {
    const { onJackpotOverride, drawId } = this.props;
    const { amount, currency } = this.state;

    e.preventDefault();

    onJackpotOverride(drawId, amount, currency.value);

    this.setState({
      displayNotify: true,
    });

    this.timeoutNotify = setTimeout(() => {
      this.setState({ displayNotify: false });
    }, 5000);
  };

  onCurrencySelect = (currency) => {
    this.setState({
      currency,
    });
  };

  onAmountChange = (name, amount) => {
    this.setState({
      amount: amount != '' ? amount * 100 : null,
    });
  };

  renderOptions() {
    const { currenciesList } = this.props;

    if (!currenciesList) {
      return [
        {
          value: 'EUR',
          label: 'EUR',
        },
      ];
    }

    return currenciesList.map((elem) => {
      return {
        value: elem,
        label: elem,
      };
    });
  }

  renderContent() {
    const { jackpotError } = this.props;
    const { currency, displayNotify } = this.state;
    // Divide valuues provide by api by 100 as it's Money amoount.
    const defaultJackpotAmount = this.props.defaultJackpotAmount != '' ? this.props.defaultJackpotAmount / 100 : '';
    const amount = this.state.amount != null ? this.state.amount / 100 : '';
    return (
      <React.Fragment>
        <Card.Body>
          <h3>Jackpot Override</h3>
          {displayNotify && jackpotError && <div className="alert alert-danger">Something went wrong</div>}
          {displayNotify && !jackpotError && <div className="alert alert-success">Jackpot Override successfully</div>}
          <form onSubmit={(e) => this.onJackpotOverrideSubmit(e)}>
            <Field
              type="number"
              name="jackpotOverride"
              label={'Amount'}
              placeholder={`Default: ${defaultJackpotAmount}`}
              className="m-0"
              value={amount}
              onChange={this.onAmountChange}
            />
            <label>Currency</label>
            <Select
              options={this.renderOptions()}
              className="mb-2 "
              onChange={(value) => this.onCurrencySelect(value)}
              value={currency}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            <button className="btn btn-primary">Send</button>
          </form>
        </Card.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        {this.renderContent()}
      </Card>
    );
  }
}

DrawJackpot.propTypes = {
  onJackpotOverride: PropTypes.func.isRequired,
  drawId: PropTypes.string,
  currenciesList: PropTypes.arrayOf(PropTypes.string),
  jackpotAmount: PropTypes.string.isRequired,
  jackpotCurrency: PropTypes.string.isRequired,
  jackpotError: PropTypes.object,
  defaultJackpotAmount: PropTypes.number,
};

export default DrawJackpot;
