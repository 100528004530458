import { REPORTS } from '../../actions/types';

const initialState = {
  entries: 25,
  searchTerm: '',
  data: null,
  isPending: false,
  isError: false,
  error: null,
  headers: null,

  csv: {
    isLoading: false,
    error: false,
    isSuccess: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORTS.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
        data: null,
      };
    case REPORTS.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
        data: null,
      };
    case REPORTS.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: action.payload.data,
        headers: action.payload.headers,
      };
    case REPORTS.CSV.FETCH:
      return {
        ...state,
        csv: {
          ...state.csv,
          error: false,
          isSuccess: false,
          isLoading: true,
        },
      };
    case REPORTS.CSV.ERROR:
      return {
        ...state,
        csv: {
          ...state.csv,
          isLoading: false,
          error: true,
        },
      };
    case REPORTS.CSV.SUCCESS:
      return {
        ...state,
        csv: {
          ...state.csv,
          isLoading: false,
          error: false,
          isSuccess: true,
        },
      };
    case REPORTS.CHANGE_ENTRIES:
      return {
        ...state,
        entries: action.payload.entries,
      };
    default:
      return state;
  }
};
