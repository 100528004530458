import { createLogic } from 'redux-logic';
import { push } from 'connected-react-router';

import { OUTBOUND_CHANNELS, NOTIFICATION } from '../actions/types';

export const getChannels = createLogic({
  type: OUTBOUND_CHANNELS.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { page, perPage } = action.payload;

    return httpClient
      .get(`/api/v1/notifications-channel-configuration?page=${page}&perPage=${perPage}`)
      .then((res) => {
        dispatch({
          type: OUTBOUND_CHANNELS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: OUTBOUND_CHANNELS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getChannel = createLogic({
  type: OUTBOUND_CHANNELS.CHANNEL.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .get(`/api/v1/notifications-channel-configuration/${id}`)
      .then((res) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const createChannel = createLogic({
  type: OUTBOUND_CHANNELS.CHANNEL.POST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { channel } = action.payload;

    return httpClient
      .post(`/api/v1/notifications-channel-configuration`, channel)
      .then((res) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Configuration created',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const patchChannel = createLogic({
  type: OUTBOUND_CHANNELS.CHANNEL.PATCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { channel, id } = action.payload;

    return httpClient
      .patch(`/api/v1/notifications-channel-configuration/${id}`, channel)
      .then((res) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Configuration saved',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const deleteChannel = createLogic({
  type: OUTBOUND_CHANNELS.CHANNEL.DELETE,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .delete(`/api/v1/notifications-channel-configuration/${id}`)
      .then((res) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Configuration sucessfully deleted',
          },
        });
        dispatch(push('/configuration/channels'));
      })
      .catch((err) => {
        dispatch({
          type: OUTBOUND_CHANNELS.CHANNEL.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getIntegrations = createLogic({
  type: OUTBOUND_CHANNELS.AVAILABLE_INTEGRATIONS.FETCH,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get(`/api/v1/system-consts/notifications/integrations-configs`)
      .then((res) => {
        dispatch({
          type: OUTBOUND_CHANNELS.AVAILABLE_INTEGRATIONS.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: OUTBOUND_CHANNELS.AVAILABLE_INTEGRATIONS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getTemplate = createLogic({
  type: OUTBOUND_CHANNELS.TEMPLATE.FETCH,
  latest: true,

  process({ httpClient }, dispatch, done) {
    return httpClient
      .get(`/api/v1/system-consts/notifications/config`)
      .then((res) => {
        dispatch({
          type: OUTBOUND_CHANNELS.TEMPLATE.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: OUTBOUND_CHANNELS.TEMPLATE.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getChannels,
  getChannel,
  getIntegrations,
  getTemplate,
  createChannel,
  patchChannel,
  deleteChannel,
};
