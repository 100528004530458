import React from 'react';
import { Card, Button } from 'tabler-react';
import propTypes from 'prop-types';

import CheckboxField from '../Reusable/FormFields/CheckboxField';

class SystemSettingsForm extends React.Component {
  state = {
    maintenanceMode: false,
  };

  componentDidMount() {
    const { maintenanceMode } = this.props;

    this.setState({
      maintenanceMode: `${maintenanceMode}`,
    });
  }

  handleCheckBoxChange = (name, value) => {
    this.setState({
      [name]: `${value}`,
    });
  };

  handleSubmitClick = () => {
    const { onSubmit } = this.props;
    const { maintenanceMode } = this.state;

    onSubmit(maintenanceMode);
  };

  render() {
    const { maintenanceMode } = this.state;

    return (
      <Card className="col-6">
        <Card.Status color="blue" />
        <Card.Header>
          <Card.Title>Maintenance mode</Card.Title>
        </Card.Header>
        <Card.Body>
          <CheckboxField
            value={maintenanceMode === 'true'}
            onChange={(name, value) => this.handleCheckBoxChange(name, value)}
            name="maintenanceMode"
            label="Enable maintenance mode"
          />
          <Button color="primary" onClick={this.handleSubmitClick}>
            Submit
          </Button>
        </Card.Body>
      </Card>
    );
  }
}

SystemSettingsForm.propTypes = {
  maintenanceMode: propTypes.bool,

  onSubmit: propTypes.func.isRequired,
};

export default SystemSettingsForm;
