import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'tabler-react';
import Spinner from '../Reusable/Spinner/Spinner';
import { ErrorHandler } from '../Reusable/ErrorHandler';
import { Formik } from 'formik';

import { Field } from '../Reusable/FormFields';
import Dater from '../Reusable/Dater/Dater';

import './DrawStyles.scss';

class DrawStats extends React.Component {
  renderDrawEdit() {
    const { drawStats, drawData, isPending, error, title, drawStatus } = this.props;
    if (isPending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    if (error) {
      return (
        <div className="mt-3 mr-3 ml-3">
          <ErrorHandler error={error} />
        </div>
      );
    }

    function calculatePercentage(total, subset) {
      if (total === 0 || !total) {
        return '0 %';
      }

      const calculatedPercentage = (subset / total) * 100;

      if (Number.isInteger(calculatedPercentage)) {
        return `${calculatedPercentage} %`;
      }

      return `${calculatedPercentage.toFixed(3)} %`;
    }

    function greyOut() {
      switch (drawStatus) {
        case 'failed':
        case 'cancelled':
        case 'processed':
        case 'voided':
        case 'invalid':
          return true;
        default:
          return false;
      }
    }

    const totalValidTickets = drawStats.tickets.totalValidTickets;
    const totalTicketsCount = drawStats.tickets.totalTicketsCount;
    const totalLinesCount = drawStats.lines.totalLinesCount;

    const wonLinesCount = drawStats.lines.wonLinesCount;
    const lostLinesCount = drawStats.lines.lostLinesCount;
    const validAndProcessedLinesCount = wonLinesCount + lostLinesCount;

    return (
      <React.Fragment>
        <Card.Body>
          {title && <h3>{title}</h3>}
          <Formik
            initialValues={{
              totalUniquePlayers:
                drawStats && drawStats.common.totalUniquePlayers ? drawStats.common.totalUniquePlayers : '0',
              avgTicketCost: drawStats && drawStats.common.avgTicketCost ? drawStats.common.avgTicketCost / 100 : 0,
              totalValidTickets,
              totalTicketsCount,
              totalLinesCount,
              validAndProcessedLinesCount,

              wonLines: `${wonLinesCount} ( ${calculatePercentage(validAndProcessedLinesCount, wonLinesCount)} )`,

              lostLines: `${lostLinesCount} ( ${calculatePercentage(validAndProcessedLinesCount, lostLinesCount)} )`,

              processedTickets: `${drawStats.tickets.totalProcessedTickets} ( ${calculatePercentage(
                totalTicketsCount,
                drawStats.tickets.totalProcessedTickets
              )} )`,

              unprocessedTickets: `${drawStats.tickets.totalUnprocessedTickets} ( ${calculatePercentage(
                totalTicketsCount,
                drawStats.tickets.totalUnprocessedTickets
              )} )`,

              rejectedTickets: `${drawStats.tickets.totalRejectedTickets} ( ${calculatePercentage(
                totalTicketsCount,
                drawStats.tickets.totalRejectedTickets
              )} )`,

              cancelledTickets: `${drawStats.tickets.totalCancelledTickets} ( ${calculatePercentage(
                totalTicketsCount,
                drawStats.tickets.totalCancelledTickets
              )} )`,

              lastTicketProcessedAt: drawData.lastTicketProcessedAt || '',
              betsToResolveCount: `${drawData.betsToResolveCount}` || '',
              timezone: drawData.game.timezone || '',
            }}
            render={({ values }) => (
              <form className="add-brand-form drawStyles">
                <Field
                  value={`${values.totalUniquePlayers}`}
                  name="totalUniquePlayers"
                  label="Total Unique Players"
                  readonly={true}
                />
                <Field
                  value={`${values.avgTicketCost.toFixed(3)} €`}
                  name="avgTivketsCost"
                  label="Average Ticket Cost"
                  readonly={true}
                />

                <div className="flexContainer">
                  <div className="flexItem">
                    <label className="hint">All Tickets/Bet Slip</label>
                    <Field
                      value={`${values.totalTicketsCount}`}
                      name="totalTicketsCount"
                      label="Total Bets"
                      readonly={true}
                    />
                  </div>
                  <div className="flexItem">
                    <label className="hint">All Lines</label>
                    <Field
                      value={`${values.totalLinesCount}`}
                      name="totalLinesCount"
                      label="Total Lines"
                      readonly={true}
                    />
                  </div>
                </div>

                <label className="hint">Total number, included rejected and cancelled tickets.</label>

                <Field
                  value={`${values.totalValidTickets}`}
                  name="totalValidTickets"
                  label="Correct Bets"
                  readonly={true}
                />

                <label className="hint">
                  Correct Bets are paid (confirmed), registered in partners and insured, not cancelled and not voided.
                </label>

                <div className="flexContainer">
                  <div className="flexItem">
                    <Field
                      value={`${values.rejectedTickets}`}
                      name="rejectedTickets"
                      label="Rejected Bets"
                      readonly={true}
                    />
                  </div>
                  <div className="flexItem">
                    <Field
                      value={`${values.cancelledTickets}`}
                      name="cancelledTickets"
                      label="Cancelled Bets"
                      readonly={true}
                    />
                  </div>
                </div>

                <label className="hint">
                  Rejected bets are unpaid, mostly due to insufficient funds. Cancelled bets are rejected or related to
                  platforms issue.
                </label>

                <div className="flexContainer">
                  <div className="flexItem">
                    <Field
                      value={`${values.processedTickets}`}
                      name="processedTickets"
                      label="Processed bets"
                      disabled={greyOut()}
                      readonly={true}
                    />
                  </div>
                  <div className="flexItem">
                    <Field
                      value={`${values.validAndProcessedLinesCount}`}
                      name="validAndProcessedLinesCount"
                      label="Processed lines"
                      disabled={greyOut()}
                      readonly={true}
                    />
                  </div>
                </div>

                <div className="flexContainer">
                  <div className="flexItem">
                    <Field
                      value={`${values.unprocessedTickets}`}
                      name="unprocessedTickets"
                      label="Bets To Process Count"
                      readonly={true}
                    />
                  </div>
                  <div className="flexItem">
                    <label>Last Processed At</label>
                    <div className="fieldWrapper">
                      <Dater time={values.lastTicketProcessedAt} timezone={values.timezone} />
                    </div>
                  </div>
                </div>

                <label className="hint">Processed bets means they are paid out.</label>

                {drawStats.tickets.totalUnprocessedTickets == 0 && (
                  <div className="flexContainer">
                    <div className="flexItem">
                      <Field value={`${values.wonLines}`} name="wonLines" label="Won lines" readonly={true} />
                    </div>
                    <div className="flexItem">
                      <Field
                        value={`${values.lostLines}`}
                        name="unprocessedTickets"
                        label="Lost lines"
                        readonly={true}
                      />
                    </div>
                  </div>
                )}
              </form>
            )}
          />
        </Card.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        {this.renderDrawEdit()}
      </Card>
    );
  }
}

DrawStats.propTypes = {
  drawStats: PropTypes.object,
  drawData: PropTypes.object,
  isPending: PropTypes.bool,
  error: PropTypes.object,
  drawId: PropTypes.string,
  title: PropTypes.string,
  drawStatus: PropTypes.string,
};

export default DrawStats;
