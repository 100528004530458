const genericAsyncReducer = (name, asyncAction) => {
  const initialState = { data: null, headers: null, isLoading: false, error: null, isSuccess: false };

  return (state = initialState, action) => {
    switch (action.type) {
      case `${name}_${asyncAction}_START`:
        return { data: null, headers: null, isLoading: true, error: null, isSuccess: false };
      case `${name}_${asyncAction}_SUCCESS`:
        return {
          data: action.payload.data,
          headers: action.payload.headers,
          isLoading: false,
          error: null,
          isSuccess: true,
        };
      case `${name}_${asyncAction}_ERROR`:
        return { data: null, headers: null, isLoading: false, error: action.payload, isSuccess: false };
      case `${name}_RESET`:
        return initialState;
      default:
        return state;
    }
  };
};

export default genericAsyncReducer;
