import { PROMOTIONS, PROMOTION, PROMOTION_DRAWS, PROMOTION_DRAWS_DATES } from './types';

import { makeActionCreator } from '../utils/makeActionCreator/makeActionCreator';

/* here i used builder for action creators for simple actions */
export const getPromotions = makeActionCreator(PROMOTIONS.GET.START, 'page', 'perPage');
export const postPromotion = makeActionCreator(PROMOTIONS.POST.START, 'data');
export const resetPromotions = makeActionCreator(PROMOTIONS.RESET);

export const getPromotion = makeActionCreator(PROMOTION.GET.START, 'id');
export const deletePromotion = makeActionCreator(PROMOTION.DELETE.START, 'id');
export const putPromotion = makeActionCreator(PROMOTION.PUT.START, 'id', 'data');
export const resetPromotion = makeActionCreator(PROMOTION.RESET);

export const getPromotionDraws = makeActionCreator(PROMOTION_DRAWS.GET.START, 'id', 'page', 'perPage');
export const getPromotionDrawsDates = makeActionCreator(PROMOTION_DRAWS_DATES.GET.START, 'id');
export const resetPromotionDraws = makeActionCreator(PROMOTION_DRAWS.RESET);
