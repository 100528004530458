import { REPORTS_FILTER } from '../../actions/types';

const initialState = {
  instantWinnings: {
    lotteryType: {},
    period: 'Day',
    parentBrand: {},
    player: {},
    dateFrom: '',
    dateTo: '',
  },
  winnings: {
    lotteryType: {},
    period: 'Day',
    parentBrand: {},
    dateFrom: '',
    dateTo: '',
    player: {},
    drawID: '',
    possiblePrizeId: '',
  },
  sales: {
    lotteryType: {},
    period: 'Day',
    parentBrand: {},
    dateFrom: '',
    dateTo: '',
  },
  draws: {
    lotteryType: {},
    period: 'Day',
    parentBrand: {},
    dateFrom: '',
    dateTo: '',
  },
  players: {
    lotteryType: {},
    period: 'Day',
    parentBrand: {},
    dateFrom: '',
    dateTo: '',
    player: {},
  },
  transactions: {
    lotteryType: {},
    period: 'Day',
    parentBrand: {},
    dateFrom: '',
    dateTo: '',
    player: {},
    drawID: '',
    betID: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_FILTER.PLAYER:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          player: action.payload.player,
        },
      };
    case REPORTS_FILTER.BET_ID:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          betID: action.payload.text,
        },
      };
    case REPORTS_FILTER.DRAW_ID:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          drawID: action.payload.text,
        },
      };
    case REPORTS_FILTER.LOTTERY_TYPE:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          lotteryType: action.payload.text || {},
        },
      };
    case REPORTS_FILTER.PERIOD:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          period: action.payload.text,
          dateFrom: '',
          dateTo: '',
        },
      };
    case REPORTS_FILTER.DATE_FROM:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          dateFrom: action.payload.text,
        },
      };
    case REPORTS_FILTER.DATE_TO:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          dateTo: action.payload.text,
        },
      };
    case REPORTS_FILTER.PARENT_BRAND:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          parentBrand: action.payload.text || {},
          lotteryType: {}, // we need to reset lottery filter every time we change brand since it will almost always be outside of the newly selected brand
        },
      };
    case REPORTS_FILTER.POSSIBLE_PRIZE_ID:
      return {
        ...state,
        [action.payload.reportType]: {
          ...state[action.payload.reportType],
          possiblePrizeId: action.payload.possiblePrizeId,
        },
      };
    case REPORTS_FILTER.CLEAR:
      return {
        ...state,
        [action.payload.reportType]: {
          ...initialState[action.payload.reportType],
        },
      };
    default:
      return state;
  }
};
