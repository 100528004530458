import { CURRENCY } from '../../actions/types';

const initialState = {
  allCurrencies: [],
  baseCurrency: null,
  additionalCurrencies: [],
  currenciesToChange: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENCY.SET.SUCCESS:
      return {
        ...state,
        allCurrencies: action.payload.allCurrencies,
        baseCurrency: action.payload.baseCurrency,
        additionalCurrencies: action.payload.additionalCurrencies,
      };
    case CURRENCY.SET.ALL:
      return {
        ...state,
        allCurrencies: action.payload,
      };
    case CURRENCY.CHANGE.ADD:
      return {
        ...state,
        currenciesToChange: [...state.currenciesToChange, action.payload],
      };
    case CURRENCY.CHANGE_BASE:
      return {
        ...state,
        baseCurrency: action.payload,
      };
    case CURRENCY.CHANGE.REMOVE:
      return {
        ...state,
        currenciesToChange: state.currenciesToChange.filter((item) => item !== action.payload),
      };
    case CURRENCY.RESET:
      return {
        ...state,
        allCurrencies: [{ amount: '', currency: 'EUR' }],
        baseCurrency: null,
        additionalCurrencies: [],
        currenciesToChange: [],
      };
    default:
      return state;
  }
};
