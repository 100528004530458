export default function reducerFactory(initialState, handlers, type) {
  return (state = initialState, action) => {
    const handler = handlers[action.type];

    if (action.type.includes('RESET') && action.type.includes(type)) {
      return initialState;
    }

    if (handler) {
      return handler(state, action);
    }

    return state;
  };
}
