import { PERCENTAGE, PRECISE } from '../constans';

/**
 * Function that returns proper promotion object to send.
 * @param {object} data - promotion data from form.
 */
export function preparePromotionDataToSend(data) {
  if (typeof data !== 'object' || !data || Array.isArray(data)) {
    return null;
  }

  const preparedObject = {
    name: data.name,
    game: {
      id: data.lottery.id,
    },
    endDatetime: data.endDate.format(),
    startDatetime: data.startDate.format(),
    promoType: data.promotionType.value,
  };

  if (data.promotionType.value === PERCENTAGE) {
    preparedObject.prizesMultipliers = preparePrize(data.selectedPrizes, PERCENTAGE);
    preparedObject.prizesOverride = null;
  }

  if (data.promotionType.value === PRECISE) {
    preparedObject.prizesMultipliers = null;
    preparedObject.prizesOverride = preparePrize(data.selectedPrizes, PRECISE);
  }

  return preparedObject;
}

function preparePrize(data, type) {
  return data.reduce((acc, cur) => {
    if (type === PRECISE) {
      return {
        ...acc,
        [cur.name]: {
          amount: cur.data.amount * 100,
          currency: cur.data.currency,
        },
      };
    } else {
      return {
        ...acc,
        [cur.name]: parseInt(cur.data),
      };
    }
  }, {});
}
