import { REPORTS, REPORTS_FILTER, REPORT_SUMMARY } from './types';

export const getReports = (reportType, page = 1) => ({
  type: REPORTS.FETCH,
  payload: {
    reportType,
    page,
  },
});

export const getReportSummary = (reportType) => ({
  type: REPORT_SUMMARY.FETCH,
  payload: reportType,
});

export const changeEntries = (entries, reportType) => ({
  type: REPORTS.CHANGE_ENTRIES,
  payload: {
    entries,
    reportType,
  },
});

export const changeLotteryType = (text, reportType) => ({
  type: REPORTS_FILTER.LOTTERY_TYPE,
  payload: {
    text,
    reportType,
  },
});

export const changeParentBrand = (text, reportType) => ({
  type: REPORTS_FILTER.PARENT_BRAND,
  payload: {
    text,
    reportType,
  },
});

export const changePeriod = (text, reportType) => ({
  type: REPORTS_FILTER.PERIOD,
  payload: {
    text,
    reportType,
  },
});

export const changeDateFrom = (text, reportType) => ({
  type: REPORTS_FILTER.DATE_FROM,
  payload: {
    text,
    reportType,
  },
});

export const changeDateTo = (text, reportType) => ({
  type: REPORTS_FILTER.DATE_TO,
  payload: {
    text,
    reportType,
  },
});

export const changePlayer = (player, reportType) => ({
  type: REPORTS_FILTER.PLAYER,
  payload: {
    player,
    reportType,
  },
});

export const changeBetID = (text, reportType) => ({
  type: REPORTS_FILTER.BET_ID,
  payload: {
    text,
    reportType,
  },
});

export const changeDrawID = (text, reportType) => ({
  type: REPORTS_FILTER.DRAW_ID,
  payload: {
    text,
    reportType,
  },
});

export const changePlayerName = (text, reportType) => ({
  type: REPORTS_FILTER.PLAYER_NAME,
  payload: {
    text,
    reportType,
  },
});

export const changeShowWithSales = (show, reportType) => ({
  type: REPORTS_FILTER.WITH_SALES,
  payload: {
    show,
    reportType,
  },
});

export const changePossiblePrizeId = (possiblePrizeId, reportType) => ({
  type: REPORTS_FILTER.POSSIBLE_PRIZE_ID,
  payload: {
    possiblePrizeId,
    reportType,
  },
});

export const resetFilters = (reportType) => ({
  type: REPORTS_FILTER.CLEAR,
  payload: {
    reportType,
  },
});

export const getReportsCSV = (reportType) => ({
  type: REPORTS.CSV.FETCH,
  payload: {
    reportType,
  },
});
