import moment from 'moment';

export const displayDate = (date) => {
  return moment(date).format('DD-MM-YYYY HH:mm');
};

export const displayDateWithoutTime = (date) => {
  return moment(date).format('DD-MM-YYYY');
};

export const displayOnlyTime = (date) => {
  return moment(date).format('HH:mm');
};

export const getFormatMode = (period) => {
  switch (period) {
    case 'Year':
      return {
        dateFormat: 'YYYY',
        viewMode: 'years',
      };
    case 'Month':
      return {
        dateFormat: 'MMMM YYYY',
        viewMode: 'months',
      };
    case 'Day':
      return {
        dateFormat: 'DD MMMM YYYY',
        viewMode: 'days',
      };
    default:
      return {
        dateFormat: false,
        viewMode: '',
      };
  }
};

export const checkIsDuringPeriod = (startDate, endDate) => {
  const now = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return end.isSameOrAfter(now) && start.isSameOrBefore(now);
};
