import genericAsyncReducer from './generic/genericAsyncReducer';

const GET = 'GET';
const SYSTEM_CONSTS = 'SYSTEM_CONSTS';
const CURRENCIES = `${SYSTEM_CONSTS}_CURRENCIES`;
const GAME_RESULTS_AGENCIES = `${SYSTEM_CONSTS}_GAME_RESULT_AGENCIES`;
const PROVIDER_DRAW_STATUSES = `${SYSTEM_CONSTS}_PROVIDER_DRAW_STATUSES`;
const AVAILABLE_WINNING_SELECTIONS = `${SYSTEM_CONSTS}_AVAILABLE_WINNING_SELECTIONS`;
const PRIZE_TYPES = `${SYSTEM_CONSTS}_PRIZE_TYPES`;

const allowedCurrencies = genericAsyncReducer(CURRENCIES, GET);
const gameResultAgencies = genericAsyncReducer(GAME_RESULTS_AGENCIES, GET);
const providerDrawStatuses = genericAsyncReducer(PROVIDER_DRAW_STATUSES, GET);
const availableWinningSelections = genericAsyncReducer(AVAILABLE_WINNING_SELECTIONS, GET);

const prizeTypes = genericAsyncReducer(PRIZE_TYPES, GET);

export default {
  allowedCurrencies,
  gameResultAgencies,
  providerDrawStatuses,
  availableWinningSelections,
  prizeTypes,
};
