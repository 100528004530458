import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

class SectionPreview extends React.Component {
  deserializeColorToRGBA(color) {
    if (!color) {
      return '';
    }

    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  }

  getButtonShadow(shadowColor, shadowProps, text) {
    return `${this.deserializeColorToRGBA(shadowColor)} ${shadowProps.x}px ${shadowProps.y}px ${shadowProps.b}px ${
      text.length ? '2' : '0'
    }px`;
  }

  getSectionProps(sectionType) {
    switch (sectionType) {
      case 'BIG':
        return { height: 360, flexD: 'column' };
      case 'MEDIUM':
        return { height: 90, flexD: 'row' };
      default:
        return { height: 120, flexD: 'column' };
    }
  }

  getImgToDisplay = (image) => (image.base64 ? `url(data:image/jpeg;base64,${image.base64})` : `url(${image.url})`);

  renderLabel = (label, index) => {
    const newLabel = (
      <p
        key={index}
        className={css`
          color: ${this.deserializeColorToRGBA(label.color)};
          font-size: ${label.fontSize}px;
          font-weight: ${label.fontWeight};
          border-width: ${label.borderWidth}px;
          border-color: ${this.deserializeColorToRGBA(label.borderColor)};
          border-style: solid;
          margin: 0 5px;
        `}
      >
        {label.text}
      </p>
    );

    return newLabel;
  };

  renderPreview = (section) => {
    const { labels, background, type, logo, button } = section;

    let desiredLabels;

    if (type !== 'MEDIUM') {
      desiredLabels = labels.map((label, i) => this.renderLabel(label, i));
    } else {
      desiredLabels = (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: normal;
            width: 52%;
          `}
        >
          {(desiredLabels = labels.map((label, i) => this.renderLabel(label, i)))}
        </div>
      );
    }

    return (
      <div
        className={css`
          min-height: ${this.getSectionProps(type).height}px;
          ${type === 'SMALL' ? 'width: 50%' : ''};
          background-color: ${this.deserializeColorToRGBA(background.fillColor)};
          border-color: ${this.deserializeColorToRGBA(background.borderColor)};
          border-width: ${background.borderWidth}px;
          border-style: solid;
          display: flex;
          flex-direction: ${this.getSectionProps(type).flexD};
          align-items: center;
          background-image: ${background.image && this.getImgToDisplay(background.image)};
          background-repeat: no-repeat;
          background-size: cover;
          padding: 10px 18px;
          ${type === 'MEDIUM' ? 'justify-content: space-between' : ''}
        `}
      >
        {type !== 'SMALL' ? (
          <div
            className={css`
              min-height: ${type === 'MEDIUM' ? '70' : '100'}px;
              width: ${type === 'MEDIUM' ? '70' : '100'}px;
              background-image: ${logo.image && this.getImgToDisplay(logo.image)};
              background-size: cover;
              background-repeat: no-repeat;
              margin: ${type === 'MEDIUM' ? '' : '40px 0px 10px 0px'};
            `}
          />
        ) : (
          <div
            className={css`
              min-height: 50px;
              width: 50px;
              background-image: ${logo.image && this.getImgToDisplay(logo.image)};
              background-size: cover;
              background-repeat: no-repeat;
              margin: 0 0 5px 0;
            `}
          />
        )}
        {desiredLabels}
        <button
          type="button"
          className={css`
            background-color: ${this.deserializeColorToRGBA(button.buttonColor)};
            font-size: ${button.text.fontSize}px;
            font-weight: ${button.text.fontWeight};
            color: ${this.deserializeColorToRGBA(button.text.color)};
            border-color: ${this.deserializeColorToRGBA(button.text.borderColor)};
            border-width: ${button.text.borderWidth}px;
            box-shadow: ${this.getButtonShadow(button.shadowColor, section.button.shadowProps, button.text.text)};
            border-radius: 4px;
            margin: 5px 0;
            padding: ${button.text.text.length ? null : '0px'};
            &:focus {
              outline: 0;
            }
          `}
        >
          {button.text.text}
        </button>
      </div>
    );
  };

  render() {
    const { section } = this.props;

    return <div className="previewContainer">{this.renderPreview(section)}</div>;
  }
}

SectionPreview.propTypes = {
  section: PropTypes.object,
};

export default SectionPreview;
