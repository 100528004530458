import { createLogic } from 'redux-logic';
import { i18n, NOTIFICATION } from '../../actions/types';

export const getLotteryI18n = createLogic({
  type: i18n.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;
    const address = `/api/v1/games/${id}/i18n`;

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: i18n.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: i18n.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getLanguage = createLogic({
  type: i18n.LANGUAGE.FETCH.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const id = action.payload;
    const address = `/api/v1/games-i18n/${id}`;

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: i18n.LANGUAGE.FETCH.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: i18n.LANGUAGE.FETCH.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const patchLanguage = createLogic({
  type: i18n.LANGUAGE.PATCH.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { language } = action.payload;
    const address = `/api/v1/games-i18n/${language.id}`;

    return httpClient
      .patch(address, language)
      .then(() => {
        dispatch({
          type: i18n.LANGUAGE.PATCH.SUCCESS,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Language updated',
          },
        });
        dispatch({
          type: i18n.LANGUAGE.FETCH.START,
          payload: language.id,
        });
      })
      .catch((err) => {
        dispatch({
          type: i18n.LANGUAGE.PATCH.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const postLanguage = createLogic({
  type: i18n.LANGUAGE.POST.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { language, id } = action.payload;
    const address = `/api/v1/games/${id}/i18n/${language.language}`;
    return httpClient
      .post(address, language)
      .then(() => {
        dispatch({
          type: i18n.LANGUAGE.POST.SUCCESS,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Language created',
          },
        });
        dispatch({
          type: i18n.FETCH,
          payload: { id },
        });
      })
      .catch((err) => {
        dispatch({
          type: i18n.LANGUAGE.POST.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const deleteLanguage = createLogic({
  type: i18n.LANGUAGE.DELETE,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { languageId, lotteryId } = action.payload;
    const address = `/api/v1/games-i18n/${languageId}`;
    return httpClient
      .delete(address)
      .then(() => {
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Language deleted',
          },
        });
        dispatch({
          type: i18n.FETCH,
          payload: { id: lotteryId },
        });
      })
      .catch((err) => {
        dispatch({
          type: i18n.LANGUAGE.POST.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getLotteryI18n,
  patchLanguage,
  postLanguage,
  deleteLanguage,
  getLanguage,
};
