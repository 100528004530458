import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'tabler-react';
import { Formik, Field } from 'formik';

import Spinner from '../Reusable/Spinner/Spinner';
import { ErrorHandler } from '../Reusable/ErrorHandler';
import Dater from '../Reusable/Dater/Dater';
import FieldInput from '../Reusable/FormFields/Field';

import './DrawStyles.scss';

class DrawEdit extends React.Component {
  renderDrawEdit() {
    const { draw, isPending, error, title } = this.props;

    if (isPending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    if (error) {
      return (
        <div className="mt-3 mr-3 ml-3">
          <ErrorHandler error={error} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Card.Body>
          {title && <h3>{title}</h3>}
          <Formik
            initialValues={{
              drawId: draw ? draw.id : '',
              drawNumber: draw ? draw.drawNumber : '',
              systemWideDrawNumber: draw ? draw.systemWideDrawNumber : '',
              brand: draw && draw.game.brand ? draw.game.brand.name : '',
              lottery: draw && draw.game ? draw.game.name : '',
              lotteryId: draw && draw.game ? draw.game.id : '',
              drawDatetime: draw ? draw.drawDatetime : '',
              openDatetime: draw ? draw.openDatetime : '',
              originalDrawDateTime: draw ? draw.originaldrawDatetime : '',
              closeDatetime: draw ? draw.closeDatetime : '',
              resolvedAt: draw ? draw.resolvedAt : '',
              resultConfirmedAt: draw ? draw.resultConfirmedAt : '',
              canceledAt: draw ? draw.canceledAt : '',
              prizesResolvedAt: draw ? draw.prizesResolvedAt : '',
              allBetsResolvedAt: draw ? draw.allBetsResolvedAt : '',
              prizesValueResolvedAt: draw ? draw.prizesValueResolvedAt : '',
              resultsFetchedAt: draw ? draw.resultsFetchedAt : '',
              voidedAt: draw ? draw.voidedAt : '',
              registeredGameResultAt: draw ? draw.registeredGameResultAt : '',
              registeredInsuranceAt: draw ? draw.registeredInsuranceAt : '',
              winningsResolvedAt: draw ? draw.winningsResolvedAt : '',
              timezone: draw ? draw.game.timezone : '',
              status: draw ? draw.status : '',
            }}
            render={({ values, errors, touched, setFieldValue, setFieldTouched }) => (
              <form className="add-brand-form drawStyles">
                <div className="form-group">
                  <label htmlFor={'drawId'}>Draw ID</label>
                  <Field
                    label="drawId"
                    name="drawId"
                    className="form-control"
                    onChange={setFieldValue}
                    onBlur={() => setFieldTouched('name', true)}
                    value={values.drawId}
                    readOnly={true}
                    touched={touched.drawId}
                    error={errors.drawId}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={'drawId'}>Draw Number</label>
                  <Field
                    label="drawNumber"
                    name="drawNumber"
                    className="form-control"
                    onChange={setFieldValue}
                    onBlur={() => setFieldTouched('name', true)}
                    value={values.drawNumber}
                    readOnly={true}
                    touched={touched.drawNumber}
                    error={errors.drawNumber}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={'drawId'}>System Wide Draw Number</label>
                  <Field
                    label="systemWideDrawNumber"
                    name="systemWideDrawNumber"
                    className="form-control"
                    onChange={setFieldValue}
                    onBlur={() => setFieldTouched('name', true)}
                    value={values.systemWideDrawNumber}
                    readOnly={true}
                    touched={touched.systemWideDrawNumber}
                    error={errors.systemWideDrawNumber}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={'brand'}>Brand</label>
                  <Field
                    label="Brand"
                    name="brand"
                    className="form-control"
                    onChange={setFieldValue}
                    onBlur={() => setFieldTouched('brand', true)}
                    value={values.brand}
                    readOnly={true}
                    touched={touched.brand}
                    error={errors.brand}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <FieldInput
                    label="Lottery"
                    name="lottery"
                    className="form-control"
                    link={`/lottery/lotteries/${values.lotteryId}`}
                    value={values.lottery}
                    readOnly={true}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <FieldInput
                    label="Status"
                    name="status"
                    className="form-control"
                    value={values.status}
                    readOnly={true}
                    type="text"
                  />
                  <label className="hint">{draw ? draw.selfExplanatoryStatus : ''}</label>
                </div>
                <label>Draw date time</label>
                <div className="fieldWrapper">
                  <Dater time={values.drawDatetime} timezone={values.timezone} />
                </div>
                <label>Open date time</label>
                <div className="fieldWrapper">
                  <Dater time={values.openDatetime} timezone={values.timezone} />
                </div>
                <label>Original draw date time</label>
                <div className="fieldWrapper">
                  <Dater time={values.originalDrawDateTime} timezone={values.timezone} />
                </div>
                <label>Close date time</label>
                <div className="fieldWrapper">
                  <Dater time={values.closeDatetime} timezone={values.timezone} />
                </div>
                <label>Results Fetched At</label>
                <div className="fieldWrapper">
                  <Dater time={values.resultsFetchedAt} timezone={values.timezone} />
                </div>
                <label>Prizes Value Resolved At</label>
                <div className="fieldWrapper">
                  <Dater time={values.prizesValueResolvedAt} timezone={values.timezone} />
                </div>
                <label>All Bets Resolved At</label>
                <div className="fieldWrapper">
                  <Dater time={values.allBetsResolvedAt} timezone={values.timezone} />
                </div>
                <label>Result confirmed at</label>
                <div className="fieldWrapper">
                  <Dater time={values.resultConfirmedAt} timezone={values.timezone} />
                </div>
                <label>Cancel at</label>
                <div className="fieldWrapper">
                  <Dater time={values.canceledAt} timezone={values.timezone} />
                </div>
                <label>Voided at</label>
                <div className="fieldWrapper">
                  <Dater time={values.voidedAt} timezone={values.timezone} />
                </div>
                <label>Registered at Results Agency</label>
                <div className="fieldWrapper">
                  <Dater time={values.registeredGameResultAt} timezone={values.timezone} />
                </div>
                <label>Registered at Insurance Agency</label>
                <div className="fieldWrapper">
                  <Dater time={values.registeredInsuranceAt} timezone={values.timezone} />
                </div>
              </form>
            )}
          />
        </Card.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        {this.renderDrawEdit()}
      </Card>
    );
  }
}

DrawEdit.propTypes = {
  draw: PropTypes.object,
  isPending: PropTypes.bool,
  error: PropTypes.object,
  drawId: PropTypes.string,
  title: PropTypes.string,
};

export default DrawEdit;
