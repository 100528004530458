import { PLAYERS as PLAYERS_TYPES } from '../actions/types';

const initialState = { data: null, headers: null, isLoading: false, error: null, isSuccess: false };
const initialFiltersState = { name: '', phone: '', id: '' };

const PLAYER = 'SINGLE_PLAYER';
const PLAYERS = 'PLAYERS';
const GET = 'GET';
const PUT = 'PUT';

const playersFactory = (name, asyncAction) => {
  return (state = initialState, action) => {
    switch (action.type) {
      case `${name}_${asyncAction}_START`:
        return { ...state, isLoading: true };
      case `${name}_${asyncAction}_SUCCESS`:
        return {
          data: action.payload.data,
          headers: action.payload.headers,
          isLoading: false,
          error: null,
          isSuccess: true,
        };
      case `${name}_${asyncAction}_ERROR`:
        return { data: null, headers: null, isLoading: false, error: action.payload, isSuccess: false };
      case `${name}_RESET`:
        return initialState;
      default:
        return state;
    }
  };
};

const playersFiltersReducer = () => {
  return (state = initialFiltersState, action) => {
    switch (action.type) {
      case PLAYERS_TYPES.FILTERS.NAME:
        return { ...state, name: action.payload };
      case PLAYERS_TYPES.FILTERS.ID:
        return { ...state, id: action.payload };
      case PLAYERS_TYPES.FILTERS.PHONE:
        return { ...state, phone: action.payload };
      case PLAYERS_TYPES.FILTERS.RESET:
        return initialState;
      default:
        return state;
    }
  };
};

const players = playersFactory(PLAYERS, GET);
const singlePlayer = playersFactory(PLAYER, GET);
const updatedPlayer = playersFactory(PLAYER, PUT);
const playersFilters = playersFiltersReducer();

export default {
  players,
  singlePlayer,
  updatedPlayer,
  playersFilters,
};
