import authLogic from './authLogic';
import brands from './brandsLogic';
import backendUsers from './backendUsersLogic';
import lotteries from './lotteryLogic/lotteryLogic';
import lotterySchedule from './lotteryLogic/lotteryScheduleLogic';
import lotteryI18n from './lotteryLogic/lotteryI18n';
import draws from './drawsLogic';
import reports from './reportsLogic';
import dashboard from './dashboardLogic';
import tickets from './ticketsLogic';
import bet from './betLogic';
import player from './playerLogic';
import bets from './betsLogic';
import lobby from './lobbyLogic';
import lobbyVersions from './lobbyVersionLogic';
import outboundChannelsLogic from './outboundChannelsLogic';
import systemConsts from './systemConsts';
import exchangeRates from './exchangeRatesLogic';
import providersLogic from './providersLogic';
import systemSettingsLogic from './systemSettingsLogic';
import playersLogic from './playersLogic';
import taxesLogic from './taxesLogic';
import providerDrawsLogic from './providerDraws';
import promotions from './promotions';

export default [
  authLogic.login,
  authLogic.logout,
  authLogic.getMe,
  authLogic.forgotPassword,
  authLogic.confirmEmail,
  authLogic.setNewPassword,
  brands.getBrands,
  brands.getBrand,
  brands.deleteBrand,
  brands.modifyBrand,
  backendUsers.getBackendUsers,
  backendUsers.deleteBackendUser,
  backendUsers.getBackendUser,
  lotteries.deleteLottery,
  lotteries.getCurrencies,
  lotteries.updateCurrencies,
  lotteries.getLottery,
  lotteries.getLotteries,
  lotteries.getLotteryType,
  lotteries.editAddingPrices,
  lotteries.changePriceCurrency,
  lotteries.changePriceType,
  lotteries.prepareEdit,
  lotteries.getTimezones,
  lotteries.getBettingAgencies,
  lotteries.getBettingAgency,
  lotteries.editLotteryMessageTemplates,
  lotteries.changeMainCurrency,
  draws.getDraws,
  draws.getDraw,
  draws.getDrawSummary,
  draws.getDrawBets,
  draws.getDrawStats,
  draws.putNewJackpot,
  draws.putResults,
  draws.deleteResults,
  reports.getReports,
  reports.getReportSummary,
  reports.getReportsCSV,
  dashboard.getDashboardData,
  tickets.getTickets,
  bet.getBet,
  bet.getBetLines,
  bet.getBetPerms,
  bet.managementAction,
  player.getPlayerStats,
  bets.getBets,
  lobby.getLobby,
  lobby.getLobbies,
  lobby.updateLobby,
  lobby.mergeVariants,
  lobby.publishLobby,
  lobby.patchLobby,
  lobby.deleteLobby,
  lotteryI18n.getLotteryI18n,
  lotteryI18n.patchLanguage,
  lotteryI18n.postLanguage,
  lotteryI18n.getLanguage,
  lotteryI18n.deleteLanguage,
  lotterySchedule.postSchedule,
  lobbyVersions.getLobbyVersions,
  lobbyVersions.getVersion,
  lobbyVersions.deleteLobbyVersion,
  outboundChannelsLogic.getIntegrations,
  outboundChannelsLogic.getTemplate,
  outboundChannelsLogic.getChannels,
  outboundChannelsLogic.getChannel,
  outboundChannelsLogic.createChannel,
  outboundChannelsLogic.patchChannel,
  outboundChannelsLogic.deleteChannel,
  systemConsts.getAllowedCurrencies,
  systemConsts.getGameResultAgencies,
  systemConsts.getProviderDrawStatuses,
  systemConsts.getAvailableWinningSelections,
  systemConsts.getPrizesTypes,
  exchangeRates.getExchangeRates,
  exchangeRates.putExchangeRates,
  exchangeRates.deleteExchangeRates,
  exchangeRates.postExchangeRates,
  providersLogic.getProviderConfig,
  providersLogic.getSingleProviderConfig,
  providersLogic.postProviderConfig,
  providersLogic.putProviderConfig,
  providersLogic.deleteSingleProviderConfig,
  providersLogic.getFewProviderConfig,
  providersLogic.postProviderSchedule,
  systemSettingsLogic.getSystemSettings,
  systemSettingsLogic.putSystemSettings,
  playersLogic.getPlayers,
  playersLogic.getSinglePlayer,
  playersLogic.putPlayer,
  taxesLogic.getBrandTaxes,
  taxesLogic.deleteTax,
  taxesLogic.putTaxes,
  providerDrawsLogic.getProviderDraws,
  providerDrawsLogic.getSingleProviderDraw,
  providerDrawsLogic.getGameDraws,
  promotions.getPromotions,
  promotions.getPromotion,
  promotions.putPromotion,
  promotions.deletePromotion,
  promotions.postPromotion,
  promotions.getPromotionDraws,
  promotions.getPromotionDrawsDates,
];
