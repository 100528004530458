import React from 'react';
import { connect } from 'react-redux';
import { Grid, Page } from 'tabler-react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import Modal from '../Reusable/Modal/CustomModal';
import Select from 'react-select';
import { Button } from 'tabler-react';
import { Redirect } from 'react-router-dom';

import Spinner from '../Reusable/Spinner';
import { SearchBrand } from '../Reusable/FormFields';

import { getLobby, deleteLobby, patchLobbyMeta, resetLobbyMetaData } from '../../actions/lobbyActions/lobbyActions';
import { languagesList } from '../../utils/constans';
import lobbySelectors from '../../selectors/lobby';

class LobbyEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
    };
  }

  componentDidMount() {
    const { getLobby } = this.props;
    getLobby(this.getID(), true);
  }

  componentWillUnmount() {
    const { resetLobbyMetaData } = this.props;
    resetLobbyMetaData();
  }

  getID = () => this.props.match.params.id;

  handleModalToggle = () => {
    this.setState((state) => ({ isDeleteModalOpen: !state.isDeleteModalOpen }));
  };

  deleteLobby = () => {
    this.props.deleteLobby(this.getID());
  };

  submitForm = (lobby) => {
    const { patchLobbyMeta } = this.props;
    patchLobbyMeta(this.getID(), { ...lobby, defaultLanguage: lobby.defaultLanguage.value });
  };

  handleDeleteButton = (e) => {
    e.preventDefault();
    this.handleModalToggle();
  };

  render() {
    const { pending, lobby, lobbyMetaData, deleteSuccess } = this.props;

    const emptyForm = {
      lobbyName: '',
    };

    if (deleteSuccess) {
      return <Redirect to={`/lobbies`} />;
    }

    return (
      <Page>
        <Page.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Page.Title>
              <span>Edit Lobby</span>
            </Page.Title>
          </div>
        </Page.Header>
        <Grid.Row>
          <div style={{ width: `100%` }}>
            {pending && <Spinner />}
            {lobby && !pending && (
              <React.Fragment>
                <Modal
                  isOpen={this.state.isDeleteModalOpen}
                  toggleModal={this.handleModalToggle}
                  handleSubmit={() => {
                    this.deleteLobby();
                    this.handleModalToggle();
                  }}
                  title="Are you sure you want to delete this lobby?"
                  classname="react-modal-custom__delete"
                  statusColor="danger"
                  btnConfirmText="Confirm"
                  btnConfirmColor="danger"
                />
                <Formik
                  onSubmit={this.submitForm}
                  enableReinitialize
                  initialValues={lobbyMetaData ? lobbyMetaData : emptyForm}
                >
                  {(lobbyForm) => (
                    <Form className="lotteryI18nConfig">
                      <label>Lobby name</label>
                      <Field name={`name`} className="form-control mr-2" />
                      <div className="form-group">
                        <label>Language</label>
                        <Select
                          name="defaultLanguage"
                          className="react-select react-select-lang"
                          options={languagesList}
                          onChange={(value) => lobbyForm.setFieldValue('defaultLanguage', value)}
                          value={lobbyForm.values.defaultLanguage}
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <SearchBrand
                          value={lobbyForm.values.brand}
                          setFieldValue={lobbyForm.setFieldValue}
                          className="react-select"
                          labelText="User's brand"
                        />
                      </div>

                      <Button loading={false} type="submit" color={'primary'}>
                        Save
                      </Button>

                      <Button loading={false} onClick={this.handleDeleteButton} color="danger">
                        Delete
                      </Button>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}
          </div>
        </Grid.Row>
      </Page>
    );
  }
}

LobbyEdit.propTypes = {
  getLobby: PropTypes.func,
  saveLobby: PropTypes.func,
  patchLobbyMeta: PropTypes.func,
  deleteLobby: PropTypes.func,
  match: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
  lobby: PropTypes.object,
  lobbyMetaData: PropTypes.object,
  deleteSuccess: PropTypes.bool,
  resetLobbyMetaData: PropTypes.func,
};

export default connect(
  (state) => ({
    lobby: state.lobby,
    pending: state.lobbyMeta.isPending,
    lobbyMetaData: lobbySelectors.getLobbyMetaData(state.lobby.data),
    deleteSuccess: state.lobbyMeta.delete.success,
  }),
  {
    getLobby,
    patchLobbyMeta,
    deleteLobby,
    resetLobbyMetaData,
  }
)(LobbyEdit);
