import { createLogic } from 'redux-logic';

import { BET, BET_LINES, BET_PERMS, MANAGEMENT_ACTION, NOTIFICATION } from '../actions/types';

export const getBet = createLogic({
  type: BET.FETCH,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id, type } = action.payload;
    const toApiType = type === 'default' ? '' : `${type}-`;
    const address = `/api/v1/${toApiType}bets/${id}`;

    return httpClient
      .get(address)
      .then((res) => {
        dispatch({
          type: BET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: BET.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getBetLines = createLogic({
  type: BET_LINES.FETCH,
  latest: true,

  process({ httpClient, action, getState }, dispatch, done) {
    const betLines = getState().betLines;
    const id = action.payload.id;

    const defaultPerPage = 100;
    const headersPerPage = betLines.headers ? betLines.headers['x-per-page'] : defaultPerPage;
    const perPage = action.payload.entries || headersPerPage;
    const page = action.payload.page;

    const address = `/api/v1/bets/${id}/lines`;

    const params = {
      page,
      perPage,
    };

    return httpClient
      .get(address, { params })
      .then((res) => {
        dispatch({
          type: BET_LINES.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: BET_LINES.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const getBetPerms = createLogic({
  type: BET_PERMS.FETCH,
  latest: true,

  process({ httpClient, action, getState }, dispatch, done) {
    const betPerms = getState().betPerms;
    const id = action.payload.id;

    const defaultPerPage = 100;
    const headersPerPage = betPerms.headers ? betPerms.headers['x-per-page'] : defaultPerPage;
    const perPage = action.payload.entries || headersPerPage;
    const page = action.payload.page;

    const address = `/api/v1/bets/${id}/permutations`;

    const params = {
      page,
      perPage,
    };

    return httpClient
      .get(address, { params })
      .then((res) => {
        const desiredData = [...res.data].map((elem) => {
          elem['selection'] = { asArray: { ...elem.asSimpleNumbersArray } };
          elem['wonPrize'] = { amount: { ...elem.cachedStats.totalRealWinnings } };
          return elem;
        });

        dispatch({
          type: BET_PERMS.SUCCESS,
          payload: {
            data: desiredData,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: BET_PERMS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export const managementAction = createLogic({
  type: MANAGEMENT_ACTION.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id, name, actionName } = action.payload;

    return httpClient
      .post(`/api/v1/${name.toLowerCase()}s/${id}/${actionName}`)
      .then(() => {
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: `${name} ${actionName} executed`,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: `Something went wrong`,
          },
        });
      })
      .then(done);
  },
});

export default {
  getBet,
  getBetLines,
  getBetPerms,
  managementAction,
};
