export const LOBBY = {
  FETCH: 'LOBBY_FETCH',
  SUCCESS: 'LOBBY_SUCCESS',
  ERROR: 'LOBBY_ERROR',
};

export const LOBBY_LIST = {
  FETCH: 'LOBBY_LIST_FETCH',
  SUCCESS: 'LOBBY_LIST_SUCCESS',
  ERROR: 'LOBBY_LIST_ERROR',
  CHANGE_ENTRIES: 'LOBBY_LIST_CHANGE_ENTRIES',
};

export const LOBBY_INIT = {
  POST: 'LOBBY_POST',
  SUCCESS: 'LOBBY_SUCCESS',
  ERROR: 'LOBBY_ERROR',
};

export const LOBBY_UPDATE = {
  MERGE: 'LOBBY_UPDATE_MERGE',
  UPDATE: 'LOBBY_UPDATE_UPDATE',
  POST: 'LOBBY_UPDATE_POST',
  SUCCESS: 'LOBBY_UPDATE_SUCCESS',
  ERROR: 'LOBBY_UPDATE_ERROR',
};

export const LOBBY_PUBLISH = {
  POST: 'LOBBY_PUBLISH_POST',
  // SUCCESS: 'LOBBY_PUBLISH_SUCCESS',
  // ERROR: 'LOBBY_PUBLISH_ERROR',
};

export const LOBBY_META = {
  UPDATE: 'LOBBY_META_UPDATE',
  DELETE: 'LOBBY_META_DELETE',
  SUCCESS: 'LOBBY_META_SUCCESS',
  ERROR: 'LOBBY_META_ERROR',
  RESET: 'LOBBY_META_RESET',
};

export const LOBBY_META_DELETE = {
  START: 'LOBBY_META_DELETE_START',
  SUCCESS: 'LOBBY_META_DELETE_SUCCESS',
  ERROR: 'LOBBY_META_DELETE_ERROR',
};
