import { REPORT_SUMMARY } from '../../actions/types';

const initialState = {
  data: null,
  isPending: false,
  isError: false,
  error: null,
  headers: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORT_SUMMARY.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
        data: null,
      };
    case REPORT_SUMMARY.SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        error: null,
        data: action.payload.data,
      };
    case REPORT_SUMMARY.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload.data,
        data: null,
      };

    default:
      return state;
  }
};
