import { PLAYERS, SINGLE_PLAYER } from './types';

export const getPlayers = (page = 1, perPage) => ({
  type: PLAYERS.GET.START,
  payload: {
    page,
    perPage,
  },
});

export const getSinglePlayer = (id) => ({
  type: SINGLE_PLAYER.GET.START,
  payload: id,
});

export const putPlayer = (id, isTestPlayer) => ({
  type: SINGLE_PLAYER.PUT.START,
  payload: {
    id,
    isTestPlayer,
  },
});

export const changeNameFilter = (name) => ({
  type: PLAYERS.FILTERS.NAME,
  payload: name,
});

export const changePhoneFilter = (phone) => ({
  type: PLAYERS.FILTERS.PHONE,
  payload: phone,
});

export const changeIdFilter = (id) => ({
  type: PLAYERS.FILTERS.ID,
  payload: id,
});

export const resetFilters = () => ({
  type: PLAYERS.FILTERS.RESET,
});
