import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { Button, Table } from 'tabler-react';
import PropTypes from 'prop-types';

import SingleScheduleRow from './SingleScheduleRow';

import Schedule from '../../../services/Schedule';

import { scheduleTableHeaders } from '../../../utils/constans';

class LotteryScheduleForm extends React.PureComponent {
  getCurrentSchedule = () => {
    const { currentSchedule } = this.props;

    if (!currentSchedule) {
      return [];
    }

    return currentSchedule;
  };

  getNewSingleSchedule = () => {
    const { timezone } = this.props;

    return Schedule.getDefaultSchedule(timezone);
  };

  validateNumber(value, min, max, errors, name) {
    if (!value) {
      errors[name] = 'Field is required';
    }

    if (value > max || value < min) {
      errors[name] = `Value should be in range ${min} - ${max}`;
    }
  }

  validateForm(values) {
    const errors = {};

    values.schedule.forEach((elem, index) => {
      this.validateNumber(elem.hour, 0, 23, errors, `schedule.${index}.hour`);
      this.validateNumber(elem.minute, 0, 59, errors, `schedule.${index}.minute`);
      this.validateNumber(elem.second, 0, 59, errors, `schedule.${index}.second`);

      if (!elem.dayOfWeek) {
        errors[`schedule.${index}.dayOfWeek`] = 'Field is required';
      }
    });

    return errors;
  }

  renderTableHead() {
    return (
      <Table.Row>
        {scheduleTableHeaders.map((elem, index) => {
          return <Table.ColHeader key={`${index}-${elem}`}>{elem}</Table.ColHeader>;
        })}
      </Table.Row>
    );
  }

  renderTableBody(schedule, errors, onInputChange, onRemoveLine) {
    return (
      <React.Fragment>
        {schedule.map((elem, index) => {
          return (
            <SingleScheduleRow
              key={index}
              id={index}
              hour={elem.hour}
              minute={elem.minute}
              seconds={elem.second}
              dayOfWeek={elem.dayOfWeek}
              timezone={elem.timeZone}
              {...{ errors, onInputChange, onRemoveLine }}
            />
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    const { onScheduleSubmit, isSubmitting } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{ schedule: this.getCurrentSchedule() }}
        onSubmit={(values) => onScheduleSubmit(values.schedule)}
        validate={(values) => this.validateForm(values)}
        validateOnBlur={false}
        validateOnMount={false}
        render={({ values, handleSubmit, setFieldValue, errors }) => (
          <Form id="scheduleForm">
            <FieldArray
              name="schedule"
              render={({ push, remove }) => (
                <React.Fragment>
                  <div>
                    <Button onClick={() => push(this.getNewSingleSchedule())} color="primary" type="button">
                      Add New
                    </Button>
                  </div>
                  {values.schedule && values.schedule.length > 0 && (
                    <Table>
                      <Table.Header>{this.renderTableHead()}</Table.Header>
                      <Table.Body>{this.renderTableBody(values.schedule, errors, setFieldValue, remove)}</Table.Body>
                    </Table>
                  )}
                </React.Fragment>
              )}
            />
            <Button onClick={handleSubmit} className="my-2" color="success" type="submit" loading={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      />
    );
  }
}

LotteryScheduleForm.propTypes = {
  timezone: PropTypes.string,
  id: PropTypes.string,
  onScheduleSubmit: PropTypes.func,
  currentSchedule: PropTypes.array,
  isSubmitting: PropTypes.bool,
};

export default LotteryScheduleForm;
