import React from 'react';
import PropTypes from 'prop-types';
import LabeledPicker from './common/LabeledPicker';
import { Field } from 'formik';
import LobbyService from '../../../services/LobbyService';

class SectionText extends React.Component {
  renderLines = (index, lineIndex) => {
    return (
      <div className="flexRow col-lg-12 py-1" key={lineIndex}>
        <label>{`Line ${lineIndex + 1}`}</label>
        <Field
          name={`sections.${index}.labels.${lineIndex}.text`}
          className="form-control mr-2"
          placeholder="Insert text"
        />
        <Field
          name={`sections.${index}.labels.${lineIndex}.fontSize`}
          className="form-control width-picker"
          type="number"
        />
        <Field name={`sections.${index}.labels.${lineIndex}.fontWeight`} className="mx-1 selector" component="select">
          <option value={700} label="Bold">
            Bold
          </option>
          <option value={400} label="Regular">
            Regular
          </option>
        </Field>
        <Field name={`sections.${index}.labels.${lineIndex}.color`} component={LabeledPicker} label="Font" />
        <Field name={`sections.${index}.labels.${lineIndex}.borderColor`} component={LabeledPicker} label="Border" />
        <Field
          name={`sections.${index}.labels.${lineIndex}.borderWidth`}
          className="form-control width-picker"
          type="number"
        />
      </div>
    );
  };

  render() {
    const { section, index } = this.props;
    const lineCount = LobbyService.getLineCount(section.type);

    return (
      <div className="border-bottom pb-2">
        <label className="title">Text: </label>
        {[...Array(lineCount)].map((e, i) => this.renderLines(index, i))}
      </div>
    );
  }
}
SectionText.propTypes = {
  lineCount: PropTypes.number,
  section: PropTypes.object,
  index: PropTypes.number,
};

export default SectionText;
