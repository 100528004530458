import React from 'react';
import { Redirect } from 'react-router-dom';

class SearchBets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldRedirect: false,
      drawId: '',
    };
  }

  handleKeyown = (e) => {
    if (e.key === 'Enter') {
      this.setState({ shouldRedirect: true });
    }
  };

  handleChange = (e) => {
    this.setState({ drawId: e.target.value });
  };

  render() {
    const { shouldRedirect } = this.state;

    if (shouldRedirect) {
      return <Redirect push to={`draws/bet/${this.state.drawId}`} />;
    }

    return (
      <React.Fragment>
        <label>Search Bets</label>
        <input
          type="text"
          className="form-control"
          placeholder="Type to search..."
          value={this.state.drawId}
          onKeyDown={this.handleKeyown}
          onChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

export default SearchBets;
