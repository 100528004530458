export const DEFAULT_TICKETS_TYPES = ['default'];
export const BETS_STATUSES = ['canceled', 'canceling', 'lost', 'pending', 'placed', 'rejected', 'voided', 'won'];

export const TICKETS_COLUMNS = [
  {
    header: 'Bet ID',
    type: 'id',
    accessor: 'id',
    link: {
      shouldLink: true,
      target: '/draws/bet',
    },
  },
  {
    header: 'Player ID',
    type: 'id',
    accessor: 'player.id',
    link: {
      shouldLink: true,
      target: '/players',
    },
  },
  {
    header: 'Created At',
    type: 'date',
    accessor: 'createdAt',
  },
  {
    header: 'Confirmed At',
    type: 'date',
    accessor: 'confirmedAt',
  },
  {
    header: 'Draw Date',
    type: 'date',
    accessor: 'gameDraw.originaldrawDatetime',
  },
  {
    header: 'Brand',
    type: 'link',
    accessor: 'brand.name',
    link: {
      shouldLink: true,
      target: '/brands',
      accessor: 'brand.id',
    },
  },
];

export const INSTANT_TICKETS_COLUMNS = [
  {
    header: 'Bet ID',
    type: 'id',
    accessor: 'id',
    link: {
      shouldLink: true,
      target: '/draws/instant-bet',
    },
  },
  {
    header: 'Player ID',
    type: 'id',
    accessor: 'player.id',
    link: {
      shouldLink: true,
      target: '/players',
    },
  },
  {
    header: 'Created At',
    type: 'date',
    accessor: 'createdAt',
  },
  {
    header: 'Confirmed At',
    type: 'date',
    accessor: 'confirmedAt',
  },
  {
    header: 'Draw Date',
    type: 'date',
    accessor: 'gameDraw.originaldrawDatetime',
  },
  {
    header: 'Brand',
    type: 'link',
    accessor: 'brand.name',
    link: {
      shouldLink: true,
      target: '/brands',
      accessor: 'brand.id',
    },
  },
];
