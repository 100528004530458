export const BTO_BET = 'btobet';
export const BTO_BET_BY_TICKET = 'btobet-by-ticket';

export const BTO_BET_COLUMNS = [
  {
    header: 'Name',
    type: 'string',
    accessor: 'name',
  },
  {
    header: 'Client Id',
    type: 'string',
    accessor: 'clientId',
  },
  {
    header: 'User name',
    type: 'string',
    accessor: 'username',
  },
  {
    header: 'URL',
    type: 'string',
    accessor: 'url',
  },
  {
    header: 'Proxy URL',
    type: 'string',
    accessor: 'proxy',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    type: 'actions',
    id: 'id',
    elements: [
      {
        header: 'Actions',
        type: 'edit',
        linkto: `/providers/${BTO_BET}/:id`,
        txt: 'Edit',
      },
    ],
  },
];

export const BTO_BET_FORM_CONFIG = [
  {
    name: 'name',
    title: 'Name',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'password',
    title: 'Password',
    type: 'password',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'clientId',
    title: 'Client Id',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'username',
    title: 'User name',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'url',
    title: 'URL',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'proxy',
    title: 'Proxy url',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
];
