import React from 'react';
import { Page, Grid, TabbedCard, Tab, Text } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import AccessControl from '../../AccessControl';
import PromotionForm from '../../Promotions/PromotionForm';
import CustomModal from '../../Reusable/Modal/CustomModal';
import Spinner from '../../Reusable/Spinner/Spinner';
import TableContainer from '../../Reusable/Table/TableContainer';

import {
  getPromotion,
  postPromotion,
  putPromotion,
  deletePromotion,
  getPromotionDraws,
  getPromotionDrawsDates,
  resetPromotion,
} from '../../../actions/promotions';

import { userRoles } from '../../../utils/roles';
import { PROMOTIONS_DRAWS_COLUMNS } from '../../../utils/tableConstans';
import { displayDate } from '../../../utils/date';

import './PromotionsPage.scss';

class PromotionPage extends React.Component {
  state = {
    isDelModalOpen: false,
  };

  componentDidMount() {
    const { getPromotion, getPromotionDraws, getPromotionDrawsDates, isEditMode } = this.props;

    if (isEditMode) {
      getPromotion(this.getID());
      getPromotionDraws(this.getID(), 1);
      getPromotionDrawsDates(this.getID());
    }
  }

  componentDidUpdate() {
    const { deletedPromotion, history } = this.props;

    if (deletedPromotion.isSuccess) {
      history.push('/promotions');
    }
  }

  componentWillUnmount() {
    const { resetPromotion } = this.props;

    resetPromotion();
  }

  handleDelModalToggle = () => {
    this.setState((prevState) => ({ isDelModalOpen: !prevState.isDelModalOpen }));
  };

  handleSubmitPromotion = (values) => {
    const { isEditMode, postPromotion, putPromotion } = this.props;

    if (isEditMode) {
      putPromotion(this.getID(), values);
      return;
    }

    postPromotion(values);
  };

  handleDeletePromotion = () => {
    const { deletePromotion } = this.props;

    deletePromotion(this.getID());

    this.setState({
      isDelModalOpen: false,
    });
  };

  getID = () => this.props.match.params.id;

  renderDraws() {
    const { promotionDraws, isEditMode, getPromotionDraws } = this.props;

    if (!promotionDraws || !isEditMode) {
      return null;
    }

    return (
      <div className="col-12">
        <h2 className="page-title">Related Draws</h2>
        <TableContainer
          asyncAction={(page, entries) => getPromotionDraws(this.getID(), page, entries)}
          columns={PROMOTIONS_DRAWS_COLUMNS}
          paginHeaders={promotionDraws.headers}
          data={promotionDraws.data}
          loading={promotionDraws.isLoading}
        />
      </div>
    );
  }

  renderPromotionDrawsDates() {
    const { promotionDrawsDates, isEditMode } = this.props;

    if (!promotionDrawsDates.data || !isEditMode) {
      return null;
    }

    return (
      <div className="col-12 d-flex flex-column">
        <h2 className="page-title">Related Draws Dates</h2>
        {promotionDrawsDates.data.map((elem, index) => {
          return (
            <Text
              key={`${index}-${elem}`}
              className={classnames('date col-6 p-3 font-weight-bold', {
                'date-even': index % 2 !== 0,
              })}
            >
              {displayDate(elem)}
            </Text>
          );
        })}
      </div>
    );
  }

  renderForm() {
    const { promotion, isEditMode } = this.props;

    if (promotion.isLoading && isEditMode) {
      return <Spinner />;
    }

    if (!promotion.data && isEditMode) {
      return null;
    }

    return (
      <PromotionForm
        isEditMode={isEditMode}
        data={promotion.data}
        onSubmit={this.handleSubmitPromotion}
        onDelete={this.handleDelModalToggle}
      />
    );
  }

  renderDrawsTabs() {
    return (
      <TabbedCard initialTab={'Form'}>
        <Tab title={'Form'}>
          <Grid.Row>{this.renderForm()}</Grid.Row>
        </Tab>
        <Tab title={'Related Draws'}>
          <Grid.Row>{this.renderDraws()}</Grid.Row>
        </Tab>
        <Tab title={'Draws Dates'}>{this.renderPromotionDrawsDates()}</Tab>
      </TabbedCard>
    );
  }

  renderContent() {
    const { isEditMode } = this.props;

    if (!isEditMode) {
      return <Grid.Row>{this.renderForm()}</Grid.Row>;
    }

    return this.renderDrawsTabs();
  }

  render() {
    const { isEditMode } = this.props;

    return (
      <AccessControl
        roles={[
          userRoles.superAdmin,
          userRoles.admin,
          userRoles.brand,
          userRoles.resultsOperator,
          userRoles.reportsManager,
        ]}
        forbiddenRedirect={'/'}
      >
        <Page>
          <Page.Header>
            <Page.Title>{isEditMode ? 'Edit promotion' : 'Add new promotion'}</Page.Title>
          </Page.Header>
          {this.renderContent()}
          <CustomModal
            isOpen={this.state.isDelModalOpen}
            toggleModal={this.handleDelModalToggle}
            handleSubmit={this.handleDeletePromotion}
            title="Are you sure you want to delete this promotion?"
            classname="react-modal-custom__delete"
            statusColor="red"
            btnConfirmText="Confirm"
            btnConfirmColor="danger"
          />
        </Page>
      </AccessControl>
    );
  }
}

PromotionPage.propTypes = {
  isEditMode: propTypes.bool,
  promotion: propTypes.object,
  deletedPromotion: propTypes.object,
  promotionDraws: propTypes.object,
  promotionDrawsDates: propTypes.object,
  match: propTypes.object,
  history: propTypes.object,

  getPromotionDrawsDates: propTypes.func,
  getPromotion: propTypes.func,
  putPromotion: propTypes.func,
  postPromotion: propTypes.func,
  getPromotionDraws: propTypes.func,
  deletePromotion: propTypes.func,
  resetPromotion: propTypes.func,
};

export default withRouter(
  connect(
    (state) => ({
      promotion: state.promotion,
      promotionDraws: state.promotionDraws,
      promotionDrawsDates: state.promotionDrawsDates,
      deletedPromotion: state.deletedPromotion,
    }),
    {
      getPromotion,
      putPromotion,
      postPromotion,
      deletePromotion,
      getPromotionDraws,
      getPromotionDrawsDates,
      resetPromotion,
    }
  )(PromotionPage)
);
