import { createLogic } from 'redux-logic';

import { EXCHANGE_RATES, NOTIFICATION } from '../actions/types';

export const getExchangeRates = createLogic({
  type: EXCHANGE_RATES.GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const params = {
      'search[brand][uuid]': action.payload,
    };

    return httpClient
      .get('/api/v1/fixed-exchange-rates', {
        params,
      })
      .then((res) => {
        dispatch({
          type: EXCHANGE_RATES.GET.SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXCHANGE_RATES.GET.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export const deleteExchangeRates = createLogic({
  type: EXCHANGE_RATES.DELETE.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .delete(`/api/v1/fixed-exchange-rates/${action.payload.id}`)
      .then((res) => {
        dispatch({
          type: EXCHANGE_RATES.DELETE.SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Exchange rate deleted successfully',
          },
        });
        dispatch({
          type: EXCHANGE_RATES.GET.START,
          payload: action.payload.brandId,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXCHANGE_RATES.DELETE.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

const getDataToSend = (data) => {
  return {
    rate: data.input.value,
    source: data.exchangeCurrency,
    target: 'EUR',
    brand: {
      id: data.brandId,
    },
  };
};

const getExchangeRatesToSend = (httpClient, data, currentState) => {
  const promiseChain = [];

  data.forEach((elem) => {
    const rateFromAPI = currentState.find((rate) => rate.id === elem.id);

    if (rateFromAPI) {
      if (elem.input.value !== rateFromAPI.rate) {
        promiseChain.push(httpClient.put(`/api/v1/fixed-exchange-rates/${elem.id}`, { ...getDataToSend(elem) }));
        return;
      } else {
        return;
      }
    } else {
      promiseChain.push(
        httpClient.post(`/api/v1/fixed-exchange-rates`, {
          ...getDataToSend(elem),
        })
      );
      return;
    }
  });

  return promiseChain;
};

export const putExchangeRates = createLogic({
  type: EXCHANGE_RATES.PUT.START,

  process({ httpClient, action, getState }, dispatch, done) {
    const exchangeRates = getState().exchangeRatesList.data;

    return Promise.all(getExchangeRatesToSend(httpClient, action.payload.data, exchangeRates))
      .then((res) => {
        dispatch({
          type: EXCHANGE_RATES.PUT.SUCCESS,
          payload: res.map((elem) => elem.data),
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Rates updated successfully',
          },
        });
        dispatch({
          type: EXCHANGE_RATES.GET.START,
          payload: action.payload.id,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXCHANGE_RATES.PUT.ERROR,
          payload: err,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

export const postExchangeRates = createLogic({
  type: EXCHANGE_RATES.POST.START,

  process({ httpClient, action }, dispatch, done) {
    return httpClient
      .post(`/api/v1/fixed-exchange-rates`, ...action.payload.data)
      .then((res) => {
        dispatch({
          type: EXCHANGE_RATES.POST.SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXCHANGE_RATES.POST.ERROR,
          payload: err,
        });
      })
      .then(done);
  },
});

export default {
  getExchangeRates,
  deleteExchangeRates,
  putExchangeRates,
  postExchangeRates,
};
