import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Datetime from 'react-datetime';

import { CheckboxField, AsyncSelect } from '../../Reusable/FormFields';

import {
  changeDateFrom,
  changeDateTo,
  changeHideFutureDraws,
  changeHideProccessedDraws,
  changeProvider,
  changeStatus,
  changeType,
  resetFilters,
  getProviderDraws,
} from '../../../actions/providerDraws';
import { getProviderDrawStatuses, getGameResultAgencies } from '../../../actions/systemConsts';
import { getProviderConfig } from '../../../actions/providersActions';

class ProviderDrawsFilter extends React.Component {
  render() {
    const {
      resetFilters,
      changeDateTo,
      changeDateFrom,
      changeHideFutureDraws,
      changeHideProccessedDraws,
      changeStatus,
      changeType,
      changeProvider,
      getProviderConfig,
      getProviderDraws,
      getGameResultAgencies,
      getProviderDrawStatuses,
      gameResultAgencies,
      providerDrawStatuses,
      status,
      dateFrom,
      dateTo,
      type,
      provider,
      providers,
      hideFutureDraws,
      hideProcessedDraws,
    } = this.props;

    return (
      <div className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <label>From</label>
            <Datetime
              value={dateFrom}
              onChange={(value) => changeDateFrom(value)}
              dateFormat={'DD MMMM YYYY'}
              viewMode={'days'}
              closeOnSelect
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group">
            <label>To</label>
            <Datetime
              value={dateTo}
              onChange={(value) => changeDateTo(value)}
              dateFormat={'DD MMMM YYYY'}
              viewMode={'days'}
              closeOnSelect
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group">
            <label>Status</label>
            <AsyncSelect
              onSelectElement={(selected) => changeStatus(selected)}
              selectedValue={status}
              asyncAction={getProviderDrawStatuses}
              data={providerDrawStatuses.data}
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group">
            <label>Provider Type</label>
            <AsyncSelect
              onSelectElement={(selected) => changeType(selected)}
              selectedValue={type}
              asyncAction={getGameResultAgencies}
              data={gameResultAgencies.data}
            />
          </div>
        </div>
        {(type || provider) && (
          <div className="col-lg-3">
            <div className="form-group">
              <label>Provider</label>
              <AsyncSelect
                onSelectElement={(selected) => changeProvider(selected)}
                selectedValue={provider}
                asyncAction={() => getProviderConfig(type ? type.value : null)}
                data={providers.data ? providers.data.list : []}
                loadCondition={type ? type.value : null}
              />
            </div>
          </div>
        )}
        <div className="col-12 d-flex align-items-center">
          <CheckboxField
            name={'check'}
            value={hideFutureDraws}
            onChange={(name, value) => changeHideFutureDraws(value)}
          />
          <span>Hide future draws</span>
        </div>
        <div className="col-12 d-flex align-items-center pb-5">
          <CheckboxField
            name={'check'}
            value={hideProcessedDraws}
            onChange={(name, value) => changeHideProccessedDraws(value)}
          />
          <span>Hide processed draws</span>
        </div>
        <div className="col-lg-3">
          <div className="form-group">
            <button
              className={'firstFilteringButton btn btn-primary'}
              onClick={() => {
                getProviderDraws();
              }}
            >
              Filter
            </button>
            <button
              className={' btn btn-secondary'}
              onClick={() => {
                resetFilters();
                getProviderDraws();
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ProviderDrawsFilter.propTypes = {
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  hideFutureDraws: PropTypes.bool,
  hideProcessedDraws: PropTypes.bool,
  status: PropTypes.string,
  provider: PropTypes.object,
  type: PropTypes.string,

  gameResultAgencies: PropTypes.object,
  providerDrawStatuses: PropTypes.object,
  providers: PropTypes.object,

  changeDateFrom: PropTypes.func,
  changeDateTo: PropTypes.func,
  changeHideFutureDraws: PropTypes.func,
  changeHideProccessedDraws: PropTypes.func,
  changeStatus: PropTypes.func,
  changeType: PropTypes.func,
  changeProvider: PropTypes.func,
  resetFilters: PropTypes.func,
  getProviderDraws: PropTypes.func,
  getGameResultAgencies: PropTypes.func,
  getProviderDrawStatuses: PropTypes.func,
  getProviderConfig: PropTypes.func,
};

export default connect(
  (state) => ({
    dateFrom: state.providerDrawFilters.dateFrom,
    dateTo: state.providerDrawFilters.dateTo,
    status: state.providerDrawFilters.status,
    type: state.providerDrawFilters.type,
    provider: state.providerDrawFilters.provider,
    hideProcessedDraws: state.providerDrawFilters.hideProcessedDraws,
    hideFutureDraws: state.providerDrawFilters.hideFutureDraws,

    providers: state.providersList,
    providerDrawStatuses: state.providerDrawStatuses,
    gameResultAgencies: state.gameResultAgencies,
  }),
  {
    changeDateFrom,
    changeDateTo,
    changeHideFutureDraws,
    changeHideProccessedDraws,
    changeStatus,
    changeType,
    changeProvider,
    resetFilters,
    getProviderDraws,
    getGameResultAgencies,
    getProviderDrawStatuses,
    getProviderConfig,
  }
)(ProviderDrawsFilter);
