import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'tabler-react';
import styled, { css } from 'react-emotion';

import { CheckboxField } from '../Reusable/FormFields';

const Input = styled('input')`
  height: calc(2rem + 2px);
`;

const tdClass = css`
  border: none !important;
`;

class EditTicketCostSingle extends React.Component {
  handleChange = (e) => {
    const { index, changeCurrencyAmount, addCurrencyToChange, currenciesToChange, name } = this.props;

    if (currenciesToChange && !currenciesToChange.includes(name)) {
      addCurrencyToChange(name);
    }

    return changeCurrencyAmount(index, e.target.value);
  };

  render() {
    const { name, value, checked, handleCheckedChange, index, hideCheckbox } = this.props;

    return (
      <Table.Row>
        {!hideCheckbox && (
          <Table.Col className={tdClass}>
            <CheckboxField name={'check'} value={checked} onChange={() => handleCheckedChange(index, name)} />
          </Table.Col>
        )}
        <Table.Col className={tdClass}>
          <Input
            type="text"
            name={'currency'}
            className="form-control"
            disabled
            placeholder={'currency'}
            value={name}
            onChange={this.handleChange}
          />
        </Table.Col>
        <Table.Col className={tdClass}>
          <Input
            type="number"
            min={0}
            step="any"
            name={'amount'}
            className="form-control"
            placeholder={'Input cost'}
            value={value}
            onChange={this.handleChange}
          />
        </Table.Col>
      </Table.Row>
    );
  }
}

EditTicketCostSingle.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  changeCurrencyAmount: PropTypes.func.isRequired,
  addCurrencyToChange: PropTypes.func.isRequired,
  handleCheckedChange: PropTypes.func,
  currenciesToChange: PropTypes.array.isRequired,
  checked: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
};

export default EditTicketCostSingle;
