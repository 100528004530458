import { BET_LINES, BET } from '../actions/types';

const initialState = {
  data: null,
  isPending: false,
  error: null,
  isError: false,
  headers: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BET_LINES.FETCH:
      return {
        ...state,
        isPending: true,
      };
    case BET_LINES.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
      };
    case BET_LINES.SUCCESS:
      return {
        ...state,
        isPending: false,
        data: action.payload.data,
        headers: action.payload.headers,
      };
    case BET.RESET:
      return initialState;
    default:
      return state;
  }
};
