import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Card } from 'tabler-react';
import propTypes from 'prop-types';

import { getFewProviderConfigs } from '../../actions/providersActions';

import providerSelectors from '../../selectors/providers';

class LotteryAgencySelection extends React.Component {
  componentDidMount() {
    const { bettingAgencyName, gameConfigName, getFewProviderConfigs } = this.props;

    getFewProviderConfigs([bettingAgencyName, gameConfigName]);
  }

  componentDidUpdate(prevProps) {
    const { getFewProviderConfigs, bettingAgencyName, gameConfigName } = this.props;
    const isBettingAgencyChange = prevProps.bettingAgencyName !== bettingAgencyName;
    const isGameResultAgencyChange = prevProps.gameConfigName !== gameConfigName;

    if (isBettingAgencyChange || isGameResultAgencyChange) {
      getFewProviderConfigs([bettingAgencyName, gameConfigName]);
    }
  }

  getSelectOptions(list) {
    return list.map((elem) => {
      return {
        label: elem.name,
        value: elem.id,
      };
    });
  }

  getSelected(selected, list) {
    if (!selected && !list) {
      return null;
    }

    if (!selected) {
      return null;
    }

    const selectedData = list.find((elem) => elem.id === selected.id);

    return {
      label: selectedData ? selectedData.name : '',
      value: selected,
    };
  }

  renderBettingAgency() {
    const { agencyList, selectedBettingAgency, onChangeBettingAgency } = this.props;

    if (!agencyList) {
      return null;
    }

    return (
      <div className="my-2">
        <label>Betting agency config</label>
        <Select
          name="agencySelection"
          className="react-select "
          options={this.getSelectOptions(agencyList)}
          onChange={(selected) => onChangeBettingAgency(selected)}
          value={this.getSelected(selectedBettingAgency, agencyList)}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      </div>
    );
  }

  renderGameResultAgency() {
    const { selectedGameConfig, gameConfigList, onChangeGameConfig } = this.props;

    if (!gameConfigList) {
      return null;
    }

    return (
      <div className="my-2">
        <label>Game result agency config</label>
        <Select
          name="gameResultSelection"
          className="react-select "
          options={this.getSelectOptions(gameConfigList)}
          onChange={(selected) => onChangeGameConfig(selected)}
          value={this.getSelected(selectedGameConfig, gameConfigList)}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      </div>
    );
  }

  renderContent() {
    return (
      <React.Fragment>
        {this.renderBettingAgency()}
        {this.renderGameResultAgency()}
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        <Card.Header>Select lottery configurations</Card.Header>
        <Card.Body>{this.renderContent()}</Card.Body>
      </Card>
    );
  }
}

LotteryAgencySelection.propTypes = {
  gameConfigName: propTypes.string.isRequired,
  bettingAgencyName: propTypes.string.isRequired,

  agencyList: propTypes.oneOfType([propTypes.array.isRequired, propTypes.oneOf([null]).isRequired]),
  gameConfigList: propTypes.oneOfType([propTypes.array.isRequired, propTypes.oneOf([null]).isRequired]),
  getFewProviderConfigs: propTypes.func.isRequired,

  selectedBettingAgency: propTypes.object.isRequired,
  selectedGameConfig: propTypes.object.isRequired,
  onChangeGameConfig: propTypes.func.isRequired,
  onChangeBettingAgency: propTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    agencyList: providerSelectors.getLotteryAgencies(state, ownProps.bettingAgencyName),
    gameConfigList: providerSelectors.getLotteryAgencies(state, ownProps.gameConfigName),
  }),
  {
    getFewProviderConfigs,
  }
)(LotteryAgencySelection);
