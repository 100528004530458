import React from 'react';
import PropTypes from 'prop-types';

import './Spinner.scss';

const Spinner = (props) => (
  <div className={`spinner-container text-${props.align} ${props.class}`}>
    <svg
      className="spinner"
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="path" fill="none" strokeWidth="5" strokeLinecap="round" cx="33" cy="33" r="30" />
    </svg>
  </div>
);

Spinner.defaultProps = {
  align: 'center',
  size: 50,
  class: null,
};

Spinner.propTypes = {
  align: PropTypes.string,
  size: PropTypes.number,
  class: PropTypes.string,
};

export default Spinner;
