import React from 'react';

import Reports from './Reports';

import { SALES_REPORTS_COLUMNS_NAMES } from '../../../utils/reports/consts';

class SalesReport extends React.Component {
  render() {
    return <Reports reportsType="sales" tableColumns={SALES_REPORTS_COLUMNS_NAMES} />;
  }
}

export default SalesReport;
