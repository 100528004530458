import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Page, Card } from 'tabler-react';
import { connect } from 'react-redux';

import AccessControl from '../../AccessControl';
import LabeledValue from '../../Reusable/LabeledValue';
import CustomTable from '../../Reusable/Table/CustomTable';
import Dater from '../../Reusable/Dater/Dater';
import Selection from '../../Reusable/Selection/Selection';

import { changeDraw } from '../../../actions/ticketsActions';

import { userRoles } from '../../../utils/roles';
import { setProperMoneyValue } from '../../../utils/money';
import { DRAW_SUMMARY_PRIZES_COLUMNS } from '../../../utils/tableConstans';

class DrawSummary extends React.Component {
  getColumns() {
    const { id } = this.props;
    const arrayBuilderCopy = [...DRAW_SUMMARY_PRIZES_COLUMNS].map((elem) => {
      if (elem.hasUrlFilters) {
        return {
          ...elem,
          linkTo: `${elem.linkTo}?drawId=${id}`,
        };
      }

      return elem;
    });

    return arrayBuilderCopy;
  }

  renderContent() {
    const {
      drawNumber,
      totalValidLinesCount,
      totalValidTicketsCount,
      gameName,
      uniquePlayersCount,
      drawDatetime,
      totalSales,
      drawSelection,
      prizes,
      prizePoolForPercentagePrizes,
      salesSumPrizePoolPercentage,
    } = this.props.drawSummary.data;

    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Col md={4}>
            <Card>
              <Card.Status color="blue" />
              <Card.Body>
                <h3>Draw Info</h3>
                <LabeledValue value={gameName} label="Name" labelWidth="75" />
                <LabeledValue value={<Dater time={drawDatetime} />} label="Draw Date" labelWidth="75" />
                <LabeledValue value={drawNumber} label="Draw Number" labelWidth="75" />
                {drawSelection && (
                  <React.Fragment>
                    <h3>Results</h3>
                    <Selection selections={drawSelection.asArray} />
                  </React.Fragment>
                )}
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col md={4}>
            <Card>
              <Card.Status color="blue" />
              <Card.Body>
                <h3>Financials</h3>
                <LabeledValue value={setProperMoneyValue(totalSales)} label="Total Sales Value" labelWidth="75" />
                <LabeledValue
                  value={setProperMoneyValue(prizePoolForPercentagePrizes)}
                  label="Prize Fund Value"
                  labelWidth="75"
                />
                <LabeledValue value={salesSumPrizePoolPercentage + '%'} label="Sales sum pool" labelWidth="75" />
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col md={4}>
            <Card>
              <Card.Status color="blue" />
              <Card.Body>
                <h3>Stats</h3>
                <LabeledValue value={totalValidTicketsCount} label="Total Tickets Sold" labelWidth="75" />
                <LabeledValue value={uniquePlayersCount} label="Total Unique Players" labelWidth="75" />
                <LabeledValue value={totalValidLinesCount} label="Total Lines Sold" labelWidth="75" />
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={12}>
            <CustomTable data={prizes} columns={this.getColumns()} allowedPagination={false} />
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    );
  }

  render() {
    if (!this.props.drawSummary.data) {
      return null;
    }

    return (
      <AccessControl
        roles={[
          userRoles.superAdmin,
          userRoles.admin,
          userRoles.brand,
          userRoles.resultsOperator,
          userRoles.reportsManager,
        ]}
        forbiddenRedirect={'/'}
      >
        <Page>
          <Page.Header>
            <Page.Title>Draw Summary</Page.Title>
          </Page.Header>
          {this.props.drawSummary.data && this.renderContent()}
        </Page>
      </AccessControl>
    );
  }
}

DrawSummary.propTypes = {
  drawSummary: PropTypes.object,
  changeDraw: PropTypes.func,
  id: PropTypes.string,
};

export default connect(null, {
  changeDraw,
})(DrawSummary);
