import { set } from 'dot-prop-immutable';
import { LOTTERY, TIMEZONES, LOTTERY_PROVIDERS } from '../../actions/types';

import { setLotteryStructure } from '../../utils/lottery';

const initialState = {
  data: {
    gameConfigsisPending: false,
    gameConfigsError: null,
  },
  isPending: false,
  error: null,
  lotteryStruct: {
    data: null,
    isPending: false,
    error: null,
  },
  lotteryStructure: {
    data: null,
    newData: {
      gameResultAgencyConfig: {},
      bettingAgencyConfig: {},
      insuranceAgencyConfig: {},
    },
  },
  timezonesData: {
    timezones: null,
    error: null,
    isPending: false,
  },
  filters: {
    brand: null,
    lottery: null,
    status: null,
  },
  lotteryAgencies: {},
  salesSumPrizePoolPercentage: null,
  baseTicketCost: null,
  currency: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOTTERY.FETCH:
      return {
        ...state,
        isPending: true,
        error: null,
        data: null,
      };
    case LOTTERY.ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
        data: null,
      };
    case LOTTERY.SUCCESS: {
      return {
        ...state,
        isPending: false,
        error: null,
        data: action.payload,
      };
    }
    case LOTTERY.CHANGE_STRUCT_START:
      return {
        ...state,
        lotteryStruct: {
          ...state.lotteryStruct,
          isPending: true,
        },
      };
    case LOTTERY.CHANGE_STRUCT_SUCCESS:
      return {
        ...state,
        lotteryStruct: {
          ...state.lotteryStruct,
          isPending: false,
          data: action.payload,
        },
      };
    case LOTTERY.CHANGE_STRUCT_ERROR:
      return {
        ...state,
        lotteryStruct: {
          ...state.lotteryStruct,
          isPending: false,
          data: null,
          error: action.payload,
        },
      };
    case LOTTERY.CHANGE_STRUCT_RESET:
      return {
        ...state,
        lotteryStruct: initialState.lotteryStruct,
      };
    case LOTTERY.UPDATE: {
      const { path, value } = action.payload;

      return set(state, `data.${path}`, value);
    }
    case LOTTERY.STRUCTURE_GET: {
      const { structure } = action.payload;
      const { newData } = state.lotteryStructure;
      const desiredNewData = setLotteryStructure(newData, structure);

      return {
        ...state,
        lotteryStructure: {
          ...state.lotteryStructure,
          data: action.payload,
          newData: desiredNewData,
        },
      };
    }
    case LOTTERY.STRUCTURE_CHANGE: {
      const { path, value } = action.payload;

      return set(state, `lotteryStructure.newData.${path}`, value);
    }
    case LOTTERY.STRUCTURE_RESET: {
      return set(state, 'lotteryStructure', {
        data: null,
        newData: {
          gameResultAgencyConfig: null,
          bettingAgencyConfig: null,
          insuranceAgencyConfig: null,
        },
      });
    }
    case LOTTERY.CLEAR:
      return initialState;
    case TIMEZONES.FETCH: {
      return {
        ...state,
        timezonesData: {
          ...state.timezonesData,
          isPending: true,
        },
      };
    }
    case TIMEZONES.ERROR: {
      return {
        ...state,
        timezonesData: {
          ...state.timezonesData,
          isPending: false,
          error: action.payload,
        },
      };
    }

    case TIMEZONES.SUCCESS: {
      return {
        ...state,
        timezonesData: {
          timezones: action.payload,
          error: null,
          isPending: false,
        },
      };
    }
    case LOTTERY.MESSAGE_TEMPLATE_START: {
      return {
        ...state,
        data: {
          ...state.data,
          gameConfigsisPending: true,
        },
      };
    }
    case LOTTERY.MESSAGE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          gameConfigsisPending: false,
        },
      };
    }
    case LOTTERY.MESSAGE_TEMPLATE_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          gameConfigsError: action.payload,
          gameConfigsisPending: false,
        },
      };
    }

    case LOTTERY.CHANGE_BRAND_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          brand: action.payload,
        },
      };
    }

    case LOTTERY.CHANGE_LOTTERY_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          lottery: action.payload,
        },
      };
    }

    case LOTTERY.CHANGE_STATUS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload,
        },
      };

    case LOTTERY.RESET_LOTTERY_FILTERS: {
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };
    }

    case LOTTERY_PROVIDERS.GET.SUCCESS: {
      const { data, names } = action.payload;

      const newData = names.reduce((acc, cur, index) => {
        const lowerCaseCur = cur.toLowerCase();
        acc[lowerCaseCur] = data[index];
        return acc;
      }, {});

      return {
        ...state,
        lotteryAgencies: {
          ...state.lotteryAgencies,
          ...newData,
        },
      };
    }
    case LOTTERY.CHANGE_PRIZE_POOL_PERCENTAGE:
      return {
        ...state,
        salesSumPrizePoolPercentage: action.payload,
      };
    case LOTTERY.CHANGE_BASE_TICKET_COST:
      return {
        ...state,
        baseTicketCost: action.payload,
      };
    case LOTTERY.CHANGE_MAIN_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case LOTTERY.RESET_CURRENCY:
      return {
        ...state,
        currency: null,
      };
    default:
      return state;
  }
};
