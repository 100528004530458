import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { close } from '../../../actions/modal';

class ModalConnector extends React.Component {
  onClose = () => {
    this.props.onClose(this.props.name);
  };

  render() {
    const { isOpen, data, children } = this.props;

    if (!isOpen) {
      return null;
    }

    return children({
      isOpen,
      data,
      onClose: this.onClose,
    });
  }
}

ModalConnector.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,

  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProp) => ({
    isOpen: state.modals.isOpen && state.modals.name === ownProp.name,
    data: state.modals.data,
  }),
  {
    onClose: close,
  }
)(ModalConnector);
