import { createLogic } from 'redux-logic';

import { TICKETS } from '../actions/types';

import TicketsService from '../services/TicketsService';

export const getTickets = createLogic({
  type: TICKETS.FETCH,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page, type } = action.payload;
    const state = getState();
    const perPage = state.tickets.entries;
    const filters = state.ticketsFilters[type];

    const params = TicketsService.getWinningsReportFilters(filters);
    const toApiType = TicketsService.getApiType(type);

    return httpClient
      .get(`/api/v1/${toApiType}bets?page=${page}&perPage=${perPage}`, { params })
      .then((res) => {
        dispatch({
          type: TICKETS.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TICKETS.ERROR,
          payload: err.response,
        });
      })
      .then(done);
  },
});

export default {
  getTickets,
};
