import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classnames from 'classnames';

import './FieldStyles.scss';

class DateField extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.setDatePickerWrapperProperStyles();
  }

  setDatePickerWrapperProperStyles() {
    this.ref.current.children[0].classList.add('date-field-wrapper');
    this.ref.current.querySelector('.react-datepicker__input-container').classList.add('date-field-wrapper');
  }

  render() {
    const { label, name, onChange, value, disabled, displayTime, required, showTimeSelect, formGroupClassName } =
      this.props;
    const formatedDate = displayTime ? moment(value).local() : moment(value);

    return (
      <div
        className={classnames('form-group d-flex flex-column justify-content-center w-100', {
          'w-100': displayTime,
          [formGroupClassName]: typeof formGroupClassName === 'string',
        })}
      >
        <label htmlFor={name}>{label}</label>
        <div className="date-picker" ref={this.ref}>
          <DatePicker
            wrapperClassName="foo"
            className="form-control"
            selected={!value || Object.keys(value).length === 0 ? null : formatedDate}
            onChange={(date) => onChange(name, date)}
            disabled={disabled}
            dateFormat={displayTime ? 'MM/D/YYYY HH:mm:ss' : 'MM/D/YYYY'}
            required={required}
            showTimeSelect={showTimeSelect}
            popperClassName="date-field-time"
          />
        </div>
      </div>
    );
  }
}

DateField.propTypes = {
  formGroupClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  displayTime: PropTypes.string,
  required: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
};

export default DateField;
