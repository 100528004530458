import { PAGE_CONFIG } from './types';

export const setPageData = (name, key, pagiNum) => ({
  type: PAGE_CONFIG.SET.DATA,
  payload: {
    name,
    key,
    pagiNum,
  },
});

export const setLocationKey = (name, key) => ({
  type: PAGE_CONFIG.SET.LOCATION_KEY,
  payload: {
    key,
    name,
  },
});

export const setPagiNumber = (name, pagiNum) => ({
  type: PAGE_CONFIG.SET.PAGI_NUMBER,
  payload: {
    name,
    pagiNum,
  },
});

export const resetPageConfig = () => ({
  type: PAGE_CONFIG.RESET,
});
