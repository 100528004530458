import React from 'react';
import PropTypes from 'prop-types';

import './LabeledValue.scss';

class LabeledValue extends React.Component {
  render() {
    const { value, label, labelWidth } = this.props;

    return (
      <div className="labeled-value d-flex">
        <p className={`label w-${labelWidth ? labelWidth : '25'}`}>{label}:</p>
        <p className="value w-75">{value}</p>
      </div>
    );
  }
}

LabeledValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  label: PropTypes.string.isRequired,
  labelWidth: PropTypes.string,
};

export default LabeledValue;
