export const LOTTERY_STATUSES = ['online', 'testing', 'archived'];

export const languagesList = [
  {
    value: 'EN',
    label: 'EN',
  },
  {
    value: 'FR',
    label: 'FR',
  },
  {
    value: 'PR',
    label: 'PR',
  },
  {
    value: 'ES',
    label: 'ES',
  },
  {
    value: 'PT',
    label: 'PT',
  },
  {
    value: 'SW',
    label: 'SW',
  },
];

export const instructionEditableRoles = ['super_admin', 'admin', 'brand_operator'];

export const scheduleTableHeaders = ['day of week', 'hour', 'minute', 'second', 'timezone', 'actions'];

export const MAINTENANCE_MODE = 'MAINTENANCE_MODE';

export const weekDays = [
  {
    label: 'Mon',
    value: 'mon',
  },
  {
    label: 'Tue',
    value: 'tue',
  },
  {
    label: 'Wed',
    value: 'wed',
  },
  {
    label: 'Thu',
    value: 'thu',
  },
  {
    label: 'Fri',
    value: 'fri',
  },
  {
    label: 'Sat',
    value: 'sat',
  },
  {
    label: 'Sun',
    value: 'sun',
  },
];

export const STATUS_CANCELED = 'canceled';
export const STATUS_CANCELLED = 'cancelled';

export const PERCENTAGE_PRIZE_TYPE_KEY = ['percent', 'multiplier'];
export const PERCENTAGE_PRIZE_TYPE_SHOW = ['percent'];
export const NUMBER_PRIZE_TYPE = ['multiplier'];
export const PERCENTAGE_PRIZE_TYPE_RAW_KEY = ['sales_percentage', 'multiplier'];

export const GET = 'GET';
export const PUT = 'PUT';
export const POST = 'POST';
export const DELETE = 'DELETE';

export const PERCENTAGE = 'percentage';
export const PRECISE = 'precise';
export const PROMOTION_TYPES = [PERCENTAGE, PRECISE];

export const TICKET_REPORTS_NAMES = ['tickets', 'instantTickets'];

export const UUID_LENGTH = 36;
