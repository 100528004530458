import { createLogic } from 'redux-logic';

import { PROMOTIONS, PROMOTION, PROMOTION_DRAWS, PROMOTION_DRAWS_DATES, NOTIFICATION } from '../actions/types';
import { preparePromotionDataToSend } from '../utils/promotions/promotions';

export const getPromotions = createLogic({
  type: PROMOTIONS.GET.START,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { page, perPage } = action.payload;
    const defaultPerPage = 25;
    const stateHeaders = getState().promotions.headers;

    const entries = stateHeaders ? stateHeaders['x-per-page'] : defaultPerPage;
    const desiredPerPage = perPage || entries;

    const params = {
      page,
      perPage: desiredPerPage,
    };

    return httpClient
      .get('/api/v1/promotions', { params })
      .then((res) => {
        dispatch({
          type: PROMOTIONS.GET.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROMOTIONS.GET.ERROR,
          payload: e,
        });
      })
      .then(done);
  },
});

export const getPromotion = createLogic({
  type: PROMOTION.GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .get(`/api/v1/promotions/${id}`)
      .then((res) => {
        dispatch({
          type: PROMOTION.GET.SUCCESS,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROMOTION.GET.ERROR,
          payload: e,
        });
      })
      .then(done);
  },
});

export const postPromotion = createLogic({
  type: PROMOTIONS.POST.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const promotionData = preparePromotionDataToSend(action.payload.data);

    return httpClient
      .post('/api/v1/promotions', promotionData)
      .then((res) => {
        dispatch({
          type: PROMOTIONS.POST.SUCCESS,
          payload: {
            data: res.data,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Promotion was successfully added',
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROMOTIONS.POST.ERROR,
          payload: e,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

export const putPromotion = createLogic({
  type: PROMOTION.PUT.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id, data } = action.payload;
    const promotionData = preparePromotionDataToSend(data);

    return httpClient
      .put(`/api/v1/promotions/${id}`, promotionData)
      .then((res) => {
        dispatch({
          type: PROMOTION.PUT.SUCCESS,
          payload: {
            data: res.data,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Promotion was successfully updated',
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROMOTION.PUT.ERROR,
          payload: e,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

export const deletePromotion = createLogic({
  type: PROMOTION.DELETE.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .delete(`/api/v1/promotions/${id}`)
      .then((res) => {
        dispatch({
          type: PROMOTION.DELETE.SUCCESS,
          payload: {
            data: res.data,
          },
        });
        dispatch({
          type: NOTIFICATION.OPEN_SUCCESS,
          payload: {
            message: 'Promotion was successfully deleted',
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROMOTION.DELETE.ERROR,
          payload: e,
        });
        dispatch({
          type: NOTIFICATION.OPEN_ERROR,
          payload: {
            message: 'Something went wrong. Please try again later',
          },
        });
      })
      .then(done);
  },
});

export const getPromotionDraws = createLogic({
  type: PROMOTION_DRAWS.GET.START,
  latest: true,

  process({ httpClient, getState, action }, dispatch, done) {
    const { id, page, perPage } = action.payload;
    const defaultPerPage = 25;
    const stateHeaders = getState().promotions.headers;

    const entries = stateHeaders ? stateHeaders['x-per-page'] : defaultPerPage;
    const desiredPerPage = perPage || entries;

    const params = {
      page,
      perPage: desiredPerPage,
    };

    return httpClient
      .get(`/api/v1/promotions/${id}/draws`, { params })
      .then((res) => {
        dispatch({
          type: PROMOTION_DRAWS.GET.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROMOTION_DRAWS.GET.ERROR,
          payload: e,
        });
      })
      .then(done);
  },
});

export const getPromotionDrawsDates = createLogic({
  type: PROMOTION_DRAWS_DATES.GET.START,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id } = action.payload;

    return httpClient
      .get(`/api/v1/promotions/${id}/draw-dates`)
      .then((res) => {
        dispatch({
          type: PROMOTION_DRAWS_DATES.GET.SUCCESS,
          payload: {
            data: res.data,
            headers: res.headers,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: PROMOTION_DRAWS_DATES.GET.ERROR,
          payload: e,
        });
      })
      .then(done);
  },
});

export default {
  getPromotions,
  getPromotion,
  postPromotion,
  putPromotion,
  deletePromotion,
  getPromotionDraws,
  getPromotionDrawsDates,
};
