import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Icon, Tooltip } from 'tabler-react';
import { css, cx } from 'react-emotion';
import get from 'lodash.get';
import moment from 'moment';
import classnames from 'classnames';

import roles, { userRoles } from '../../../utils/roles';
import days from '../../../utils/days';
import { setProperMoneyValue } from '../../../utils/money';
import getSelections from '../../../utils/selections/selections';
import { getPrizeTypeKey } from '../../../utils/lottery';
import { PERCENTAGE_PRIZE_TYPE_SHOW, NUMBER_PRIZE_TYPE } from '../../../utils/constans';

import IdClipper from './../../Reusable/IdClipper';
import Dater from './../../Reusable/Dater/Dater';
import InputField from '../../Reusable/FormFields/Field';
import AccessControl from '../../AccessControl';
import Countdown from '../Countdown';

const successClass = css`
  font-size: 1.15rem;
  color: #20c997;
`;

const failClass = css`
  font-size: 1.15rem;
  color: #dc3545;
`;

const noMoneyClass = css`
  font-size: 1.15rem;
  color: #dddd00;
`;

const cancelledClass = css`
  font-size: 1.15rem;
  color: #ffa500;
`;

const iconClass = css`
  font-size: 1.25rem;
  margin-right: 0.785rem;
  align-self: center;

  &:hover {
    text-decoration: none;
  }
`;

const disabledClass = css`
  pointer-events: none;
`;

const confirmationAwait = css`
  background: green;
  color: #fff;
`;

class TableElement extends React.Component {
  renderElement(item, col) {
    const currentElement = get(item, col.accessor, '-');
    const secondaryElement = get(item, col.secondAccessor, '-');
    const currency = get(item, col.currencyAccessor, '\u20AC');
    const itemId = item.id;
    const id = get(item, col.id) || itemId;

    switch (col.type) {
      case 'id':
        return this.renderTableIdElement(currentElement, col.link, col.linkToDraw);
      case 'amount':
        return this.renderTableAmountElement(currentElement);
      case 'price':
        return this.renderTablePriceElement(currentElement, currency);
      case 'reportPrice':
        return this.renderTablePriceElement(currentElement, currency);
      case 'boolean':
        return this.renderTableBooleanElement(currentElement);
      case 'days':
        return this.renderDaysElement(currentElement);
      case 'string':
        return this.renderTableStringElement(currentElement);
      case 'date':
        return this.renderTableDateElement(currentElement, secondaryElement);
      case 'date/user':
        return this.renderDateUserElement(currentElement, secondaryElement);
      case 'period':
        return this.renderTablePeriodElement(currentElement);
      case 'event':
        return this.renderTableClickableElement(currentElement, item, col);
      case 'dropdown':
        return this.renderTableDropdownElement(item, col, id);
      case 'drawSelection':
        return this.renderTableDrawSelectionsElement(currentElement);
      case 'link':
        return this.renderLinkElement(item, currentElement, col.link);
      case 'roles':
        return this.renderTableRoleElement(currentElement);
      case 'brands':
        return this.renderTableBrandsElement(currentElement);
      case 'accept':
        return this.renderResultConfirmationOverrideElement(item, col, id);
      case 'actions':
        return this.renderActionsElements(item, col, id);
      case 'versions':
        return this.renderActionsElements(item, col, id);
      case 'stamp':
        return this.renderStampElement(currentElement);
      case 'index':
        return this.props.index + 1;
      case 'possiblePrize':
        return this.renderPossiblePrize(item);
      case 'delete':
        return (
          <AccessControl roles={col.roles}>
            <Button onClick={() => col.handler(id)} color="danger" icon="trash" disabled={item.disabled} />
          </AccessControl>
        );
      // case 'modal':
      //   return (
      //     <Button
      //       onClick={() => col.handler(id)}
      //       color="success"
      //       icon={col.icon}
      //     />
      //   );
      case 'status':
        return (
          <Icon
            name={item.cancelledAt ? col.cancelledIcon : item.rejectedAt ? col.noMoneyIcon : !item.confirmedAt ? col.failIcon : col.successIcon}
            className={item.cancelledAt ? cancelledClass : item.rejectedAt ? noMoneyClass : !item.confirmedAt ? failClass : successClass}
          />
        );
      case 'icons':
        return this.renderTableIconslement(item, col, id);
      case 'tableAsString':
        return this.renderTableAsString(currentElement);
      case 'countdown':
        return <Countdown draw={item} field={col.accessor} />;
      case 'input':
        return this.renderTableInputElement(item, col.accessor);
      case 'actionButton':
        return this.renderActionButtonElement(item, col);
      case 'number':
        return this.renderTableNumberElement(currentElement);
      case 'multiplicationPrice':
        return this.renderMultiplicationElement(currentElement, secondaryElement, currency);
      case 'dropdownButton':
        return this.renderDropdownButton(item, col);
      default:
        return null;
    }
  }

  renderDropdownButton(item, col) {
    return (
      <Button.Dropdown value="Go to draws" className="btn btn-primary">
        {col.linksTo.map((elem, index) => {
          const disabledAccessor = get(item, elem.disabled);
          const data = elem.accessor ? get(item, elem.accessor) : item;

          if (typeof elem.disabled !== 'undefined' && !disabledAccessor) {
            return null;
          }

          return (
            <Dropdown.Item className="dropdown-item" key={`${index}`} onClick={() => elem.action(data)}>
              {elem.label}
            </Dropdown.Item>
          );
        })}
      </Button.Dropdown>
    );
  }

  renderMultiplicationElement(firstValue, secondValue, currency) {
    return this.renderTablePriceElement((firstValue * secondValue).toString(), currency);
  }

  renderPossiblePrize(item) {
    const key = getPrizeTypeKey(item);
    const value = item[key];

    if (!value) {
      return '-';
    }

    if (NUMBER_PRIZE_TYPE.indexOf(key) !== -1) {
      return value;
    }

    if (PERCENTAGE_PRIZE_TYPE_SHOW.indexOf(key) !== -1) {
      return `${value} %`;
    }

    return this.renderTablePriceElement(value);
  }

  renderActionButtonElement(item, col) {
    // In draw summary case disableAccessor is falsy (winningsPlayersCount) and we want to disable button then.
    function getIsDisabled(elem) {
      if (typeof elem === 'number') {
        if (elem === 0) {
          return true;
        }
      }

      return false;
    }

    const isDisabled =
      typeof item[col.disableAccessor] !== 'undefined' ? getIsDisabled(item[col.disableAccessor]) : false;
    const properLink = col.filterAccessors
      ? col.linkTo +
        col.filterAccessors
          .map((elem) => {
            return `&${elem.name}=${item[elem.accessor]}`;
          })
          .join('')
      : col.linkTo;

    return (
      <Link
        className={classnames('btn btn-primary', {
          disabled: isDisabled,
        })}
        role="button"
        to={properLink}
        target={col.target || '_self'}
        onClick={col.action ? () => col.action(item.id, item[col.accessor]) : null}
      >
        {col.text}
      </Link>
    );
  }

  renderTableInputElement(item, name) {
    if (!item[name]) {
      return '-';
    }

    if (item.disable) {
      return item.value;
    }

    return (
      <InputField
        formGroupClassName={'m-0'}
        label={''}
        name={`${item[name].name}${item[name].id}`}
        type={item[name].type}
        onChange={(inputName, value) => item[name].onChange(item.id, value)}
        value={item[name].value}
        readonly={item.disabled}
        min={item[name].min}
        max={item[name].max}
      />
    );
  }

  renderTableAsString(elem) {
    if (typeof elem === 'number') {
      return elem;
    }

    return elem.join(', ');
  }

  renderStampElement(elem) {
    return (
      <div className="d-flex justify-content-center">
        <span
          className={classnames('badge badge-pill', {
            'badge-secondary': elem === 'online',
            'badge-primary': elem === 'testing',
            'badge-warning': elem === 'archived',
          })}
        >
          {elem}
        </span>
      </div>
    );
  }

  renderResultConfirmationOverrideElement(item, col, id) {
    const { wonBetSelection, candidateSelectionData, resultConfirmedAt } = item;
    const { candidateProviderUser } = candidateSelectionData;
    const isNonAllowedRole = col.user.data.roles.filter((role) => col.roles.indexOf(role) !== -1).length === 0;
    const isAllowedStatus = item.status === 'waiting-for-results';
    const disabled =
      wonBetSelection || !candidateSelectionData || resultConfirmedAt || isNonAllowedRole || !isAllowedStatus;
    const isAnyCandidate = candidateProviderUser;
    const isSameUser = candidateProviderUser && candidateProviderUser.id === col.user.data.id;
    const displayIcon = disabled || !isAnyCandidate;
    const handler = isAnyCandidate && isSameUser ? col.secondaryHandler : col.handler;

    return (
      <Button
        key={id}
        className={cx({
          [disabledClass]: disabled,
          [confirmationAwait]: !disabled,
        })}
        outline={isAnyCandidate && isSameUser}
        onClick={() => handler(id)}
        color={disabled ? 'secondary' : 'success'}
        icon={displayIcon && col.icon}
        disabled={disabled}
      >
        {!displayIcon && '1/2'}
      </Button>
    );
  }

  renderDateUserElement(date, user) {
    return (
      <div>
        <p className="m-0">
          {this.renderTableDateElement(date)}
          {user && (
            <React.Fragment>
              <small>
                by {user.firstName} {user.lastName}
              </small>
            </React.Fragment>
          )}
        </p>
      </div>
    );
  }

  renderTableIdElement(id, link) {
    if (link && link.shouldLink && link.target) {
      const linkUrl = `${link.target}/${id}`;
      return <IdClipper id={id} linkUrl={linkUrl} />;
    }

    return <IdClipper id={id} />;
  }

  renderActionsElements(item, col, id) {
    return (
      <div className="d-flex justify-content-around">
        {col.elements.map((elem, index) => {
          if (!elem.linkto) {
            return (
              <AccessControl key={`ac_wrapper_${index}`} roles={[userRoles.superAdmin]}>
                <Button
                  className={'m-1'}
                  key={`${elem.type}-${index}`}
                  onClick={() => elem.handler(id)}
                  color={'danger'}
                >
                  {elem.txt}
                </Button>
              </AccessControl>
            );
          }

          const additionalParam = elem.versionId ? id : '';
          const lobbyId = elem.versionId ? elem.lobbyId : id;
          const link = elem.linkto.replace(':id', lobbyId).replace(':versionId', additionalParam);

          return (
            <Link key={index} to={`${link}`}>
              <Button
                className={'m-1'}
                key={`${elem.type}-${index}`}
                onClick={() => {}}
                color={elem.type === 'edit' ? 'success' : 'primary'}
              >
                {elem.txt}
              </Button>
            </Link>
          );
        })}
      </div>
    );
  }

  renderTableIconslement(item, col, id) {
    const content = col.elements.map((element, index) => {
      const properId = get(item, element.id);

      if (!properId) {
        return null;
      }

      if (element.type === 'accept') {
        return item.candidateSelectionData ? (
          this.renderResultConfirmationOverrideElement(item, element, id)
        ) : (
          <Button key={index} color={'secondary'} icon={element.icon} disabled />
        );
      }

      const link = (
        <Link key={index} to={`${element.linkto}/${properId}`} className={iconClass}>
          <Icon name={element.icon} />
        </Link>
      );

      if (element.tooltip) {
        return (
          <Tooltip key={index} content={element.tooltip} placement="top">
            {link}
          </Tooltip>
        );
      }

      return link;
    });

    return <div className="d-flex">{content}</div>;
  }

  renderTableDropdownElement(item, col, id) {
    const itemObject = [];

    Object.values(col.dropdownElements).forEach((value) => {
      itemObject.push({
        value: value.value,
        icon: value.icon,
        onClick: () => value.handler(id, get(item, value.data)),
      });
    });

    return (
      <Dropdown
        type="button"
        color="primary"
        toggle={false}
        arrow
        triggerContent={col.header}
        itemsObject={itemObject}
      />
    );
  }

  getLink(header, id) {
    switch (header) {
      case 'game Name':
        return `/lottery/lotteries/${id}`;
      case 'brand Name':
      case 'player Brand Name':
        return `/brands/${id}`;
      case 'draw Id':
        return `/draws/bets/${id}`;
      case 'player Name':
        return `/players/${id}`;
      default:
        return '/';
    }
  }

  renderLinkElement(item, elem, link) {
    const id = get(item, link.accessor, '-');

    if (link.shouldLink) {
      return (
        <Link to={`${link.target}/${id}`}>
          <div>{elem}</div>
        </Link>
      );
    }

    return <div>{elem}</div>;
  }

  renderTablePriceElement(elem, currency, divide = true) {
    const money = typeof elem === 'object' ? elem : { amount: elem, currency };
    return setProperMoneyValue(money, divide);
  }

  renderTableAmountElement(elem) {
    if (!elem || elem === '-') {
      return '-';
    }

    if (elem === 'N/A') {
      return elem;
    }

    return (elem / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  renderDaysElement(elem) {
    return elem.map((day) => {
      const result = days.filter((d) => {
        return d.id === day;
      });
      return result[0].name + ', ';
    });
  }

  renderTableDrawSelectionsElement(elem) {
    return getSelections(elem);
  }

  renderTableStringElement(elem) {
    if (!elem) {
      return '-';
    }

    return elem;
  }

  renderTableNumberElement(elem) {
    if (typeof elem !== 'number') {
      return '-';
    }

    return elem;
  }

  renderTableRoleElement(elem) {
    if (!elem) {
      return '-';
    }

    const finalRoles = [];

    elem.map((element) => {
      for (const systemRole of roles) {
        if (element === systemRole.id) {
          finalRoles.push(systemRole.name);
        }
      }
    });

    return finalRoles;
  }

  renderTableBrandsElement(elem) {
    if (!elem || !elem.length) {
      return '-';
    }

    return elem.map((brand) => brand.name).join(', ');
  }

  renderTableDateElement(elem, timezone) {
    if (!elem || Object.keys(elem).length === 0) {
      return '-';
    }

    const dateUser = moment(elem);
    const isValid = dateUser.isValid();

    if (!isValid) {
      return '-';
    }

    return <Dater time={elem} timezone={timezone !== '-' ? timezone : null} />;
  }

  renderTablePeriodElement(elem) {
    if (!elem || Object.keys(elem).length === 0) {
      return '-';
    }

    const period = elem.split(' - ');
    if (!period || period.length === 0) {
      return '-';
    }

    const [date1, date2] = period;
    const isValid = moment(date1).isValid() && moment(date2).isValid();
    if (!isValid) {
      return '-';
    }

    return `${moment(date1).format('DD/MM/YY')} - ${moment(date2).format('DD/MM/YY')}`;
  }

  renderTableBooleanElement(elem) {
    return elem.toString();
  }

  renderTableClickableElement(elem, item, col) {
    const text = col.text ? col.text : elem;
    const allRoles = [
      userRoles.superAdmin,
      userRoles.admin,
      userRoles.brand,
      userRoles.support,
      userRoles.resultsOperator,
    ];
    const roles = col.roles || allRoles;
    const id = get(item, col.id);

    if (!id) {
      return null;
    }

    if (col.icon) {
      return (
        <Link to={`${col.linkto}/${id}`} className={iconClass}>
          <Icon name={col.icon} />
        </Link>
      );
    }

    if (text === '-') {
      return text;
    }

    return (
      <AccessControl roles={roles} forbiddenRender={text}>
        <Link to={`${col.linkto}/${id}`}>{text}</Link>
      </AccessControl>
    );
  }

  render() {
    const { item, col } = this.props;
    return this.renderElement(item, col);
  }
}

TableElement.propTypes = {
  item: PropTypes.object,
  col: PropTypes.object,
  toggleDeleteModal: PropTypes.func,
  index: PropTypes.number,
};

export default TableElement;
