import React from 'react';
import { Page, Grid, Button } from 'tabler-react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import CustomTable from '../../Reusable/Table/CustomTable';
import Spinner from '../../Reusable/Spinner/Spinner';

import withPageConfig from '../../hoc/withPageConfig';

import { getProviderConfig, resetProvidersData } from '../../../actions/providersActions';

import { toUpperCase } from '../../../utils/toUpperCase';
import { getProvidersTableColumns } from '../../../utils/providers/providers';
import { PROVIDERS_PAGE } from '../../../utils/pagesConstans';

import providerSelectors from '../../../selectors/providers';

class ProvidersPage extends React.Component {
  state = {
    perPage: 25,
  };

  componentDidMount() {
    const { getProviderConfig, pagiNumber } = this.props;

    getProviderConfig(this.getProviderName(), pagiNumber);
  }

  componentWillUnmount() {
    const { resetProvidersData } = this.props;

    resetProvidersData();
  }

  getProviderName() {
    const { name } = this.props.match.params;

    return name;
  }

  getTableColumns() {
    return getProvidersTableColumns(this.getProviderName());
  }

  handleAddBtnClick = () => {
    const { push } = this.props.history;

    push(`/providers/${this.getProviderName()}/add`);
  };

  handlePageChange = (page) => {
    const { getProviderConfig, onPaginationPageChange } = this.props;
    const { perPage } = this.state;

    getProviderConfig(this.getProviderName(), page, perPage);
    onPaginationPageChange(page);
  };

  handleChangeEntriesNumber = (page, perPage) => {
    const { getProviderConfig } = this.props;

    getProviderConfig(this.getProviderName(), 1, perPage);

    this.setState({ perPage });
  };

  renderContent() {
    const { providersList, providersListHeaders, isLoading, error } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (error || !providersList) {
      return null;
    }

    return (
      <CustomTable
        onChangeEntries={this.handleChangeEntriesNumber}
        onPageChanged={this.handlePageChange}
        paginHeaders={providersListHeaders}
        data={providersList}
        columns={this.getTableColumns()}
      />
    );
  }

  render() {
    return (
      <Page>
        <Page.Header>
          <div className="w-100 d-flex justify-content-between">
            <Page.Title>{toUpperCase(this.getProviderName())} Configuration</Page.Title>
            <Button color="success" onClick={this.handleAddBtnClick}>
              Add
            </Button>
          </div>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={12}>{this.renderContent()}</Grid.Col>
        </Grid.Row>
      </Page>
    );
  }
}

ProvidersPage.propTypes = {
  getProviderConfig: propTypes.func.isRequired,
  resetProvidersData: propTypes.func.isRequired,
  onPaginationPageChange: propTypes.func.isRequired,
  match: propTypes.shape({
    params: propTypes.shape({
      name: propTypes.string,
    }),
  }).isRequired,
  history: propTypes.shape({
    push: propTypes.func,
  }).isRequired,
  isLoading: propTypes.bool.isRequired,
  error: propTypes.oneOfType([propTypes.object.isRequired, propTypes.oneOf([null]).isRequired]),
  providersList: propTypes.array,
  providersListHeaders: propTypes.object,
  pagiNumber: propTypes.number,
};

export default withRouter(
  connect(
    (state) => ({
      error: state.providersList.error,
      isLoading: state.providersList.isLoading,
      providersList: providerSelectors.getProvidersData(state),
      providersListHeaders: providerSelectors.getProvidersHeaders(state),
    }),
    {
      getProviderConfig,
      resetProvidersData,
    }
  )(withPageConfig(PROVIDERS_PAGE)(ProvidersPage))
);
