export const SINGULAR = 'singular';

export const SINGULAR_COLUMNS = [
  {
    header: 'Name',
    type: 'string',
    accessor: 'name',
  },
  {
    header: 'URL',
    type: 'string',
    accessor: 'url',
  },
  {
    header: 'Proxy URL',
    type: 'string',
    accessor: 'proxy',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    type: 'actions',
    id: 'id',
    elements: [
      {
        header: 'Actions',
        type: 'edit',
        linkto: `/providers/${SINGULAR}/:id`,
        txt: 'Edit',
      },
    ],
  },
];

export const SINGULAR_FORM_CONFIG = [
  {
    name: 'name',
    title: 'Name',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'secret',
    title: 'Secret',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'url',
    title: 'URL',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'proxy',
    title: 'Proxy url',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
];
