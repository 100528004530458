class FormService {
  static prepareEmptyDynamicForm(fields) {
    const finalForm = {};
    fields.map((field) => {
      finalForm[field.name] = field.type === 'bool' ? false : '';
    });
    return finalForm;
  }
}

export default FormService;
