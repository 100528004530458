import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button, Card } from 'tabler-react';
import Select from 'react-select';

import { ErrorHandler } from '../../Reusable/ErrorHandler';
import httpClient from '../../../services/httpClient';
import Spinner from '../../Reusable/Spinner/Spinner';
import { SearchBrand, Field } from '../../Reusable/FormFields';

import { languagesList } from '../../../utils/constans';

class AddLobby extends React.Component {
  constructor() {
    super();

    this.state = {
      submitLobbySuccess: false,
      submitLobbyError: null,
    };
  }

  validateUser(values) {
    const errors = {};
    if (!values.lobbyName) {
      errors.lobbyName = 'Required';
    }

    if (!values.language) {
      errors.language = 'Required';
    }

    return errors;
  }

  submitLobby = (values, actions) => {
    this.setState({
      submitLobbySuccess: false,
      submitLobbyError: null,
    });

    const newLobby = {
      brand: values.brand ? { id: values.brand.id } : null,
      defaultLanguage: values.language.value,
      name: values.lobbyName,
    };

    actions.setSubmitting(true);

    return httpClient
      .post('/api/v1/lobbies', newLobby)
      .then(() => {
        actions.resetForm();
        this.setState({
          submitLobbySuccess: true,
          submitLobbyError: false,
        });
      })
      .catch((err) => {
        actions.setSubmitting(false);
        this.setState({
          submitLobbySuccess: false,
          submitLobbyError: err.response,
        });
      });
  };

  renderForm() {
    const { submitLobbyError, submitLobbySuccess } = this.state;
    const { isPending, error } = this.props;

    if (isPending) {
      return <Spinner class="mt-3 mb-3" />;
    }

    if (error) {
      return (
        <div className="mt-3 mr-3 ml-3">
          <ErrorHandler error={error} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Card.Header>
          <Card.Title>Add new lobby</Card.Title>
        </Card.Header>
        <Card.Body>
          {submitLobbySuccess && <div className="alert alert-success">Lobby added successfully</div>}
          {submitLobbyError && <ErrorHandler error={submitLobbyError} />}
          <Formik
            initialValues={{
              lobbyName: '',
              language: languagesList[0],
            }}
            validate={(values) => this.validateUser(values)}
            onSubmit={(values, actions) => this.submitLobby(values, actions)}
            render={({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, setFieldTouched }) => (
              <form className="add-brand-form" onSubmit={handleSubmit}>
                <Field
                  label="Lobby Name"
                  type="text"
                  name="lobbyName"
                  onChange={setFieldValue}
                  onBlur={() => setFieldTouched('lobbyName', true)}
                  value={values.lobbyName}
                  disabled={false}
                  touched={touched.lobbyName}
                  error={errors.lobbyName}
                />
                <div className="form-group">
                  <label>Language</label>
                  <Select
                    name="language"
                    className="react-select react-select-lang"
                    options={languagesList}
                    onChange={(value) => setFieldValue('language', value)}
                    value={values.language}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                  />
                  {errors.language && <div className="alert alert-danger">Required</div>}
                </div>
                <div className="form-group">
                  <SearchBrand
                    value={values.brand}
                    setFieldValue={setFieldValue}
                    className="react-select"
                    labelText="User's brand"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Button loading={isSubmitting} type="submit" color="primary">
                    Send
                  </Button>
                </div>
              </form>
            )}
          />
        </Card.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card>
        <Card.Status color="blue" />
        {this.renderForm()}
      </Card>
    );
  }
}

AddLobby.propTypes = {
  getBackendUsers: PropTypes.func,
  userId: PropTypes.string,
  getBackendUser: PropTypes.func,
  user: PropTypes.object,
  isPending: PropTypes.bool,
  error: PropTypes.object,
};

export default AddLobby;
