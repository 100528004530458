import { PROVIDERS, SINGLE_PROVIDER, LOTTERY_PROVIDERS } from './types';

export const getFewProviderConfigs = (names) => ({
  type: LOTTERY_PROVIDERS.GET.START,
  payload: names,
});

export const getProviderConfig = (name, page, perPage) => ({
  type: PROVIDERS.GET.START,
  payload: {
    name,
    page,
    perPage,
  },
});

export const getSingleProviderConfig = (name, id) => ({
  type: SINGLE_PROVIDER.GET.START,
  payload: {
    id,
    name,
  },
});

export const postNewConfig = (name, data) => ({
  type: SINGLE_PROVIDER.POST.START,
  payload: {
    name,
    data,
  },
});

export const putProviderConfig = (name, id, data) => ({
  type: SINGLE_PROVIDER.PUT.START,
  payload: {
    name,
    id,
    data,
  },
});

export const patchProviderConfig = (name, id, data) => ({
  type: SINGLE_PROVIDER.PATCH.START,
  payload: {
    name,
    id,
    data,
  },
});

export const deleteProviderConfig = (name, id) => ({
  type: SINGLE_PROVIDER.DELETE.START,
  payload: {
    name,
    id,
  },
});

export const postProviderSchedule = (name, id, schedule) => ({
  type: SINGLE_PROVIDER.SCHEDULE.POST.START,
  payload: {
    id,
    name,
    schedule,
  },
});

export const resetProvidersData = () => ({
  type: PROVIDERS.RESET,
});

export const resetSingleProviderData = () => ({
  type: SINGLE_PROVIDER.RESET,
});
