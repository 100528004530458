import { i18n } from '../../actions/types';

const initialState = {
  languages: [],
  isPending: false,
  isError: false,
  error: null,

  language: {
    formData: {},
    isPending: false,
    isError: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case i18n.CLEAR:
      return initialState;
    case i18n.FETCH:
      return {
        ...state,
        isPending: true,
        isError: false,
        error: null,
      };
    case i18n.SUCCESS:
      return {
        ...state,
        languages: action.payload,
        isPending: false,
      };
    case i18n.ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload,
      };
    case i18n.LANGUAGE.CLEAR:
      return {
        ...state,
        language: initialState.language,
      };
    case i18n.LANGUAGE.PATCH.START:
    case i18n.LANGUAGE.POST.START:
      return {
        ...state,
        language: {
          formData: action.payload.language,
          isPending: true,
          isError: false,
          error: null,
        },
      };
    case i18n.LANGUAGE.PATCH.SUCCESS:
    case i18n.LANGUAGE.POST.SUCCESS:
      return {
        ...state,
        language: {
          ...state.language,
          isPending: false,
          isError: false,
          error: null,
        },
      };
    case i18n.LANGUAGE.PATCH.ERROR:
    case i18n.LANGUAGE.POST.ERROR:
      return {
        ...state,
        language: {
          ...state.language,
          isPending: false,
          isError: true,
          error: action.payload,
        },
      };
    case i18n.LANGUAGE.FETCH.START:
      return {
        ...state,
        language: {
          isPending: true,
          isError: false,
          error: null,
        },
      };
    case i18n.LANGUAGE.FETCH.SUCCESS:
      return {
        ...state,
        language: {
          formData: action.payload,
          isPending: false,
          isError: false,
          error: null,
        },
      };
    case i18n.LANGUAGE.FETCH.ERROR:
      return {
        ...state,
        language: {
          isPending: false,
          isError: true,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
