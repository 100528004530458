import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TabbedCard, Tab } from 'tabler-react';

import LotteryEdit from './LotteryEdit';
import I18n from './LotteryI18n/I18nContainer';
import LotterySchedule from './LotterySchedule';
import Channels from './LotteryMessages/Channels';

class LotteryEditPage extends React.Component {
  getId = () => {
    const { searchLotteryId } = this.props;

    if (!searchLotteryId) {
      return this.props.match && this.props.match.params.id ? this.props.match.params.id : null;
    }

    return searchLotteryId;
  };

  render() {
    return (
      <div className="mt-5">
        <TabbedCard initialTab={'General'} className={'mt-5'}>
          <Tab title={'General'}>
            <LotteryEdit id={this.getId()} />
          </Tab>
          <Tab title={'I18n'}>
            <I18n id={this.getId()} />
          </Tab>
          <Tab title={'Schedule'}>
            <LotterySchedule />
          </Tab>
          <Tab title={'Messages'}>
            <Channels />
          </Tab>
        </TabbedCard>
      </div>
    );
  }
}

LotteryEditPage.propTypes = {
  match: PropTypes.object,
  searchLotteryId: PropTypes.string,
};

export default connect(
  (state) => ({
    searchLotteryId: state.search.lottery.id,
  }),
  null
)(LotteryEditPage);
