import React from 'react';
import { Table, Button } from 'tabler-react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';

import { weekDays } from '../../../utils/constans';

import Field from '../../Reusable/FormFields/Field';

import './SingleScheduleRow.scss';

class SingleScheduleRow extends React.PureComponent {
  getUpperCaseDay = (day) => {
    if (!day) {
      return null;
    }

    return `${day.charAt(0).toUpperCase()}${day.slice(1)}`;
  };

  render() {
    const { id, hour, minute, seconds, timezone, dayOfWeek, onInputChange, onRemoveLine, errors } = this.props;

    return (
      <React.Fragment>
        <Table.Row>
          <Table.Col className="py-1 tb-col">
            <Select
              name="dayOfWeek"
              className={classnames('react-select ', {
                'react-select-is-invalid': errors[`schedule.${id}.dayOfWeek`],
              })}
              options={weekDays}
              onChange={(day) => onInputChange(`schedule.${id}.dayOfWeek`, day.value)}
              value={{ value: dayOfWeek, label: this.getUpperCaseDay(dayOfWeek) }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            {errors[`schedule.${id}.dayOfWeek`] && (
              <div className="text-danger">{errors[`schedule.${id}.dayOfWeek`]}</div>
            )}
          </Table.Col>
          <Table.Col className="py-1 tb-col">
            <Field
              name={`schedule.${id}.hour`}
              touched={`schedule.${id}.hour`}
              type="number"
              min={0}
              max={23}
              onChange={(name, value) => onInputChange(name, value)}
              value={hour}
              formGroupClassName="m-0"
              error={errors[`schedule.${id}.hour`]}
            />
          </Table.Col>
          <Table.Col className="py-1 tb-col">
            <Field
              name={`schedule.${id}.minute`}
              touched={`schedule.${id}.minute`}
              error={errors[`schedule.${id}.minute`]}
              type="number"
              min={0}
              max={59}
              onChange={(name, value) => onInputChange(name, value)}
              value={minute}
              formGroupClassName="m-0"
            />
          </Table.Col>
          <Table.Col className="py-1 tb-col">
            <Field
              name={`schedule.${id}.second`}
              touched={`schedule.${id}.second`}
              error={errors[`schedule.${id}.second`]}
              type="number"
              min={0}
              max={59}
              onChange={(name, value) => onInputChange(name, value)}
              value={seconds}
              formGroupClassName="m-0"
            />
          </Table.Col>
          <Table.Col className="py-1 tb-col">
            <Field name={`schedule.${id}.timeZone`} type="text" readonly value={timezone} formGroupClassName="m-0" />
          </Table.Col>
          <Table.Col className="text-left py-1 tb-col">
            <Button onClick={() => onRemoveLine(id)} icon="trash" color="danger" type="button" />
          </Table.Col>
        </Table.Row>
      </React.Fragment>
    );
  }
}

SingleScheduleRow.propTypes = {
  hour: PropTypes.string,
  minute: PropTypes.string,
  seconds: PropTypes.string,
  dayOfWeek: PropTypes.string,
  timezone: PropTypes.string,
  onInputChange: PropTypes.func,
  onRemoveLine: PropTypes.func,
  id: PropTypes.number,
  errors: PropTypes.object,
};

export default SingleScheduleRow;
