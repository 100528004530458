import React from 'react';
import PropTypes from 'prop-types';

import ColorPicker from '../../../Reusable/ColorPicker/ColorPicker';

class LabeledPicker extends React.Component {
  changeColor = (color) => {
    this.props.form.setFieldValue(this.props.field.name, color);
  };

  render() {
    const { label } = this.props;

    const initialColor = this.props.field ? this.props.field.value : {};

    return (
      <div className="flexRow labelPicker">
        <ColorPicker className="picker" initialColor={initialColor} onColorChange={this.changeColor} />
        <label className="label">{label}</label>
      </div>
    );
  }
}

LabeledPicker.propTypes = {
  color: PropTypes.object,
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default LabeledPicker;
