import { PERCENTAGE_PRIZE_TYPE_RAW_KEY } from '../utils/constans';

export const setLotteryStructure = (data, structure) => {
  const { insuranceAgencyConfig, gameResultAgencyConfig } = data;
  const { insuranceAgencyConfigStructure, gameResultAgencyConfigStructure } = structure;

  if (insuranceAgencyConfigStructure) {
    data['insuranceAgencyConfig'] = getInitialConfigValues(
      insuranceAgencyConfigStructure.configProperties,
      insuranceAgencyConfig
    );
  }

  if (gameResultAgencyConfigStructure) {
    data['gameResultAgencyConfig'] = getInitialConfigValues(
      gameResultAgencyConfigStructure.configProperties,
      gameResultAgencyConfig
    );
  }

  return data;
};

const getInitialConfigValues = (fields, data) => {
  return fields.reduce((acc, field) => {
    const name = field.name;
    const value = data ? data[name] : '';
    return {
      ...acc,
      [name]: value,
    };
  }, {});
};

export const getApiAmount = (amount, prizeType) => {
  if (PERCENTAGE_PRIZE_TYPE_RAW_KEY.indexOf(prizeType) !== -1) {
    return amount;
  }

  return amount * 100;
};

export const getApiPrizeTypeAmount = (moneyObject, prizeType) => {
  switch (prizeType) {
    case 'multiplier':
    case 'sales_percentage':
      return getApiAmount(moneyObject.amount, prizeType).toString();
    default:
      return {
        amount: getApiAmount(moneyObject.amount, prizeType).toString(),
        currency: moneyObject.currency,
      };
  }
};

export const getPrizeTypeKey = (prize) => {
  const type = prize.prizeType || prize.type;

  switch (type) {
    case 'fixed':
      return 'realAmount';
    case 'integration':
      return null;
    case 'disabled':
      return null;
    case 'jackpot':
      return 'jackpotAmount';
    case 'sales_percentage':
      return 'percent';
    case 'multiplier':
      return 'multiplier';
    default:
      return null;
  }
};
